
import React, { useEffect, useState } from "react";
import LoadingWheel from "../../components/Shared/LoadingWheel";

import { withAgency } from "../../data/withAgency";

import useReviewReports from "../../data/useReviewReports";
import ReviewSourcesPie from "../../components/Analytics/Reviews/ReviewSourcesPie";
import ReviewSourcesTable from "../../components/Analytics/Reviews/ReviewSourcesTable";

function _ReviewAnalytics({ agencyReady, agency }) {
  const { reviewStats } = useReviewReports(agency?.id);
  const [isTotal, setIsTotal] = useState(false);

  useEffect(() => {
    if (agencyReady && !reviewStats.isLoading && reviewStats.data) {
      // console.log(reviewStats.data)
      // console.log("valuation stats loaded")
      // console.log(isTotal)
    }
  }, [agency, agencyReady, reviewStats.data, reviewStats.isLoading, isTotal])

  return (
    <div className="px-12">
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Review Analytics</h1>
          <p className="mt-2 text-sm text-gray-700">
            Details about the reviews left, including totals and averages.
          </p>
        </div>
        <div>
          {/* <select onChange={(e) => setIsTotal(e.target.value === "true")}>
            <option value={"false"}>Last 30 Days</option>
            <option value={"true"}>All</option>
          </select> */}
        </div>
      </div>


      <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >

        {reviewStats.isLoading ? (
          <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Total Number of Reviews
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {reviewStats.data.totalReviews}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Change in last 30 days
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {reviewStats.data.change > 0 && "+"} {reviewStats.data.change}%
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Reviews in Last 30 Days
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {reviewStats.data.totalReviews30Days}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Average Rating
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {reviewStats.data.averageRating}
              </dd>
            </div>
          </>
        )}
      </div>

      <div className="py-4 mt-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">
        <div className="shadow rounded-lg bg-white">
          <ReviewSourcesPie stats={reviewStats} />
        </div>

        <div className="col-span-1 xl:col-span-1 2xl:col-span-2 shadow rounded-lg bg-white">
          <ReviewSourcesTable stats={reviewStats} />
        </div>
      </div>
    </div>
  )

}


export const ReviewAnalytics = withAgency(_ReviewAnalytics);