import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  EyeIcon,
  PencilAltIcon,
  UserGroupIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";

import useEmailTemplates from "../../data/useEmailTemplates";

function EmailTemplateRow({ emailTemplate }) {
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => navigate(emailTemplate.id)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {emailTemplate.name}

      </td>

      <td className="py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6">
        {emailTemplate.emailType == 1 ? "Client" : "Agent"}
      </td>
    </tr>
  );
}


export default function EmailTemplates() {
  const navigate = useNavigate();
  const { emailTemplates: allEmailTemplates } = useEmailTemplates();
  const [addOpen, setAddOpen] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState(allEmailTemplates);
  const [serachTerm, setSearchTerm] = useState("");


  useEffect(() => {
    document.title = "EstateTrack Admin | Email Templates";
    if (!allEmailTemplates.isLoading) {
      setEmailTemplates(
        allEmailTemplates.data.filter((emailTemplates) =>
          emailTemplates.name.toLowerCase().includes(serachTerm.toLowerCase())
        )
      );
    }
  }, [allEmailTemplates.isLoading, allEmailTemplates.data, serachTerm]);

  return (
    <>


      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Email Templates</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all email templates
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserGroupIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Template name"
                />
              </div>
              {/* <button
                type="button"
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button> */}
            </div>
          </div>


        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {emailTemplates.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>

          ) : emailTemplates.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Type
                  </th>

                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">

                {emailTemplates.map((emailTemplate) => (
                  <EmailTemplateRow
                    key={emailTemplate.id}
                    emailTemplate={emailTemplate}
                  />
                ))}

              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {serachTerm === "" ? (
                <NoItems
                  itemName="emailtemplate"
                  onNewClick={() => navigate("/blog/designer")}
                />
              ) : (
                <NoItems searching itemName="emailtemplate" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
