import { useEffect, useState } from "react"

import date from 'date-and-time';
import { useForm } from "react-hook-form"

import { ChevronDownIcon, ChevronUpIcon, SaveIcon, StarIcon, TrashIcon } from "@heroicons/react/outline";

import useSocialPosts from "../../data/useSocialPosts";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import classNames from "../../utils/classNames";
import InsertMediaModal from "../MediaManager/InsertMediaModal";

function PostRow({ post, webhook, postType, agencyId, last, next }) {
  const { add, update, reorder, remove } = useSocialPosts(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Social Post Settings")
  const [mediaOpen, setMediaOpen] = useState(false);
  const { register, setValue, handleSubmit, reset } = useForm({
    defaultValues: {
      url: post.url,
      instaUrl: post.instaUrl,
      message: post.message,
    }
  });

  const onSubmit = (data) => {
    if (!data.url && !data.message) {
      return;
    }

    if (post.id) {
      update.mutate({ ...post, ...data, postType: postType, webhook: webhook, socialPostId: post.id }, saveHandlers);
    } else {
      add.mutate({ ...post, ...data, postType: postType, webhook: webhook, socialPostId: post.id }, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          reset({ url: "", message: "", webhook: "" });
        },
        onError: saveHandlers.onError
      });
    }
  }

  const handleReorder = (increase) => {
    reorder.mutate({ postId: post.id, increase: increase }, saveHandlers);
  }

  const handleDelete = () => {
    remove.mutate(post.id, deleteHandlers);
  }

  const handleMediaInsert = (_, __, media) => {
    // console.log(media)
    // console.log(`${CDN_URL}` + `${media.path}`);
    if (postType === 0) {
      setValue("instaUrl", `${CDN_URL}` + `${media.path}`);
    } else {
      setValue("url", `${CDN_URL}` + `${media.path}`);
    }
    setMediaOpen(false);
  }

  return (
    <>
      <InsertMediaModal
        allowVideo={postType == 2}
        allowImage={postType != 2}
        allowURL={false}
        open={mediaOpen}
        setOpen={setMediaOpen}
        onInsert={handleMediaInsert}
      />
      <tr className="[&>td]:px-2 [&>td]:py-1">
        <td>
          {next && (
            <StarIcon className="w-5 h-5 text-yellow-400" />
          )}
        </td>
        <td className="flex justify-center mt-2">
          <span className="mr-1">{post.order + 1}</span>
          {post.id ? (
            <>
              <button
                type="button"
                onClick={() => handleReorder(0)}
                disabled={post.order === 0}
              >
                <ChevronUpIcon className={classNames(post.order === 0 ? "text-gray-400" : "", "w-5 h-5")} />
              </button>
              <button
                type="button"
                onClick={() => handleReorder(1)}
                disabled={last}
              >
                <ChevronDownIcon className={classNames(last ? "text-gray-400" : "", "w-5 h-5")} />
              </button>
            </>
          ) : (
            <span className="w-10"></span>
          )}
        </td>

        <td>
          <textarea {...register("message")}
            type="text"
            className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Message"
          />
        </td>

        <td>
          <div className="flex items-center">
            <div>
              <input {...register("url")}
                type="text"
                className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="URL"
              />
              {postType == 0 && (
                <input {...register("instaUrl")}
                  type="text"
                  className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Instagram Image"
                />
              )}
            </div>

            <button
              type="button"
              onClick={() => setMediaOpen(true)}
              className="w-1/3 ml-2 py-3 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
              Add Media
            </button>
          </div>
        </td>

        <td className="flex justify-center">
          {post.id && (
            post.lastRan ? date.format(new Date(post.lastRan), "DD/MM/YYYY HH:mm") : "Never"
          )}
        </td>

        <td className="space-x-2">
          <button type="button" onClick={handleSubmit(onSubmit)} className="">
            <SaveIcon className="h-5 w-5 text-green-600 hover:text-green-700" />
          </button>
          {post.id && (
            <button type="button" onClick={() => handleDelete()} className="">
              <TrashIcon className="h-5 w-5 text-red-600 hover:text-red-700" />
            </button>
          )}
        </td>
      </tr>
    </>
  )
}


export default function SocialPostSettingsAddEdit({ posts, agencyId, postType }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Social Post Settings")
  const { updateWebhook } = useSocialPosts(agencyId);
  const [webhook, setWebhook] = useState(posts[0]?.webhook)
  const [nextPost, setNextPost] = useState(null)

  useEffect(() => {
    if (posts.length === 0) {
      setWebhook("");
      return;
    }

    if (posts.length > 0) {
      setNextPost(findNextPost(posts));
    }

    setWebhook(posts[0]?.webhook)
  }, [postType, posts])

  const findNextPost = (posts) => {
    const latest = posts.sort((a, b) => new Date(b.lastRan) - new Date(a.lastRan))[0];
    let nextPost;

    if (!latest.lastRan) {
      // Not run yet, run order 0
      nextPost = posts.sort((a, b) => a.order - b.order)[0];
    } else {
      // Add to order and get next one
      nextPost = posts.find(x => x.order === latest.order + 1);

      // If next one is null, start at the beginning
      nextPost = nextPost || posts.sort((a, b) => a.order - b.order)[0];
    }

    return nextPost;
  }

  const handleWebhookUpdate = () => {
    updateWebhook.mutate({ agencyId: agencyId, webhook: webhook, postType: postType }, saveHandlers);
  }

  return (
    <div>

      <div className="flex gap-x-6 pr-10 my-4">
        <div className="grow">
          <label>
            <span className="block text-sm font-medium text-gray-700">Webhook</span>
          </label>
          <input
            onChange={(e) => setWebhook(e.target.value)}
            value={webhook}
            type="text"
            className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Webhook"
          />
        </div>

        <div className="mt-5">
          <button
            className={classNames(posts.length === 0 ? "bg-gray-400" : " bg-etpink-600 hover:bg-etpink-700", "text-center w-full md:w-auto items-center px-3 py-2 mt-px border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500")}
            onClick={() => handleWebhookUpdate()}
            disabled={posts.length === 0}
          >
            Update
          </button>
        </div>
      </div>

      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="text-center text-sm font-semibold text-gray-900"
            >
              Next
            </th>
            <th
              scope="col"
              className="py-3.5 text-center text-sm font-semibold text-gray-900"
            >
              Order
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Message
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              URL
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
            >
              Last Ran
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {posts.map((post, i) => (
            <PostRow
              key={post.id}
              post={post}
              postType={postType}
              webhook={webhook}
              agencyId={agencyId}
              last={posts.length === i + 1}
              next={nextPost?.id === post.id}
            />
          ))}
          <PostRow key={postType + agencyId} post={{ order: posts.length }} webhook={webhook} postType={postType} agencyId={agencyId} last={false} next={false} />
        </tbody>
      </table>
    </div>
  )
}