import { useState } from "react";

export default function BranchAddEditPartial({ handleSave }) {
  const [inviteEmail, setInviteEmail] = useState("");

  const save = () => {
    handleSave({
      email: inviteEmail,
    });
  };

  return (
    <>
      <div className="block">
        <label
          htmlFor="InviteEmail"
          className="block text-sm font-medium text-gray-700"
        >
          Email Address
        </label>
        <div className="mt-1">
          <input
            type="Text"
            name="InviteEmail"
            id="InviteEmail"
            value={inviteEmail}
            onChange={(e) => setInviteEmail(e.target.value)}
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="joe.bloggs@myestateagency.com"
          />
        </div>
      </div>
      <div className="mt-8">
        <button
          type="button"
          onClick={save}
          className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
            Send Invite
        </button>
      </div>
    </>
  );
}
