export default function DefaultRightSide({ register }) {

  const colOptions = [
    { value: 6, label: "Full" },
    { value: 3, label: "Half" },
    { value: 2, label: "One Third" },
    { value: 4, label: "Two Thirds" },
  ]

  return (
    <>
      <div className="w-full flex">
        <input
          type="checkbox"
          {...register("required")}
          className="w-5 h-5 mt-1 mr-2"
        />
        <label className="block mt-1 text-sm font-medium text-gray-700">
          Required
        </label>
      </div>

      <div className="w-full">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Width
        </label>
        <select {...register("cols")} className="w-full shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block sm:text-sm border-gray-300 rounded-md">
          {colOptions.map((option, index) => (
            <option key={index} value={option.value}>{option.label}</option>
          ))}
        </select>
        {/* <input type="number" {...register("cols")} min="1" max="6"
          className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
        /> */}
      </div>

    </>
  )
}