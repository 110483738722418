import { Fragment, useEffect, useState } from "react";
import { ChatAltIcon, TagIcon, UserCircleIcon } from "@heroicons/react/solid";
import date from "date-and-time";
import useActivityLogs from "../../data/useActivityLog";
import useAgencyUsers from "../../data/useAgencyUsers";
import classNames from "../../utils/classNames";
import LoadingWheel from "../Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import Gravatar from "react-gravatar";
import Avatar from "../Shared/Avatar";

function SingleActivityItem({ i, length, activityItem, types, sources }) {
  const {
    user: { agency },
  } = useUserContext();
  const { users } = useAgencyUsers(agency.id);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!users.isLoading) {
      let user = users.data.find((u) => u.userId === activityItem.userId);
      setUser(user);
      setIsLoading(false);
    }
  }, [activityItem, users.isLoading]);

  if (isLoading) {
    return "";
  } else {
    return (
      <li key={i}>
        <div
          className={classNames(
            i !== length - 1 ? "" : "pb-[5rem]",
            "relative pb-8"
          )}
        >
          {i !== length - 1 ? (
            <span
              className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex items-start space-x-3">
            <>
              <div className="relative px-1">
                <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                  {user ? (
                    // <Gravatar
                    //   email={user.user.emailAddress}
                    //   className="h-8 w-8 rounded-full"
                    //   size={128}
                    // />
                    <Avatar classes="w-8 h-8" userId={user.user.id} />
                  ) : (
                    <UserCircleIcon
                      className="h-5 w-5 text-gray-500"
                      aria-hidden="true"
                    />
                  )}
                  
                </div>
              </div>

              <div className="min-w-0 flex-1">
                <div className="text-sm text-gray-500">
                  {user
                    ? `${types[activityItem.actionType]} by ${
                        user.user.firstName
                      } ${user.user.surname} `
                    : `${types[activityItem.actionType]} by ${
                        sources[activityItem.actionSource]
                      } `}

                  <span className="whitespace-nowrap">
                    {date.format(
                      new Date(activityItem.created),
                      "DD/MM/YYYY HH:mm"
                    )}
                  </span>
                </div>
              </div>
            </>
          </div>
        </div>
      </li>
    );
  }
}

export default function ActivityItem() {
  const { recentActivityLogs, actionTypes, actionSources } = useActivityLogs();
  const [types, setTypes] = useState(null);
  const [sources, setSources] = useState(null);

  useEffect(() => {
    if (!actionTypes.isLoading && !actionSources.isLoading) {
      setTypes(Object.keys(actionTypes.data));
      setSources(Object.keys(actionSources.data));
    }
  }, [
    recentActivityLogs.isLoading,
    recentActivityLogs.data,
    actionTypes.isLoading,
    actionSources.isLoading,
  ]);

  if (
    recentActivityLogs.isLoading ||
    actionTypes.isLoading ||
    actionSources.isLoading ||
    types === null ||
    sources === null
  ) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <ul role="list" className="pr-2">
        {recentActivityLogs.data.map((activityItem, i) => (
          <SingleActivityItem
            key={i}
            i={i}
            length={recentActivityLogs.data.length}
            activityItem={activityItem}
            types={types}
            sources={sources}
          />
        ))}
      </ul>
    );
  }
}
