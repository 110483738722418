import { useEffect, useState } from "react";

import useUserContext from "../../../contexts/UserContext";
import { CDN_URL } from "../../../services/config";
import useApiHelper from "../../../services/useApiHelper";
import useNotification from "../../notification/useNotifications";
import LoadingWheel from "../../Shared/LoadingWheel";

export default function UploadPdf({ register, getValues, setValue }) {
  const { user: { agency } } = useUserContext();
  const { post, formData } = useApiHelper();
  const { addNotification } = useNotification();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = () => {
    setIsLoading(true);

    //Create media record first
    var thumbAgency = "00000000-0000-0000-0000-000000000000"
    post(`/media/agency/${thumbAgency}`, {
      name: file.name,
      filename: file.data.name,
      type: file.data.type,
      size: file.data.size,
      altText: file.altText,
    })
      .then((res) => {
        //Afterwards upload the file with the media ID
        let fd = new FormData();
        fd.append("media", file.data, res.data.filename);
        formData(`/media/${res.data.id}`, fd).then((res2) => {
          // console.log(res.data.path);
          setValue("submissionPdfLink", CDN_URL + res.data.path);
          addNotification({ variant: "success", primaryText: "PDF uploaded successfully!" });
          setIsLoading(false);
        });
      })
      .catch((err) => {
        console.error(err);
        addNotification({ variant: "error", primaryText: "Unable to upload PDF. Please try again." });
        setIsLoading(false);
      });
  }

  useEffect(() => {

  }, [getValues("submissionPdfLink")])

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Optionally add a PDF for users to download!
        </label>
      </div>
      {getValues("submissionPdfLink") && (
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Current PDF
          </label>
          <div className="flex justify-between">
            <a
              className="mt-1 text-sm underline text-blue-400"
              href={getValues("submissionPdfLink")}
              target="_blank"
              rel="noreferrer"
            >
              {getValues("submissionPdfLink")}
            </a>

            <button
              className="px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              onClick={() => setValue("submissionPdfLink", "")}
            >
              Clear PDF
            </button>
          </div>
        </div>
      )}

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Upload PDF
        </label>

        {!isLoading ? (
          <div className="mt-1">
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => setFile({ data: e.target.files[0], name: e.target.files[0].name })}
            />

            <button
              type="button"
              onClick={() => handleUpload()}
              className="px-3 mt-2 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
              Upload
            </button>
          </div>
        ) : (
          <div className="mt-1">
            <div className="flex items-center justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          </div>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Download Button Text
        </label>
        <div className="mt-1">
          <input
            {...register("downloadButtonText")}
            type="text"
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Download"
          ></input>
        </div>
      </div>

    </div>
  )

}