
export default function JsonDateTimeInput({ element }) {

  return (
    <div className="">
      <label className="block mb-2 text-sm font-medium text-gray-700">
        {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
      </label>
      <input
        type="datetime-local"
        className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
      />
    </div>
  )
}