import { useEffect, useState } from "react";
import Gravatar from "react-gravatar";
import useUserContext from "../../contexts/UserContext";
import useAgencyUsers from "../../data/useAgencyUsers";
import classNames from "../../utils/classNames";


export default function Avatar({ classes, userId = null }) {
  const { user: { user, agency }, } = useUserContext();
  const { users } = useAgencyUsers(agency.id);
  const [foundUser, setFoundUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //TODO: Make unable to highlight text
  useEffect(() => {
    if (!users.isLoading) {
      var foundUser = null;
      // if (userId === null) {
      //   foundUser = users.data.find((u) => u.userId === user.id);
      // } 
      // else {
      //   foundUser = users.data.find((u) => u.userId === userId);
      // }

      foundUser = users.data.find((u) => u.userId === userId);

      if (foundUser) {
        setFoundUser(foundUser.user);
      } else {
        setFoundUser(null);
      }

      setIsLoading(false);
    }
  }, [users.isLoading, users.data, userId, user.id]);

  var stringToColour = function (str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }


  if (isLoading || !foundUser) {
    return ("")
  } else {
    return (
      <div className={`${classes} aspect-square mx-auto`}>
        <span style={{ background: stringToColour(foundUser.id) }} className="w-full h-full inline-flex items-center justify-center rounded-full">
          <span className="text-lg leading-none text-white">
            {`${foundUser.firstName} ${foundUser.surname}`
              .split(" ")
              .filter((w) => RegExp(/^\p{L}/, "u").test(w))
              .map((w, i) =>
                i < 2
                  ? w.substring(0, 1).toUpperCase()
                  : "")}
          </span>
        </span>
      </div>
    )
  }
}

// <Gravatar
        //   // email={foundUser.user.emailAddress}
        //   email="test@test.com"
        //   className={`rounded-full border`}
        //   size={128}
        //   default="robohash"
        // />