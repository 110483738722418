import { useEffect, useState } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { PencilAltIcon, ViewGridIcon } from "@heroicons/react/outline";

import ElementConfig from "./ElementConfig";
import JsonCheckboxInput from "./Inputs/JsonCheckboxInput";
import JsonDateInput from "./Inputs/JsonDateInput";
import JsonDateTimeInput from "./Inputs/JsonDateTimeInput";
import { JsonHeading } from "./Inputs/JsonHeading";
import { JsonLineBreak } from "./Inputs/JsonLineBreak";
import { JsonNumberInput } from "./Inputs/JsonNumberInput";
import { JsonParagraph } from "./Inputs/JsonParagraph";
import JsonRadioInput from "./Inputs/JsonRadioInput";
import { JsonSelectInput } from "./Inputs/JsonSelectInput";
import { JsonTextareaInput } from "./Inputs/JsonTextareaInput";
import { JsonTextInput } from "./Inputs/JsonTextInput";
import JsonTimeInput from "./Inputs/JsonTimeInput";
import { JsonImageInput } from "./Inputs/JsonImage";
import { JsonSignatureInput } from "./Inputs/JsonSignature";
import { JsonPdfInput } from "./Inputs/JsonPdfInput";

export default function FormElement({ id, element, formJson, setFormJson, refresh, setRefresh, isOpen }) {
  const [open, setOpen] = useState(false);
  const [opened, setOpened] = useState(false);

  const renderElement = (element) => {
    var elementToRender = null;

    switch (element.type) {
      case "input":
        elementToRender = <JsonTextInput element={element} />
        break;
      case "number":
        elementToRender = <JsonNumberInput element={element} />
        break;
      case "select":
        elementToRender = <JsonSelectInput element={element} />
        break;
      case "checkbox":
        elementToRender = <JsonCheckboxInput element={element} />
        break;
      case "radio":
        elementToRender = <JsonRadioInput element={element} />
        break;
      case "textarea":
        elementToRender = <JsonTextareaInput element={element} />
        break;
      case "date":
        elementToRender = <JsonDateInput element={element} />
        break;
      case "time":
        elementToRender = <JsonTimeInput element={element} />
        break;
      case "datetime":
        elementToRender = <JsonDateTimeInput element={element} />
        break;
      case "linebreak":
        elementToRender = <JsonLineBreak element={element} />
        break;
      case "heading":
        elementToRender = <JsonHeading element={element} />
        break;
      case "paragraph":
        elementToRender = <JsonParagraph element={element} />
        break;
      case "image":
        elementToRender = <JsonImageInput element={element} />
        break;
      case "signature":
        elementToRender = <JsonSignatureInput element={element} />
        break;
      case "pdf":
        elementToRender = <JsonPdfInput element={element} />
        break;


      default:
        break;
    }

    return elementToRender;
  }

  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    // transform: CSS.Transform.toString(transform),
    gridColumn: `span ${element.cols} / span ${element.cols}`,
    boxShadow: isDragging ? '-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)' : undefined,
    transition,
  };

  useEffect(() => {
    // console.log(element)
  }, [refresh])

  useEffect(() => {
    //This will trigger when the element is created
    //Don't want it triggering more than once
    //It still triggers more than once somehow... but only the first one?
    if (!opened && isOpen) {
      // console.log("opening")
      // console.log("isOpen", isOpen)
      // console.log("opened", opened)
      setOpen(true);
      setOpened(true);
    }
  }, [isOpen, opened])

  return (
    <>
      <ElementConfig open={open} setOpen={setOpen} element={element} formJson={formJson} setFormJson={setFormJson} refresh={refresh} setRefresh={setRefresh} />
      <div
        ref={setNodeRef} style={style} {...attributes}
        className={`hover:bg-gray-200 hover:cursor-default rounded p-1`}
      >
        <div className="flex h-full gap-x-4" >
          <div className="grow w-full h-full hover:cursor-move" ref={setActivatorNodeRef} {...listeners}>
            <div className="pointer-events-none">
              {renderElement(element)}
            </div>
          </div>
          <button type="button" className="flex flex-col justify-end mb-2 hover:cursor-pointer" onClick={(e) => { e.preventDefault(); setOpen(true); }} >
            <PencilAltIcon className="h-5 w-5 " />
          </button>
        </div>
      </div>
    </>
  )
}