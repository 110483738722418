import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useTranspond from "../../data/useTranspond";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import { ConfirmNavigateAway } from "../Shared/ConfirmNavigateAway";
import TranspondAccountAdd from "./TranspondAccountAdd";
import Flyout from "../../containers/Flyout";
import useAgencies from "../../data/useAgencies";
import ConfirmDelete from "../Shared/ConfirmDelete";
import ConfirmDisconnect from "../Shared/ConfirmDisconnect";

export default function TranspondSettingsPartial({ domain, agency, agencyId }) {
  const { removeTranspondKey } = useAgencies();
  const { transpondAccounts, updateAccount } = useTranspond(agencyId);
  const { saveHandlers } = useDefaultCRUDHandlers("Transpond Settings");
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!kinstaSettings.isLoading && !isDirty) {


  //     reset({

  //     });
  //   }
  // }, [kinstaSettings.isLoading, kinstaSettings.data, isDirty, reset]);

  const handleSave = (data) => {

  };

  const handleDisconnect = () => {
    removeTranspondKey.mutate(agencyId, saveHandlers);
    setConfirmDeleteOpen(false);
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <Flyout open={open} setOpen={setOpen} title="Add Email Marketing Account">
          <TranspondAccountAdd agencyId={agencyId} setOpen={setOpen} />
        </Flyout>
        <ConfirmDisconnect
          itemName="Marketing account"
          open={confirmDeleteOpen}
          setOpen={setConfirmDeleteOpen}
          onConfirm={handleDisconnect}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />


        <div className="divide-y-2 divide-gray-200">
          <div className="-mx-6 px-6">
            <h3 className="text-xl font-medium text-gray-900">
              Email Marketing Settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Email Marketing settings for this agency
            </p>
            {!agency.transpondAgencyKey ? (
              <div className="my-6">

                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-2">
                    <button
                      onClick={() => setOpen(true)}
                      className="h-[40px] leading-5 text-center w-full items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                    >
                      Setup
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="my-6">

                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-2">
                    <button
                      onClick={() => setConfirmDeleteOpen(true)}
                      className="h-[40px] leading-5 text-center w-full items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Debug */}
            {/* <div className="my-6">
              <div className="grid grid-cols-10 gap-4">
                <div className="col-span-10 md:col-span-2">
                  <button
                    onClick={() => updateAccount.mutate(agencyId, saveHandlers)}
                    className="h-[40px] leading-5 text-center w-full items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div> */}
            {/* Debug */}

          </div>
        </div>
        <form onSubmit={handleSubmit(handleSave)}>
        </form>
      </>
    );
  }
}
