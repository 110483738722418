import { useState } from "react";

import { PencilAltIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";

import Flyout from "../../containers/Flyout";
import useAgencyBranches from "../../data/useAgencyBranches";
import useAgencyDepartments from "../../data/useAgencyDepartments";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmDelete from "../Shared/ConfirmDelete";
import LoadingWheel from "../Shared/LoadingWheel";
import DepartmentAddEditPartial from "./DepartmentAddEditPartial";

function DepartmentListRow({ department, onEditClick, remove }) {
  const { deleteHandlers } = useDefaultCRUDHandlers("Department");
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    remove.mutate(department.id, deleteHandlers);
  };

  return (
    <>
      <ConfirmDelete
        itemName="Department"
        open={open}
        setOpen={setOpen}
        onConfirm={handleDelete}
      />
      <li className="pl-4 pr-4 py-3 flex items-center justify-between text-sm">
        <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
          <p className="text-sm font-medium text-etpink-600 md:w-1/5">
            {department.name}
          </p>
        </div>
        <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
          <button
            onClick={() => setOpen(true)}
            className="mx-2 text-gray-400 hover:text-gray-600"
          >
            <span className="sr-only">Remove</span>
            <TrashIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <button
            onClick={() => onEditClick(department)}
            className="mx-2 text-gray-400 hover:text-gray-600"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </li>
    </>
  );
}

export default function DepartmentListPartial({ agencyId }) {
  const { departments, add, update, updateUsers, remove } = useAgencyDepartments(agencyId);
  const { branches } = useAgencyBranches(agencyId);
  const { saveHandlers } = useDefaultCRUDHandlers("Department");
  const [open, setOpen] = useState(false);
  const [editDepartment, setEditDepartment] = useState({});

  const handleEditDepartment = (department) => {
    setEditDepartment(department);
    setOpen(true);
  };

  const handleSave = (department) => {
    console.log(department);
    if (department.id) {
      update.mutate(department, {
        ...saveHandlers,
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
      });
      updateUsers.mutate({ ...department, users: { users: department.users } });
    } else {
      add.mutate(department, {
        ...saveHandlers,
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
      });
    }
  };

  return (
    <>
      <Flyout
        open={open}
        setOpen={setOpen}
        title={editDepartment.name ? editDepartment.name : "Add Department"}
      >
        <DepartmentAddEditPartial
          department={editDepartment}
          agencyId={agencyId}
          onSave={handleSave}
        />
      </Flyout>
      <h3 className="text-xl font-medium text-gray-900">Departments</h3>
      <p className="mt-1 text-sm text-gray-500">View and edit departments</p>
      <button
        type="button"
        onClick={() => {
          setEditDepartment({});
          setOpen(true);
        }}
        className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Add Department
      </button>
      <ul className="border border-gray-200 bg-white rounded-md divide-y divide-gray-200 my-4">
        {departments.isLoading || branches.isLoading ? (
          <li className="py-4 flex justify-center">
            <LoadingWheel width="w-8" height="h-8" />
          </li>
        ) : departments.data.length > 0 ? (
          branches.data.map((branch, i) => (
            <div key={i}>
              <li className="pl-3 pr-4 py-3 flex items-center justify-between bg-gray-100">
                <span dangerouslySetInnerHTML={{ __html: branch.name }}></span>
              </li>
              {departments.data
                .filter((dep) => dep.branchId === branch.id)
                .map((department, i) => (
                  <DepartmentListRow
                    department={department}
                    key={`${branch.id}-${department.id}`}
                    onEditClick={handleEditDepartment}
                    remove={remove}
                  />
                ))}
            </div>
          ))
        ) : (
          //   departments.data
          //     .map((d) => d.branch.name)
          //     .map((b) => (
          //       <>
          //         <li className="pl-3 pr-4 py-3 flex items-center justify-between bg-gray-100">
          //           {b}
          //         </li>
          //         {departments.data
          //           .filter((dep) => dep.branch.name === b)
          //           .map((department, i) => (
          //             <DepartmentListRow
          //               department={department}
          //               key={`${b}-${department.name}`}
          //               onEditClick={handleEditDepartment}
          //             />
          //           ))}
          //       </>
          //     ))
          <li>
            <div className="py-5 text-center text-sm text-gray-400">
              No Departments
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
