import { useEffect, useState } from "react";
import {
  PencilAltIcon,
  PlusIcon,
  RefreshIcon,
  TrashIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAgencyBranches from "../../data/useAgencyBranches";

import Flyout from "../../containers/Flyout";
import BranchAddEditPartial from "./BranchAddEditPartial";
import useUserContext from "../../contexts/UserContext";

function BranchListRow({ branch, onEditClick, onRemoveClick }) {
  const {
    user: { agency },
  } = useUserContext();
  const getAddressString = () => {
    const notAddressParts = ["id", "name", "agencyId", "created", "updated"];
    let addressString = "";
    let entries = Object.entries(branch).filter(
      (el) => !notAddressParts.includes(el[0])
    );

    entries.map((el, i) => {
      if (el[1] !== "" && el[1] !== null) {
        addressString =
          addressString + (i === entries.length - 1 ? el[1] : `${el[1]}, `);
      }
    });

    return addressString;
  };

  const addressString = getAddressString();

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="text-sm font-medium text-etpink-600 md:w-1/3">
          <span dangerouslySetInnerHTML={{ __html: branch.name }}></span>
        </p>
        <span className="flex-1 w-full truncate md:ml-2">{addressString}</span>
        {!agency && <span className="flex-1 w-full truncate text-xs md:ml-2">{branch.id}</span>}
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        {/* <button
          onClick={() => onRemoveClick(branch.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Delete</span>
          <TrashIcon className="h-6 w-6" aria-hidden="true" />
        </button> */}
        {/* <button
          onClick={() => onEditClick(branch)}
          className="mx-2 text-gray-400 hover:text-gray-600"
        >
          <span className="sr-only">Edit</span>
          <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
        </button> */}
      </div>
    </li>
  );
}

export default function BranchListPartial({ agencyId }) {
  const {
    user: { agency },
  } = useUserContext();
  const {
    branches,
    sync,
    add: addBranch,
    update: updateBranch,
    remove: removeBranch,
  } = useAgencyBranches(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Branch");
  const [open, setOpen] = useState(false);
  const [editBranch, setEditBranch] = useState({});

  const handleEditBranch = (branch) => {
    setEditBranch(branch);
    setOpen(true);
  };

  const handleSaveBranch = (branch) => {
    if (branch.id) {
      update(branch);
    } else {
      add(branch);
    }
  };

  const update = (branch) =>
    updateBranch.mutate(branch, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onError: saveHandlers.onError,
    });

  const add = (branch) =>
    addBranch.mutate(
      { ...branch, agencyId: agencyId },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      }
    );

  const remove = (branchId) => removeBranch.mutate(branchId, deleteHandlers);

  return (
    <>
      <h3 className="text-xl font-medium text-gray-900">Branches</h3>
      <p className="mt-1 text-sm text-gray-500">Add branches to an agency</p>
      <Flyout
        open={open}
        setOpen={setOpen}
        title={editBranch.name ? editBranch.name : "Add Branch"}
      >
        <BranchAddEditPartial
          branch={editBranch}
          handleSave={handleSaveBranch}
        />
      </Flyout>
      {!agency && (
        <button
          type="button"
          onClick={() => {
            sync.mutate("", saveHandlers);
          }}
          className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
          <RefreshIcon className="w-4 h-4 mr-2" />
          Sync Branches with WordPress
        </button>
      )}
      <ul
        className={`border border-gray-200 bg-white rounded-md divide-y divide-gray-200 ${agency && "mt-6"
          }`}
      >
        {branches.isLoading ? (
          <li key="branches-loading" className="py-4 flex justify-center">
            <LoadingWheel width="w-8" height="h-8" />
          </li>
        ) : branches.data.length > 0 ? (
          branches.data.map((branch, i) => (
            <BranchListRow
              branch={branch}
              key={i}
              onEditClick={handleEditBranch}
              onRemoveClick={remove}
            />
          ))
        ) : (
          <li>
            <div className="py-5 text-center text-sm text-gray-400">
              No Branches
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
