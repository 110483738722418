

export const JsonPdfInput = ({ element }) => {

    return (
      <div className="">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
        </label>
        <div className="mt-1">

            <input
                type="file"
                accept="application/pdf"
            />

            <button
                type="button"
                className="px-3 mt-2 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
                Upload
            </button>
        </div>
      </div>
    )
  }