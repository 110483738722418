import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyBranches(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const branches = useQuery(
    ["branches", agencyId],
    () => get(`/agency/${agencyId}/branches`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const sync = useMutation(() => post(`/branch/sync/${agencyId}`,), {
    onSuccess: () => {
      queryClient.invalidateQueries(["branches", agencyId]);
    },
  });

  const add = useMutation((branch) => post("/branch", branch), {
    onSuccess: (addedBranch) => {
      queryClient.setQueryData(["branches", agencyId], (currentBranches) => [
        addedBranch.data,
        ...currentBranches,
      ]);
    },
  });

  const update = useMutation((branch) => put(`/branch/${branch.id}`, branch), {
    onSuccess: (updatedBranch) => {
      queryClient.setQueryData(["branches", agencyId], (currrentBranches) =>
        currrentBranches.map((branch) =>
          branch.id === updatedBranch.data.id ? updatedBranch.data : branch
        )
      );
    },
  });

  const remove = useMutation((branchId) => del(`/branch/${agencyId}/${branchId}`), {
    onSuccess: (_, branchId) => {
      queryClient.setQueryData(["branches", agencyId], (currentBranches) =>
        currentBranches.filter((branch) => branch.id !== branchId)
      );
    },
  });

  return {
    branches,
    sync,
    add,
    update,
    remove,
  };
}
