import { useEffect } from 'react'

import EstateTrackStandingStan from "../../assests/EstateTrackStandingStan.jpg";

const posts = [
  //{ id: 1, name: 'widnes', url: 'adamsea.co.uk/marketreports/widnes.pdf', updatedDate: '01/07/2022' },

]

export default function PortalQualifiers() {

  useEffect(() => {
    document.title = "EstateTrack Portal | Portal Qualifiers";
  }, [])

  return (
    <>
      <div className="mx-auto flex justify-center">

        <div className="flex py-8 p-4 text-cente">
          <img src={EstateTrackStandingStan} className="w-28 mr-6" />
          <div className="flex flex-col justify-center">
            <h1 className="text-3xl font-semibold text-gray-900 mb-10">Portal Qualifiers</h1>
            <h1 className="text-2xl ">Please use the link below to book an apppointment</h1>
            <h1 className="text-2xl ">with us to get your portal qualifiers set up!</h1>
            <h1 className="text-2xl mt-4 text-blue-500 underline">
              <a target={"_blank"} href="https://calendly.com/estatetrackportalqualifier">https://calendly.com/estatetrackportalqualifier</a>
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}