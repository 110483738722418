import { Fragment, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import {
  XCircleIcon,
  CheckCircleIcon,
  CheckIcon,
  SelectorIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";

import useAuthHelper from "../../services/useAuthHelper";
import useAuthService from "../../services/useAuthService";
import useApiHelper from "../../services/useApiHelper";
import useUserContext, { UserContextActions } from "../../contexts/UserContext";
import classNames from "../../utils/classNames";
import "./invite.css";
import ResetPasswordModal from "../../components/ResetPassword/ResetPasswordModal";

import EstateTrackLogo from "../../assests/EstateTrackLogo.png";


function InviteError({ error }) {
  return (
    <div className="rounded-md bg-red-50 mt-6 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-800">{error}</p>
        </div>
      </div>
    </div>
  );
}

export default function Invite() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { get, post } = useApiHelper();
  const { post: authPost, get: authGet } = useAuthHelper();
  const { setTokenRefresh } = useAuthService();
  const { dispatch } = useUserContext();
  const [inviteToken, setInviteToken] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("Mr");
  const [firstName, setFirstName] = useState("");
  const [surname, setSurname] = useState("");
  const [password, setPassword] = useState("");
  const [complexityError, setComplexityError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmError, setConfirmError] = useState(false);
  const [wrongPassword, setWrongPassword] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [creating, setCreating] = useState(false);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const complexityRegex = new RegExp(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
  );

  const getInviteDetails = (token) => {
    get(`/userinvite/token?token=${token}`,)
      .then((inviteRes) => {
        setEmail(inviteRes.data.email);
        setAgencyId(inviteRes.data.agencyId);

        if (inviteRes.data.accepted) {
          throw Error();
        }
        checkExists(inviteRes.data.email, token);

      })
      .catch((e) => {
        console.error(e);
        setError("Error retrieving invite details");
        setLoading(false);
      });
  };

  const checkExists = (email, token) => {
    console.log("Email: ", email)
    authGet(`/auth/check-exists?email=${encodeURIComponent(email)}`, token)
      .then((res) => {
        console.log("check exists", res);
        if (res.success === true) {
          setExists(true);
          setLoading(false);
        } else if (res.success === false) {
          setExists(false);
          setLoading(false);
        }

      })
      .catch((err) => console.error(err));
  }

  const getInviteToken = () => {
    let inviteToken = search.get("t");
    if (inviteToken !== null) {
      try {
        let inviteTokenParts = jwt_decode(inviteToken);
        console.log(inviteTokenParts);
        setInviteToken(inviteToken);
        if (inviteTokenParts.inviteId) {
          getInviteDetails(inviteToken);
        } else {
          throw Error();
        }
      } catch {
        setError("Invalid invite token");
        setLoading(false);
      }
    } else {
      setError("Error retrieving invite details");
      setLoading(false);
    }
  };

  const checkPasswordComplexity = (password) => {
    if (!complexityRegex.test(password)) {
      setComplexityError(true);
    } else {
      setComplexityError(false);
    }
  };

  const checkPasswordsMatch = (confirmPassword) => {
    if (confirmPassword !== password) {
      setConfirmError(true);
    } else {
      setConfirmError(false);
    }
  };

  const handleRegister = () => {
    authPost("/auth/register", {
      email: email,
      password: password,
      confirmPassword: confirmPassword,
      acceptTerms: acceptTerms,
    })
      .then((registerRes) => {
        console.log("register", registerRes);
        dispatch({ type: UserContextActions.setIdentity, payload: registerRes })
        handleCreateUser();
      })
      .catch((err) => {
        console.error(err);
        setCreating(false);
      });
  };

  const handleAuthenticate = () => {
    authPost("/auth/authenticate", {
      email: email,
      password: password
    })
      .then((authRes) => {
        console.log("authenticate", authRes);
        dispatch({ type: UserContextActions.setIdentity, payload: authRes })
        handleCreateUser();
      })
      .catch((err) => {
        console.error(err);
        if (err.Message === "Email or password is incorrect") {
          setWrongPassword(true);
        }
        setCreating(false);
      });
  };

  const handleCreateUser = () => {
    post("/user", { title: title, firstName: firstName, surname: surname, emailAddress: email, defaultAgency: agencyId })
      .then((createRes) => {
        console.log("user", createRes);
        dispatch({ type: UserContextActions.setUser, payload: createRes.data })
        setTokenRefresh(createRes.data);
        handleAcceptInvite();
      })
      .catch((err) => {
        console.error(err);
        setError(err.Message);
        setCreating(false);
      });
  };

  const handleAcceptInvite = () => {
    post(`/userinvite/accept?t=${inviteToken}`)
      .then((acceptRes) => {
        console.log(acceptRes.data);
        setCreating(false);
        navigate('/signin');
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCreating(true);

    //Logout
    dispatch({ type: UserContextActions.removeIdentityUserAgency });

    if (exists) {
      handleAuthenticate();
    } else {
      if (complexityRegex.test(password)) {
        if (confirmPassword === password) {
          handleRegister();
        } else {
          setConfirmError(true);
        }
      } else {
        setComplexityError(true);
      }
    }
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Invite";
    setLoading(true);
    localStorage.clear();
    getInviteToken();
  }, []);

  return (
    <>
    <ResetPasswordModal open={showForgotPassword} setOpen={setShowForgotPassword} />
      <div className="w-screen block md:flex">
        <div className="signinImage flex-1"></div>
        <div className="w-full h-screen sm:2-3/4 md:w-2/4 lg:w-2/5 2xl:w-1/4 py-4 flex justify-center bg-white overflow-y-auto">
          <div className="flex w-11/12 lg:w-10/12">
            <div className="flex flex-col justify-center my-8 w-full">
              <img
                className="mb-10 h-10 mx-auto w-auto"
                src={EstateTrackLogo}
                alt="Workflow"
              />
              {loading ? (
                <div className="flex justify-center">
                  <LoadingWheel width="w-32" height="w-32" />
                </div>
              ) : (
                <>
                  <h2 className="text-3xl font-bold text-gray-900">{exists ? "Join Agency" : "Register"}</h2>
                  {exists && <h2 className="text-sm mt-2 text-gray-900">You already have an account, please login</h2>}
                  {error !== "" ? (
                    <InviteError error={error} />
                  ) : (
                    <div className="mt-6">
                      <form onSubmit={handleSubmit} className="space-y-6">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              disabled={true}
                              autoComplete="email"
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-10 gap-2">
                          <div className="col-span-10 sm:col-span-2">
                            <Listbox
                              value={title}
                              onChange={setTitle}
                              disabled={creating}
                            >
                              {({ open }) => (
                                <>
                                  <Listbox.Label className="block text-sm font-medium text-gray-700">
                                    Title
                                  </Listbox.Label>
                                  <div className="mt-1 relative h-[43px] sm:h-[40px]">
                                    <Listbox.Button
                                      className={`h-full bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-5 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"
                                        }`}
                                    >
                                      <span className="block truncate">
                                        {title}
                                      </span>
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none">
                                        <SelectorIcon
                                          className="h-5 w-5 text-gray-400"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {["Mr", "Mrs", "Miss", "Dr"].map(
                                          (title) => (
                                            <Listbox.Option
                                              key={title}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "text-white bg-etpink-600"
                                                    : "text-gray-900",
                                                  "cursor-default select-none relative py-2 pl-3"
                                                )
                                              }
                                              value={title}
                                            >
                                              {({ selected, active }) => (
                                                <>
                                                  <span
                                                    className={classNames(
                                                      selected
                                                        ? "font-semibold"
                                                        : "font-normal",
                                                      "block truncate"
                                                    )}
                                                  >
                                                    {title}
                                                  </span>
                                                </>
                                              )}
                                            </Listbox.Option>
                                          )
                                        )}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                          <div className="mt-4 col-span-5 sm:mt-0 sm:col-span-4">
                            <label
                              htmlFor="first name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              First Name
                            </label>
                            <div className="mt-1">
                              <input
                                id="FirstName"
                                name="FirstName"
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                required
                                disabled={creating}
                                className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"
                                  }`}
                              />
                            </div>
                          </div>
                          <div className="mt-4 col-span-5 sm:mt-0 sm:col-span-4">
                            <label
                              htmlFor="last name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Surname
                            </label>
                            <div className="mt-1">
                              <input
                                id="Surname"
                                name="Surname"
                                autoComplete="family-name"
                                type="text"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                required
                                disabled={creating}
                                className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"
                                  }`}
                              />
                            </div>
                          </div>
                        </div>
                        {!exists ? (
                          <>
                            <div>
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                              >
                                New Password
                              </label>
                              <div className="mt-1">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  onBlur={(e) => {
                                    checkPasswordComplexity(e.target.value);
                                    e.target.value &&
                                      confirmPassword !== "" &&
                                      checkPasswordsMatch(confirmPassword);
                                  }}
                                  disabled={creating}
                                  required
                                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"
                                    } ${complexityError &&
                                    "text-red-900 border-red-300 placeholder-red-300"
                                    }`}
                                />
                                {complexityError && (
                                  <p className="mt-2 text-xs text-red-600">
                                    Password be at least 8 characters and contain 1
                                    uppercase letter and 1 number
                                  </p>
                                )}
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Confirm Password
                              </label>
                              <div className="mt-1">
                                <input
                                  id="confirmPassword"
                                  name="confirmPassword"
                                  type="password"
                                  value={confirmPassword}
                                  onChange={(e) => setConfirmPassword(e.target.value)}
                                  onBlur={(e) => checkPasswordsMatch(e.target.value)}
                                  disabled={creating}
                                  required
                                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"
                                    } ${confirmError &&
                                    "text-red-900 border-red-300 placeholder-red-300"
                                    }`}
                                />
                                {confirmError && (
                                  <p className="mt-2 text-sm text-red-600">
                                    Passwords do not match
                                  </p>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <label
                                htmlFor="password"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Password
                              </label>
                              <div className="mt-1">
                                <input
                                  id="password"
                                  name="password"
                                  type="password"
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  disabled={creating}
                                  required
                                  className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${creating && "bg-gray-100"} `}
                                />
                                {wrongPassword && (
                                  <p className="mt-2 text-sm text-red-600">
                                    Incorrect password
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="text-sm">
                              <button
                                type="button"
                                onClick={() => setShowForgotPassword(true)}
                                className="font-medium text-etpink-600 hover:text-etpink-500"
                              >
                                Forgot your password?
                              </button>
                            </div>
                          </>
                        )}
                        {/* <div className="flex items-center">
                        <input
                          id="acceptTerms"
                          name="acceptTerms"
                          type="checkbox"
                          disabled={creating}
                          className={`h-4 w-4 text-etpink-600 focus:ring-etpink-500 border-gray-300 rounded ${creating && "bg-gray-100"
                            }`}
                          onChange={(e) => setAcceptTerms(e.target.checked)}
                        />
                        <label
                          htmlFor="acceptTerms"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Accept Terms & Conditions
                        </label>
                      </div> */}
                        <div>
                          <button
                            disabled={
                              creating ||
                              confirmError ||
                              complexityError
                            }
                            type="submit"
                            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 ${Boolean(
                              creating ||
                              confirmError ||
                              complexityError
                            ) &&
                              "!text-gray-300 bg-gray-100 hover:pointer-events-none hover:bg-gray-100"
                              }`}
                          >
                            {creating ? (
                              <LoadingWheel width="w-6" height="h-6" />
                            ) : (
                              exists ? "Join Agency" : "Register"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
