import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useReviewReports(agencyId) {
  const staleTime = 600000;
  const queryClient = useQueryClient();

  const { get, post, put, del } = useApiHelper();

  const reviewStats = useQuery(
    ["reviewStats", agencyId],
    () => get(`/Reports/${agencyId}/reviewstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  return {
    reviewStats,
  };
}
