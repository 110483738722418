import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import useMortgages from "../../data/useMortgages";
import { mortgageStatusEnum } from "../../constants/enums";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAllAgencyProperties from "../../data/useAllAgencyProperties";
import Avatar from './../../components/Shared/Avatar';


function MortgagesRow({ mortgage, onDeleteClick }) {
  const navigate = useNavigate();
  const [propertyTitle, setPropertyTitle] = useState(null);
  const [propertyDepartment, setPropertyDepartment] = useState(null);
  const [formattedDepartment, setFormattedDepartment] = useState(null);

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(mortgage.id);
  };

  const StatusBubble = (label, status) => {
    var className = "rounded-full px-3 py-1 text-sm font-semibold ";
    switch (status) {
      case 0: //New
        className += "bg-yellow-100 text-yellow-800";
        break;
      case 1: //Contact made
        className += "bg-blue-100 text-blue-600";
        break;
      case 2: //Confirmed
        className += "bg-blue-100 text-blue-600";
        break;
      case 3: //Completed
        className += "bg-green-100 text-green-800";
        break;
      default:
        className += "bg-yellow-100 text-yellow-800";
        break;
    }

    return <span className={className}>{label}</span>
  };

  return (
    <tr onClick={() => navigate(`mortgage/${mortgage.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell">
        {mortgage.userId &&
          <Avatar classes="w-10 h-10" userId={mortgage.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {mortgage.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Status</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span
              className={`prose-sm rounded-full py-1 whitespace-nowrap font-bold`}
            >
              {StatusBubble(mortgageStatusEnum[mortgage.status], mortgage.status)}
            </span>
          </dd>
          <dt className="sr-only sm:hidden">Created</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {date.format(new Date(mortgage.created), "DD/MM/YYYY HH:mm")}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full py-1 whitespace-nowrap font-bold`}
        >
          {StatusBubble(mortgageStatusEnum[mortgage.status], mortgage.status)}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.emailAddress}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.telephoneNumber}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(mortgage.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {mortgage.followUpDate ? date.format(new Date(mortgage.followUpDate), "DD/MM/YYYY") : "None"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            className="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">View</span>
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          <button
            onClick={handleDeleteClick}
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Mortgages() {
  const navigate = useNavigate();
  const { mortgages: allMortgages, remove } = useMortgages();
  const { properties } = useAllAgencyProperties();
  const { deleteHandlers } = useDefaultCRUDHandlers("Mortgage");
  const [mortgages, setMortgages] = useState(allMortgages);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortTerm, setSortTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [isQualified, setIsQualified] = useState(true);

  const deleteMortgage = (mortgageId) => {
    setDeleteId(mortgageId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Mortgages";
    if (!allMortgages.isLoading) {

      var filtered = allMortgages.data.filter((mortgage) =>
        mortgage.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setMortgages(filtered);
    }
  }, [allMortgages.isLoading, allMortgages.data, properties.data, properties.isLoading, searchTerm, sortTerm, sortDirection]);

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  return (
    <>
      <ConfirmDelete
        itemName="Mortgage"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Mortgages</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all mortgage leads that have come in with a registration.
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name"
                />
              </div>
            </div>
          </div>

        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {mortgages.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : mortgages.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {/* Avatar */}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                  >
                    <span className="flex">Name</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Status</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Email Address</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Telephone Number</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Created</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Follow Up</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {mortgages.map((mortgage) => (
                  <MortgagesRow
                    key={mortgage.id}
                    properties={properties}
                    mortgage={mortgage}
                    onDeleteClick={deleteMortgage}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="mortgage"
                // onNewClick={() => navigate("")}
                />
              ) : (
                <NoItems searching itemName="mortgage" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
