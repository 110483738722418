import { useState } from "react";

import { useForm } from "react-hook-form";

import { Switch } from "@headlessui/react";

import classNames from "../../utils/classNames";
import useNotification from "../notification/useNotifications";
import EmailChips from "../Shared/EmailChips";
import Modal from "../Shared/Modal";


export default function FormOptionsModal({ open, setOpen, formJson, setFormJson, refresh, setRefresh, isLandingPage }) {
  const { addNotification } = useNotification();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: {
      captcha: formJson.options.captcha,
      hideMenu: formJson.options.hideMenu,
    }
  });
  const [notificationEmails, setNotificationEmails] = useState(formJson.options.notificationEmails);

  const onSubmit = (data) => {
    var newFormJson = formJson;

    var updatedOptions = {
      ...newFormJson.options,
      ...data,
      notificationEmails: notificationEmails,
    }

    newFormJson.options = updatedOptions;

    setFormJson(newFormJson);

    // addNotification({
    //   variant: "success",
    //   primaryText: "Updated form options",
    // })

    setOpen(false);
    setRefresh(!refresh);
  }

  // useEffect(() => {
  //   console.log(notificationEmails)
  // }, [notificationEmails])

  const captcha = watch("captcha");
  const hideMenu = watch("hideMenu");

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-6xl"}>
      <h1 className="text-lg font-medium">Form Options</h1>
      <hr className="mb-4" />

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="grid grid-cols-2 divide-x-2">

          <div className="w-full grid-span-1 px-4 space-y-4">
            {/* Left side */}
            {/* <Switch.Group as="div" className="flex justify-between">
              <span className="mr-10">
                <Switch.Label as="span" className="text-md text-gray-900" passive>
                  Captcha
                </Switch.Label>
              </span>
              <Switch
                {...register("captcha")}
                checked={captcha}
                onChange={(e) => setValue("captcha", e)}
                className={classNames(
                  captcha ? 'bg-etpink-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    captcha ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group> */}

            {isLandingPage && (
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Hide Menu
                  </Switch.Label>
                </span>
                <Switch
                  {...register("hideMenu")}
                  checked={hideMenu}
                  onChange={(e) => setValue("hideMenu", e)}
                  className={classNames(
                    hideMenu ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      hideMenu ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            )}
          </div>

          <div className="col-span-1 space-y-4 px-4">
            {/* Right side */}
            <h1>Notification Emails:</h1>
            <EmailChips additionalEmails={notificationEmails} setAdditionalEmails={setNotificationEmails} />
          </div>

        </div>

        <div className="mt-8 px-4 w-full flex justify-end">
          <button type="submit"
            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
          >
            Update
          </button>
        </div>
      </form>
    </Modal>
  )
}