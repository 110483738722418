
import React, { useEffect, useState } from "react";
import LoadingWheel from "../../components/Shared/LoadingWheel";

import { withAgency } from "../../data/withAgency";

import RequestsByPortalTable from "../../components/Analytics/Portal/RequestsByPortalTable";
import usePortalReports from "../../data/usePortalReports";

function _PortalAnalytics({ agencyReady, agency }) {
  const { portalStats } = usePortalReports(agency?.id);
  const [isTotal, setIsTotal] = useState(false);

  useEffect(() => {
    if (agencyReady && !portalStats.isLoading && portalStats.data) {
      // console.log(portalStats.data)
      // console.log("valuation stats loaded")
      // console.log(isTotal)
    }
  }, [agency, agencyReady, portalStats.data, portalStats.isLoading, isTotal])

  return (
    <div className="px-12">
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Portal Analytics</h1>
          <p className="mt-2 text-sm text-gray-700">
            Details about the valuations, including their status', sources and more.
          </p>
        </div>
        <div>
          <select onChange={(e) => setIsTotal(e.target.value === "true")}>
            <option value={"false"}>Last 30 Days</option>
            <option value={"true"}>All</option>
          </select>
        </div>
      </div>


      <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >
        {portalStats.isLoading ? (
          <div className="py-2 col-span-1 lg:col-span-3 mx-auto">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Sales Viewing Requests" : "Sales Viewing Requests "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.totalViewingRequestsSales : portalStats.data.totalViewingRequestsSalesMonth}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Lettings Viewing Requests" : "Lettings Viewing Requests "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.totalViewingRequestsLettings : portalStats.data.totalViewingRequestsLettingsMonth}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Viewing Requests" : "Viewing Requests "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.totalViewingRequests : portalStats.data.totalViewingRequestsMonth}
              </dd>
            </div>
          </>
        )}
      </div>

      <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >
        {portalStats.isLoading ? (
          <div className="py-2 col-span-1 lg:col-span-3 mx-auto">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <>
            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Qualified Sales Enquiries" : "Qualified Sales Enquiries "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.totalQualifiedSales : portalStats.data.totalQualifiedSalesMonth}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Qualified Lettings Enquiries" : "Qualified Lettings Enquiries "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.totalQualifiedLettings : portalStats.data.totalQualifiedLettingsMonth}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Percentage Of Qualified Enquiries" : "Percentage Of Qualified Enquiries "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? portalStats.data.percentageOfQualifiedForms : portalStats.data.percentageOfQualifiedFormsMonth}%
              </dd>
            </div>
          </>
        )}
      </div>

      <div className=" mt-5 w-full shadow rounded-lg bg-white">
        <RequestsByPortalTable stats={portalStats} isTotal={isTotal} />
      </div>

      <div className="py-4 mt-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">

      </div>


    </div>
  )

}


export const PortalAnalytics = withAgency(_PortalAnalytics);