import { useEffect, useState } from "react";

import date from 'date-and-time';
import { useNavigate } from "react-router-dom";

import { ChevronDownIcon, ChevronUpIcon, EyeIcon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

import Avatar from "../../components/Shared/Avatar";
import LeadTypeListBox from "../../components/Shared/LeadTypeListBox";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import { formLeadStatusEnum, mortgageStatusEnum, valuationStatusEnum, viewingStatusEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useUserLeads from './../../data/useUserLeads';

function LeadsRow({ lead }) {
  const navigate = useNavigate();
  const { user: { agency } } = useUserContext();

  return (
    <tr onClick={() => navigate(`/${agency.logicalName}${lead.link}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell">
        {lead.userId &&
          <Avatar classes="w-10 h-10" userId={lead.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {lead.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Status</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span
              className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${lead.status === 0 || lead.status === 1 ? ("text-yellow-600 bg-yellow-100") :
                  (lead.status === 2 ?
                    ("text-blue-600 bg-blue-100")
                    : ("text-green-600 bg-green-100")
                  )}`}
            >
              {lead.statusName}
            </span>
          </dd>
          <dt className="sr-only sm:hidden">Lead Type</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {lead.leadType}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${lead.status === 0 || lead.status === 1 ? ("text-yellow-600 bg-yellow-100") :
              (lead.status === 2 ?
                ("text-blue-600 bg-blue-100")
                : ("text-green-600 bg-green-100")
              )}`}
        >
          {lead.statusName}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {lead.leadType}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {lead.sourceName}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(lead.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {lead.followUpDate ? date.format(new Date(lead.followUpDate), "DD/MM/YYYY") : "Not Set"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            className="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">View</span>
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          {/* <button
            onClick={handleDeleteClick}
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button> */}
        </span>
      </td>
    </tr>
  );

}

export default function MyLeads() {
  const { leads: allLeads } = useUserLeads();
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [leadTypeFilter, setLeadTypeFilter] = useState(null);
  const [sortDirection, setSortDirection] = useState(false);
  const [sortTerm, setSortTerm] = useState("followUpDate");

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      if (!sortDirection) {
        setSortTerm(null);
      }
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | My Leads";
    if (!allLeads.isLoading && allLeads.data) {

      //Filter and extract data from all the different lead types
      var filtered = [];

      //Vals
      if (leadTypeFilter?.value === 1 || leadTypeFilter === null)
        filtered.push(...allLeads.data.valuations
          .filter((val) =>
            val.yourName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            val.sourceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            "valuation".includes(searchTerm.toLowerCase())
          )
          .map((filtered) => {
            return {
              ...filtered,
              fullName: filtered.yourName,
              leadType: "Valuation",
              link: "/valuations/valuation/" + filtered.id,
              statusName: valuationStatusEnum[filtered.status]
            }
          })
        );

      //Enquiries
      if (leadTypeFilter?.value === 2 || leadTypeFilter === null)
        filtered.push(...allLeads.data.enquiries
          .filter((enquiry) =>
            enquiry.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            enquiry.sourceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            "enquiry".includes(searchTerm.toLowerCase()) ||
            "enquiries".includes(searchTerm.toLowerCase())
          )
          .map((filtered) => {
            return {
              ...filtered,
              status: null,
              statusName: "N/A",
              leadType: "Enquiry",
              link: "/enquiries/enquiry/" + filtered.id
            }
          })
        );

      //Viewings
      if (leadTypeFilter?.value === 3 || leadTypeFilter === null)
        filtered.push(...allLeads.data.viewings
          .filter((viewing) =>
            viewing.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            viewing.sourceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            "viewings".includes(searchTerm.toLowerCase())
          )
          .map((filtered) => {
            return {
              ...filtered,
              leadType: "Viewing",
              sourceName: filtered.source,
              link: "/viewings/viewing/" + filtered.id,
              statusName: viewingStatusEnum[filtered.status]
            }
          })
        );

      //Mortgages
      if (leadTypeFilter?.value === 4 || leadTypeFilter === null)
        filtered.push(...allLeads.data.mortgages
          .filter((mortgage) =>
            mortgage.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            mortgage.sourceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            "mortgages".includes(searchTerm.toLowerCase())
          )
          .map((filtered) => {
            return {
              ...filtered,
              leadType: "Mortgage",
              link: "/mortgages/mortgage/" + filtered.id,
              statusName: mortgageStatusEnum[filtered.status]
            }
          })
        );

      //Form Leads
      if (leadTypeFilter?.value === 5 || leadTypeFilter === null)
        filtered.push(...allLeads.data.formLeads
          .filter((formLead) =>
            formLead.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            formLead.sourceName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            "form leads".includes(searchTerm.toLowerCase())
          )
          .map((filtered) => {
            return {
              ...filtered,
              leadType: "Form Lead",
              link: "/formleads/formlead/" + filtered.id,
              statusName: formLeadStatusEnum[filtered.status]
            }
          })
        );

      // filtered.sort((a, b) => new Date(b.followUpDate) - new Date(a.followUpDate));

      //Sorts the dates and puts the nulls at the end
      if (sortTerm && !sortDirection) {
        filtered.sort((a, b) => {
          if (a[sortTerm] === null && b[sortTerm] === null) {
            return 0;
          } else if (a[sortTerm] === null) {
            return 1;
          } else if (b[sortTerm] === null) {
            return -1;
          } else {
            return new Date(b[sortTerm]).getTime() - new Date(a[sortTerm]).getTime();
          }
        });
      } else if (sortTerm && sortDirection) {
        filtered.sort((a, b) => {
          if (a[sortTerm] === null && b[sortTerm] === null) {
            return 0;
          } else if (a[sortTerm] === null) {
            return 1;
          } else if (b[sortTerm] === null) {
            return -1;
          } else {
            return new Date(a[sortTerm]).getTime() - new Date(b[sortTerm]).getTime();
          }
        });
      }

      setLeads(filtered);
    }
  }, [allLeads.isLoading, allLeads.data, searchTerm, leadTypeFilter, sortTerm, sortDirection]);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">My Leads</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all leads that have been assigned to me
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="flex w-[13rem] mb-4 px-2">
                <LeadTypeListBox setType={setLeadTypeFilter} />
              </div>

              <div className="w-64 relative py-6 flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute py-6 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name, Lead Type or Source"
                />
              </div>

            </div>
          </div>

          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to=""
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Post
            </Link>
          </div> */}
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {allLeads.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : leads.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {/* Avatar */}
                  </th>
                  <th
                    // onClick={() => handleSort("fullName")}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                  >
                    <span className="flex">Name </span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Status</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Lead Type</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Source</span>
                  </th>
                  <th
                    onClick={() => handleSort("created")}
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Date Created{sortTerm === "created" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th
                    onClick={() => handleSort("followUpDate")}
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Follow Up {sortTerm === "followUpDate" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {leads.map((lead) => (
                  <LeadsRow
                    key={lead.id}
                    // branches={branches}
                    // properties={properties}
                    lead={lead}
                  // onDeleteClick={deleteViewing}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="lead"
                  plural="leads"
                  hasButton={false}
                // onNewClick={() => navigate("")}
                />
              ) : (
                <NoItems searching itemName="lead" plural="leads" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}