import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useFormLeads() {
  const staleTime = 1000 * 60; // 60 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const formLeads = useQuery(["formdata", agency.id],
    () => get(`/formdata/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation((updatedFormLead) => put(`/formdata/${updatedFormLead.id}`, updatedFormLead), {
    onSuccess: () => {
      queryClient.invalidateQueries(["formdata", agency.id]);
    },
  });

  const remove = useMutation((formId) => del(`/formdata/${formId}`),
    {
      onSuccess: (_, formId) => {
        queryClient.setQueryData(["formdata", agency.id], (currentForms) =>
          currentForms.filter((form) => form.id !== formId)
        );
      },
    }
  );


  const updateNotes = useMutation((mutateData) => put(`/formdata/notes/${mutateData.formDataId}`, mutateData.newNote), {
    onSuccess: () => {
      queryClient.invalidateQueries(["formdata", agency.id]);
    },
  });

  const removeNote = useMutation((ids) => put(`/formdata/notes/${ids.formDataId}/${ids.noteId}`), {
    onSuccess: (_, ids) => {
      queryClient.invalidateQueries(["formdata", agency.id]);
    },
  });

  return {
    formLeads,
    update,
    remove,
    updateNotes,
    removeNote,
  };
}
