import { Fragment, useEffect, useRef, useState } from "react";

import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

import DraggableForm from "../../components/FormBuilder/DraggableForm";
import FormOptionsModal from "../../components/FormBuilder/FormOptionsModal";
import FormTitle from "../../components/FormBuilder/FormTitle";
import SubmissionOptions from "../../components/FormBuilder/SubmissionOptions";
import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import useNotification from "../../components/notification/useNotifications";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import Modal from "../../components/Shared/Modal";
import { inputTypeEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useAllMedia from "../../data/useAllMedia";
import useForms from "../../data/useForms";
import { CDN_URL } from "../../services/config";
import defaultForm from "../../stubs/defaultForm.json";
import classNames from "../../utils/classNames";
import randId from "../../utils/randId";
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';


const submissionOptions = [
  {
    name: "Thank You Message",
    id: 0
  },
  {
    name: "URL Redirect",
    id: 1
  },
  // {
  //   name: "Upload PDF",
  //   id: 2
  // },
];

export default function FormBuilderAddEdit({ isLandingPage }) {
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: {
      formTitle: "",
      subtitle: null,
      description: null,
      type: isLandingPage ? 1 : 0,
      submissionOption: submissionOptions[0].id,
      submissionButtonText: "Submit",
      thankYouTitle: null,
      thankYouMessage: null,
      submissionPdfLink: null,
      downloadButtonText: "Download",
      urlRedirect: null,
    },
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user: { agency }, } = useUserContext();
  const { saveHandlers } = useDefaultCRUDHandlers(isLandingPage ? "Landing Page" : "Forms");
  const { addNotification } = useNotification();
  const { formId } = useParams();
  const { media } = useAllMedia();
  const { forms, add, update } = useForms();
  const [form, setForm] = useState(null);
  const [formJson, setFormJson] = useState(null);
  const [newId, setNewId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveButton, setIsSaveButton] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [featuredMediaValue, setFeaturedMediaValue] = useState("");

  const formEndRef = useRef(null);

  const elementTypes = [
    ...Object.entries(inputTypeEnum),
  ];


  useEffect(() => {
    document.title = `EstateTrack Portal | ${isLandingPage ? "Landing Page" : "Form"} Builder`;

    //If this runs more than once it overwrites any new elements
    if (!forms.isLoading && !media.isLoading && !loaded) {
      // console.log(JSON.stringify(testForm))
      let testForm = JSON.parse(JSON.stringify(defaultForm));
      setFormJson(testForm);

      //If form is found then editing - set form and parse JSON
      let form = forms.data.find((form) => form.id === formId);
      if (form) {
        setForm(form);
        setFeaturedMediaValue(media.data.find((m) => m.path === form.cdnImageLink?.replace(CDN_URL, ""))?.id);
        setFormJson(JSON.parse(form.formJson));

        reset({
          formTitle: form.title,
          subtitle: form.subtitle,
          description: form.description,
          submissionOption: submissionOptions[form.submissionOption].id,
          submissionButtonText: form.submissionButtonText,
          thankYouTitle: form.thankYouTitle,
          thankYouMessage: form.thankYouMessage,
          submissionPdfLink: form.submissionPdfLink,
          downloadButtonText: form.downloadButtonText,
          urlRedirect: form.urlRedirect,
        })
      }
      setIsLoading(false);
      setLoaded(true);
    }
  }, [forms.isLoading, forms.data, media.isLoading, media.data, formId])

  useEffect(() => {
    if (form) {
      // console.log(form)
      // console.log(formJson)
    }
  }, [formJson, form])

  const onSubmit = (data) => {
    // console.log(data)
    if (!data.formTitle) return;

    //If adding a new form, don't update until the button is pressed
    if (!isSaveButton && !form) return;

    let cdnLink;

    if (!featuredMediaValue) {
      cdnLink = null;
    } else {
      cdnLink = `${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`;
    }

    let tempForm;

    if (form) tempForm = form;

    //check for empty subtitle and description
    if (data.subtitle === "<p><br></p>") data.subtitle = null;
    if (data.description === "<p><br></p>") data.description = null;

    tempForm = {
      ...tempForm,
      agencyId: agency.id,
      type: isLandingPage ? 1 : 0,
      title: data.formTitle,
      subtitle: data.subtitle,
      description: data.description,
      submissionOption: data.submissionOption.id,
      submissionButtonText: data.submissionButtonText,
      thankYouTitle: data.thankYouTitle,
      thankYouMessage: data.thankYouMessage,
      submissionPdfLink: data.submissionPdfLink,
      downloadButtonText: data.downloadButtonText,
      urlRedirect: data.urlRedirect,
      cdnImageLink: cdnLink,
      formJson: JSON.stringify(formJson),
    };

    if (form) {
      update.mutate(tempForm, {
        onSuccess: saveHandlers.onSuccess,
        onError: (error) => {
          handleError(error);
        },
      });
    } else {
      add.mutate(tempForm, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          if (isLandingPage) {
            navigate(`/${agency.logicalName}/landingpages`);
          } else {
            navigate(`/${agency.logicalName}/forms`);
          }
        },
        onError: (error) => {
          handleError(error);
        },
      });
    }

    setIsSaveButton(false);
  }

  const handleError = (error) => {
    //If title already exists, else show generic
    if (error.message.includes("title")) {
      addNotification({
        variant: "error",
        primaryText: error.message,
      })
    } else {
      saveHandlers.onError();
    }
  }

  const addElement = (e) => {
    const id = randId(10);

    let newElement = {
      id: id,
      type: e,
      // label: e.charAt(0).toUpperCase() + e.slice(1),
      label: "Add Title",
      placeholder: "",
      required: false,
      options: [],
      cols: 6,
    };

    newElement = handleSpecialElement(e, newElement);

    let newFormJson = formJson;
    newFormJson.elements.push(newElement);

    setFormJson(newFormJson);
    setNewId(id);
    setRefresh(!refresh);

    setTimeout(() => {
      scrollToBottom();
    }, 250);
  }

  const handleSpecialElement = (e, newElement) => {
    if (e === "linebreak") {
      newElement.showLine = true;
    }

    if (e === "valuation") {
      newElement.type = "select";
      newElement.label = "Do you have a property to sell?";
      newElement.placeholder = "Do you have a property to sell?";
      newElement.options = ["Yes", "No"];
    }

    if (e === "mortgage") {
      newElement.type = "select";
      newElement.label = "Do you require a mortgage?";
      newElement.placeholder = "Do you require a mortgage?";
      newElement.options = ["I would like to speak to someone about mortgage offers", "I already have a mortgage arranged", "I do not require a mortgage"];
    }

    return newElement;
  }


  const scrollToBottom = () => {
    formEndRef.current?.scrollIntoView({ block: "start", behavior: 'smooth' })
  }

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("allMedia");

    setTimeout(() => {
      setFeaturedMediaValue(media.id);
      setFeaturedMediaOpen(false);
    }, 1000);
  };

  const pages = [
    { to: "..", label: isLandingPage ? "Pages" : "Form", current: false },
    {
      to: formId && form ? `${form.id}` : "",
      label: form ? `${form.title} ${isLandingPage ? "Page" : "Form"}` : `New ${isLandingPage ? "Page" : "Form"}`,
      current: true,
    },
  ];

  if (isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-20 flex justify-center">
          <LoadingWheel width="w-20" height="h-20" />
        </div>
      </div>
    )
  }

  return (
    <>
      <InsertMediaModal
        allowVideo={false}
        allowURL={true}
        open={featuredMediaOpen}
        setOpen={setFeaturedMediaOpen}
        onInsert={handleFeaturedMediaChange}
      />
      <FormOptionsModal
        open={optionsOpen}
        setOpen={setOptionsOpen}
        formJson={formJson}
        setFormJson={setFormJson}
        refresh={refresh}
        setRefresh={setRefresh}
        isLandingPage={isLandingPage}
      />
      <Modal open={tooltipOpen} setOpen={setTooltipOpen} width="max-w-4xl" >
        <div className="p-2">
          <h1 className="mb-2">Main Image</h1>
          <hr className="mb-4" />
          <p className="mb-2">Depending on your websites theme this image is likely to be very wide, please make sure you use a large image - ideally at least 1920px wide.</p>
          {/* <p className="mb-2"></p> */}
        </div>
      </Modal>
      <form onSubmit={handleSubmit(onSubmit)} className="px-4 sm:px-6 lg:px-8 min-h-[70vh] h-full">
        <Breadcrumbs pages={pages} />

        <div className="grid grid-cols-4">
          <div className="sm:flex-auto col-span-2 my-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {form ? form.title : `New ${isLandingPage ? "Page" : "Form"}`}
            </h3>
            <p className="mt-2 text-sm text-gray-700">
              {form
                ? `View and edit ${form.title} form details`
                : `Enter your new ${isLandingPage ? "page" : "form"} layout below`}
            </p>
          </div>

          <div className="col-span-1 flex justify-end items-center">
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button
                  className="px-3 py-3 mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                >
                  <span className="sr-only">Open add element menu</span>
                  Add Element ▼
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-20 origin-top-right absolute right-0 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {elementTypes.map(([k, v], i) => (
                    <Menu.Item key={i}>
                      {({ active }) => (
                        <button
                          onClick={() => addElement(k)}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                          )}
                        >
                          {v}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4">

          <div className="mb-28 flex flex-col gap-y-4 col-span-1 lg:col-span-3">
            {/* Left side */}
            <FormTitle register={register} getValues={getValues} setValue={setValue} isLandingPage={isLandingPage} />

            <DraggableForm formJson={formJson} setFormJson={setFormJson} refresh={refresh} setRefresh={setRefresh} newId={newId} />

            {/* Move the scroll anchor to make the new element more central */}
            <div className="relative">
              <div ref={formEndRef} className="absolute -top-[25rem] h-0 w-0" />
            </div>

            <SubmissionOptions options={submissionOptions} register={register} getValues={getValues} setValue={setValue} watch={watch} />

          </div>

          <div className="px-4 col-span-1 lg:col-span-1">
            {/* Right side */}

            {isLandingPage && (
              <div className="mb-4 bg-white rounded-md shadow-sm p-4">
                <label className="flex justify-between mb-2 text-sm font-medium text-gray-700">
                  <span>Main Image</span>
                  <button type="button" onClick={() => setTooltipOpen(true)}>
                    <QuestionMarkCircleIcon className="w-5 h-5" />
                  </button>
                </label>
                <div className="border h-60">
                  {featuredMediaValue && !media.isLoading && media.data ? (
                    <img
                      src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                      className="h-full w-full object-contain"
                    />
                  ) : (
                    <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                  )}
                </div>
                <button
                  type="button"
                  onClick={() => setFeaturedMediaOpen(true)}
                  className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                >
                  {!featuredMediaValue ? "Choose" : "Change"}
                </button>

                {featuredMediaValue &&
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaValue("")}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Remove
                  </button>
                }
              </div>
            )}

            <button
              type="button"
              onClick={() => setOptionsOpen(true)}
              className="w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
              Options
            </button>


            <button
              type="submit"
              onClick={() => setIsSaveButton(true)}
              className="px-3 mt-2 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
              Save
            </button>
          </div>

        </div>

      </form>
    </>
  )
}