import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import useUserContext from "../../contexts/UserContext";
import FullscreenLoading from "../../components/Shared/FullscreenLoading";

export default function DefaultAgencyRedirect() {
  const {
    user: { agencies: allAgencies, user, loading: userLoading },
  } = useUserContext();
  const [redirectTo, setRedirectTo] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!userLoading) {
      setRedirectTo(
        allAgencies.find((a) => a.id === user.defaultAgency).logicalName
      );
      setLoading(false);
    }
  }, [userLoading]);

  if (loading) {
    return <FullscreenLoading />;
  } else {
    return <Navigate to={redirectTo} />;
  }
}
