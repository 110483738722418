import { useEffect, useState } from "react";
import { DocumentDuplicateIcon, ExclamationIcon, PencilAltIcon, PlusIcon, RefreshIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Flyout from "../../containers/Flyout";
import useAgencyBranches from "../../data/useAgencyBranches";
import useBranchPostcodes from "../../data/useBranchPostcodes";
import useBranchStats from "../../data/useBranchStats";
import { apiUrl } from "../../services/config";
import useAreaGuides from "../../data/useAreaGuides";
import AreaGuidePostcodesAddEditPartial from "./AreaGuidePostcodesAddEditPartial";
import AreaGuideAddModal from "./AreaGuideAddModal";
import useAreaGuidePostcodes from "../../data/useAreaGuidePostcodes";
import ConfirmDelete from "../Shared/ConfirmDelete";
import useNotification from "../notification/useNotifications";


function AreaGuidesListRow({ areaGuide, onEditClick, onDeleteClick }) {
  const { postcodes } = useAreaGuidePostcodes(areaGuide.id);
  const { addNotification } = useNotification();
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(areaGuide.id);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);

    addNotification({
      variant: "success",
      primaryText: "Link Copied",
    });

  };

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="text-sm font-medium text-etpink-600 md:w-1/5">
          {areaGuide.name}
        </p>
        <div className="flex flex-1 gap-x-4 w-full truncate md:ml-2">
          <span>
            {!postcodes.isLoading ? postcodes.data.length > 0 ? (
              postcodes.data.length === 1 ? `${postcodes.data.length} postcode area` : `${postcodes.data.length} postcode areas`
            ) : (
              "0 postcode areas"
            ) : ""}
          </span>
          <span className="">
            Last run: {areaGuide.lastCalculationDate ? new Date(areaGuide.lastCalculationDate).toLocaleDateString() : "Never"}
          </span>
          <span className="hover:cursor-pointer -mr-4"
            onClick={() => copyToClipboard(`${apiUrl}/api/AreaGuide/${areaGuide.agencyId}?areaName=${encodeURI(areaGuide.name)}`)}>
            <DocumentDuplicateIcon className="h-5 w-5 mr-1" />
          </span>
          <span className="truncate w-1/2">
            {`${apiUrl}/api/BranchStats/${areaGuide.agencyId}?areaName=${areaGuide.name}`}
          </span>
        </div>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onEditClick(areaGuide)}
          className="mx-2 text-gray-400 hover:text-gray-600"
        >
          <span className="sr-only">Edit</span>
          <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <button
          onClick={handleDeleteClick}
          type="button"
          className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
        >
          <span className="sr-only">Delete</span>
          <TrashIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </li>
  );
}

export default function AreaGuidesPartial({ agencyId }) {
  const { areaGuides, sync, add, remove } = useAreaGuides(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Area Guide");
  const [open, setOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [editArea, setEditArea] = useState({});
  const [calculating, setCalculating] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deletePost = (post) => {
    setDeleteId(post);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const handleEditArea = (area) => {
    setEditArea(area);
    setOpen(true);
  };

  const handleCalculate = () => {
    setCalculating(true);
    sync.mutate("", {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setCalculating(false);
      },
      onError: () => {
        saveHandlers.onError();
        setCalculating(false);
      }
    });
  }

  useEffect(() => {

  }, [areaGuides.isLoading, areaGuides.data])

  return (
    <>
      <ConfirmDelete
        itemName="Area Guide"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <AreaGuideAddModal add={add} open={openAddModal} setOpen={setOpenAddModal} />
      <div className="divide-y-2">
        <div>
          <h3 className="text-xl font-medium text-gray-900">Area Guides</h3>
          <p className="mt-1 text-sm text-gray-500">Add an area, then add postcodes to create your area guides</p>
          <Flyout
            open={open}
            setOpen={setOpen}
            title={editArea.name ? editArea.name : "Add Area Guide"}
          >
            <AreaGuidePostcodesAddEditPartial
              area={editArea}
            />
          </Flyout>
          <button
            type="button"
            onClick={() => {
              // setEditArea({});
              setOpenAddModal(true);
            }}
            className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            <PlusIcon className="w-4 h-4 mr-2" />
            Add Agency Area
          </button>
          <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
            {areaGuides.isLoading ? (
              <li key="areas-loading" className="py-4 flex justify-center">
                <LoadingWheel width="w-8" height="h-8" />
              </li>
            ) : areaGuides.data.length > 0 ? (
              areaGuides.data.map((area, i) => (
                <AreaGuidesListRow
                  areaGuide={area}
                  key={i}
                  onEditClick={handleEditArea}
                  onDeleteClick={deletePost}
                />
              ))
            ) : (
              <li>
                <div className="py-5 text-center text-sm text-gray-400">
                  No Areas
                </div>
              </li>
            )}
          </ul>
        </div>
        {!areaGuides.isLoading && areaGuides.data.length > 0 &&
          <div>
            <div className="flex">
              <div>
                {!calculating ?
                  (<button
                    type="button"
                    onClick={() => handleCalculate()}
                    className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    <RefreshIcon className="w-4 h-4 mr-2" />
                    Calculate area stats
                  </button>)
                  :
                  (
                    <div className="my-6 mr-2">
                      <LoadingWheel width="w-8" height="h-8" />
                    </div>
                  )}
              </div>
              <div className="flex items-center ml-4">
                <p className="flex items-center text-red-600 text-sm"><ExclamationIcon className="w-4 h-4 mr-2" /> This will use some of your Property Market Intel calls</p>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
