import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useBranchStats(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const sync = useMutation(() => post(`/BranchStats/calculate/${agencyId}`), {
    onSuccess: () => {},
  });


  return {
    sync
  };
}
