import { useCallback, useEffect, useState } from "react";
import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useForm } from "react-hook-form";
import useAgencies from "../../data/useAgencies";
import { Switch } from "@headlessui/react";
import classNames from "../../utils/classNames";
import useNotificationPolicies from "../../data/useNotificationPolicies";

export default function ValuationSettingsPartial({ agencyId }) {
  const { agencies, updateValuationSettings } = useAgencies();
  const { policies } = useNotificationPolicies(agencyId);
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm();
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation setting");
  const [agency, setAgency] = useState(null);
  const [hideSales, setHideSales] = useState(null);
  const [hideRentals, setHideRentals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDefaultPolicy, setIsDefaultPolicy] = useState(true);


  const onSubmit = (data) => {
    console.log("submitting", data);
    setIsLoading(true);

    let settings = {
      valuationCatchall: data.valuationCatchall,
      instantValuationHideSales: hideSales,
      instantValuationHideRentals: hideRentals
    }

    updateValuationSettings.mutate({ agencyId, settings },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setIsLoading(false);
        },
        onError: () => {
          saveHandlers.onError();
          setIsLoading(false);
        }
      }
    );

    reset({
      valuationCatchall: data.valuationCatchall,
    });


  }

  const loadAgency = useCallback((foundAgency) => {
    setAgency(foundAgency);

    setHideSales(foundAgency.instantValuationHideSales);
    setHideRentals(foundAgency.instantValuationHideRentals);

    if (policies.data) {
      if (!policies.data.filter(p => p.policyName.toLowerCase() === "system default").length > 0) {
        setIsDefaultPolicy(false);
      }
    }

    reset({
      valuationCatchall: foundAgency.valuationCatchall,
    });

    setIsLoading(false);
  },
    [reset]
  );

  useEffect(() => {
    if (agencyId && !agencies.isLoading && !policies.isLoading) {
      let foundAgency = agencies.data.find((a) => a.id === agencyId);
      if (foundAgency) {
        loadAgency(foundAgency);
      }
    }
  }, [agencies.isLoading, agencies.data, policies.isLoading])

  return (
    <div>
      <h3 className="text-xl font-medium text-gray-900">Notification Settings</h3>
      <p className="mt-1 text-sm text-gray-500">
        Any settings related to valuations shown here
      </p>


      {isLoading ? (
        <div className="flex p-4 justify-center items-center">
          <LoadingWheel width="w-10" height="h-10" />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="mt-6">
            <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-700">
              Default notification policy email
            </label>
            <div className="mt-1 flex items-center">
              <input
                {...register("valuationCatchall")}
                type="text"
                className={classNames(isDefaultPolicy ? "" : "bg-gray-400", "shadow-sm w-1/2 focus:ring-etpink-400 focus:border-etpink-400 block sm:text-sm border-gray-300 rounded-md")}
                placeholder="noreply@agencyemail.co.uk"
              />
              {!isDefaultPolicy && <span className="ml-2 text-sm text-gray-500"> - There is no default policy for this agency</span>}
            </div>
          </div>

          <div className="mt-6 w-1/2">
            <Switch.Group as="div" className="flex justify-between mb-4">
              <span className="mr-10">
                <Switch.Label as="span" className="text-md text-gray-900" passive>
                  Instant Valuation Hide Sales
                </Switch.Label>
              </span>
              <Switch
                checked={hideSales}
                onChange={(e) => setHideSales(e)}
                className={classNames(
                  hideSales ? 'bg-etpink-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    hideSales ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>

            <Switch.Group as="div" className="flex justify-between mb-4">
              <span className="mr-10">
                <Switch.Label as="span" className="text-md text-gray-900" passive>
                  Instant Valuation Hide Rentals
                </Switch.Label>
              </span>
              <Switch
                checked={hideRentals}
                onChange={(e) => setHideRentals(e)}
                className={classNames(
                  hideRentals ? 'bg-etpink-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    hideRentals ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          <div className="mt-10">
            <button
              type="submit"
              className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
            >
              Save
            </button>
          </div>
        </form>
      )}

    </div>
  );
}
