import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useReviews() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const reviews = useQuery(
    ["reviews", agency.id],
    () => get(`/Review/${agency.id}?minStars=0`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((review) => post(`/Review/${agency.id}`, review), {
    onSuccess: () => queryClient.invalidateQueries(["reviews", agency.id]),
  });

  const requestReview = useMutation((review) => post(`/Review/${agency.id}/request`, review), {
  });

  const update = useMutation(
    (review) => put(`/Review/${agency.id}/${review.id}`, review),
    {
      onSuccess: () => queryClient.invalidateQueries(["reviews", agency.id]),
    }
  );

  const remove = useMutation(
    (reviewId) => del(`/Review/${agency.id}/${reviewId}`),
    {
      onSuccess: (_, reviewId) => {
        queryClient.setQueryData(["reviews", agency.id], (currentReviews) =>
          currentReviews.filter((site) => site.id !== reviewId)
        );
      },
    }
  );

  return {
    reviews,
    add,
    requestReview,
    update,
    remove,
  };
}
