import { useState, useEffect, useCallback, Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Listbox, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, UserIcon } from "@heroicons/react/outline";
import { useForm, useFormState } from "react-hook-form";
import date from "date-and-time";
import ReactQuill from "react-quill";

import useNotification from "../../components/notification/useNotifications";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import classNames from "../../utils/classNames";
import useRegistrations from "../../data/useRegistrations";
import useAgencyBranches from "../../data/useAgencyBranches";
import useUserContext from "../../contexts/UserContext";

const propertyTypes = ["For Sale", "To Rent"]

const salePrices = ["None", "50000", "60000", "70000", "80000", "90000", "100000", "110000", "120000", "125000", "130000", "140000", "150000", "160000", "170000", "175000", "180000", "190000", "200000", "210000", "220000", "230000", "240000", "250000", "260000", "270000", "280000", "290000", "300000", "325000", "350000", "375000", "400000", "425000", "450000", "475000", "500000", "550000", "600000", "650000", "700000", "800000", "900000", "1000000", "1250000", "1500000", "1750000", "2000000", "2500000", "3000000", "4000000", "5000000", "7500000", "10000000", "15000000", "20000000"];

const rentPrices = ["None", "100", "150", "200", "250", "300", "350", "400", "450", "500", "600", "700", "800", "900", "1000", "1100", "1200", "1250", "1300", "1400", "1500", "1750", "2000", "2250", "2500", "2750", "3000", "3500", "4000", "4500", "5000", "5500", "6000", "6500", "7000", "8000", "9000", "10000", "12500", "15000", "17500", "20000", "25000", "30000", "35000", "40000"];

const bedrooms = ["None", "Studio", "1", "2", "3", "4", "5"];

const propertyToSellOptions = ["No", "Yes"];

const requireMortgageOptions = ["I would like to speak to someone about mortgage offers", "I already have a mortgage arranged", "I do not require a mortgage"];

export default function RegistrationsAddEdit() {
  const { registrationId } = useParams();
  const { user: { agency }, } = useUserContext();
  const { registrations, add, update } = useRegistrations();
  const { branches } = useAgencyBranches(agency.id);
  const { register, getValues, setValue, watch, handleSubmit, reset, control, formState: { errors } } = useForm({
    defaultValues: {}
  });
  const navigate = useNavigate();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Registration");
  const [registration, setRegistration] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [branch, setBranch] = useState(null);
  const [created, setCreated] = useState(false);

  const onTypeChange = (type) => {
    setValue("type", type);

    if (type === "For Sale") {
      setValue("minPrice", salePrices[0]);
      setValue("maxPrice", salePrices[0]);
    } else {
      setValue("minPrice", rentPrices[0]);
      setValue("maxPrice", rentPrices[0]);
    }
  }

  const loadRegistration = useCallback(
    (registration, branches) => {
      setRegistration(registration);

      var loadBranch = registration.branchId;

      if (!loadBranch) {
        loadBranch = branches.data[0].id;
      }

      //This will remove commas
      const commaRegex = /\,/g;
      //Remove leading whitespace
      const leadingWhitespaceRegex = /^[ \t]+/gm;

      let extras = null;

      if (registration.extraFields) {
        extras = registration.extraFields?.replace(commaRegex, "");
        extras = extras.replace(leadingWhitespaceRegex, "");
        extras = extras.replace("HTML Block:", "");
      }

      reset({
        name: registration.yourName,
        email: registration.emailAddress,
        phone: registration.telephoneNumber,
        branch: loadBranch,
        type: registration.propertyType,
        minPrice: (registration.minPrice === "" || registration.minPrice === "0") ? "None" : registration.minPrice,
        maxPrice: (registration.maxPrice === "" || registration.maxPrice === "0") ? "None" : registration.maxPrice,
        minBeds: (registration.minBedrooms === "" || registration.minBedrooms === "0") ? "None" : registration.minBedrooms,
        maxBeds: registration.maxBedrooms,
        propertyToSell: registration.propertyToSell === true ? "Yes" : "No",
        requireMortgage: registration.requireMortgage,
        extraFields: extras ?? "",

      });

      setLoading(false);
    },
    [reset]
  );

  const onSubmit = (data) => {
    console.log("submitting", data);
    setLoading(true);

    var branch = branches.data.find((b) => b.id === data.branch);

    var isPropertyToSell = (data.propertyToSell === "Yes");

    let updatedRegistration = {
      ...registration,
      yourName: data.name,
      emailAddress: data.email,
      telephoneNumber: data.phone,
      branchId: data.branch,
      branch: branch.name,
      propertyType: data.type,
      minPrice: data.minPrice,
      maxPrice: data.maxPrice,
      minBedrooms: data.minBeds,
      maxBedrooms: data.maxBeds,
      propertyToSell: isPropertyToSell,
      requireMortgage: data.requireMortgage,
      extraFields: data.extraFields,
    };

    if (registrationId) {
      update.mutate(updatedRegistration,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        }
      );
    } else {
      add.mutate(updatedRegistration,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setLoading(false);
          }
        }
      );
    }

    reset({
      name: data.name,
      email: data.email,
      phone: data.phone,
      branchId: data.branch,
      type: data.type,
      minPrice: data.minPrice,
      maxPrice: data.maxPrice,
      minBeds: data.minBeds,
      maxBeds: data.maxBeds,
      propertyToSell: data.propertyToSell,
      requireMortgage: data.requireMortgage,
      extraFields: data.extraFields,
    });

    setRegistration(updatedRegistration);
  };

  useEffect(() => {

    if (!registrations.isLoading && !branches.isLoading && branches.data) {
      if (registrationId) {
        let foundRegistration = registrations.data.find((r) => r.id == registrationId);
        loadRegistration(foundRegistration, branches);
      } else {
        setValue("branch", branches.data[0].id);
        setValue("type", propertyTypes[0]);
        setValue("minPrice", salePrices[0]);
        setValue("maxPrice", salePrices[0]);
        setValue("minBeds", bedrooms[0]);
        setValue("propertyToSell", propertyToSellOptions[0]);
        setValue("requireMortgage", requireMortgageOptions[0]);
        setLoading(false);
      }
    }
  }, [registrations.isLoading, registrations.data, branches.isLoading, branches.data, registrationId, loadRegistration, register]);


  const pages = [
    { to: "..", label: "Registrations", current: false },
    {
      to: registrationId && registration ? `${registrationId}` : "",
      label:
        registrationId && registration
          ? `${registration.yourName}'s Registration`
          : "New Registration",
      current: true,
    },
  ];

  const branchValue = watch("branch");
  const propertyType = watch("type");
  const minPrice = watch("minPrice");
  const maxPrice = watch("maxPrice");
  const minBeds = watch("minBeds");
  const maxBeds = watch("maxBeds");
  const propertyToSell = watch("propertyToSell");
  const requireMortgage = watch("requireMortgage");

  if (loading || branches.isLoading || !branches.data) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-20 flex justify-center">
          <LoadingWheel width="w-20" height="h-20" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 h-full sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="sm:flex-auto mt-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {registrationId
                ? `${registration.yourName}'s Registration`
                : "New Registration"}
            </h3>
            <p className="mt-2 text-sm text-gray-700">
              {registrationId
                ? `View and edit ${registration.yourName}'s Registration`
                : "Create a new registration using the form below"}
            </p>
          </div>

          {registrationId && <p className="text-sm text-gray-700">{date.format(new Date(registration.created), "DD/MM/YYYY HH:mm")}</p>}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        {...register("name", { required: true })}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Your name"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email Address
                    </label>
                    <div className="mt-1">
                      <input
                        type="text"
                        {...register("email")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Telephone number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telephone Number
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        {...register("phone")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Telephone Number"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("branch")}
                      value={branchValue}
                      onChange={(b) => setValue("branch", b.id)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Branch
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  branches.data.find((branch) => branch.id === branchValue).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {branches.data.map((branch, i) => (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={branch} >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("branch") === branch.id
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )} >
                                        {branch.name}
                                      </span>
                                      {getValues("branch") === branch.id ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("type")}
                      value={propertyType}
                      onChange={(type) => onTypeChange(type)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Property Type
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  propertyTypes.find((type, i) => type === propertyType)
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {propertyTypes.map((type, i) => (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={type} >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("type") === type
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )} >
                                        {type}
                                      </span>
                                      {getValues("type") === type ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("minBeds")}
                      value={minBeds}
                      onChange={(beds) => setValue("minBeds", beds)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Minimum Bedrooms
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  bedrooms.find((beds) => beds === minBeds)
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {bedrooms.map((beds, i) => (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={beds} >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("minBeds") === beds
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )} >
                                        {beds}
                                      </span>
                                      {getValues("minBeds") === beds ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("minPrice")}
                      value={minPrice}
                      onChange={(price) => setValue("minPrice", price)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            {propertyType === "For Sale" ? "Minimum Price (£)" : "Minimum Price (£ PCM)"}
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  propertyType === "For Sale" ? (
                                    //Checks if equals the first string, format the ints
                                    salePrices.find((price) => price === minPrice) !== "None" ? new Intl.NumberFormat().format(parseInt(salePrices.find((price) => price === minPrice))) : salePrices.find((price) => price === minPrice)
                                  ) : (
                                    rentPrices.find((price) => price === minPrice) !== "None" ? new Intl.NumberFormat().format(parseInt(rentPrices.find((price) => price === minPrice))) : rentPrices.find((price) => price === minPrice)
                                  )
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {propertyType === "For Sale" ? (
                                  salePrices.map((price, i) => (
                                    <Listbox.Option
                                      key={i}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-etpink-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={price} >
                                      <>
                                        <span
                                          className={classNames(
                                            getValues("minPrice") === price
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )} >
                                          {price !== "None" ? new Intl.NumberFormat().format(price) : price}
                                        </span>
                                        {getValues("minPrice") === price ? (
                                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    </Listbox.Option>
                                  ))
                                ) : (
                                  rentPrices.map((price, i) => (
                                    <Listbox.Option
                                      key={i}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-etpink-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={price} >
                                      <>
                                        <span
                                          className={classNames(
                                            getValues("minPrice") === price
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )} >
                                          {price !== "None" ? new Intl.NumberFormat().format(price) : price}
                                        </span>
                                        {getValues("minPrice") === price ? (
                                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    </Listbox.Option>
                                  ))
                                )}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("maxPrice")}
                      value={maxPrice}
                      onChange={(price) => setValue("maxPrice", price)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            {propertyType === "For Sale" ? "Minimum Price (£)" : "Minimum Price (£ PCM)"}
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  propertyType === "For Sale" ? (
                                    //Checks if equals the first string, format the ints
                                    salePrices.find((price) => price === maxPrice) !== "None" ? new Intl.NumberFormat().format(parseInt(salePrices.find((price) => price === maxPrice))) : salePrices.find((price) => price === maxPrice)
                                  ) : (
                                    rentPrices.find((price) => price === maxPrice) !== "None" ? new Intl.NumberFormat().format(parseInt(rentPrices.find((price) => price === maxPrice))) : rentPrices.find((price) => price === maxPrice)
                                  )
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {propertyType === "For Sale" ? (
                                  salePrices.map((price, i) => (
                                    <Listbox.Option
                                      key={i}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-etpink-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={price} >
                                      <>
                                        <span
                                          className={classNames(
                                            getValues("maxPrice") === price
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )} >
                                          {price !== "None" ? new Intl.NumberFormat().format(price) : price}
                                        </span>
                                        {getValues("maxPrice") === price ? (
                                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    </Listbox.Option>
                                  ))
                                ) : (
                                  rentPrices.map((price, i) => (
                                    <Listbox.Option
                                      key={i}
                                      className={({ active }) =>
                                        classNames(
                                          active
                                            ? "text-white bg-etpink-600"
                                            : "text-gray-900",
                                          "cursor-default select-none relative py-2 pl-8 pr-4"
                                        )
                                      }
                                      value={price} >
                                      <>
                                        <span
                                          className={classNames(
                                            getValues("maxPrice") === price
                                              ? "font-semibold"
                                              : "font-normal",
                                            "block truncate"
                                          )} >
                                          {price !== "None" ? new Intl.NumberFormat().format(price) : price}
                                        </span>
                                        {getValues("maxPrice") === price ? (
                                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                            <CheckIcon
                                              className="h-5 w-5"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        ) : null}
                                      </>
                                    </Listbox.Option>
                                  ))
                                )}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("propertyToSell")}
                      value={propertyToSell}
                      onChange={(option) => setValue("propertyToSell", option)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Property To Sell
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  propertyToSellOptions.find((type, i) => type === propertyToSell)
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {propertyToSellOptions.map((option, i) => (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={option} >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("propertyToSell") === option
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )} >
                                        {option}
                                      </span>
                                      {getValues("propertyToSell") === option ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("requireMortgage")}
                      value={requireMortgage}
                      onChange={(option) => setValue("requireMortgage", option)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Require Mortgage
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  requireMortgageOptions.find((option) => option === requireMortgage) ?? "Unanswered"
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {requireMortgageOptions.map((option, i) => (
                                  <Listbox.Option
                                    key={i}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={option} >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("requireMortgage") === option
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )} >
                                        {option}
                                      </span>
                                      {getValues("requireMortgage") === option ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:mx-2">
                    <label
                      htmlFor=""
                      className="block text-sm font-medium text-gray-700"
                    >
                      Extra Info
                    </label>
                    <div className="w-full mt-4 md:mt-0  ">
                      <textarea
                        type="text"
                        rows={6}
                        {...register("extraFields")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>



              </div>

              <div className="mt-6 lg:mt-10">
                <div className="w-full md:mx-2">
                  <button
                    type="submit"
                    className="saveReview px-3 py-2.5 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save
                  </button>
                </div>
              </div>

            </div>
          </form>
        </div>
      </>
    );
  }
}
