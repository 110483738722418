import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useForms() {
  const staleTime = 1000 * 60 * 2; // 2 minutes
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const forms = useQuery(["forms", agency.id],
    () => get(`/form/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((form) => post(`/form/${form.agencyId}`, form), {
    onSuccess: (addedForm) => {
      queryClient.setQueryData(["forms", agency.id], (currentForms) => [
        ...currentForms,
        addedForm.data,
      ]);
    },
  });

  const update = useMutation((form) => put(`/form/${form.id}`, form), {
    onSuccess: () => {
      queryClient.invalidateQueries(["forms", agency.id])
    },
  }
  );

  const duplicate = useMutation((formId) => post(`/form/duplicate/${formId}`, null), {
    onSuccess: () => {
      queryClient.invalidateQueries(["forms", agency.id])
    },
  }
  );

  const remove = useMutation((formId) => del(`/form/${formId}`),
    {
      onSuccess: (_, formId) => {
        queryClient.setQueryData(["forms", agency.id], (currentForms) =>
          currentForms.filter((form) => form.id !== formId)
        );
      },
    }
  );

  return {
    forms,
    add,
    update,
    duplicate,
    remove,
  };
}
