import React, { useEffect, useState } from "react";

import { ArcElement, CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { useNavigate } from "react-router-dom";

import { ArrowSmDownIcon } from "@heroicons/react/outline";
import { ArrowSmUpIcon } from "@heroicons/react/solid";

import LiveValuations from "../../components/Analytics/Valuations/LiveValuations";
import YearGraph from "../../components/Graphs/YearGraph";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import { valuationSourceEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useProperties from "../../data/useProperties";
import useReports from "../../data/useReports";
import useValuations from "../../data/useValuations";
import { withAgency } from "../../data/withAgency";
import classNames from "../../utils/classNames";
import usePropertiesTopViewed from "../../data/usePropertiesTopViewed";

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);


const SmallHomeCard = ({ icon, num, text, change, background, border, valuationMonths }) => {

  useEffect(() => {

  }, [num, change, valuationMonths])

  return (
    <div key={text} >
      <dt className="text-base font-normal text-gray-800 px-6 lg:pt-2">{text}</dt>
      <dd className="mt-1 flex items-baseline justify-between md:block lg:flex px-6 pb-2">
        <div className="flex items-baseline text-2xl font-semibold text-etpink-600">
          {num}
          <span className="ml-2 text-sm font-medium text-gray-500">last 30 days</span>
        </div>

        {change !== 0 ?
          (
            <>
              {change &&
                <div
                  className={classNames(
                    change > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                    'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                  )}
                >
                  <span className="sr-only"> {change > 0 ? 'Increased' : 'Decreased'} by </span>
                  {change ? (change > 0 ?
                    (<span className="flex"><ArrowSmUpIcon className="mt-[2px] w-4 h-4" /> {change} %</span>) :
                    (<span className="flex"><ArrowSmDownIcon className="mt-[2px] w-4 h-4" /> {change} %</span>)) : (null)}
                </div>
              }
            </>
          ) :
          (
            <>
              {change === 0 &&
                <div className="bg-yellow-100 text-yellow-800 inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0">
                  <span className="sr-only">No change</span>
                  <span className="flex">{change} %</span>
                </div>
              }
            </>
          )}


      </dd>

      <div>
        {valuationMonths && <YearGraph backgroundColor={background} borderColor={border} data={valuationMonths} />}
      </div>
    </div>

  )
}

const LargeHomeCardValuations = ({ }) => {
  const navigate = useNavigate();
  const { valuations } = useValuations();

  useEffect(() => {

  }, [valuations.isLoading, valuations.data])

  return (
    <div className="p-5 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Recent Valuations</h1>
      <hr className="pb-4" />
      {valuations.isLoading ?
        <div className="flex w-full p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
        :
        <table className="w-full">
          <thead className="mb-5">
            <tr className="text-left text-etpink-400">
              <th className="font-medium w-full py-2">Name</th>
              <th className="font-medium w-[0%]">Postcode</th>
              <th className="font-medium w-[0%]">Type</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {valuations.data.slice(0, 8).map((valuation, i) => (
              <tr key={i} className="hover:cursor-pointer hover:bg-gray-100" onClick={() => navigate(`valuations/valuation/${valuation.id}`)}>
                <td className="py-4 truncate max-w-[1px]">{valuation.yourName}</td>
                <td className="whitespace-nowrap pr-4">{valuation.postcode}</td>
                <td className="whitespace-nowrap">{valuationSourceEnum[valuation.valuationSource]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
}

const LargeHomeCardProperties = ({ }) => {
  const navigate = useNavigate();
  const { properties } = usePropertiesTopViewed();
  const [orderedProperties, setOrderedProperties] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!properties.isLoading) {

      setIsLoading(false);
    }
  }, [properties.isLoading, properties.data]);

  return (
    <div className="p-5 w-full">

      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Top Viewed Properties</h1>
      <hr className="pb-4" />
      {properties.isLoading ?
        <div className="flex w-full p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
        :
        <table className="w-full">
          <thead className="mb-5">
            <tr className="text-left text-etpink-400">
              <th className="font-medium w-full py-2">Address</th>
              <th className="font-medium w-[0%] pr-4">Enquiries</th>
              <th className="font-medium w-[0%]">Views</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {properties.data.slice(0, 8).map((property, i) => (
              <tr key={i} className="hover:cursor-pointer hover:bg-gray-100" onClick={() => navigate(`properties/property/${property.id}`)}>
                <td className="py-4 truncate max-w-[1px]"><span className="" dangerouslySetInnerHTML={{ __html: property.title }} /></td>
                <td className="pl-4 whitespace-nowrap">{property.enquiries}</td>
                <td className="whitespace-nowrap">{property.views}</td>
              </tr>
            ))}
          </tbody>
        </table>
      }
    </div>
  );
}

function _AppHome({ agencyReady, agency }) {
  const { user: { user }, } = useUserContext();
  const { valuationsStats, registrationStats, reviewStats, viewingStats } = useReports(agency);

  useEffect(() => {
    document.title = "EstateTrack Portal";
  }, [])

  if (!agencyReady) {
    return (
      <div className="flex p-10 mt-28 justify-center">
        <LoadingWheel width="w-28" height="h-28" />
      </div>
    );
  } else {
    return (
      <>
        {/* <div style={{ position: "absolute", height: "200px", width: "100%", background: "linear-gradient(to right bottom, #ff4977, #FFADBF)", zIndex: "-1000000", top: "0" }}></div> */}
        <div className="px-12">
          <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >
            <div>
              <SmallHomeCard
                num={!valuationsStats.isLoading ? valuationsStats.data[0].valuationLeads : "Loading..."}
                change={!valuationsStats.isLoading ? valuationsStats.data[0].leadsPercentage : 0}
                valuationMonths={!valuationsStats.isLoading ? valuationsStats.data[0].valuationMonths : null}
                text={"Valuations"}
                background="rgba(119,221,119,0.1)" border="rgba(119,221,119,0.8)"
              />
            </div>
            <div>
              <SmallHomeCard
                num={!viewingStats.isLoading ? viewingStats.data.newViewings : "Loading..."}
                change={!viewingStats.isLoading ? viewingStats.data.viewingsPercentage : 0}
                valuationMonths={!viewingStats.isLoading ? viewingStats.data.viewingsMonths : null}
                text={"Viewing Requests"}
                background="rgba(101, 116, 205, 0.1)" border="rgba(101, 116, 205, 0.8)"
              />
            </div>
            <div>
              <SmallHomeCard
                num={!registrationStats.isLoading ? registrationStats.data.newRegistrations : "Loading..."}
                change={!registrationStats.isLoading ? registrationStats.data.registrationsPercentage : 0}
                valuationMonths={!registrationStats.isLoading ? registrationStats.data.registrationMonths : null}
                text={"Registrations"}
                background="rgba(246, 153, 63, 0.1)" border="rgba(246, 153, 63, 0.8)"
              />
            </div>
            <div>
              <SmallHomeCard
                num={!reviewStats.isLoading ? reviewStats.data.totalReviews30Days : "Loading..."}
                change={!reviewStats.isLoading ? reviewStats.data.change : 0}
                valuationMonths={!reviewStats.isLoading ? reviewStats.data.monthStats : null}
                text={"Reviews"}
                background="rgba(210, 77, 151,0.1)" border="rgba(210, 77, 151,0.8)"
              />
            </div>
          </div>

          <div className="py-4 mt-4 grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-8">

            <div className="shadow rounded-md bg-white ">
              <LiveValuations valuations={valuationsStats} />
            </div>

            {/* Top properties and new valuations */}
            <div className="bg-white shadow rounded-md">
              <LargeHomeCardProperties />
            </div>
            <div className="bg-white shadow rounded-md">
              <LargeHomeCardValuations />
            </div>

          </div>
        </div>
      </>
    );
  }
}

export const AppHome = withAgency(_AppHome);
