import { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    document.title = "EstateTrack 404 | Page Not Found";
  }, [])
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold text-etpink-600 sm:text-5xl">
                404
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Page not found
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    Please check the URL in the address bar and try again.
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <a
                    href="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    Go back home
                  </a>
                  <button
                    onClick={() => window.location = 'mailto:contact@estatetrack.co.uk'}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    Contact support
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
}
