import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useMortgages() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { user: { agency }, } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const mortgages = useQuery(
    ["mortgages", agency.id],
    () => get(`/Mortgage/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((mortgage) => post(`/Mortgage/${agency.id}`, mortgage), {
    onSuccess: (addedMortgage) => {
      queryClient.setQueryData(["mortgages", agency.id], (currentMortgages) => [
        addedMortgage.data,
        ...currentMortgages,
      ]);
    },
  });

  const update = useMutation(
    (mortgage) => put(`/Mortgage/${mortgage.id}`, mortgage),
    {
      onSuccess: () => queryClient.invalidateQueries(["mortgages", agency.id]),
    }
  );

  const remove = useMutation(
    (mortgageId) => del(`/Mortgage/${mortgageId}`),
    {
      onSuccess: (_, mortgageId) => {
        queryClient.setQueryData(["mortgages", agency.id], (currentMortgages) =>
          currentMortgages.filter((site) => site.id !== mortgageId)
        );
      },
    }
  );

  const updateNotes = useMutation((mutateData) => put(`/Mortgage/notes/${mutateData.mortgageId}`, mutateData.newNote), {
    onSuccess: (updatedMortgage) => {
      // queryClient.setQueryData(["mortgages", agency.id], (currentMortgages) =>
      //   currentMortgages.map((mortgage) =>
      //     mortgage.id === updatedMortgage.data.id ? updatedMortgage.data : mortgage
      //   )
      // );
      queryClient.invalidateQueries(["mortgages", agency.id]);
    },
  });

  const removeNote = useMutation((ids) => put(`/Mortgage/notes/${ids.mortgageId}/${ids.noteId}`), {
    onSuccess: (_, ids) => {
      queryClient.invalidateQueries(["mortgages", agency.id]);
      // queryClient.setQueryData(["mortgages", agency.id], (currentMortgages) =>
      //   currentMortgages.map((mortgage) => {
      //     if (mortgage.notes.some(c => c.id === ids.noteId)) {
      //       mortgage.notes = mortgage.notes.filter(note => note.id !== ids.noteId);
      //       return mortgage;
      //     } else {
      //       return mortgage;
      //     }
      //   })
      // );
    },
  });

  return {
    mortgages,
    add,
    update,
    remove,
    updateNotes,
    removeNote
  };
}
