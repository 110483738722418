import { useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "../../utils/classNames";
import { Fragment } from "react";


const options = ["Show All", "Hide Unqualified"]

export default function QualifiedDropdown({ setIsQualified }) {
  const [currentOption, setCurrentOption] = useState("Show All");

  const handleSetOption = (option) => {
    if (option === "Show All") {
      setIsQualified(false);
    } else {
      setIsQualified(true);
    }

    setCurrentOption(option);
  };


  return (
    <div className="w-full">
      <Listbox
        value={currentOption}
        onChange={(o) => handleSetOption(o)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Show Qualified
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {
                    options.find(
                      (o) => o === currentOption
                    )
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option, i) => (
                    <Listbox.Option
                      key={i}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-etpink-600"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={option}
                    >
                      <>
                        <span
                          className={classNames(
                            currentOption === option
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option}
                        </span>

                        {currentOption === option ? (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}