import { Fragment, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";
import useUserContext from "../../contexts/UserContext";
import useAgencyBranches from "../../data/useAgencyBranches";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "../../utils/classNames";
import useAgencyUsers from "../../data/useAgencyUsers";


export default function ValuationAssignee({ setUser }) {
  const { user: { agency }, } = useUserContext();
  const { users: allUsers } = useAgencyUsers(agency.id);
  const [dropdownUsers, setDropdownUsers] = useState(null);
  const [userValue, setUserValue] = useState(0);

  useEffect(() => {
    if (!allUsers.isLoading) {
      var tempUsers = allUsers.data.slice();

      tempUsers.sort((a, b) => { return a.user.firstName.localeCompare(b.user.firstName) });

      var all = {
        user: {
          firstName: "All",
          surname: "",
          id: 0
        }
      }
      tempUsers.unshift(all);

      setDropdownUsers(tempUsers);
    }

  }, [allUsers.isLoading, allUsers.data])

  const handleSetUser = (user) => {
    if (user.user.firstName === "All") {
      setUser(null);
    } else {
      setUser(user.user.id);
    }

    setUserValue(user.user.id);
  }

  if (allUsers.isLoading || !dropdownUsers) {
    return ("")
  } else {

    return (
      <div className="w-full">
        <Listbox
          value={userValue}
          onChange={(b) => handleSetUser(b)}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                Assignees
              </Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                  <span className="block truncate">
                    {
                      (() => {
                        let user = dropdownUsers.find((user) => user.user.id === userValue);

                        return `${user.user.firstName} ${user.user.surname}`
                      })()
                    }
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  appear={true}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {dropdownUsers.map((user, i) => (
                      <Listbox.Option
                        key={i}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-etpink-600"
                              : "text-gray-900",
                            "cursor-default select-none relative py-2 pl-8 pr-4"
                          )
                        }
                        value={user}
                      >
                        <>
                          <span
                            className={classNames(
                              userValue === user.user.id
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate"
                            )}
                          >
                            {`${user.user.firstName} ${user.user.surname}`}
                          </span>

                          {userValue === user.user.id ? (
                            <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
    )
  }
}