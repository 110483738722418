import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useGroups() {
  const staleTime = 100000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const groups = useQuery(
    "groups",
    () => get("/group").then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((group) => post("/group", group), {
    onSuccess: (addedGroup) => {
      queryClient.setQueryData("groups", (currentGroups) => [
        addedGroup.data,
        ...currentGroups,
      ]);
    },
  });

  const update = useMutation((group) => put(`/group/${group.id}`, group), {
    onSuccess: (updatedGroup) => {
      queryClient.setQueryData("groups", (currrentGroups) =>
        currrentGroups.map((group) =>
          group.id === updatedGroup.data.id ? updatedGroup.data : group
        )
      );
    },
  });

  const remove = useMutation((groupId) => del(`/group/${groupId}`), {
    onSuccess: (_, groupId) => {
      queryClient.setQueryData("groups", (currentGroups) =>
        currentGroups.filter((group) => group.id !== groupId)
      );
    },
  });

  return {
    groups,
    add,
    update,
    remove,
  };
}
