import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { highlight, languages } from 'prismjs/components/prism-core';
import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import useEmailTemplates from "../../data/useEmailTemplates";
import Editor from "react-simple-code-editor";
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';


export default function ViewEmailTemplate() {
    const { emailTemplateId } = useParams();
    const { emailTemplates: allEmailTemplates } = useEmailTemplates();
    const navigate = useNavigate();


    const [emailTemplate, setEmailTemplate] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);





    const loadEmailTemplate = useCallback(
        (emailTemplate) => {
            setEmailTemplate(emailTemplate);

        },
        []
    );
    useEffect(() => {
        if (!allEmailTemplates.isLoading) {
            let emailTemplate = allEmailTemplates.data.find((g) => g.id === emailTemplateId);
            loadEmailTemplate(emailTemplate);

        }
    }, [allEmailTemplates.isLoading, allEmailTemplates.data, loadEmailTemplate]);



    const pages = [
        { to: "/admin", label: "Email Template", current: false },
        {
            to: emailTemplate && emailTemplate ? `${emailTemplateId}` : "",
            label: emailTemplate ? emailTemplate.name : "Email Template",
            current: true,
        },
    ];


    if (isLoading || allEmailTemplates.isLoading) {
        return (
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="h-80 max-w-7xl flex items-center justify-center">
                    <LoadingWheel width="w-12" height="h-12" />
                </div>
            </div>
        );
    } else {
        return (
            <>

                <div className="px-4 sm:px-6 lg:px-8">
                    <Breadcrumbs pages={pages} />

                    <div className=" my-4">
                        <h3 className="text-xl font-semibold text-gray-900">
                            <button

                                className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                            >
                                Save Template
                            </button>
                            Subject: {emailTemplate?.subject}

                        </h3>

                        <div
                            dangerouslySetInnerHTML={{ __html: emailTemplate?.body }}
                            className="emailPreview my-4 float-left"

                        >
                        </div>
                        <div className="my-4"
                            style={{
                                maxHeight: "800px",
                                overflow: "auto",
                                backgroundColor: "#f5f2f0",
                            }}>

                            <Editor
                                value={emailTemplate ? emailTemplate.body : ""}
                                onValueChange={code => setEmailTemplate(emailTemplate => ({ ...emailTemplate, body: code }))}
                                highlight={code => highlight(code, languages.js)}
                                padding={10}
                                style={{
                                    fontFamily: '"Fira code", "Fira Mono", monospace',
                                    fontSize: 12,
                                }}
                            />

                        </div>
                    </div>


                </div>
            </>
        );
    }
}

