import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useContentEngineCategories() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const categories = useQuery(
    "contentEngineCategories",
    () => get("/contentengine/categories").then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (category) => post("/contentengine/categories", category),
    {
      onSuccess: (addedCategory) => {
        queryClient.setQueryData("contentEngineCategories", (currentCategories) => [
          addedCategory.data,
          ...currentCategories,
        ]);
      },
    }
  );

  const update = useMutation(
    (category) => put(`/contentengine/categories/${category.id}`, category),
    {
      onSuccess: (updatedCategory) => {
        queryClient.setQueryData("contentEngineCategories", (currentCategories) =>
          currentCategories.map((category) =>
            category.id === updatedCategory.data.id ? updatedCategory.data : category
          )
        );
      },
    }
  );

  const remove = useMutation(
    (categoryId) => del(`/contentengine/categories/${categoryId}`),
    {
      onSuccess: (_, categoryId) => {
        queryClient.setQueryData("contentEngineCategories", (currentCategories) =>
          currentCategories.filter((category) => category.id !== categoryId)
        );
      },
    }
  );

  return {
    categories,
    add,
    update,
    remove,
  };
}
