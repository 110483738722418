import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useIntegrations(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const integrations = useQuery(["integrations", agencyId], () => get(`/Integration/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const add = useMutation((integration) => post(`/Integration/${agencyId}`, integration), {
    onSuccess: (addedIntegration) => {
      console.log(addedIntegration);
      queryClient.setQueryData(["integrations", agencyId], (curentIntegrations) => 
      [
        ...curentIntegrations,
        addedIntegration.data,
      ]
      );
    },
  });

  const update = useMutation((integration) => put(`/Integration/${integration.id}`, integration), {
    onSuccess: (updatedIntegration) => {
      queryClient.setQueryData(["integrations", agencyId], (curentIntegrations) =>
        curentIntegrations.map((integration) =>
          integration.id === updatedIntegration.id ? updatedIntegration : integration
        )
      );
    },
  });

  const remove = useMutation((integrationId) => del(`/Integration/${integrationId}`), {
    onSuccess: (_, integrationId) => {
      queryClient.setQueryData(["integrations", agencyId], (curentIntegrations) =>
      curentIntegrations.filter((integration) => integration.id !== integrationId)
      );
    },
  });

  return {
    integrations,
    add,
    update,
    remove,
  };
}
