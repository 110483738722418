import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useGroupAgencies(groupId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, put } = useApiHelper();

  const groupAgencies = useQuery(
    ["groupAgencies", groupId],
    () => get(`/group/${groupId}/agencies`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );


  const update = useMutation((agencyIds) => put(`/group/${groupId}/agencies`, agencyIds), {
    onSuccess: (updatedGroup) => {
      queryClient.setQueryData(["groupAgencies", groupId], () =>
        updatedGroup.data
      );
      queryClient.invalidateQueries(["groups"]);
    },
  });

  const updateYoastHeaders = useMutation(() => put(`/group/${groupId}/agencies/yoast`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["groupAgencies", groupId]);
    },
  });


  return {
    groupAgencies,
    update,
    updateYoastHeaders
  };
}
