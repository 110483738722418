import { useEffect, useState } from "react";
import { PencilAltIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

import Flyout from "../../containers/Flyout";
import ConfirmDelete from "../Shared/ConfirmDelete";
import useBlogPostCategories from "../../data/useBlogPostCategories";
import AgencyCategoryAddEditPartial from "./AgencyCategoryAddEditPartial";

function CategoryListRow({ category, onEditClick, onDeleteClick }) {

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="text-sm grow font-medium text-etpink-600 md:w-1/5">
          {category.name}
        </p>
        <span
          className={`truncate px-3 font-medium text-sm md:ml-2 rounded-full ${category.fromContentEngine
            && "bg-blue-100 text-blue-500"}`}
        >
          {category.fromContentEngine && "Default"}
        </span>
      </div>
      {!category.fromContentEngine &&
        <>
          <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
            <button
              onClick={() => onDeleteClick(category.id)}
              className="mx-2 text-gray-400 hover:text-red-600"
            >
              <span className="sr-only">Delete</span>
              <TrashIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
            <button
              onClick={() => onEditClick(category)}
              className="mx-2 text-gray-400 hover:text-gray-600"
            >
              <span className="sr-only">Edit</span>
              <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </>
      }
    </li>
  );
}

export default function AgencyCategoryListPartial({ agencyId }) {
  const { categories, add, update, remove } = useBlogPostCategories(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Category");
  const [open, setOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deleteCategory = (categoryId) => {
    setDeleteId(categoryId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const handleEditCategory = (category) => {
    setEditCategory(category);
    setOpen(true);
  };

  const handleSaveCategory = (category) => {
    console.log("category", category);

    if (category.id) {
      update.mutate(category, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      });
    } else {
      add.mutate(category, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      });
    }
  };

  useEffect(() => { }, [categories.data]);

  return (
    <>
      <ConfirmDelete
        itemName="Category"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <Flyout
        open={open}
        setOpen={setOpen}
        title={editCategory.name ? editCategory.name : "Add New Category"}
      >
        <AgencyCategoryAddEditPartial category={editCategory} handleSaveCategory={handleSaveCategory} />
      </Flyout>
      <h3 className="text-xl font-medium text-gray-900">
        Blog Post Categories
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Manage your categories for blog posts
      </p>
      <button
        type="button"
        onClick={() => {
          setEditCategory({});
          setOpen(true);
        }}
        className="my-6 flex items-center justify-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Add Category
      </button>
      <ul className="border border-gray-200 bg-white rounded-md divide-y divide-gray-200 my-4">
        {categories.isLoading ? (
          <li key="branches-loading" className="py-4 flex justify-center">
            <LoadingWheel width="w-8" height="h-8" />
          </li>
        ) : categories.data.length > 0 ? (
          categories.data.map((category, i) => (
            <CategoryListRow
              category={category}
              key={i}
              onEditClick={handleEditCategory}
              onDeleteClick={deleteCategory}
            />
          ))
        ) : (
          <li>
            <div className="py-5 text-center text-sm text-gray-400">
              No Blog Post Categories
            </div>
          </li>
        )}
      </ul>
    </>
  );
}
