import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function usePropertiesTopViewed() {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const properties = useQuery(["topProperties", agency.id], () => get(`/Property/${agency.id}/topViewed`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );


  return {
    properties,
  };
}
