import { useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { Listbox, Transition } from "@headlessui/react";

import {
  SelectorIcon,
  CheckIcon,
  PlusIcon,
  MinusCircleIcon,
} from "@heroicons/react/outline";

import classNames from "../../utils/classNames";
import LoadingWheel from "../Shared/LoadingWheel";

import useAgencyBranches from "../../data/useAgencyBranches";
import useAgencyUsers from "../../data/useAgencyUsers";
import useAgencyDepartments from "../../data/useAgencyDepartments";
import DepartmentAddUserModal from "./DepartmentAddUserModal";

export default function DepartmentAddEditPartial({
  agencyId,
  department,
  onSave,
}) {
  const { branches } = useAgencyBranches(agencyId);
  const { users } = useAgencyUsers(agencyId);
  const [departmentUsers, setDepartmentUsers] = useState(
    department.departmentUsers ?? []
  );
  const [addOpen, setAddOpen] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: department.id,
      name: department.name,
      branchId: department.branchId,
    },
  });

  const handleUserAdd = (users) => {
    setDepartmentUsers((oldUsers) => [...oldUsers, ...users]);
    setAddOpen(false);
  };

  const getUserName = (userId) => {
    let user = users.data.find((cu) => cu.userId === userId);

    return `${user.user.firstName} ${user.user.surname}`;
  };

  const removeUser = (userId) => {
    setDepartmentUsers((oldUsers) =>
      oldUsers.filter((ou) => ou.userId !== userId)
    );
  };

  const branchIdValue = watch("branchId");

  return (
    <>
      <DepartmentAddUserModal
        open={addOpen}
        setOpen={setAddOpen}
        currentUsers={departmentUsers}
        agencyId={agencyId}
        onAddClick={handleUserAdd}
      />
      <form
        onSubmit={handleSubmit((data) =>
          onSave({ ...data, users: departmentUsers.map((du) => du.userId) })
        )}
      >
        <div className="my-4 block">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            Department Name
          </label>
          <div className="mt-1">
            <input
              {...register("name", { required: true })}
              type="text"
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Commercial Lettings"
            />
          </div>
          {errors.name && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Department Name is required
            </p>
          )}
        </div>

        <div className="my-4 block">
          {branches.isLoading ? (
            <div className="py-4 flex justify-center">
              <LoadingWheel width="w-6" height="h-6" />
            </div>
          ) : (
            <Listbox
              {...register("branchId")}
              value={branchIdValue}
              onChange={(v) => setValue("branchId", v)}
              disabled={department.id !== undefined}
            >
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium text-gray-700">
                    Branch
                  </Listbox.Label>
                  <div className="mt-1 relative">
                    <Listbox.Button
                      className={`relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm ${
                        department.id !== undefined ? "bg-gray-100" : "bg-white"
                      }`}
                    >
                      <span className="block truncate">
                        {branchIdValue
                          ? branches.data.find((b) => b.id === branchIdValue)
                              .name
                          : "Choose..."}
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {branches.data.map((branch) => (
                          <Listbox.Option
                            key={branch.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-etpink-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-8 pr-4"
                              )
                            }
                            value={branch.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? "font-semibold" : "font-normal",
                                    "block truncate"
                                  )}
                                >
                                  {branch.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-etpink-600",
                                      "absolute inset-y-0 left-0 flex items-center pl-1.5"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
        </div>

        {department.id !== undefined && (
          <div className="my-4 block">
            <div className="flex items-end justify-between">
              <label className="block text-sm font-medium text-gray-700">
                Users
              </label>
              <button
                onClick={() => setAddOpen(true)}
                type="button"
                className="text-center w-full md:w-auto flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                <PlusIcon className="w-3 h-3 mr-1" />
                Add
              </button>
            </div>
            <div className="mt-2">
              {users.isLoading ? (
                <div className="py-4 flex justify-center">
                  <LoadingWheel width="w-6" height="h-6" />
                </div>
              ) : (
                <ul
                  role="list"
                  className="rounded border relative z-0 divide-y divide-gray-200"
                >
                  {departmentUsers.length > 0 ? (
                    departmentUsers.map((u) => (
                      <li key={u.userId} className="bg-white">
                        <div className="relative px-4 py-2 flex items-center space-x-3">
                          <div className="flex-1 min-w-0">
                            <p className="text-sm font-medium text-gray-900">
                              {getUserName(u.userId)}
                            </p>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              onClick={() => removeUser(u.userId)}
                              className="px-1 py-1 flex items-center rounded text-red-500 hover:text-red-700"
                            >
                              <MinusCircleIcon className="w-5 h-5" />
                            </button>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>
                      <div className="py-4 text-center text-sm text-gray-400">
                        No Users
                      </div>
                    </li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}

        <div className="mt-8">
          <button
            type="submit"
            className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            {getValues("id") ? "Save" : "Create"}
          </button>
        </div>
      </form>
    </>
  );
}
