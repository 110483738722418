import { useEffect, useState } from "react";

import date from 'date-and-time';
import { useNavigate } from "react-router-dom";

import { PencilAltIcon, SearchIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import useFormLeads from "../../data/useFormLeads";
import useForms from "../../data/useForms";
import ConfirmModal from './../../components/Shared/ConfirmModal';
import { formLeadStatusEnum, formTypeEnum } from './../../constants/enums';
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';
import Avatar from "../../components/Shared/Avatar";
import useUserContext from "../../contexts/UserContext";

function FormRow({ form: formLead, onDeleteClick }) {
  const { user: { agency } } = useUserContext();
  const navigate = useNavigate();
  const { forms } = useForms();
  const [form, setForm] = useState(forms.data?.find((f) => f.id === formLead.formId));

  useEffect(() => {
    // console.log(form.)
  }, [agency]);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteClick(formLead.id);
  };

  return (
    <tr
      onClick={() => navigate(`formlead/${formLead.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell">
        {formLead.userId &&
          <Avatar classes="w-10 h-10" userId={formLead.userId} />
        }
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {formLead.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Email</dt>
          <dd className="mt-1 truncate">
            {formLead.emailAddress}
          </dd>
          <dt className="sr-only sm:hidden">Form</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {form?.title}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {formLead.emailAddress}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {form?.title}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formTypeEnum[form?.type]}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${formLead.status === 0 || formLead.status === 1 ? ("text-yellow-600 bg-yellow-100") :
              (formLead.status === 2 ?
                ("text-blue-600 bg-blue-100")
                : ("text-green-600 bg-green-100")
              )}`}
        >
          {formLeadStatusEnum[formLead.status]}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {agency && formLead.sourceUrl?.replace(agency?.domain, "").replace("/landing/", "")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formLead.source}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(formLead.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formLead.followUpDate ? date.format(new Date(formLead.followUpDate), "DD/MM/YYYY") : "None"}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={() => navigate(`formlead/${formLead.id}`)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={(e) => handleDelete(e)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2  text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}


export default function FormLeads({ }) {
  const navigate = useNavigate();
  const { forms } = useForms();
  const { formLeads: allFormLeads, remove } = useFormLeads();
  const { deleteHandlers } = useDefaultCRUDHandlers("Forms");
  const [formLeads, setFormLeads] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const deleteForm = (formId) => {
    setDeleteId(formId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Form Leads";
    if (!allFormLeads.isLoading && !forms.isLoading) {

      let filter = allFormLeads.data;

      filter = filter.filter((lead) =>
        lead.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        forms.data.find((form) => form.id === lead.formId).title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.source?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFormLeads(filter);
      setIsLoading(false);
    }
  }, [allFormLeads.isLoading, allFormLeads.data, forms.isLoading, searchTerm]);

  return (
    <>
      <ConfirmModal
        title={"Form Lead"} message={`Are you sure you want to delete this lead?`}
        open={confirmDeleteOpen} setOpen={setConfirmDeleteOpen} onConfirm={confirmDelete}
        confirmButtonText={"Delete"}
      />
      <div className="px-4 sm:px-6 lg:px-8">

        <div className="sm:flex sm:items-center">
          <div className="flex grow flex-col sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Form Leads
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              View and Manage the leads that come from your custom forms here.
            </p>
          </div>

          <div>
            <div className="mt-2 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name, Form or Source"
                />
              </div>
            </div>
          </div>

          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to={isLandingPage ? "landingpage" : "form"}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add New Form
            </Link>
          </div> */}

        </div>


        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : formLeads?.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {/* Avatar */}
                  </th>
                  <th
                    scope="col"
                    className="hidden !pl-6 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Name</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Email</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Form</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Type</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Status</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Source URL</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 !pl-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Source</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Date Created</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Follow Up Date</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {formLeads.map((form) => (
                  <FormRow
                    key={form.id}
                    form={form}
                    onDeleteClick={deleteForm}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="form lead"
                  hasButton={false}
                />
              ) : (
                <NoItems searching itemName="form lead" />
              )}
            </div>
          )}
        </div>

      </div>
    </>
  );
}