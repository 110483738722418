
import React, { useEffect, useRef, useState } from "react";
import LoadingWheel from "../../components/Shared/LoadingWheel";

import { withAgency } from "../../data/withAgency";

import RequestsByPortalTable from "../../components/Analytics/Portal/RequestsByPortalTable";
import usePortalReports from "../../data/usePortalReports";
import useAreaGuides from "../../data/useAreaGuides";
import formatCurrency from "../../utils/formatCurrency";

function _AreaGuideData({ agencyReady, agency }) {
  const { areaGuides } = useAreaGuides(agency?.id);
  const [isLoading, setIsLoading] = useState(true);
  const [areaGuide, setSelectedAreaGuide] = useState(null);

  useEffect(() => {
    if (agencyReady && !areaGuides.isLoading && areaGuides.data) {
      setSelectedAreaGuide(areaGuides.data[0])

      setIsLoading(false);
    }
  }, [agency, agencyReady, areaGuides.data, areaGuides.isLoading])

  const handleAreaGuideChange = (e) => {
    setSelectedAreaGuide(areaGuides.data.find((areaGuide) => areaGuide.id === e))
  }

  if (isLoading) {
    return(
      <div className="px-12 flex flex-center h-100">
        <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    )
  } else {
    return (
      <div className="px-12">
        <div className="sm:flex sm:items-center justify-between">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Area Guide Data</h1>
            <p className="mt-2 text-sm text-gray-700">
              You'll be able to see your area guides here.
            </p>
          </div>
          <div>
            <select onChange={(e) => handleAreaGuideChange(e.target.value)}>
              {areaGuides.data.map((areaGuide) => (
                <option key={areaGuide.id} value={areaGuide.id}>{areaGuide.name}</option>
              ))}
            </select>
          </div>
        </div>


        <div className="grid grid-cols-1 lg:grid-cols-4 mt-4 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >

          {areaGuides.isLoading || isLoading ? (
            <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Average Property Price
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.allSold12MonthPriceAvg)} <br />
                  <span className="text-base">£{areaGuide.allAvgPpsf} per square foot</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Price Change
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.allSold12MonthPriceChange > 0 && "+"} {areaGuide.allSold12MonthPriceChange}% <br />
                  <span className="text-base">Last 12 Months</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Price Change
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.allSold5YearPriceChange > 0 && "+"}{areaGuide.allSold5YearPriceChange}% <br />
                  <span className="text-base">Last 5 Years</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Properties Sold
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.allSoldLast12Months} <br />
                  <span className="text-base">Last 12 Months</span>
                </dd>
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 mt-4 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >

          {areaGuides.isLoading || isLoading ? (
            <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Rental Value Change
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.avgRentalGrowth > 0 && "+"} {areaGuide.avgRentalGrowth}% <br />
                  <span className="text-base">In Last 12 Months</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Average House Rent
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.houseRentAvgPerMonth)} <br />
                  <span className="text-base">Per Calendar Month</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Average Flat Rent
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.flatRentAvgPerMonth)} <br />
                  <span className="text-base">Per Calendar Month</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Average Rental Yield
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.averageYield > 0 ? "+" : "-"} {areaGuide.averageYield}% <br />
                </dd>
              </div>
            </>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-4 mt-4 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >

          {areaGuides.isLoading || isLoading ? (
            <div className="py-2 col-span-1 lg:col-span-4 mx-auto">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : (
            <>
              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Detached House Stats
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.detachedSold12MonthPriceAvg)} <br />
                  <span className="text-base">Average Price</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.detachedSold12MonthPriceChange > 0 && "+"} {areaGuide.detachedSold12MonthPriceChange}% <br />
                  <span className="text-base">12 Month Price Change</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.detachedMarketSharePercent > 0 && "+"} £{areaGuide.detachedMarketSharePercent}% <br />
                  <span className="text-base">% Of Market</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Semi-Detached House Stats
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.semiSold12MonthPriceAvg)} <br />
                  <span className="text-base">Average Price</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.semiSold12MonthPriceChange > 0 && "+"} {areaGuide.semiSold12MonthPriceChange}% <br />
                  <span className="text-base">12 Month Price Change</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.semiMarketSharePercent > 0 && "+"} £{areaGuide.semiMarketSharePercent}% <br />
                  <span className="text-base">% Of Market</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Terraced House Stats
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.terraceSold12MonthPriceAvg)} <br />
                  <span className="text-base">Average Price</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.terraceSold12MonthPriceChange > 0 && "+"} {areaGuide.terraceSold12MonthPriceChange}% <br />
                  <span className="text-base">12 Month Price Change</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.terraceMarketSharePercent > 0 && "+"} £{areaGuide.terraceMarketSharePercent}% <br />
                  <span className="text-base">% Of Market</span>
                </dd>
              </div>

              <div className="flex flex-col items-center px-4">
                <dt className="font-medium text-gray-700 truncate">
                  Flat Stats
                </dt>
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {formatCurrency(areaGuide.flatSold12MonthPriceAvg)} <br />
                  <span className="text-base">Average Price</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.flatSold12MonthPriceChange > 0 && "+"} {areaGuide.flatSold12MonthPriceChange}% <br />
                  <span className="text-base">12 Month Price Change</span>
                </dd>
                <hr className="w-4/5 my-2" />
                <dd className="mt-1 text-3xl text-center font-semibold text-etpink-600">
                  {areaGuide.flatMarketSharePercent > 0 && "+"} £{areaGuide.flatMarketSharePercent}% <br />
                  <span className="text-base">% Of Market</span>
                </dd>
              </div>
            </>
          )}
        </div>
        
      </div>
    )
  }
}


export const AreaGuideData = withAgency(_AreaGuideData);