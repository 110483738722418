import { useEffect, useState } from "react";

import LoadingWheel from "../Shared/LoadingWheel";
import useIntegrations from "../../data/useIntegrations";

import StreetLogo from "./Assets/StreetLogo.png";
import ZapierLogo from "./Assets/ZapierLogo.png";
import JupixLogo from "./Assets/JupixLogo.png";
import Apex27Logo from "./Assets/Apex27Logo.png";
import AltoLogo from "./Assets/AltoLogo.png";
import LoopLogo from "./Assets/LoopLogo.svg";

const availableIntegrations = [
  {
    appName: "Street",
    logo: StreetLogo,
  },
  {
    appName: "Zapier",
    logo: ZapierLogo,
  },
  {
    appName: "Jupix",
    logo: JupixLogo,
  },
  {
    appName: "Apex27",
    logo: Apex27Logo,
  },
  {
    appName: "Loop",
    logo: LoopLogo,
  },
  {
    appName: "Alto",
    logo: AltoLogo,
  },
];

function IntegrationsListRow({ integration }) {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    switch (integration.appName) {
      case "Street":
        setLogo(StreetLogo)
        break;

      default:
        break;
    }
  }, [integration])

  return (
    <li className="p-3 flex items-center border border-gray-200 bg-white rounded-md hover:bg-gray-100">
      <div className="w-full items-center flex justify-between">
        <div>{integration.appName}</div>
        <div><img className="w-20" src={logo} /></div>
      </div>
    </li>
  );
}

export default function IntegrationsSettingsViewPartial({ agencyId }) {
  const { integrations } = useIntegrations(agencyId);

  if (integrations.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <div className="divide-y">
          <div>
            <div className="-mx-6 px-6">
              <h3 className="text-xl font-medium text-gray-900">
                Integration Settings
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                See what apps your agency has integrated with
              </p>
            </div>
            <ul className=" grid grid-cols-1 md:grid-cols-2 gap-5 my-6">
              {integrations.isLoading ? (
                <li key="userInvites-loading" className="py-4 flex justify-center">
                  <LoadingWheel width="w-8" height="h-8" />
                </li>
              ) : integrations.data.length > 0 ? (
                integrations.data.map((integration, i) => (
                  <IntegrationsListRow
                    integration={integration}
                    key={i}
                  />
                ))
              ) : (
                <li>
                  <div className="py-5 text-center text-sm text-gray-400">
                    No Integrations Configured
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div className="pt-5">
            <h3 className="text-xl font-medium text-gray-900">
              Available Integrations
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Please contact EstateTrack to get connected!
            </p>

            {/* grid of different logos */}
            <ul className=" grid grid-cols-1 md:grid-cols-2 gap-5 my-6">
              {availableIntegrations.map((integration, i) => (
                <li className="p-3 flex items-center">
                  <div className="w-full items-center gap-8 flex justify-center">
                    {/* <div>{integration.appName}</div> */}
                    <div><img className="w-36" src={integration.logo} /></div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </>
    );
  }
}
