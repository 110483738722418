import DefaultLeftSide from "./DefaultLeftSide"
import DefaultRightSide from "./DefaultRightSide"

export const JsonDefaultConfig = ({ register, placeholder }) => {

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

          <DefaultLeftSide register={register} placeholder={placeholder} />

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        <DefaultRightSide register={register} />

      </div>

    </div>
  )
}