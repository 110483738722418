import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyGroups(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, put } = useApiHelper();

  const groups = useQuery(
    ["agencyGroups", agencyId],
    () => get(`/agency/${agencyId}/groups`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation(
    (groups) => put(`/agency/${agencyId}/groups`, groups),
    {
      onSuccess: (updatedGroups) => {
        queryClient.setQueryData(
          ["agencyGroups", agencyId],
          () => updatedGroups.data
        );
      },
    }
  );

  return {
    groups,
    update,
  };
}
