import { Fragment, useState } from "react";

import DatePicker from "react-datepicker";
import { useForm, useFormState } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import useNotification from "../../components/notification/useNotifications";
import BranchListBox from "../../components/Shared/BranchListBox";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import { valuationPropertyTypeEnum, valuationTypeEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useValuations from "../../data/useValuations";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import classNames from "../../utils/classNames";

import "react-datepicker/dist/react-datepicker.css";

import "./datepicker.css";

let minTime = new Date();
minTime.setHours(10);
minTime.setMinutes(0);

let maxTime = new Date();
maxTime.setHours(18);
maxTime.setMinutes(30);

export default function ValuationAdd() {
  const { user: { agency }, } = useUserContext();
  const navigate = useNavigate();
  const { add } = useValuations();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm({
      defaultValues: {
        propertyType: 0,
        valuationType: 0,
        appointmentDateTime: new Date(),
      },
    });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { addNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [branch, setBranch] = useState(null);

  const onSubmit = (data) => {
    setIsLoading(true);

    let valuation = {
      yourName: data.yourName,
      telephoneNumber: data.telephoneNumber,
      emailAddress: data.emailAddress,
      propertyType: data.propertyType,
      valuationType: data.valuationType,
      appointmentDateTime: data.appointmentDateTime,
      houseNumber: data.houseNumber,
      street: data.street,
      town: data.town,
      postcode: data.postcode,
      sourceName: "Manual",
      branchId: branch?.id,
    };

    add.mutate(valuation, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        navigate(`/${agency.logicalName}/valuations`);
      },
      onError: () => {
        saveHandlers.onError();
        setIsLoading(false);
      }
    });

    reset({
      yourName: data.yourName,
      telephoneNumber: data.telephoneNumber,
      emailAddress: data.emailAddress,
      propertyType: data.propertyType,
      houseNumber: data.houseNumber,
      street: data.street,
      town: data.town,
      postcode: data.postcode,
    });
  };

  const pages = [
    { to: "..", label: "Valuations", current: false },
    {
      to: "",
      label: "Book Valuation",
      current: true,
    },
  ];

  const propertyTypes = Object.values(valuationPropertyTypeEnum).map(
    (t, i) => ({ name: t, value: i })
  );

  const valuationTypes = Object.values(valuationTypeEnum).map(
    (t, i) => ({ name: t, value: i })
  );

  const propertyTypeValue = watch("propertyType");
  const appointmentDateTimeValue = watch("appointmentDateTime");
  const valuationTypeValue = watch("valuationType");

  return (
    isLoading ? (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    ) : (
      <>
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="max-w-7xl">
            <div className="max-w-3xl">
              <div className="sm:flex-auto my-4">
                <h3 className="text-xl font-semibold text-gray-900">
                  Book a Valuation
                </h3>
                <p className="mt-2 text-sm text-gray-700">
                  Book an in person valuation
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-4 -mx-2 flex items-center">
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="yourName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("yourName")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="John Doe"
                      />
                    </div>
                  </div>
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="telephoneNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Telephone Number
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("telephoneNumber")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="01234123456"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4 -mx-1">
                  <label
                    htmlFor="emailAddress"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email Address
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("emailAddress")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="john.doe@email.com"
                    />
                  </div>
                </div>
                <div className="my-4 -mx-2 flex items-center">
                  <div className="mx-1 w-1/2">
                    <Listbox
                      {...register("propertyType")}
                      value={propertyTypeValue}
                      onChange={(v) => setValue("propertyType", v)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Property Type
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  propertyTypes.find(
                                    (t) => t.value === propertyTypeValue
                                  ).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {propertyTypes.map((t) => (
                                  <Listbox.Option
                                    key={t.value}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={t.value}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("propertyType") === t.value
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {t.name}
                                      </span>

                                      {getValues("propertyType") === t.value ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="houseNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Appointment Date & Time
                    </label>
                    <div className="mt-1">
                      <DatePicker
                        selected={appointmentDateTimeValue}
                        onChange={(v) => setValue("appointmentDateTime", v)}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy p"
                        minDate={new Date()}
                        minTime={minTime}
                        maxTime={maxTime}
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4 -mx-2 flex items-center">
                  <div className="mx-1 w-1/2">
                    <Listbox
                      {...register("valuationType")}
                      value={valuationTypeValue}
                      onChange={(v) => setValue("valuationType", v)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                            Valuation Type
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                              <span className="block truncate">
                                {
                                  valuationTypes.find(
                                    (t) => t.value === valuationTypeValue
                                  ).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {valuationTypes.map((t) => (
                                  <Listbox.Option
                                    key={t.value}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={t.value}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("valuationType") === t.value
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {t.name}
                                      </span>

                                      {getValues("valuationType") === t.value ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="houseNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      House Name/Number
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("houseNumber")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="12"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4 -mx-1">
                  <label
                    htmlFor="street"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Street
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("street")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Placeholder Street"
                    />
                  </div>
                </div>
                <div className="my-4 -mx-2 flex items-center">
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="town"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Town
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("town")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Example Town"
                      />
                    </div>
                  </div>
                  <div className="mx-1 w-1/2">
                    <label
                      htmlFor="postcode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Postcode
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("postcode")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="SW1 1AA"
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4 -mx-2 flex items-center">
                  <div className="mx-1 w-1/2">
                    <BranchListBox setBranch={setBranch} none={true} />
                  </div>
                  <div>

                  </div>
                </div>
                <button
                  type="submit"
                  className="saveReview inline-flex items-center px-4 py-2 my-6 mr-2 mb-10 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                  Book Valuation
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  );
}

