import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import date from "date-and-time";
import ordinal from "date-and-time/plugin/ordinal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  interaction: {
    intersect: false,
    mode: "index"
  },
  scales: {
    x: {
      grid: {
        drawBorder: true,
        display: false
      },
      ticks: {
        display: true
      }
    },
    y: {
      grid: {
        drawBorder: true,
        display: true
      },
      ticks: {
        display: true
      }
    }
  }
};

date.plugin(ordinal);

const d = new Date();

const labels = Array(30)
  .fill("")
  .map((_, i) => new Date(new Date().setDate(new Date().getDate() - (i + 1))),)
  .reverse();


export default function WebsiteAnalyticsGraph({ stats }) {

  const data = {
    labels: labels.map((day) => date.format(new Date(day), "ddd DD")),
    datasets: [
      {
        label: "Visitors",
        data: labels.map((day) => {
          const dateString = date.format(new Date(day), "YYYY-MM-DD");
          const matchingData = stats.timeSeries.find((item) => item.date === dateString);
          return matchingData ? matchingData.visitors : 0;
        }),
        borderColor: "#2563eb",
        backgroundColor: "#2563eb",
        lineTension: 0.3,
      },
      {
        label: "Page Views",
        data: labels.map((day) => {
          const dateString = date.format(new Date(day), "YYYY-MM-DD");
          const matchingData = stats.timeSeries.find((item) => item.date === dateString);
          return matchingData ? matchingData.pageViews : 0;
        }),
        borderColor: "#7CFC00",
        backgroundColor: "#7CFC00",
        // hidden: true,
        lineTension: 0.3,
      },
      {
        label: "Visit Duration (Seconds)",
        data: labels.map((day) => {
          const dateString = date.format(new Date(day), "YYYY-MM-DD");
          const matchingData = stats.timeSeries.find((item) => item.date === dateString);
          return matchingData ? matchingData.visitDuration : 0;
        }),
        borderColor: "#BE33FF",
        backgroundColor: "#BE33FF",
        // hidden: true,
        lineTension: 0.3,
      },
      {
        label: "Bounce Rate (%)",
        data: labels.map((day) => {
          const dateString = date.format(new Date(day), "YYYY-MM-DD");
          const matchingData = stats.timeSeries.find((item) => item.date === dateString);
          return matchingData ? matchingData.bounceRate : 0;
        }),
        borderColor: "#FFBE33",
        backgroundColor: "#FFBE33",
        // hidden: true,
        lineTension: 0.3,
      },
    ],
  };


  return <Line options={options} data={data} />;
}
