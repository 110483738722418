import { Fragment, useCallback, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Dialog, Transition } from "@headlessui/react";
import { XCircleIcon } from '@heroicons/react/solid'

import useApiHelper from "../../services/useApiHelper";
import useNotification from "../notification/useNotifications";
import LoadingWheel from "../Shared/LoadingWheel";
import UnsplashGallery from "./UnsplashGallery";
import useUserContext from "../../contexts/UserContext";


export default function AiPromptModal({
  postTitle = "",
  blogPost,
  open,
  setOpen,
  onGenerate,
  onInsertImage
}) {
  const { user: { agency } } = useUserContext();
  const { post } = useApiHelper();
  const { addNotification } = useNotification();
  const [aiContent, setAiContent] = useState(undefined)
  const [imageSearchQuery, setImageSearchQuery] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [loadingImages, setLoadingImages] = useState(false)
  const [error, setError] = useState(undefined)
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    let timer;

    if (loading) {
      timer = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % loadingArray.length);
      }, 3000); // Change every 3 seconds
    } else {
      clearInterval(timer);
      setCurrentIndex(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [loading]);

  const onSubmit = (data) => {
    setError(undefined)
    setLoading(true);
    setAiContent(undefined);

    let postData = { ...data, agencyId: agency?.id }
    // let postData = { ...data, agencyId: agency?.id }
    postData.Keywords = postData.Keywords.split(",").map(keyword => keyword.trim());
    post(`/aiblogwriter/prompt`, postData).then(res => {
      if (Object.hasOwn(res, 'data') && res.data.choices.length > 0) {
        const jsonRes = JSON.parse(res.data.choices[0].message.content)
        insertContent(jsonRes, postData)
        setImageSearchQuery(postData.Keywords.join(','))
      } else {
        setError('Error getting AI content. Please reload the page and try again.')
      }
    }).catch(e => {
      setError(e)
      setLoading(false)
    })
  }



  const handleSubmitWithoutPropagation = e => {
    e.preventDefault()
    e.stopPropagation();
    handleSubmit(onSubmit)(e)
  }

  const insertContent = (content, postdata) => {
    setAiContent(content)
    onGenerate(content, postdata)
    addNotification({
      variant: "success",
      primaryText: "AI content successfully generated!",
    });
  }

  const resetModal = useCallback(() => {
    setOpen(false);
    reset();
    setError(undefined)
    setImageSearchQuery(undefined)
    setLoading(false)
    setAiContent(undefined);
  }, [reset, setOpen])

  const onImageFetched = useCallback(() => {
    setLoadingImages(false);
    setLoading(false)
  }, [])

  const onUnsplashGalleryError = useCallback((message) => {
    setError(message);
    setLoadingImages(false);
    setLoading(false)
  }, [])

  const onImagesLoading = useCallback(() => {
    setError(false);
    setLoading(true);
    setLoadingImages(true);
  }, [])

  const onImageSelected = useCallback((imageId) => {
    setLoadingImages(true)
    onInsertImage(imageId)
    resetModal()
  }, [resetModal, onInsertImage])

  const inputClassName = "shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block sm:text-sm border-gray-300 rounded-md disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500"
  const buttonClassName = "inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm disabled:cursor-not-allowed"
  const actionButtonClassName = `${buttonClassName} border-transparent bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 sm:col-start-2`
  const cancelButtonClassName = `${buttonClassName} w-full border-gray-300 bg-white text-gray-700 hover:bg-gray-50 focus:ring-etpink-500 sm:mt-0 sm:col-start-1`

  const loadingArray = [
    "Submitting your request...",
    "Talking to the AI...",
    "Asking very nicely...",
    "The AI is thinking...",
    "AI writing the post so you don't have to...",
    "The AI is finding inspiration...",
    "Running through a neural network...",
    "Translating from AI to human...",
    "Decoding the AI's message...",
    "Formatting your article...",
    "This can take a few minutes...",
    "Won't be long now...",
    "Still quicker than writing it yourself!",
    "Making a deal...",
    "Filling in the gaps...",
    "Loading", "Loading.", "Loading..", "Loading...", "Loading", "Loading.", "Loading..", "Loading...", "Loading", "Loading.", "Loading..", "Loading..."
  ]

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-6xl sm:w-full sm:p-6">
                <div className="flex items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Create an article using AI
                  </Dialog.Title>
                </div>

                <div className="mt-3 sm:mt-5">

                  {error &&
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">Error</h3>
                          <div className="mt-2 text-sm text-red-700">
                            <p>{error}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {!aiContent &&
                    <form onSubmit={handleSubmitWithoutPropagation}>
                      <div className="titleContainer my-4">
                        <label
                          htmlFor="Title"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Enter the title
                        </label>
                        <div className="mt-1">
                          <input
                            {...register("Title", { required: true, minLength: 10 })}
                            disabled={loading}
                            type="text"
                            className={`${inputClassName} w-full`}
                            placeholder="E.g.: Housing trends for 2024"
                          />
                        </div>
                      </div>

                      <div className="titleContainer my-4">
                        <label
                          htmlFor="keywords"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          Add a few keywords (comma separated)
                        </label>
                        <input
                          {...register("Keywords", { required: true })}
                          disabled={loading}
                          type="text"
                          className={`${inputClassName} w-full`}
                          placeholder="housing trends, housing market"
                        />
                      </div>

                      <div className="titleContainer my-4">
                        <label
                          htmlFor="ArticleTone"
                          className="block mb-2 text-sm font-medium text-gray-700"
                        >
                          What tone should the article have?
                        </label>
                        <div className="mt-1">
                          <select
                            {...register("ArticleTone")}
                            disabled={loading}
                            className={inputClassName}
                          >
                            <option value="Informative">Informative</option>
                            <option value="Friendly">Friendly</option>
                            <option value="Salesy">Salesy</option>
                          </select>
                        </div>
                      </div>


                      <div className="mt-1">
                        <button
                          type="submit"
                          disabled={loading}
                          className={`${actionButtonClassName} disabled:bg-etpink-100 disabled:text-gray-500`}
                        >
                          Generate content
                        </button>
                      </div>
                    </form>
                  }

                  {loading &&
                    <div className="px-4 sm:px-6 lg:px-8">
                      <div className="max-w-7xl flex flex-col items-center justify-center">
                        {loadingImages ?
                          <p className="mb-4">Getting images, please wait...</p>
                          :
                          <p className="mb-4">Generating AI content, please wait... This can take up to a minute...</p>
                        }
                        <LoadingWheel width="w-12" height="h-12" />
                        {!loadingImages && <p className="mt-4">{loadingArray[currentIndex]}</p>}
                      </div>
                    </div>
                  }

                  {imageSearchQuery &&
                    <UnsplashGallery
                      searchTerms={imageSearchQuery}
                      onError={onUnsplashGalleryError}
                      onImagesFetched={onImageFetched}
                      onLoading={onImagesLoading}
                      onImageSelected={onImageSelected}
                    />
                  }
                </div>


                <div className="flex justify-between">
                  <div className="w-full sm:w-1/4 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                    <button
                      type="button"
                      className={cancelButtonClassName}
                      onClick={resetModal}
                    >
                      Close
                    </button>
                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}