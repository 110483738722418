import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAreaGuides(agencyId) {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const areaGuides = useQuery(
    ["areaGuides", agencyId],
    () => get(`/AreaGuide/agencyGuides/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const sync = useMutation(() => post(`/AreaGuide/calculate/${agencyId}`,), {
    onSuccess: () => {
      queryClient.invalidateQueries(["areaGuides", agencyId]);
    },
  });

  const add = useMutation((areaGuide) => post(`/AreaGuide/${agencyId}`, areaGuide), {
    onSuccess: (addedGuide) => {
      queryClient.setQueryData(["areaGuides", agencyId], (currentAreaGuides) => [
        addedGuide.data,
        ...currentAreaGuides,
      ]);
    },
  });

//   const update = useMutation((branch) => put(`/branch/${branch.id}`, branch), {
//     onSuccess: (updatedBranch) => {
//       queryClient.setQueryData(["areaGuides", agencyId], (currrentBranches) =>
//         currrentBranches.map((branch) =>
//           branch.id === updatedBranch.data.id ? updatedBranch.data : branch
//         )
//       );
//     },
//   });

  const remove = useMutation((areaGuideId) => del(`/AreaGuide/${areaGuideId}`), {
    onSuccess: (_, areaGuideId) => {
      queryClient.setQueryData(["areaGuides", agencyId], (currentAreaGuides) =>
        currentAreaGuides.filter((areaGuide) => areaGuide.id !== areaGuideId)
      );
    },
  });

  return {
    areaGuides,
    sync,
    add,
    // update,
    remove,
  };
}
