import { useEffect } from "react";
import { useForm } from "react-hook-form";
import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

import { TextInput } from "../Shared/Inputs/TextInput";
import { ButtonInput } from "../Shared/Inputs/ButtonInput";
import useAgencyPolicyInfo from "../../data/useAgencyPolicyInfo";
import SwitchInput from "../Shared/Inputs/SwitchInput";
import PopOutBox from "../Shared/PopOutBox";

export default function PolicyInfoPartial({ agencyId }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Policy Information");
  const { policyInformation, update } = useAgencyPolicyInfo(agencyId);
  const { register, handleSubmit, setValue, reset, watch, control } = useForm({
    defaultValues: {
      hasDataProtectionOfficer: false,
      doCollectSpecialData: false,
      ...policyInformation.data
    },
  });

  useEffect(() => {
    if (!policyInformation.isLoading && policyInformation.data) {
      reset(policyInformation.data);
    }
  }, [policyInformation.isLoading, policyInformation.data]);

  const onSubmit = (data) => {
    // console.log(data);

    update.mutate({ ...data }, saveHandlers);
  };


  if (policyInformation.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-y-4">
            <div className="-mx-6 px-6">
              <h3 className="text-xl font-medium text-gray-900">
                Policy Information
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Extra information needed to fill in the policies for this agency
              </p>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <TextInput
                label={"Company Number"}
                register={register}
                registerName={"companyNumber"}
              />

              <TextInput
                label={"Trading Address"}
                register={register}
                registerName={"tradingAddress"}
              />

              <TextInput
                label={"Regulator Name"}
                register={register}
                registerName={"regulatorName"}
              />

              <TextInput
                label={"Regulator Address"}
                register={register}
                registerName={"regulatorAddress"}
              />

              <div className="flex">
                <SwitchInput
                  label={"Has Data Protection Officer?"}
                  register={register}
                  registerName={"hasDataProtectionOfficer"}
                  setValue={setValue}
                  watch={watch}
                  column
                />
                <PopOutBox classes="w-96">
                  <span>Check you have a dedicated Data Protection Officer or whether you have a Nominated Representative - if nominated fill in data protection name and email for them.</span>
                </PopOutBox>
              </div>

              <div>
                {/* Spacer */}
              </div>

              <TextInput
                label={"Data Protection Name"}
                register={register}
                registerName={"dataProtectionName"}
              />

              <TextInput
                label={"Data Protection Email"}
                register={register}
                registerName={"dataProtectionEmail"}
              />

              <TextInput
                label={"Third Party Companies That Process Data"}
                register={register}
                registerName={"thirdPartyCompanies"}
              />

              <div className="flex">
                <SwitchInput
                  label={"Do you collect special data?"}
                  register={register}
                  registerName={"doCollectSpecialData"}
                  setValue={setValue}
                  watch={watch}
                  column
                />
                <PopOutBox classes="w-96">
                  <span>This includes details about race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data.</span>
                </PopOutBox>
              </div>

            </div>

            <div className="!mt-8 -my-6 -mx-4 sm:-m-6 lg:-mb-8 p-4 flex justify-between border-t-2 border-gray-200 bg-white">
              <ButtonInput
                label={"Save"}
                isSubmit={true}
                classes={""}
              />
            </div>

          </div>
        </form>
      </>
    );
  }
}
