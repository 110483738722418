import { useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

import classNames from "../../utils/classNames";
import { SwitchAgency, SwitchAgencyDialog } from "./SwitchAgency";

import EstateTrackLogo from "../../assests/EstateTrackLogo.png";
import { ChevronRightIcon } from "@heroicons/react/outline";
import ContactSupport from "./ContactSupport";

export default function SidebarDesktop({ navigation }) {
  const [contactSupportOpen, setContactSupportOpen] = useState(false);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [analyticsOpen, setAnalyticsOpen] = useState(false);
  const nav = Object.keys(navigation);

  return (
    <>
      <ContactSupport open={contactSupportOpen} setOpen={setContactSupportOpen} />
      {/* Static sidebar for desktop */}
      <SwitchAgencyDialog open={switchOpen} setOpen={setSwitchOpen} />
      <div className="hidden md:flex md:w-56 md:flex-col md:fixed md:inset-y-0">
        <div className="flex flex-col flex-grow border-r border-gray-200 py-4 bg-white overflow-y-auto">
          <div className="flex items-center flex-shrink-0 pl-3 pr-2">
            <SwitchAgency
              setOpen={setSwitchOpen}
              sidebarOpen={false}
              setSidebarOpen={() => { }}
            />
          </div>
          <div className="flex-grow flex flex-col">
            <nav className="flex-1 px-2">
              {nav.length > 0
                ? nav.map((key) => (
                  <div key={key} className="mt-6 mb-4">
                    {key !== "Analytics" ? (
                      <h4 className="px-3 text-xs font-semibold text-etpink-600 uppercase tracking-wider mb-3">
                        {key}
                      </h4>
                    ) : (
                      <h4 className="px-3 flex justify-between items-center text-xs font-semibold text-etpink-600 uppercase tracking-wider mb-3 cursor-pointer"
                        onClick={() => setAnalyticsOpen(!analyticsOpen)}
                      >
                        {key}
                        <span style={{ transform: `rotate(${analyticsOpen ? "0" : "-270"}deg)` }} className="transition-all duration-500">
                          <ChevronRightIcon className="h-4 w-4" />
                        </span>
                      </h4>
                    )}
                    {navigation[key].map((item) => {
                      if (key === "Analytics" && analyticsOpen) {
                        return null;
                      } else {
                        return (
                          item.disabled === true ?
                            <span
                              onClick={() => setContactSupportOpen(true)}
                              key={item.name}
                              className="group text-gray-300 flex items-center px-2 py-1.5 text-sm font-small rounded-md cursor-not-allowed"
                            >
                              <item.icon
                                className="text-gray-300 mr-3 flex-shrink-0 h-6 w-6"
                                aria-hidden="true"
                              />
                              {item.name}
                            </span>
                            :
                            <NavLink
                              key={item.name}
                              to={item.href}
                              end={item.end ? item.end : false}
                            >
                              {({ isActive }) => (
                                <span
                                  className={classNames(
                                    isActive
                                      ? "bg-gray-100 text-gray-900"
                                      : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                    "group flex items-center px-2 py-1.5 text-sm font-small rounded-md"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      isActive
                                        ? "text-gray-500"
                                        : "text-gray-400 group-hover:text-gray-500",
                                      "mr-3 flex-shrink-0 h-6 w-6"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </span>
                              )}
                            </NavLink>
                        );
                      }
                    })}
                  </div>
                ))
                : ""}
            </nav>
            <div className="flex items-center flex-shrink-0 px-4 pt-4 border-t border-gray-200">
              <img
                className="w-auto object-contain"
                src={EstateTrackLogo}
                alt="EstateTrack Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
