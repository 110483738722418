export const CDN_URL = "https://estatetrack-cdn.azureedge.net";

// export const apiUrl = "https://estatetrack-api.azurewebsites.net";
export const apiUrl = window.location.host.includes("localhost") ? "https://localhost:7259" : "https://estatetrack-dashboard-api-live.azurewebsites.net";
// export const authUrl = window.location.host.includes("localhost") ? "https://localhost:7009" : "https://estatetrack-auth.azurewebsites.net";
export const authUrl = "https://estatetrack-auth.azurewebsites.net";

export const htmlOptions = {
    entityStyleFn: (entity) => {
        const entityType = entity.get("type");
        if (entityType === "VIDEO") {
            const data = entity.getData();
            console.log("data", data);
            if (!data.type) {
                return {
                    element: "iframe",
                    attributes: {
                        src: data.src,
                        width: 480,
                        height: 270,
                    },
                };
            } else {
                return {
                    element: "video",
                    attributes: {
                        src: data.src,
                        type: data.type,
                        controls: true,
                    },
                };
            }
        } else {
            return null;
        }
    },
};