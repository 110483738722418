import { useEffect, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { CashIcon, CheckCircleIcon, CollectionIcon, CurrencyPoundIcon, DocumentSearchIcon, InboxInIcon, PlusIcon, SearchIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import useUserNotifications from "../../data/useUserNotifications";

const icons = [
  <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
  <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
  <PlusIcon className="mr-3 w-5 h-5" />,
  <InboxInIcon className="mr-3 w-5 h-5" />,
  <DocumentSearchIcon className="mr-3 w-5 h-5" />,
  <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
  <PlusIcon className="mr-3 w-5 h-5" />,
  <DocumentSearchIcon className="mr-3 w-5 h-5" />,
  <CashIcon className="mr-3 w-5 h-5" />,
  <CollectionIcon className="mr-3 w-5 h-5" />,
]

function NotificationRow({ notification, handleMarkRead, handleClick }) {

  const markRead = (e) => {
    e.stopPropagation();
    handleMarkRead(notification)
  }

  return (
    <tr
      onClick={() => handleClick(notification)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="hidden px-2 py-4 text-sm text-gray-500 text-center vertical-middle sm:table-cell">
        {icons[notification.actionType]}
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {notification.title}
        <dl className="font-normal sm:hidden">
          <dt className="sr-only">Notification title</dt>
          <dd className="hidden mt-1 truncate text-gray-500">{notification.title}</dd>
          <dt className="sr-only sm:hidden">Notification read</dt>
          <dd className="mt-1 truncate text-gray-500"><span
            className={`rounded-full px-5 py-1 font-bold ${notification.read === true
              ? "text-green-600 bg-green-100"
              : "text-blue-600 bg-blue-100"
              }`}
          >
            {notification.read == true ? "Read" : "Unread"}
          </span></dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {notification.agency.logicalName}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`rounded-full px-5 py-1 font-bold ${notification.read === true
            ? "text-green-600 bg-green-100"
            : "text-blue-600 bg-blue-100"
            }`}
        >
          {notification.read == true ? "Read" : "Unread"}
        </span>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(notification.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={(e) => markRead(e)}
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <CheckCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Notifications() {
  const navigate = useNavigate();
  const { notifications: allNotifications, markRead, markAllRead } = useUserNotifications();
  const [notifications, setNotifications] = useState(allNotifications);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    document.title = "EstateTrack Portal | Notifications";
    if (!allNotifications.isLoading) {
      setNotifications(
        allNotifications.data.filter((notification) =>
          notification.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.agency.logicalName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allNotifications.isLoading, allNotifications.data, searchTerm]);

  const handleMarkRead = (notification) => {

    if (!notification.read) {
      markRead.mutate(notification.id);
    }
  }

  const handleClick = (notification) => {
    if (!notification.read) {
      markRead.mutate(notification.id);
    }

    switch (notification.actionType) {
      case 0:
      case 1:
      case 5:
        navigate(`/${notification.agency.logicalName}/valuations`);
        break;
      case 2:
      case 6:
        navigate(`/${notification.agency.logicalName}/registrations`);
        break;
      case 3:
        navigate(`/${notification.agency.logicalName}/enquiries`);
        break;
      case 4:
      case 7:
        navigate(`/${notification.agency.logicalName}/viewings`);
        break;
      case 8:
        navigate(`/${notification.agency.logicalName}/mortgages`);
        break;
      case 9:
        navigate(`/${notification.agency.logicalName}/formleads`);
        break;
      default:
        break;
    }
  }

  const handleMarkAll = () => {
    if (notifications) {
      var ids = [];
      notifications.forEach(notification => {
        if (!notification.read) {
          ids.push(notification.id);
        }
      });

      if (ids.length > 0) {
        markAllRead.mutate(ids);
      }
    }
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Notifications</h1>
            <p className="mt-2 text-sm text-gray-700">A list of all of your notifications</p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Content or Agency"
                />
              </div>
              {/* <button
                type="button"
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button> */}
              <div className="mt-4 sm:mt-0 sm:ml-5 sm:flex-none">
                <button
                  onClick={() => handleMarkAll()}
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                >
                  Mark all as read
                </button>
              </div>
            </div>

          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {allNotifications.isLoading || notifications.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-10" height="w-10" />
            </div>
          ) : notifications.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Content
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Agency
                  </th>
                  <th
                    scope="col"
                    className="hidden pl-6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Read
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Date
                  </th>
                  <th scope="col" className="hidden pl-6 px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell">
                    <span className="sr-only">Actions</span>
                    Mark as read
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {notifications.map((notification) => (
                  <NotificationRow
                    key={notification.id}
                    notification={notification}
                    handleMarkRead={handleMarkRead}
                    handleClick={handleClick}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              <div className="text-center text-sm text-gray-400">
                No Notifications
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
