import { useEffect, useState } from "react";
import DefaultLeftSide from "./DefaultLeftSide";
import DefaultRightSide from "./DefaultRightSide";

export default function JsonRadioConfig({ register, options, element, formJson, setFormJson, refresh, setRefresh }) {
  const [localRefresh, setLocalRefresh] = useState(false);
  const [newOption, setNewOption] = useState("");

  const removeOption = (option, i) => {
    var newFormJson = formJson;

    let index = newFormJson.elements.findIndex(x => x.id === element.id);

    newFormJson.elements[index].options.splice(i, 1);

    setFormJson(newFormJson);
    setLocalRefresh(!localRefresh);
  }

  const addNewOption = () => {
    var newFormJson = formJson;

    if (newOption === "") return;

    let index = newFormJson.elements.findIndex(x => x.id === element.id);

    if (!index) return;

    if (newFormJson.elements[index].options === undefined) {
      newFormJson.elements[index].options = [];
    }

    newFormJson.elements[index].options.push(newOption);

    setFormJson(newFormJson);
    setNewOption("");
    setLocalRefresh(!localRefresh);
  }

  useEffect(() => {
    // console.log("refresh select config")
  }, [options, formJson, localRefresh])

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

          <DefaultLeftSide register={register} />

          <div className="">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              Options
            </label>
            {options.map((option, i) => (
              <div key={i} className="flex justify-between">
                <span>
                  {option}
                </span>
                <span>
                  <button type="button" onClick={() => removeOption(option, i)} className="text-red-500 font-semibold hover:text-red-700">X</button>
                </span>
              </div>
            ))}
            <div className="flex mt-4">
              <input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Add new option"
                className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
              />
              <button
                type="button"
                onClick={() => addNewOption()}
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Add
              </button>
            </div>
          </div>

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        <DefaultRightSide register={register} />

      </div>

    </div>
  )
}