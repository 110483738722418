import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useMeta(agencyId) {
  const staleTime = 1000 * 60 * 2; // 2 minutes
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const metaPageConfigs = useQuery(["meta", agencyId], () => get(`/meta/${agencyId}`), {
    staleTime,
  });

  const remove = useMutation((pageConfigId) => del(`/meta/${pageConfigId}`, null), {
    onSuccess: () => {
      queryClient.invalidateQueries(["meta", agencyId])
    },
  });

  const setup = useMutation((body) => post(`/meta/${agencyId}/setup`, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(["integrations", agencyId]);
      queryClient.invalidateQueries(["meta", agencyId]);
    },
  });


  return {
    metaPageConfigs,
    remove,
    setup
  };
}
