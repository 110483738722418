import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEnquiries() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { user: { agency }, } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const enquiries = useQuery(
    ["enquiries", agency.id], () => get(`/agency/${agency.id}/enquiries`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  //   const add = useMutation((agency) => post("/agency", agency), {
  //     onSuccess: (addedAgency) => {
  //       queryClient.setQueryData(["enquiries", agency.id], (currentAgencies) => [
  //         addedAgency.data,
  //         ...currentAgencies,
  //       ]);
  //     },
  //   });

  const update = useMutation((enquiry) => put(`/agency/${enquiry.agencyId}/enquiries`, enquiry), {
    onSuccess: () => {
      queryClient.invalidateQueries(["enquiries", agency.id]);
    },
  });

  const updateComments = useMutation((mutateData) => put(`/agency/${agency.id}/enquiriesComment/${mutateData.enquiryId}`, mutateData.newComment), {
    onSuccess: (updatedEnquiry) => {
      queryClient.setQueryData(["enquiries", agency.id], (currrentEnquiries) =>
        currrentEnquiries.map((enquiry) =>
          enquiry.id === updatedEnquiry.data.id ? updatedEnquiry.data : enquiry
        )
      );
    },
  });

  const removeComment = useMutation((ids) => put(`/agency/${agency.id}/enquiriesComment/${ids.enquiryId}/${ids.commentId}`), {
    onSuccess: (_, ids) => {
      queryClient.setQueryData(["enquiries", agency.id], (currentEnquiries) =>
        currentEnquiries.map((enquiry) => {
          if (enquiry.comments.some(c => c.id === ids.commentId)) {
            enquiry.comments = enquiry.comments.filter(comment => comment.id !== ids.commentId);
            return enquiry;
          } else {
            return enquiry;
          }
        })
      );
    },
  });

  const remove = useMutation((enquiryId) => del(`/agency/${agency.id}/enquiries/${enquiryId}`), {
    onSuccess: (_, enquiryId) => {
      queryClient.setQueryData(["enquiries", agency.id], (currentEnquiries) =>
        currentEnquiries.filter((enquiry) => enquiry.id !== enquiryId)
      );
    },
  });

  return {
    enquiries,
    // add,
    update,
    updateComments,
    removeComment,
    remove,
  };
}
