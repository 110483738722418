import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useViewings() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { user: { agency }, } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const viewings = useQuery(
    ["viewings", agency.id],
    () => get(`/Viewing/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((viewing) => post(`/Viewing/${agency.id}`, viewing), {
    onSuccess: (addedViewing) => {
      queryClient.setQueryData(["viewings", agency.id], (currentViewings) => [
        addedViewing.data,
        ...currentViewings,
      ]);
    },
  });

  const resendQualifier = useMutation((viewingId) => post(`/Viewing/resendqualifier/${viewingId}`, {}), {
    onSuccess: (res) => {
      
    },
  });

  const update = useMutation(
    (viewing) => put(`/Viewing/${viewing.id}`, viewing),
    {
      onSuccess: () => queryClient.invalidateQueries(["viewings", agency.id]),
    }
  );

  const notifyClient = useMutation(
    (viewing) => put(`/Viewing/notify/${viewing.id}`, viewing),
    {
      onSuccess: () => queryClient.invalidateQueries(["viewings", agency.id]),
    }
  );

  // const update = useMutation(
  //   (viewing) => put(`/Viewing/${agency.id}/${viewing.id}`, viewing),
  //   {
  //     onSuccess: (updatedViewing) => {
  //       queryClient.setQueryData(["viewings", agency.id], (currentViewings) =>
  //         currentViewings.map((site) =>
  //           site.id === updatedViewing.id ? updatedViewing : site
  //         )
  //       );
  //     },
  //   }
  // );

  const remove = useMutation(
    (viewingId) => del(`/Viewing/${viewingId}`),
    {
      onSuccess: (_, viewingId) => {
        queryClient.setQueryData(["viewings", agency.id], (currentViewings) =>
          currentViewings.filter((site) => site.id !== viewingId)
        );
      },
    }
  );

  const updateNotes = useMutation((mutateData) => put(`/Viewing/notes/${mutateData.viewingId}`, mutateData.newNote), {
    onSuccess: (updatedViewing) => {
      queryClient.invalidateQueries(["viewings", agency.id]);
      // queryClient.setQueryData(["viewings", agency.id], (currentViewings) =>
      //   currentViewings.map((viewing) =>
      //     viewing.id === updatedViewing.data.id ? updatedViewing.data : viewing
      //   )
      // );
    },
  });

  const removeNote = useMutation((ids) => put(`/Viewing/notes/${ids.viewingId}/${ids.noteId}`), {
    onSuccess: (_, ids) => {
      queryClient.invalidateQueries(["viewings", agency.id]);
      // queryClient.setQueryData(["viewings", agency.id], (currentViewings) =>
      //   currentViewings.map((viewing) => {
      //     if (viewing.notes.some(c => c.id === ids.noteId)) {
      //       viewing.notes = viewing.notes.filter(note => note.id !== ids.noteId);
      //       return viewing;
      //     } else {
      //       return viewing;
      //     }
      //   })
      // );
    },
  });

  return {
    viewings,
    add,
    update,
    notifyClient,
    remove,
    updateNotes,
    removeNote,
    resendQualifier
  };
}
