import { Fragment, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import useTranspond from "../../data/useTranspond";
import { Listbox, Transition } from "@headlessui/react";
import classNames from "../../utils/classNames";
import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";
import useAgencies from "../../data/useAgencies";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

export default function TranspondAccountAdd({ setOpen, agencyId }) {
  const { addTranspondKey } = useAgencies();
  const { transpondAccounts, createAccount } = useTranspond(agencyId);
  const { saveHandlers } = useDefaultCRUDHandlers("Transpond key");
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({ defaultValues: { account: "" } });

  useEffect(() => {
    setValue("account", "None")
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    const ids = {
      agencyId: agencyId,
      // This will use the ACTUAL transpond ID
      transpondAccountId: data.account
    }
    addTranspondKey.mutate(ids, saveHandlers);
    setOpen(false);
  }

  const handleCreateTranspond = () => {
    createAccount.mutate(agencyId, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onError: () => {
        saveHandlers.onError();
        setOpen(false);
      }
    });
  }

  const accountValue = watch("account");

  if (transpondAccounts.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <div className="flex h-full flex-col content-between">
        <div>
          <p>Please choose an account to link with this agency:</p>
          <form onSubmit={handleSubmit(onSubmit)} className="mx-1">
            <div>
              <Listbox
                {...register("account")}
                value={accountValue}
                onChange={(v) => setValue("account", v)}
              >
                {({ open }) => (
                  <>
                    <div className="mt-1 relative">
                      <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                        <span className="block truncate">
                          {
                            transpondAccounts.data.accounts?.find((c) => c.id === accountValue) ? (
                              transpondAccounts.data.accounts.find((c) => c.id === accountValue).name
                            ) : (
                              accountValue
                            )
                          }
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        appear={true}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {transpondAccounts.data.accounts.map((account, i) => (
                            <Listbox.Option
                              key={i}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-etpink-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-8 pr-4"
                                )
                              }
                              value={account.id} >
                              <>
                                <span
                                  className={classNames(
                                    getValues("account") === account
                                      ? "font-semibold"
                                      : "font-normal",
                                    "block truncate"
                                  )} >
                                  {account.name}
                                </span>
                                {getValues("account") === account ? (
                                  <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              <button
                type="submit"
                className="float-right px-3 py-2 mt-5 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div className="border-t mt-[50vh]">
          <p className="mt-4">Or, if you do not have a transpond account please click the button below to automatically create one:</p>

          <button
            type="button"
            onClick={() => handleCreateTranspond()}
            className="px-3 py-2 mt-5 ml-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            Create Account
          </button>
        </div>

      </div>
    )
  }
}