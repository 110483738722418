

export const JsonHeading = ({ element }) => {

  return (
    <div className="">
      <label className="block mb-2 text-lg font-medium text-gray-700">
        {element.label}
      </label>
    </div>
  )
}