import UploadPdf from "./UploadPdf";

export default function ThankYouMessage({ register, getValues, setValue }) {



  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Thank You Title
        </label>
        <div className="mt-1">
          <input
            {...register("thankYouTitle")}
            type="text"
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Thank you for your submission!"
          ></input>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Thank You Message
        </label>
        <div className="mt-1">
          <textarea
            {...register("thankYouMessage")}
            rows="3"
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="We are very excited to have you join us!"
          ></textarea>
        </div>
      </div>

      <UploadPdf register={register} getValues={getValues} setValue={setValue} />

    </div>
  )
}