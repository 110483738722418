import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useApiHelper from "../../services/useApiHelper";

export default function BookValuation() {
  const valuationId = useParams();
  const { get, post, patch, del } = useApiHelper();

  useEffect(() => {
    document.title = "EstateTrack | Book Valuation";
    console.log(valuationId)
    if (valuationId) {
      //Change from instant to in person and contact made

      //Change from instant to in person and contact made
      patch(`/valuation/conversion/${valuationId.valuationId}`);
    }
  }, [])
  return (
    <>

      <div className="bg-white min-h-[100vh]">
        <div className="p-5">
          <img
            className="h-12 w-auto"
            src="https://portal.estatetrack.co.uk/images/estatetrack-logo-black.png"
            alt="Workflow"
          />
        </div>
        <div className="max-w-max mx-auto">
          <main className="flex pt-[25vh] prose text-center">
            <div>
              <h1>Thanks for booking your valuation!</h1>
              <h2>An agent will be in touch shortly!</h2>
            </div>
            <div>
              <img
                className="w-48 -mt-32"
                src="https://portal.estatetrack.co.uk/images/standing-man.jpeg"
                alt="Standing man"
              />
            </div>
          </main>
        </div>
      </div>

    </>
  );
}
