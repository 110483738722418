import { useState } from "react";
import { DocumentDuplicateIcon, ExclamationIcon, PencilAltIcon, PlusIcon, RefreshIcon, TrashIcon } from "@heroicons/react/outline";

import BranchPostcodesAddEditPartial from "./BranchPostcodesAddEditPartial";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Flyout from "../../containers/Flyout";
import useAgencyBranches from "../../data/useAgencyBranches";
import useBranchPostcodes from "../../data/useBranchPostcodes";
import useBranchStats from "../../data/useBranchStats";
import { apiUrl } from "../../services/config";


function BranchPostcodesListRow({ branch, onEditClick }) {
  const { postcodes } = useBranchPostcodes(branch.id);

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="text-sm font-medium text-etpink-600 md:w-1/5">
          <span dangerouslySetInnerHTML={{__html: branch.name}}></span>
        </p>
        <span className="flex flex-1 w-full truncate md:ml-2">
          {!postcodes.isLoading ? postcodes.data.length > 0 ? (
            postcodes.data.length === 1 ? `${postcodes.data.length} postcode area` : `${postcodes.data.length} postcode areas`
          ) : (
            "0 postcode areas"
          ) : ""}
        </span>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onEditClick(branch)}
          className="mx-2 text-gray-400 hover:text-gray-600"
        >
          <span className="sr-only">Edit</span>
          <PencilAltIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function BranchPostcodesPartial({ agencyId }) {
  const { branches } = useAgencyBranches(agencyId);
  const { sync } = useBranchStats(agencyId);
  const { saveHandlers } = useDefaultCRUDHandlers("Branch stats");
  const [open, setOpen] = useState(false);
  const [editBranch, setEditBranch] = useState({});

  const handleEditBranch = (branch) => {
    setEditBranch(branch);
    setOpen(true);
  };

  return (
    <>
      <div className="divide-y-2">
        <div>
          <h3 className="text-xl font-medium text-gray-900">Branch postcodes</h3>
          <p className="mt-1 text-sm text-gray-500">Add postcode areas to a branch</p>
          <Flyout
            open={open}
            setOpen={setOpen}
            title={editBranch.name ? editBranch.name : "Add Branch Postcodes"}
          >
            <BranchPostcodesAddEditPartial
              branch={editBranch}
            />
          </Flyout>
          {/* <button
        type="button"
        onClick={() => {
          setEditBranch({});
          setOpen(true);
        }}
        className="my-6 flex items-center justify-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Add Branch Postcodes
      </button> */}
          <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
            {branches.isLoading ? (
              <li key="branches-loading" className="py-4 flex justify-center">
                <LoadingWheel width="w-8" height="h-8" />
              </li>
            ) : branches.data.length > 0 ? (
              branches.data.map((branch, i) => (
                <BranchPostcodesListRow
                  branch={branch}
                  key={i}
                  onEditClick={handleEditBranch}
                />
              ))
            ) : (
              <li>
                <div className="py-5 text-center text-sm text-gray-400">
                  No Branches
                </div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
