import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useBlogPosts() {
  const staleTime = 300000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const blogPosts = useQuery(
    ["blogPosts", agency.id],
    () => get(`/blogpost/${agency.id}/post`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (blogPost) => post(`/blogpost/${agency.id}/post`, blogPost),
    {
      onSuccess: (addedPost) => {
        queryClient.setQueryData(["blogPosts", agency.id], (currentPosts) => [
          addedPost.data[0],
          ...currentPosts,
        ]);
      },
    }
  );

  const update = useMutation(
    (blogPost) => put(`/blogpost/${agency.id}/post/${blogPost.id}`, blogPost),
    {
      onSuccess: () => { 
        queryClient.invalidateQueries(["blogPosts", agency.id]);
      },
    }
  );

  // const update = useMutation(
  //   (blogPost) => put(`/blogpost/${agency.id}/post/${blogPost.id}`, blogPost),
  //   {
  //     onSuccess: (updatedPost) => {
  //       console.log("setting post", updatedPost);
  //       queryClient.setQueryData(["blogPosts", agency.id], (currentPosts) =>
  //         currentPosts.map((post) => {
  //           return post.id === updatedPost.data[0].id ? updatedPost.data[0] : post;
  //         })
  //       );
  //     },
  //   }
  // );

  const remove = useMutation(
    (blogPostId) => del(`/blogpost/${agency.id}/post/${blogPostId}`),
    {
      onSuccess: (_, postId) => {
        queryClient.setQueryData(["blogPosts", agency.id], (currentPosts) =>
          currentPosts.filter((posts) => posts.id !== postId)
        );
      },
    }
  );

  return {
    blogPosts,
    add,
    update,
    remove,
  };
}
