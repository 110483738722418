import { useCallback } from "react";
import { useNotifier } from "react-headless-notifier";
import {
  SuccessNotification,
  InfoNotification,
  WarningNotification,
  ErrorNotification,
} from "./Notification";

export const NotificationVariant = {
  success: "success",
  info: "info",
  warn: "warn",
  error: "error",
};

export default function useNotification() {
  const { notify } = useNotifier();

  const addNotification = useCallback((notification, config) => {
    switch (notification.variant) {
      case NotificationVariant.success:
        return notify(<SuccessNotification {...notification} />, config);
      case NotificationVariant.info:
        return notify(<InfoNotification {...notification} />, config);
      case NotificationVariant.warn:
        return notify(<WarningNotification {...notification} />, config);
      case NotificationVariant.error:
        return notify(<ErrorNotification {...notification} />, config);
      default:
        return notify(<InfoNotification {...notification} />, config);
    }
  }, []);

  return {
    addNotification,
  };
}
