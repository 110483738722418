import { useEffect, useState } from "react";
import LoadingWheel from "../../Shared/LoadingWheel";

const ReviewSiteRow = ({ reviewSite }) => {
  return (
    <tr>
      <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
        {reviewSite.referrerName}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {reviewSite.total}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {reviewSite.new}
      </td>
    </tr>
  );
}

const ReviewSourcesTable = ({ stats }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewSites, setReviewSites] = useState(null);

  useEffect(() => {
    if (!stats.isLoading) {
      //empty array
      let reviewSites = [];
      //convert from object to array
      const ratings = Object.entries(stats.data.reviewSiteRatings);

      let i = 0;
      //loop through object and push to array
      for (const [key, value] of Object.entries(stats.data.reviewSiteCounts)) {
        reviewSites.push({
          referrerName: key,
          total: value,
          new: ratings[i][1],
        });
        i++;
      }

      setReviewSites(reviewSites.sort((b, a) => a.total - b.total));

      setIsLoading(false);
    }
  }, [stats.isLoading])

  return (
    <div className="px-8 py-4 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Review Sites</h1>

      <hr className="" />

      {isLoading ? (
        <div className="flex p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <div className="overflow-auto">
          <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                >
                  Referrer Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900"
                >
                  Total Count
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  Average Rating
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {reviewSites.map((reviewSite, i) => (
                <ReviewSiteRow reviewSite={reviewSite} key={i} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ReviewSourcesTable;