import { useState, useCallback, useEffect } from "react";
import { useForm, useFormState, useWatch } from "react-hook-form";

import { PhotographIcon } from "@heroicons/react/outline";

import useAgencies from "../../data/useAgencies";
import useApiHelper from "../../services/useApiHelper";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import { ConfirmNavigateAway } from "../Shared/ConfirmNavigateAway";
import LoadingWheel from "../Shared/LoadingWheel";

export default function AgencyProfileAddEditPartial({ agency }) {
  const { update: updateAgency } = useAgencies();
  const { saveHandlers } = useDefaultCRUDHandlers("Agency");
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      name: agency.name,
      domain: agency.domain,
      primaryEmail: agency.primaryEmail,
      telephoneNumber: agency.telephoneNumber,
      vatNumber: agency.vatNumber,
      primaryColour: agency.primaryColour,
      secondaryColour: agency.secondaryColour,
      facebookUrl: agency.facebookUrl,
      twitterUrl: agency.twitterUrl,
      instagramUrl: agency.instagramUrl,
      linkedInUrl: agency.linkedInUrl,
      youtubeUrl: agency.youtubeUrl,
      rssUrl: agency.rssUrl,
      ctaUrl: agency.ctaUrl,
      houseNo: agency.houseNo,
      address1: agency.address1,
      address2: agency.address2,
      town: agency.town,
      county: agency.county,
      postcode: agency.postcode,
      country: agency.country || "United Kingdom",
    },
  });
  const { formData } = useApiHelper();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const [uploading, setUploading] = useState(false);
  const primaryColourValue = useWatch({ control, name: "primaryColour" });
  const secondaryColourValue = useWatch({ control, name: "secondaryColour" });

  const update = (data) => {
    updateAgency.mutate(
      {
        ...agency,
        name: data.name,
        domain: data.domain,
        primaryEmail: data.primaryEmail,
        telephoneNumber: data.telephoneNumber,
        vatNumber: data.vatNumber,
        primaryColour: data.primaryColour,
        secondaryColour: data.secondaryColour,
        facebookUrl: data.facebookUrl,
        twitterUrl: data.twitterUrl,
        instagramUrl: data.instagramUrl,
        linkedInUrl: data.linkedInUrl,
        youtubeUrl: data.youtubeUrl,
        rssUrl: data.rssUrl,
        ctaUrl: data.ctaUrl,
        houseNo: data.houseNo,
        address1: data.address1,
        address2: data.address2,
        town: data.town,
        county: data.county,
        postcode: data.postcode,
        country: data.country,
      },
      saveHandlers
    );
    reset({
      name: data.name,
      domain: data.domain,
      primaryEmail: data.primaryEmail,
      telephoneNumber: data.telephoneNumber,
      vatNumber: data.vatNumber,
      primaryColour: data.primaryColour,
      secondaryColour: data.secondaryColour,
      facebookUrl: data.facebookUrl,
      twitterUrl: data.twitterUrl,
      instagramUrl: data.instagramUrl,
      linkedInUrl: data.linkedInUrl,
      youtubeUrl: data.youtubeUrl,
      rssUrl: data.rssUrl,
      ctaUrl: data.ctaUrl,
      houseNo: data.houseNo,
      address1: data.address1,
      address2: data.address2,
      town: data.town,
      county: data.county,
      postcode: data.postcode,
      country: data.country,
    });
  };

  const handleAgencyLogoChange = useCallback((event) => {
    setUploading(true);
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      let fd = new FormData();
      fd.append("avatar", file, file.name);
      formData(`/avatar/${agency.id}`, fd).then((res) => {
        //Timeout allows the CDN to update
        setTimeout(() => setUploading(false), 10000);
        saveHandlers.onSuccess();
      });
    } else {
      saveHandlers.onError();
      setUploading(false);
    }
  }, []);

  useEffect(() => { }, [agency.avatar]);

  return (
    <>
      <ConfirmNavigateAway
        show={showPrompt}
        onOK={confirmNavigation}
        onCancel={cancelNavigation}
      />
      <h3 className="flex items-center gap-x-1 text-xl font-medium text-gray-900">
        Agency Profile
        <div onClick={() => navigator.clipboard.writeText(agency.id)} className="cursor-pointer text-sm text-gray-500"> - {agency.id}</div>
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Set agency specifc details for use in the agency dashboard
      </p>
      <form onSubmit={handleSubmit(update)}>
        <div className="my-6">
          <div className="mt-1 flex items-center">
            {agency.avatar ? (
              !uploading && (
                <div className="rounded-lg overflow-hidden">
                  <img
                    src={agency.avatar}
                    width="180"
                    height="180"
                    alt="Agency Logo"
                  />
                </div>
              )
            ) : (
              <div className="p-12 bg-white text-gray-500 border-2 border-gray-500 border-dashed rounded-lg">
                <PhotographIcon className="w-20 h-20" />
              </div>
            )}
            <div className="ml-4">
              <input
                type="file"
                id="agencyLogo"
                className="hidden"
                onChange={handleAgencyLogoChange}
              />
              {uploading ? (
                <div className="flex p-4 justify-center items-center">
                  <LoadingWheel width="w-10" height="h-10" />
                </div>
              ) : (
                <label
                  htmlFor="agencyLogo"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                  Change Logo
                </label>
              )}
            </div>
          </div>
        </div>
        <div className="divide-y divide-gray-200">
          <div className="py-4">
            <h3 className="text-xl font-medium text-gray-900 my-4">
              Basic Information
            </h3>
            <div className="my-4 block">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Agency Name
              </label>
              <div className="mt-1">
                <input
                  {...register("name")}
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="domain"
                  className="block text-sm font-medium text-gray-700"
                >
                  Domain
                </label>
                <div className="mt-1">
                  <input
                    {...register("domain")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="primaryEmail"
                  className="block text-sm font-medium text-gray-700"
                >
                  Primary Email
                </label>
                <div className="mt-1">
                  <input
                    {...register("primaryEmail")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="telephoneNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Telephone Number
                </label>
                <div className="mt-1">
                  <input
                    {...register("telephoneNumber")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="vatNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  VAT Number
                </label>
                <div className="mt-1">
                  <input
                    {...register("vatNumber")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="primaryColour"
                  className="block text-sm font-medium text-gray-700"
                >
                  Primary Colour
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span
                    className="w-2/12 md:w-1/12 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                    style={{ backgroundColor: primaryColourValue }}
                  ></span>
                  <input
                    {...register("primaryColour")}
                    type="text"
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300"
                    placeholder="#000000"
                  />
                </div>
              </div>
              <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                <label
                  htmlFor="secondaryColour"
                  className="block text-sm font-medium text-gray-700"
                >
                  Secondary Colour
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                  <span
                    className="w-2/12 md:w-1/12 inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
                    style={{ backgroundColor: secondaryColourValue }}
                  ></span>
                  <input
                    {...register("secondaryColour")}
                    type="text"
                    className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300"
                    placeholder="#FFFFFF"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <h3 className="text-xl font-medium text-gray-900">URLs</h3>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="facebookUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  Facebook Page
                </label>
                <div className="mt-1">
                  <input
                    {...register("facebookUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="twitterUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  Twitter Page
                </label>
                <div className="mt-1">
                  <input
                    {...register("twitterUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="instagramUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  Instagram Page
                </label>
                <div className="mt-1">
                  <input
                    {...register("instagramUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="linkedInUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  LinkedIn Page
                </label>
                <div className="mt-1">
                  <input
                    {...register("linkedInUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="youtubeUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  Youtube Channel
                </label>
                <div className="mt-1">
                  <input
                    {...register("youtubeUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="rssUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  RSS Url
                </label>
                <div className="mt-1">
                  <input
                    {...register("rssUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="ctaUrl"
                  className="block text-sm font-medium text-gray-700"
                >
                  CTA Url
                </label>
                <div className="mt-1">
                  <input
                    {...register("ctaUrl")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2" />
            </div>
          </div>
          <div className="py-4">
            <h3 className="text-xl font-medium text-gray-900">
              Address Information
            </h3>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="houseNo"
                  className="block text-sm font-medium text-gray-700"
                >
                  House No
                </label>
                <div className="mt-1">
                  <input
                    {...register("houseNo")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="address1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 1
                </label>
                <div className="mt-1">
                  <input
                    {...register("address1")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="address2"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address Line 2
                </label>
                <div className="mt-1">
                  <input
                    {...register("address2")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="town"
                  className="block text-sm font-medium text-gray-700"
                >
                  Town
                </label>
                <div className="mt-1">
                  <input
                    {...register("town")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="county"
                  className="block text-sm font-medium text-gray-700"
                >
                  County
                </label>
                <div className="mt-1">
                  <input
                    {...register("county")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="postcode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Postcode
                </label>
                <div className="mt-1">
                  <input
                    {...register("postcode")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="my-4 block md:flex md:-mx-2">
              <div className="w-full md:w-1/2 md:mx-2">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <div className="mt-1">
                  <input
                    {...register("country")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div className="w-full md:w-1/2 md:mx-2" />
            </div>
          </div>
        </div>
        <div className="!mt-8 -my-6 -mx-4 sm:-m-6 lg:-mb-8 p-4 flex justify-between border-t-2 border-gray-200 bg-white">
          <button
            type="submit"
            className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}
