import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import ConfigGoogle from "./ConfigGoogle";
import ConfigTrustPilot from "./ConfigTrustPilot";
import ConfigFeefo from "./ConfigFeefo";
import ConfigDefault from "./ConfigDefault";
import ConfigEstas from "./ConfigEstas";
import ConfigAllAgents from "./ConfigAllAgents";
import ConfigWebsite from "./ConfigWebsite";

import EstasLogo from "./assets/EstasLogo.png";
import FeefoLogo from "./assets/FeefoLogo.png";
import GoogleLogo from "./assets/GoogleLogo.png";
import TrustpilotLogo from "./assets/TrustpilotLogo.png";
import AllAgentsLogo from "./assets/AllAgentsLogo.gif";
import EstateTrackLogo from "../../assests/EstateTrackLogo.png"


export default function UpdateReviewConfigModal({ open, setOpen, site, handleUpdateReviewSite }) {
    const [component, setComponent] = useState(undefined);

    const reviewSites = [
        {
            name: "Google",
            config: <ConfigGoogle site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite} />,
            logo: GoogleLogo
        },
        {
            name: "TrustPilot",
            config: <ConfigTrustPilot site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite} />,
            logo: TrustpilotLogo
        },
        {
            name: "Feefo",
            config: <ConfigFeefo site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite} />,
            logo: FeefoLogo
        },
        {
            name: "Estas",
            config: <ConfigEstas site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite} />,
            logo: EstasLogo
        },
        {
            name: "AllAgents",
            config: <ConfigAllAgents site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite}/>,
            logo: AllAgentsLogo
        },
        {
            name: "Website",
            config: <ConfigWebsite site={site} isUpdate={true} setOpen={setOpen} handleUpdateReviewSite={handleUpdateReviewSite}/>,
            logo: EstateTrackLogo
        },
    ]

    useEffect(() => {
        if (site) {
            setComponent(reviewSites[site.siteName].config);
        }
    }, [site]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                                {component}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
