import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencies() {
  const staleTime = 8000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const agencies = useQuery("agencies", () => get("/agency").then((res) => res.data), 
  {
    staleTime: staleTime,
  });

  const add = useMutation((agency) => post("/agency", agency), {
    onSuccess: (addedAgency) => {
      queryClient.setQueryData("agencies", (currentAgencies) => [
        addedAgency.data,
        ...currentAgencies,
      ]);
    },
  });

  const update = useMutation((agency) => put(`/agency/${agency.id}`, agency), {
    onSuccess: (updatedAgency) => {
      // queryClient.setQueryData("agencies", (currrentAgencies) =>
      //   currrentAgencies.map((agency) =>
      //     agency.id === updatedAgency.data.id ? updatedAgency.data : agency
      //   )
      // );
      queryClient.refetchQueries("agencies");
    },
  });

  const addTranspondKey = useMutation((ids) => put(`/Transpond/${ids.agencyId}/connectTranspond/${ids.transpondAccountId}`), {
    onSuccess: (updatedAgency) => {
      queryClient.setQueryData("agencies", (currrentAgencies) =>
        currrentAgencies.map((agency) =>
          agency.id === updatedAgency.data.id ? updatedAgency.data : agency
        )
      );
    },
  });

  const removeTranspondKey = useMutation((agencyId) => put(`/Transpond/${agencyId}/disconnectTranspond`), {
    onSuccess: (updatedAgency) => {
      queryClient.setQueryData("agencies", (currrentAgencies) =>
        currrentAgencies.map((agency) =>
          agency.id === updatedAgency.data.id ? updatedAgency.data : agency
        )
      );
    },
  });

  const updateValuationSettings = useMutation((params) => put(`/agency/${params.agencyId}/valuationsettings`, params.settings), {
    onSuccess: (updatedAgency) => {
      queryClient.setQueryData("agencies", (currrentAgencies) =>
        currrentAgencies.map((agency) =>
          agency.id === updatedAgency.data.id ? updatedAgency.data : agency
        )
      );
    },
  });

  const updateReportSettings = useMutation((params) => put(`/agency/${params.agencyId}/reportsettings`, params.settings), {
    onSuccess: (updatedAgency) => {
      queryClient.setQueryData("agencies", (currrentAgencies) =>
        currrentAgencies.map((agency) =>
          agency.id === updatedAgency.data.id ? updatedAgency.data : agency
        )
      );
    },
  });

  const updateServicesSettings = useMutation((params) => put(`/agency/${params.agencyId}/servicessettings`, params.settings), {
    onSuccess: (updatedAgency) => {
      queryClient.setQueryData("agencies", (currrentAgencies) =>
        currrentAgencies.map((agency) =>
          agency.id === updatedAgency.data.id ? updatedAgency.data : agency
        )
      );
    },
  });

  const remove = useMutation((agencyId) => del(`/agency/${agencyId}`), {
    onSuccess: (_, agencyId) => {
      // queryClient.setQueryData("agencies", (currentAgencies) =>
      //   currentAgencies.filter((agency) => agency.id !== agencyId)
      // );
      queryClient.refetchQueries("agencies");
    },
  });

  return {
    agencies,
    add,
    update,
    addTranspondKey,
    removeTranspondKey,
    updateValuationSettings,
    updateReportSettings,
    updateServicesSettings,
    remove,
  };
}
