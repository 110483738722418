import { useEffect, useState } from "react";
import date from "date-and-time";
import { useForm } from "react-hook-form";

import { TrashIcon } from "@heroicons/react/outline";
import ConfirmDelete from "../Shared/ConfirmDelete";


export default function Notes({ notes, submitFunction, deleteFunction }) {
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({});
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [noteDelete, setNoteDelete] = useState(null);

  const handleDeleteOpen = (note) => {
    setNoteDelete(note);
    setConfirmDeleteOpen(true);
  }

  const handleDelete = () => {
    deleteFunction(noteDelete);
    setConfirmDeleteOpen(false);
  }

  const onSubmit = (data) => {
    if (data.note !== "") {
      submitFunction(data);
    }

    reset({
      note: ""
    })
  }

  useEffect(() => {

  }, [notes])

  return (
    <>
      <ConfirmDelete
        itemName="Note"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleDelete}
      />
      <div className="sm:col-span-2 mb-6">
        <dt className="text-sm font-medium text-gray-500">Notes</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {notes === null || notes.length === 0 ? (
            <ul role="list" className="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li className="pl-3 pr-4 py-3 text-sm">
                <p className="text-center">There are no notes!</p>
              </li>
            </ul>
          ) : (
            notes.map((note, i) => {
              return (
                <ul key={i} role="list" className="mb-3 border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li className="flex justify-between pl-3 pr-4 py-3 text-sm">
                    <div>
                      <p className="text-sm font-small text-gray-500">{date.format(new Date(note.created), "DD/MM/YYYY HH:mm")}</p>
                      <p>{note.content ? note.content : note.note}</p>
                    </div>
                    <button
                      onClick={() => handleDeleteOpen(note)}
                      type="button"
                      className="-ml-px flex rounded-full px-2 py-2 text-sm font-medium text-gray-500 hover:text-red-600 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
                    >
                      <span className="sr-only">Delete</span>
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </li>
                </ul>)
            })
          )}

        </dd>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sm:col-span-2">
          <label
            htmlFor="Review Source"
            className="block text-sm font-medium text-gray-700"
          >
            New Note
          </label>
          <div className="mt-1">
            <input
              type="Text"
              {...register("note")}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="New note"
            />
            <div className="flex mt-4">
              <button type="submit" className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto">
                Save note
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}