import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from "../../utils/classNames";
import useReviews from "../../data/useReviews";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useReviewSites from "../../data/useReviewSites";
import LoadingWheel from "../Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import { reviewSiteEnum } from "../../constants/enums";


export default function ReviewConfigModal({ open, setOpen }) {
  const { user: { agency } } = useUserContext();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({ defaultValues: { reviewType: "Sales Applicant" }, });
  const { requestReview } = useReviews();
  const { reviewSiteLinks } = useReviewSites(agency.id);
  const { saveHandlers } = useDefaultCRUDHandlers("Request review");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const onSubmit = (data) => {
    console.log("submitting", data);

    if (data.name !== "" && data.email !== "" && data.completionDate !== "") {
      setError("");
      requestReview.mutate(data,
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset({
              reviewType: "Sales Applicant",
              reviewLink: reviewSiteLinks.data[0].requestReviewLink,
              name: "",
              email: "",
              completionDate: "",
            });
            setOpen(false);
          },
          onError: () => {
            saveHandlers.onError();
          }
        }
      );
    } else {
      setError("Please fill in all values!");
    }
  }

  const reviewTypes = ["Sales Applicant", "Tenant", "Vendor", "Landlord"]

  const reviewTypeValue = watch("reviewType");
  const reviewLinkValue = watch("reviewLink");

  useEffect(() => {
    if (!reviewSiteLinks.isLoading && reviewSiteLinks.data) {
      setValue("reviewLink", reviewSiteLinks.data[0]?.requestReviewLink);
      setIsLoading(false);
    }
  }, [reviewSiteLinks.isLoading, reviewSiteLinks.data]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">

                <h1 className="text-xl font-semibold text-gray-900">Request a review</h1>
                <p className="mt-2 text-sm text-gray-700">
                  Request a review from a customer.
                </p>

                {isLoading ? (
                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="h-80 max-w-7xl flex items-center justify-center">
                      <LoadingWheel width="w-12" height="h-12" />
                    </div>
                  </div>
                ) : (
                  reviewSiteLinks.data.length === 0 ? (
                    <p className="mt-8">No review sites found</p>
                  ) : (
                    <>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="w-full mt-6">
                          <Listbox
                            {...register("reviewType")}
                            value={reviewTypeValue}
                            onChange={(type) => setValue("reviewType", type)}
                          >
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                                  Review Type
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-400 focus:border-etpink-400 sm:text-sm">
                                    <span className="block truncate">
                                      {
                                        reviewTypes.find(
                                          (c) => c === reviewTypeValue
                                        )
                                      }
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    appear={true}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {reviewTypes.map((type) => (
                                        <Listbox.Option
                                          key={type}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-etpink-600"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-8 pr-4"
                                            )
                                          }
                                          value={type}
                                        >
                                          <>
                                            <span
                                              className={classNames(
                                                getValues("reviewType") === type
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {type}
                                            </span>

                                            {getValues("reviewType") === type ? (
                                              <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>

                        <div className="w-full mt-6">
                          <Listbox
                            {...register("reviewLink")}
                            value={reviewLinkValue}
                            onChange={(type) => setValue("reviewLink", type)}
                          >
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                                  Review Site
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-400 focus:border-etpink-400 sm:text-sm">
                                    <span className="block truncate">
                                      {
                                        `${reviewSiteEnum[reviewSiteLinks.data.find(
                                          (rsl) => rsl.requestReviewLink === reviewLinkValue
                                        ).siteName]} - ${reviewSiteLinks.data.find(
                                          (rsl) => rsl.requestReviewLink === reviewLinkValue
                                        ).reviewBranch ?? ""}`
                                      }
                                    </span>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <SelectorIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    appear={true}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {reviewSiteLinks.data.map((rsl, i) => (
                                        <Listbox.Option
                                          key={i}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-etpink-600"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-8 pr-4"
                                            )
                                          }
                                          value={rsl.requestReviewLink}
                                        >
                                          <>
                                            <span
                                              className={classNames(
                                                getValues("reviewLink") === rsl
                                                  ? "font-semibold"
                                                  : "font-normal",
                                                "block truncate"
                                              )}
                                            >
                                              {`${reviewSiteEnum[rsl.siteName]} ${rsl.reviewBranch !== null ? "- " + rsl.reviewBranch : ""}`}
                                            </span>

                                            {getValues("reviewLink") === rsl ? (
                                              <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                                <CheckIcon
                                                  className="h-5 w-5"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            ) : null}
                                          </>
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>



                        <div className="w-full mt-6">
                          <label className="mb-2 block text-sm font-medium text-gray-700">
                            Name
                          </label>
                          <input {...register("name")}
                            type="text"
                            className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="w-full mt-6">
                          <label className="mb-2 block text-sm font-medium text-gray-700">
                            Email
                          </label>
                          <input {...register("email")}
                            type="text"
                            className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        <div className="w-full mt-6">
                          <label className="mb-2 block text-sm font-medium text-gray-700">
                            Completion Date/Tenancy Start Date
                          </label>
                          <input {...register("completionDate")}
                            type="date"
                            className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                          />
                        </div>

                        {getValues("reviewType") === "Sales Applicant" && (
                          <div className="w-full flex mt-6">
                            <input {...register("automatedEmail")}
                              type="checkbox"
                              className="shadow-sm mt-px focus:ring-etpink-400 focus:border-etpink-400 block sm:text-sm border-gray-500 rounded-md"
                            />
                            <label className="ml-2 mb-2 block text-sm font-medium text-gray-700">
                              Check this box to trigger an automated valuation anniversary email
                            </label>
                          </div>
                        )}

                        <div className="mt-6 flex justify-between">
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                          >
                            Request Review
                          </button>
                        </div>

                        <div className="w-full mt-6">
                          <p className="text-red-500">{error}</p>
                        </div>
                      </form>
                    </>
                  )
                )}

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
