import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import classNames from "../../utils/classNames";
import { SwitchAgency, SwitchAgencyDialog } from "./SwitchAgency";

import EstateTrackLogo from "../../assests/EstateTrackLogo.png";
import ContactSupport from "./ContactSupport";

export default function SidebarMobile({
  sidebarOpen,
  setSidebarOpen,
  switchOpen,
  setSwitchOpen,
  navigation,
  onNavItemClick,
}) {
  const [contactSupportOpen, setContactSupportOpen] = useState(false);

  const nav = Object.keys(navigation);
  return (
    <>
      <ContactSupport open={contactSupportOpen} setOpen={setContactSupportOpen} />
      <SwitchAgencyDialog open={switchOpen} setOpen={setSwitchOpen} />
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={() => setSidebarOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              {/* <div className="flex-shrink-0 flex items-center px-4">
                <img
                  className="h-8 w-auto"
                  src={EstateTrackLogo}
                  alt="Workflow"
                />
              </div> */}
              <div className="flex items-center flex-shrink-0 px-4">
                <SwitchAgency
                  setOpen={setSwitchOpen}
                  sidebarOpen={sidebarOpen}
                  setSidebarOpen={setSidebarOpen}
                />
              </div>
              {/* <div className="mt-5 flex-1 h-0 overflow-y-auto"> */}
              <div className="flex flex-1 flex-col">
                <nav className="px-2 pb-4 grow">
                  {nav.length > 0
                    ? nav.map((key) => (
                      <div key={key} className="mt-8 mb-6">
                        {key !== "general" && (
                          <h4 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider mb-3">
                            {key}
                          </h4>
                        )}
                        {navigation[key].map((item) => {
                          return (
                            item.disabled === true ?
                              <span
                                onClick={() => setContactSupportOpen(true)}
                                key={item.name}
                                className="group text-gray-300 flex items-center px-2 py-2 text-sm font-small rounded-md cursor-not-allowed"
                              >
                                <item.icon
                                  className="text-gray-300 mr-3 flex-shrink-0 h-6 w-6"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </span>
                              :
                              <NavLink
                                key={item.name}
                                to={item.href}
                                end={item.end ? item.end : false}
                                onClick={() => setSidebarOpen(false)}
                              >
                                {({ isActive }) => (
                                  <span
                                    className={classNames(
                                      isActive
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                                      "group flex items-center px-2 py-2 text-sm font-small rounded-md"
                                    )}
                                  >
                                    <item.icon
                                      className={classNames(
                                        isActive
                                          ? "text-gray-500"
                                          : "text-gray-400 group-hover:text-gray-500",
                                        "mr-3 flex-shrink-0 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    {item.name}
                                  </span>
                                )}
                              </NavLink>
                          );
                        })}
                      </div>
                    ))
                    : ""}
                </nav>
                <div className="flex items-center flex-shrink-0 px-4 pt-4 border-t border-gray-200">
                  <img
                    className="h-6 w-auto"
                    src={EstateTrackLogo}
                    alt="Workflow"
                  />
                </div>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
