

export const JsonParagraphConfig = ({ register }) => {

  return (
    <div className="w-full h-full">

      <div className="w-full ">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Paragraph text
        </label>
        <textarea
          {...register("label")}
          type="text"
          rows={10}
          className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
        />
      </div>

    </div>
  )
}