import { TrashIcon } from "@heroicons/react/outline";

export default function UserListRow({ user, onRemoveClick }) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600">
          {user.user.firstName} {user.user.surname} - {user.user.emailAddress}
        </p>
        {/* <p className="flex-shrink-0 text-sm font-light text-gray-500 md:w-1/4">
          {user.agencyRole.name}
        </p> */}
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onRemoveClick(user.user.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Delete</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}
