import { authUrl } from "./config";

function handleAuthRepsonse(res) {
  return res.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!res.ok) {
      return Promise.reject(data);
    } else {
      return data;
    }
  });
}

export default function useAuthHelper() {
  const buildPath = (path) => {
    return authUrl + path;
  };

  const get = async (path, token = "") =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
      };

      if (token !== "") {
        requestOptions.headers = {
          ...requestOptions.headers,
          Authorization: token,
        };
      }

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const post = async (path, data, token = "") =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        credentials: 'include'
      };

      if (token !== "") {
        requestOptions.headers = {
          ...requestOptions.headers,
          Authorization: token,
        };
      }

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const patch = async (path, data, token = "") =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
        credentials: 'include'
      };

      if (token !== "") {
        requestOptions.headers = {
          ...requestOptions.headers,
          Authorization: token,
        };
      }

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const del = async (path, token = "") =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
      };

      if (token !== "") {
        requestOptions.headers = {
          ...requestOptions.headers,
          Authorization: token,
        };
      }

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  return {
    get,
    post,
    patch,
    del,
  };
}
