import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useAllMedia() {
  const staleTime = 10000;
  const { user: { agency }, } = useUserContext();
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const media = useQuery(
    ["allMedia", agency.id],
    () => get(`/media/all/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  return {
    media,
  };
}
