import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyUserInvites(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const userInvites = useQuery(
    ["userInvites", agencyId],
    () => get(`/agency/${agencyId}/userinvites`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((userInvite) => post("/userinvite", userInvite), {
    onSuccess: (addedUserInvite) => {
      queryClient.setQueryData(
        ["userInvites", agencyId],
        (currentUserInvites) => [addedUserInvite.data, ...currentUserInvites]
      );
    },
  });

  const resend = useMutation(
    (userInvite) => put(`/userinvite/${userInvite.id}/resend`),
    {
      onSuccess: (updatedUserInvite) => {
        queryClient.setQueryData(
          ["userInvites", agencyId],
          (currrentUserInvites) =>
            currrentUserInvites.map((userInvite) =>
              userInvite.id === updatedUserInvite.data.id
                ? updatedUserInvite.data
                : userInvite
            )
        );
      },
    }
  );

  const oldUserInvite = useMutation(
    (userInvite) => put(`/userinvite/${userInvite.id}/OldPortalUserInvite`),
    {
      onSuccess: (updatedUserInvite) => {
        queryClient.setQueryData(
          ["userInvites", agencyId],
          (currrentUserInvites) =>
            currrentUserInvites.map((userInvite) =>
              userInvite.id === updatedUserInvite.data.id
                ? updatedUserInvite.data
                : userInvite
            )
        );
      },
    }
  );

  const remove = useMutation(
    (userInviteId) => del(`/userinvite/${userInviteId}`),
    {
      onSuccess: (_, userInviteId) => {
        queryClient.setQueryData(
          ["userInvites", agencyId],
          (currentUserInvites) =>
            currentUserInvites.filter(
              (userInvite) => userInvite.id !== userInviteId
            )
        );
      },
    }
  );

  return {
    userInvites,
    add,
    resend,
    oldUserInvite,
    remove,
  };
}
