import { Fragment, useEffect, useState } from "react";
import { AdjustmentsIcon, QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import useEstasBranches from "../../data/useEstasBranches";
import classNames from "../../utils/classNames";

export default function ConfigEstas({ site, setIsConfig, handleAddReviewSite, setOpen, isUpdate, handleUpdateReviewSite }) {
  const { register, handleSubmit, setValue } = useForm();
  const { estasBranches: allEstasBranches } = useEstasBranches();
  const [estasBranches, setEstasBranches] = useState(allEstasBranches);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const onSubmit = (data) => {
    data.siteName = 3;

    var api = "";

    if (selected) {
      api = selected.estasId.toString();
    }

    if (isUpdate) {
      var updatedSite = site;
      updatedSite.api = api;
      updatedSite.requestReviewLink = data.requestReviewLink;
      updatedSite.reviewBranch = data.reviewBranch;

      handleUpdateReviewSite(updatedSite)
    } else {
      data.api = api;
      handleAddReviewSite(data);
      setIsConfig(false);
    }
  };

  const handleClear = () => {
    setSelected();
  }

  useEffect(() => {
    if (!allEstasBranches.isLoading) {
      if (isUpdate) {
        setSelected(allEstasBranches.data.find(branch => branch.estasId == site.api));
        setValue("requestReviewLink", site.requestReviewLink);
        setValue("reviewBranch", site.reviewBranch);
      }
    }
  }, [allEstasBranches.isLoading, allEstasBranches.data]);

  useEffect(() => {
    if (!allEstasBranches.isLoading && searchTerm.length > 0) {

      setEstasBranches(
        allEstasBranches.data.filter((branch) =>
          branch.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          branch.address.toLowerCase().includes(searchTerm.toLowerCase()) ||
          branch.postcode.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allEstasBranches.isLoading, allEstasBranches.data, searchTerm]);



  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center">
        <div className="mr-5 flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
          <AdjustmentsIcon
            className="h-5 w-5 text-gray-600"
            aria-hidden="true"
          />
        </div>
        <Dialog.Title
          as="h3"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Configure Estas Reviews
        </Dialog.Title>
      </div>
      <div className="mt-3 sm:mt-5">
        <div className="mt-2">
          {loading || allEstasBranches.isLoading ? (
            <div className="flex p-4 justify-center items-center">
              <LoadingWheel width="w-10" height="h-10" />
            </div>
          ) : (
            <div className="">
              <div className="p-3">
                <div className="mt-1">
                  <Combobox
                    onChange={(estasBranch) => setSelected(estasBranch)}
                  >
                    {({ open }) => (
                      <>
                        <Combobox.Label className="block text-sm font-medium text-gray-700">
                          Estas Branch
                        </Combobox.Label>
                        <div className="relative mt-1">
                          <Combobox.Input
                            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-etpink-500 focus:outline-none focus:ring-1 focus:ring-etpink-500 sm:text-sm"
                            onChange={(e) => setSearchTerm(e.target.value)
                            }
                            displayValue={searchTerm}
                            placeholder="Start typing to search for a branch"
                          />
                          <Transition
                            show={open}
                            appear={true}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Combobox.Options
                              static
                              className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                            >
                              {estasBranches.length > 0 && searchTerm.length > 0 ? (
                                estasBranches.map((branch, i) => (
                                  <Combobox.Option
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    key={i}
                                    value={branch}
                                  >
                                    <span className="block truncate">
                                      {branch.name}
                                    </span>
                                  </Combobox.Option>
                                ))
                              ) : (
                                <Combobox.Option
                                  disabled
                                  className="cursor-default select-none relative py-2 text-center text-gray-500"
                                >
                                  No Estas branches match the search terms
                                </Combobox.Option>
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Combobox>
                  <input
                    value={selected ? selected.name : ""}
                    type="text"
                    className="mt-2 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Selected Branch"
                    disabled
                  />
                  <button type="button" onClick={handleClear} className="absolute right-12 -translate-y-[1.8rem]">X</button>
                </div>
              </div>

              <div className="p-3">
                <label
                  htmlFor="requestReviewLink"
                  className="block text-sm font-medium text-gray-700"
                >
                  Request Review Link
                </label>
                <div className="mt-1">
                  <input
                    {...register("requestReviewLink")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>

              <div className="p-3">
                <label
                  htmlFor="reviewBranch"
                  className="block text-sm font-medium text-gray-700"
                >
                  Review Branch
                </label>
                <div className="mt-1">
                  <input
                    {...register("reviewBranch")}
                    type="text"
                    className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="w-full sm:w-1/2 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
          >
            Save
          </button>
          {isUpdate ? (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          ) : (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setIsConfig(false)}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </form >
  );
}