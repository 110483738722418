
export const userRoleEnum = {
  user: "User",
  admin: "Admin",
};

export const postStatusEnum = {
  0: "Published",
  1: "Private",
  2: "Scheduled",
  3: "Un-Published",
  4: "Pending",
  5: "Trash",
};

export const reviewSiteEnum = {
  0: "Google",
  1: "TrustPilot",
  2: "Feefo",
  3: "Estas",
  4: "AllAgents",
  5: "Website"
};

export const qualifierEnum = {
  0: "Zoopla",
  1: "Rightmove",
  2: "OnTheMarket",
  3: "Website",
};

export const valuationStatusEnum = {
  0: "New",
  1: "Lost",
  2: "Contact Made",
  3: "Booked",
  4: "Appraisal Sent",
  5: "Won",
  6: "Updated",
};

export const limitedValuationStatusEnum = {
  2: "Contact Made",
  3: "Booked",
  5: "Won",
  1: "Lost",
};

export const valuationQualityEnum = {
  0: "Not set",
  1: "Very Poor",
  2: "Poor",
  3: "Moderate",
  4: "Good",
  5: "Excellent",
}

export const viewingStatusEnum = {
  0: "New",
  1: "Contact Made",
  2: "Confirmed",
  3: "Completed",
};

export const formLeadStatusEnum = {
  0: "New",
  1: "Contact Made",
  2: "Confirmed",
  3: "Completed",
};

export const mortgageStatusEnum = {
  0: "New",
  1: "Contact Made",
  2: "Confirmed",
  3: "Completed",
};

export const valuationSourceEnum = {
  0: "Virtual",
  1: "Instant",
  2: "In Person",
  3: "Portal",
};

export const valuationPropertyTypeEnum = {
  0: "Detached",
  1: "Semi-Detached",
  2: "Terraced",
  3: "Flat/Maisonette",
  4: "Other",
};

export const valuationTypeEnum = {
  0: "Combined",
  1: "Sales",
  2: "Lettings"
}

export const propertyDataPropertyTypeEnum = {
  flat: "Flat",
  terraced_house: "Terraced",
  "semi-detached_house": "Semi-Detached",
  detached_house: "Detached",
};

export const notificationPolicyTypeEnum = {
  0: "All",
  1: "Branch",
  2: "Department",
  3: "User",
};

export const notificationActionTypeEnum = {
  0: "Blog Published",
  1: "Blog Unpublished",
  2: "Blog Deleted",
  3: "Review Published",
  4: "Review Unpublished",
  5: "Review Deleted",
  6: "Video Review Published",
  7: "Video Review Unpublished",
  8: "Video Review Deleted",
  9: "User Added",
  10: "User Deleted",
  11: "Team Member Added",
  12: "Team Member Deleted",
  13: "Valuation Added",
  14: "Valuation Deleted",
  15: "Registration Added",
  16: "Registration Deleted",
  17: "Enquiry Added",
  18: "Enquiry Deleted",
};

export const logStatusEnum ={
  0: "Not Run",
  1: "Running",
  2: "Success",
  3: "Error"
}

export const formTypeEnum = {
  0: "Form Only",
  1: "Landing Page"  
}


export const inputTypeEnum = {
  "input": "Text Input",
  "textarea": "Large Text Input",  
  "number": "Number Input",  
  "select": "Dropdown",  
  "checkbox": "Checkbox",  
  "radio": "Radio Group",  
  "date": "Date",  
  "time": "Time",  
  // "datetime": "Date & Time",  
  "linebreak": "Linebreak",  
  "heading": "Heading",  
  "paragraph": "Paragraph",  
  "valuation": "Valuation",  
  "mortgage": "Mortgage",  
  "image": "Image",
  "pdf": "PDF",
  "signature": "Signature"
}

export const leadTypeEnum = {
  0: "All",
  1: "Valuations",
  2: "Enquiries",
  3: "Viewings",
  4: "Mortgages",
  5: "Form Leads"
}

export const socialPostTypeEnum = {
  0: "Evergreen",
  1: "Infographic",
  2: "Video"
}