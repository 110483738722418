export default function UrlRedirect({ register }) {

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        URL Redirect
      </label>
      <div className="mt-1">
        <input
          {...register("urlRedirect")}
          type="text"
          className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
          placeholder="https://www.yoursite.co.uk/thank-you"
        ></input>
      </div>

    </div>
  )

}