import { Navigate, Outlet, useLocation, useMatch } from "react-router-dom";

import Dashboard from "./components/Dashboard";
import SignIn from "./pages/SignIn/SignIn";
import Invite from "./pages/Invite/Invite";
import NotFound from "./pages/notFound";
import { AppHome } from "./pages/App/home";
import BlogPosts from "./pages/BlogPosts/BlogPosts";
import BlogPostAddEdit from "./pages/BlogPosts/BlogPostAddEdit";
import TeamMembers from "./pages/Team/TeamMembers";
import Valuations from "./pages/Valuations/valuations";
import Registrations from "./pages/Registrations/registrations";
import RegistrationsAddEdit from "./pages/Registrations/RegistrationsAddEdit";
import Properties from "./pages/Properties/Properties";
import PropertyView from "./pages/Properties/PropertyView";
import Reviews from "./pages/Reviews/Reviews";
import VideoReviews from "./pages/VideoReviews/VideoReviews";
import MarketReports from "./pages/MarketReports/marketReports";
import ServiceHealth from "./pages/Admin/serviceHealth";
import ServiceLog from "./pages/Admin/serviceLog";
import TeamMemberAddEdit from "./pages/Team/TeamMemberAddEdit";
import Agencies from "./pages/Agencies/Agencies";
import AgencyEdit from "./pages/Agencies/AgencyEdit";
import ReviewAddEdit from "./pages/Reviews/ReviewAddEdit";
import AdminHome from "./pages/Admin/Index";
import DefaultAgencyRedirect from "./pages/App/DefaultAgencyRedirect";
import Groups from "./pages/Groups/Groups";
import GroupEdit from "./pages/Groups/GroupEdit";
import MediaManager from "./pages/MediaManager/MediaManager";
import EmailTemplates from "./pages/EmailTemplates/EmailTemplates";

import useUserContext from "./contexts/UserContext";
import { userRoleEnum } from "./constants/enums";
import ContentEnginePosts from "./pages/ContentEngine/ContentEnginePosts";
import ContentEnginePostAddEdit from "./pages/ContentEngine/ContentEnginePostAddEdit";
import Enquiries from "./pages/Enquiries/Enquiries";
import ValuationView from "./pages/Valuations/ValuationView";
import ViewEnquiry from "./pages/Enquiries/ViewEnquiry";
import ValuationAdd from "./pages/Valuations/ValuationAdd";
import Settings from "./pages/Settings/Settings";
import Notifications from "./pages/Notifications/Notifications";
import VideoReviewAddEdit from "./pages/VideoReviews/VideoReviewAddEdit";
import BookValuation from "./pages/PublicPages/BookValuation";
import ContentEngineCategories from "./pages/ContentEngineCategories/ContentEngineCategories";
import Viewings from "./pages/Viewings/Viewings";
import ViewViewing from "./pages/Viewings/ViewViewing";
import UserProfile from "./pages/UserProfile/UserProfile";
import PortalQualifiers from "./pages/Portal Qualifier/PortalQualifiers";
import ViewEmailTemplate from "./pages/EmailTemplates/ViewEmailTemplate";
import ForgotPassword from "./pages/SignIn/ForgotPassword";
import UnsubscribeSuccessful from "./pages/Unsubscribe/UnsubscribeSuccessful";
import { ValuationsAnalytics } from "./pages/Analytics/ValuationsAnalytics";
import Mortgages from "./pages/Mortgages/Mortgages";
import ViewMortgage from "./pages/Mortgages/ViewMortgage";
import { PortalAnalytics } from "./pages/Analytics/PortalAnalytics";
import { AreaGuideData } from "./pages/Analytics/AreaGuideData";
import { ReviewAnalytics } from "./pages/Analytics/ReviewAnalytics";
import { EmailAnalytics } from "./pages/Analytics/EmailAnalytics";
import { WebsiteAnalytics } from "./pages/Analytics/WebsiteAnalytics";
import FormBuilder from "./pages/FormBuilder/FormBuilder";
import FormBuilderAddEdit from "./pages/FormBuilder/FormBuilderAddEdit";
import FormLeads from "./pages/FormLeads/FormLeads";
import FormLeadView from "./pages/FormLeads/FormLeadView";
import MyLeads from "./pages/MyLeads/MyLeads";
import PolicyManager from "./pages/PolicyManager/PolicyManager";

function ProtectedRoute({ redirectPath = "/signin", adminRoute = false }) {
  const { user } = useUserContext();
  const location = useLocation();

  //Ensure user is logged in
  if (!user.loggedIn) {
    return (
      <Navigate
        replace
        to={redirectPath}
        state={{
          attemptedRoute: location.pathname,
        }}
      />
    );
  }

  //If the route requires admin, check the user has admin role
  if (adminRoute && user.identity.role !== userRoleEnum.admin) {
    return (
      <Navigate replace to={redirectPath} state={{ unauthorized: true }} />
    );
  }

  return <Outlet />;
}

const routes = [
  { path: "signin", element: <SignIn /> },
  { path: "invite", element: <Invite /> },
  { path: "forgot-password", element: <ForgotPassword /> },
  { path: "unsubbed", element: <UnsubscribeSuccessful /> },
  {
    path: "bookedvaluation",
    element: <BookValuation />,
    children: [
      { path: ":valuationId", element: <BookValuation /> }
    ]
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "",
        element: <Dashboard />,
        children: [
          {
            index: true,
            element: <DefaultAgencyRedirect />,
          },
          {
            path: "profile",
            element: <UserProfile />,
          },
          {
            path: ":agencyName",
            children: [
              {
                index: true,
                element: <AppHome />,
              },
              {
                path: "settings",
                element: <Settings />,
                children: [
                  {
                    path: ":section", element: <Settings />
                  },
                ]
              },
              {
                path: "myleads",
                children: [
                  {
                    index: true,
                    element: <MyLeads />,
                  },
                ],
              },
              {
                path: "team",
                children: [
                  {
                    index: true,
                    element: <TeamMembers />,
                  },
                  {
                    path: "member",
                    element: <TeamMemberAddEdit />,
                    children: [
                      { path: ":teamMemberId", element: <TeamMemberAddEdit /> },
                    ],
                  },
                ],
              },
              {
                path: "blog",
                children: [
                  { index: true, element: <BlogPosts /> },
                  {
                    path: "designer",
                    element: <BlogPostAddEdit />,
                    children: [
                      { path: ":postId", element: <BlogPostAddEdit /> },
                    ],
                  },
                ],
              },
              {
                path: "reviews",
                children: [
                  { index: true, element: <Reviews /> },
                  {
                    path: "review",
                    element: <ReviewAddEdit />,
                    children: [
                      { path: ":reviewId", element: <ReviewAddEdit /> },
                    ],
                  },
                ],
              },
              {
                path: "valuations",
                children: [
                  { index: true, element: <Valuations /> },
                  { path: "book", element: <ValuationAdd /> },
                  {
                    path: "valuation",
                    element: <ValuationView />,
                    children: [
                      {
                        path: ":valuationId",
                        element: <ValuationView />,
                      },
                    ],
                  },
                ],
              },
              { path: "portalqualifier", element: <PortalQualifiers /> },
              {
                path: "registrations",
                children: [
                  { index: true, element: <Registrations /> },
                  {
                    path: "registration",
                    element: <RegistrationsAddEdit />,
                    children: [
                      {
                        path: ":registrationId",
                        element: <RegistrationsAddEdit />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "enquiries",
                children: [
                  { index: true, element: <Enquiries /> },
                  {
                    path: "enquiry",
                    element: <ViewEnquiry />,
                    children: [
                      {
                        path: ":enquiryId",
                        element: <ViewEnquiry />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "properties",
                children: [
                  { index: true, element: <Properties /> },
                  {
                    path: "property",
                    element: <PropertyView />,
                    children: [
                      {
                        path: ":propertyId",
                        element: <PropertyView />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "viewings",
                children: [
                  { index: true, element: <Viewings /> },
                  {
                    path: "viewing",
                    element: <ViewViewing />,
                    children: [
                      {
                        path: ":viewingId",
                        element: <ViewViewing />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "mortgages",
                children: [
                  { index: true, element: <Mortgages /> },
                  {
                    path: "mortgage",
                    element: <ViewMortgage />,
                    children: [
                      {
                        path: ":mortgageId",
                        element: <ViewMortgage />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "formleads",
                children: [
                  { index: true, element: <FormLeads /> },
                  {
                    path: "formlead",
                    element: <FormLeadView />,
                    children: [
                      {
                        path: ":formLeadId",
                        element: <FormLeadView />,
                      },
                    ],
                  },
                ],
              },
              {
                path: "videoreviews",
                children: [
                  { index: true, element: <VideoReviews /> },
                  {
                    path: "videoreview",
                    element: <VideoReviewAddEdit />,
                    children: [
                      { path: ":reviewId", element: <VideoReviewAddEdit /> },
                    ],
                  },
                ],
              },
              {
                path: "forms",
                children: [
                  { index: true, element: <FormBuilder isLandingPage={false} /> },
                  {
                    path: "form",
                    element: <FormBuilderAddEdit isLandingPage={false} />,
                    children: [
                      { path: ":formId", element: <FormBuilderAddEdit isLandingPage={false} /> },
                    ],
                  },
                ],
              },
              {
                path: "landingpages",
                children: [
                  { index: true, element: <FormBuilder isLandingPage={true} /> },
                  {
                    path: "landingpage",
                    element: <FormBuilderAddEdit isLandingPage={true} />,
                    children: [
                      { path: ":formId", element: <FormBuilderAddEdit isLandingPage={true} /> },
                    ],
                  },
                ],
              },
              {
                path: "analytics/valuations",
                children: [
                  { index: true, element: <ValuationsAnalytics /> },
                ],
              },
              {
                path: "analytics/reviews",
                children: [
                  { index: true, element: <ReviewAnalytics /> },
                ],
              },
              {
                path: "analytics/portal",
                children: [
                  { index: true, element: <PortalAnalytics /> },
                ],
              },
              {
                path: "analytics/areaguides",
                children: [
                  { index: true, element: <AreaGuideData /> },
                ],
              },
              {
                path: "analytics/emails",
                children: [
                  { index: true, element: <EmailAnalytics /> },
                ],
              },
              {
                path: "analytics/website",
                children: [
                  { index: true, element: <WebsiteAnalytics /> },
                ],
              },
              { path: "marketreports", element: <MarketReports /> },
              { path: "notifications", element: <Notifications /> },
            ],
          },
          { path: "404", element: <NotFound /> },
          { path: "*", element: <Navigate replace to="404" /> },
        ],
      },
    ],
  },
  {
    path: "admin",
    element: <ProtectedRoute adminRoute />,
    children: [
      {
        path: "",
        element: <Dashboard type="admin" />,
        children: [
          { index: true, element: <AdminHome /> },
          {
            path: "agencies",
            children: [
              { index: true, element: <Agencies /> },
              {
                path: ":agencyId",
                element: <AgencyEdit />,
                children: [{ path: ":section", element: <AgencyEdit /> }],
              },
            ],
          },
          {
            path: "groups",
            children: [
              { index: true, element: <Groups /> },
              { path: ":groupId", element: <GroupEdit /> },
            ],
          },
          {
            path: "emailtemplates",
            children: [
              { index: true, element: <EmailTemplates /> },

              { path: ":emailTemplateId", element: <ViewEmailTemplate /> },

            ],
          },
          {
            path: "content-engine",
            children: [
              { index: true, element: <ContentEnginePosts /> },
              {
                path: "designer",
                element: <ContentEnginePostAddEdit />,
                children: [
                  { path: ":postId", element: <ContentEnginePostAddEdit /> },
                ],
              },
            ],
          },
          {
            path: "ce-categories",
            element: <ContentEngineCategories />,
          },
          {
            path: "media-manager",
            element: <MediaManager />,
          },
          {
            path: "policy-manager",
            element: <PolicyManager />,
            children: [{ path: ":section", element: <PolicyManager /> }],
          },
          {
            path: "servicehealth",
            children: [
              { index: true, element: <ServiceHealth /> },
              { path: ":id", element: <ServiceLog /> },
            ],
          },
          { path: "404", element: <NotFound /> },
          { path: "*", element: <Navigate replace to="404" /> },
        ],
      },
    ],
  },
];

export default routes;
