import { useState, useImperativeHandle, forwardRef } from "react";
import Dropzone from "react-dropzone";
import Compressor from 'compressorjs';
import { UploadIcon, XIcon } from "@heroicons/react/outline";

import useApiHelper from "../../services/useApiHelper";
import bytesToSize from "../../utils/bytesToSize";
import LoadingWheel from "../Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import { useQueryClient } from "react-query";

const UploadMedia = forwardRef(({ maxFiles }, ref) => {
  const { user: { agency }, } = useUserContext();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const { post, formData } = useApiHelper();
  const queryClient = useQueryClient();

  const uploadFiles = async () => {
    return new Promise((resolve, reject) => {
      setUploading(true);
      let toUpload = [];
      files.forEach((f) => toUpload.push(uploadFile(f)));
      Promise.all(toUpload)
        .then((res) => {
          setFiles([]);
          setUploading(false);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const uploadFile = (file) => {
    const url = agency ? `/media/agency/${agency.id}` : "/media";

    return new Promise((resolve, reject) => {
      post(url, {
        name: file.name,
        filename: file.data.name,
        type: file.data.type,
        size: file.data.size,
        altText: file.altText,
        attributionText: file.attributionText
      })
        .then((res) => {
          let fd = new FormData();
          fd.append("media", file.data, res.data.filename);
          formData(`/media/${res.data.id}`, fd).then((_) => {
            agency ? queryClient.invalidateQueries(["media", agency?.id]) :
              queryClient.invalidateQueries(["media"]);
            resolve(res.data);
          });
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  };

  const processFiles = (acceptFiles) => {
    acceptFiles.forEach((file) => {
      if (file.type.includes("image")) {
        new Compressor(file, {
          quality: 0.7,
          success: (compressedFile) => {
            setFiles((prevFiles) => [
              {
                data: compressedFile,
                name: "",
                altText: "",
              },
              ...prevFiles,
            ]);

          }
        });
      } else {
        setFiles((prevFiles) => [
          {
            data: file,
            name: "",
            altText: "",
          },
          ...prevFiles,
        ]);
      }
    });
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => i !== index));
  };

  useImperativeHandle(ref, () => ({ upload: uploadFiles }), [files]);

  return (
    <>
      <Dropzone onDrop={processFiles} maxFiles={maxFiles}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div
              className="flex flex-col items-center text-gray-500 hover:text-gray-700  bg-white border-2 border-gray-300 border-dashed rounded-lg p-10 hover:border-gray-400"
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              {uploading ? (
                <LoadingWheel width="w-6" height="h-6" />
              ) : (
                <>
                  <UploadIcon className="w-16 h-16 m-2" />
                  <span className="text-center">
                    Drop some files here, or click to select files
                  </span>
                </>
              )}
            </div>
          </section>
        )}
      </Dropzone>

      {files.length > 0 && (
        <ul
          role="list"
          className="bg-gray-200 my-4 p-6 rounded grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {files.map((file, i) => (
            <li key={`media-${i}`} className="relative">
              <div className="group block w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 overflow-hidden">
                {file.data.type.includes("video") ? (
                  <video className="object-cover ml-auto mr-auto" controls>
                    <source
                      src={`${URL.createObjectURL(file.data)}`}
                      type={file.data.type}
                    />
                  </video>
                ) : (
                  <img
                    src={`${URL.createObjectURL(file.data)}`}
                    alt={file.altText}
                    className="object-cover ml-auto mr-auto"
                  />
                )}
                <span
                  onClick={() => removeFile(i)}
                  className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 inline-flex items-center p-1 rounded-full text-xs font-medium bg-red-100 text-red-800 hover:cursor-pointer hover:bg-red-200"
                >
                  <XIcon className="w-5 h-5" />
                </span>
              </div>
              <p className="mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
                {bytesToSize(file.data.size)}
              </p>
              <div className="mt-2 block">
                <input
                  value={file.name}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        name: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Friendly Name"
                />
              </div>
              <div className="mt-2 block">
                <input
                  value={file.altText}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        altText: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Short Description"
                />
              </div>
              <div className="mt-2 block">
                <input
                  value={file.attributionText}
                  onChange={(e) =>
                    setFiles((oldFiles) => {
                      let withoutCurrent = oldFiles.filter(
                        (f) => f.data.name !== file.data.name
                      );

                      withoutCurrent.splice(i, 0, {
                        ...oldFiles[i],
                        attributionText: e.target.value,
                      });

                      return withoutCurrent;
                    })
                  }
                  type="text"
                  className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Attribution Text"
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </>
  );
});

export default UploadMedia;
