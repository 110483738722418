import { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";

import Notes from "../../components/Notes/Notes";
import AssignUser from "../../components/Shared/AssignUser";
import Avatar from "../../components/Shared/Avatar";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import StatusDropdown from "../../components/Shared/StatusDropdown";
import ViewingUpdateModal from "../../components/Viewing/ViewingUpdateModal";
import { viewingStatusEnum } from "../../constants/enums";
import useAllAgencyProperties from "../../data/useAllAgencyProperties";
import useViewings from "../../data/useViewings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useNotification from "../../components/notification/useNotifications";

export default function ViewViewing() {
  const navigate = useNavigate();
  const { properties } = useAllAgencyProperties();
  const { viewings, update, updateNotes, removeNote, resendQualifier } = useViewings();
  const { agencyName, viewingId } = useParams();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Viewing");
  const [viewing, setViewing] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [property, setProperty] = useState(null);
  const [formattedDepartment, setFormattedDepartment] = useState(null);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [qualifierResponses, setQualifierResponse] = useState("");
  const { addNotification } = useNotification();

  const confirmDelete = (note) => {
    var updateData = {
      noteId: note.id,
      viewingId: viewingId
    }

    removeNote.mutate(updateData, deleteHandlers);
  };

  const onSubmit = (data) => {
    console.log("submitting", data);
    let mutateData = {
      viewingId: viewingId,
      newNote: {
        note: data.note
      }
    };
    if (viewingId) {
      updateNotes.mutate(mutateData, saveHandlers);
    }
  };

  const sendQualiferAgain = () => {
    resendQualifier.mutate(viewingId, {
        onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: `Qualifer Email Sent`,
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: `Failed To Send Qualifer Email`,
        });
      }
    });
  }


  useEffect(() => {
    if (!viewings.isLoading && !properties.isLoading) {
      if (viewingId) {
        let foundViewing = viewings.data.find((e) => e.id == viewingId);
        // let foundViewing = viewingsStub.viewings.find((e) => e.id == viewingId);

        let property = properties.data.find((p) => p.id === foundViewing.propertyId);

        if (property) {
          var nohyphen = property.department.replace(/-/g, " ");
          setFormattedDepartment(nohyphen.charAt(0).toUpperCase() + nohyphen.slice(1));
          setProperty(property)
        }

        setViewing(foundViewing);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [viewings.isLoading, viewings.data, viewingId, properties.isLoading, properties.data]);

  useEffect(() => {
    if (viewing && loading === false) {
      if (viewing.qualifierResponses === null) {
        return;
      }

      //This will remove commas
      const commaRegex = /\,/g;
      //Remove leading whitespace
      const leadingWhitespaceRegex = /^[ \t]+/gm;

      let responses = viewing.qualifierResponses.replace(commaRegex, "");
      responses = responses.replace(leadingWhitespaceRegex, "");

      setQualifierResponse(responses);
      // setQualifierResponse(viewing.qualifierResponses);
    }
  }, [loading, viewing])

  const pages = [
    { to: "..", label: "Viewings", current: false },
    {
      to: viewingId && viewing ? `${viewingId}` : "",
      label:
        viewingId && viewing
          ? `${viewing.fullName}'s Viewing`
          : "New Viewing",
      current: true,
    },
  ];

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ViewingUpdateModal
          open={updateOpen}
          setOpen={setUpdateOpen}
          viewing={viewing}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="flex justify-center mt-5">
            <div className="bg-white block w-full shadow overflow-hidden sm:rounded-lg">
              <div className="flex justify-between">
                <div className="flex px-4 py-5 sm:px-6">
                  <div className="flex flex-col">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Viewing Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Details related to this viewing</p>
                  </div>

                  <div>
                    <Avatar classes="ml-2 w-10 h-10" userId={viewing.userId ?? ""} />
                  </div>

                  <div className="flex items-center ml-5">
                    <span
                      className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
                      ${viewing.isQualified ?
                          ("text-green-600 bg-green-100")
                          :
                          ("text-yellow-600 bg-yellow-100")
                        }`}
                    >
                      {viewing.isQualified ? "Qualified" : "Not Qualified"}
                    </span>
                  </div>

                </div>

                <div className="flex items-center gap-x-4 mr-4">
                  <StatusDropdown lead={viewing} statusEnum={viewingStatusEnum} update={update} saveHandler={"Viewing"} />
                  <AssignUser open={open} setOpen={setOpen} lead={viewing} update={update} saveHandler={"Viewing"} classes={"mt-5"} />
                  <button
                    onClick={() => setUpdateOpen(true)}
                    type="button"
                    className="mt-5 whitespace-nowrap inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Add Date/Time
                  </button>
                  <button
                    onClick={() => sendQualiferAgain()}
                    disabled={viewing.isQualified}
                    type="button"
                    className="mt-5 whitespace-nowrap inline-flex items-center justify-center rounded-md border border-transparent enabled:bg-etpink-600 bg-gray-500 px-4 py-2 text-sm font-medium text-white shadow-sm enabled:hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Send Qualifier
                  </button>
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 mb-16 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{viewing.fullName}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                    <dd className="mt-1 text-sm text-gray-900">{viewing.emailAddress}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                    <dd className="mt-1 text-sm text-gray-900">{viewing.telephoneNumber}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Date and Time</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {/* {date.format(new Date(viewing.dateCreated), "DD/MM/YYYY HH:mm")} */}
                      {`${viewing.date} ${viewing.time}`}
                    </dd>
                  </div>


                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Source</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {viewing.source ? viewing.source : "Website"}
                    </dd>
                  </div>

                  {property &&
                    <>
                      <div className="col-span-2 pt-4 border-t">
                        <div className="mb-4">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Property Information</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">A property on the portal is linked to this viewing</p>
                        </div>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Property link:</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <div className="text-blue-600 underline hover:cursor-pointer"
                            onClick={() => navigate(`/${agencyName}/properties/property/${property.id}`)}
                            dangerouslySetInnerHTML={{ __html: property.title }}></div>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Department</dt>
                        <dd className="mt-1 text-sm text-gray-900">{formattedDepartment}</dd>
                      </div>
                    </>
                  }

                  {/* {viewing.isFromPortal && viewing.isQualified && */}
                  {viewing.isQualified &&
                    <>
                      <div className="col-span-2 pt-4 border-t">
                        <div className="mb-2">
                          <h3 className="text-lg leading-6 font-medium text-gray-900">Qualifier Questions</h3>
                          <p className="mt-1 max-w-2xl text-sm text-gray-500">Someone has answered additional questions about this viewing.</p>
                        </div>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Responses</dt>
                        {/* <br></br> */}
                        {viewing.qualifierResponsesFormatted ? (
                          <dd className="mt-1 p-5 border rounded-md border-slate-300 text-sm text-gray-900" dangerouslySetInnerHTML={{ __html: viewing.qualifierResponsesFormatted }}>
                          </dd>
                        ) : (
                          <dd className="mt-1 p-5 border rounded-md border-slate-300 text-sm text-gray-900 whitespace-pre">
                            {qualifierResponses}
                          </dd>
                        )}
                        
                      </div>

                    </>
                  }
                </dl>
                <Notes notes={viewing.notes} submitFunction={onSubmit} deleteFunction={confirmDelete} />

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}