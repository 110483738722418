import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAgencyUsers from "../../data/useAgencyUsers";

import UserListRow from "./UserListRow";
import { useState } from "react";
import ConfirmDelete from "../Shared/ConfirmDelete";

export default function UserListPartial({ agencyId, RowComponent }) {
  const { users, remove: removeUser } = useAgencyUsers(agencyId);
  const { deleteHandlers } = useDefaultCRUDHandlers("User");
  const [deleteId, setDeleteId] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const deleteUser = (userId) => {
    setDeleteId(userId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove(deleteId);
  };

  const remove = (userId) => removeUser.mutate(userId, deleteHandlers);

  return (
    <>
      <ConfirmDelete
        itemName="User"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div>
        <h3 className="text-xl font-medium text-gray-900">Users</h3>
        <p className="mt-1 text-sm text-gray-500">
          Users who have access to the dashboard for this agency
        </p>
        <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
          {users.isLoading ? (
            <li key="userInvites-loading" className="py-4 flex justify-center">
              <LoadingWheel width="w-8" height="h-8" />
            </li>
          ) : users.data.length > 0 ? (
            users.data.map((user, i) =>
              RowComponent ? (
                <RowComponent user={user} key={i} />
              ) : (
                <UserListRow user={user} key={i} onRemoveClick={deleteUser} />
              )
            )
          ) : (
            <li>
              <div className="py-5 text-center text-sm text-gray-400">
                No Users
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
