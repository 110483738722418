import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyPolicyInfo(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put } = useApiHelper();

  const policyInformation = useQuery(
    ["policyInfo", agencyId],
    () => get(`/agency/${agencyId}/policy`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation(
    (updatedPolicyInfo) =>
      post(`/agency/${agencyId}/policy`, updatedPolicyInfo),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["policyInfo", agencyId]);
      },
    }
  );


  return {
    policyInformation,
    update,
  };
}
