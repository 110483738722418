export default function DefaultLeftSide({ register, placeholder = false }) {

  return (
    <>
      <div className="w-full">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Field Label
        </label>
        <input
          type="text"
          {...register("label")}
          className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
        />
      </div>

      {placeholder && (
        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Placeholder
          </label>
          <input
            type="text"
            {...register("placeholder")}
            className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
          />
        </div>
      )}
    </>
  )
}