import { useState } from "react";

import { RadioGroup } from "@headlessui/react";

import classNames from "../../utils/classNames";
import { useEffect } from "react";
import useSocialPosts from "../../data/useSocialPosts";
import SocialPostSettingsAddEdit from "../../components/SocialPosts/SocialPostSettingsAddEdit";


export default function SocialPostsPartial({ agencyId }) {
  const { socialPosts } = useSocialPosts(agencyId);
  const [viewType, setViewType] = useState(0);
  const [evergreens, setEvergreens] = useState([]);
  const [infographics, setInfographics] = useState([]);
  const [videos, setVideos] = useState([]);

  const viewTypes = [
    { value: 0, label: "Evergreens" },
    { value: 1, label: "Infographics" },
    { value: 2, label: "Videos" },
  ];

  useEffect(() => {
    if (!socialPosts.isLoading && socialPosts.data) {
      setEvergreens(socialPosts.data.filter((post) => post.postType === 0));
      setInfographics(socialPosts.data.filter((post) => post.postType === 1));
      setVideos(socialPosts.data.filter((post) => post.postType === 2));
    }
  }, [socialPosts.isLoading, socialPosts.data])

  return (
    <>
      <div className="-mx-6 px-6">
        <h3 className="text-xl font-medium text-gray-900">
          Social Post Settings
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Set up your social posts content and schedule
        </p>
      </div>

      <RadioGroup value={viewType} onChange={setViewType} className="my-4">
        <RadioGroup.Label className="sr-only">
          Choose which view to display
        </RadioGroup.Label>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          {viewTypes.map((type) => (
            <RadioGroup.Option
              key={`${type.value}`}
              value={type.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-etpink-500" : "",
                  checked
                    ? "bg-etpink-600 border-transparent text-white hover:bg-etpink-700"
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                )
              }
            >
              <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>


      {{
        0:
          <div>
            <p className="pr-10 text-gray-900">Evergreens are for posting links to certain parts of your website, enter a URL of the part of the site you want to promote and a message relating to that topic.</p>
            <p className="pr-10 text-gray-900">Evergreens run on Wednesdays.</p>
            <SocialPostSettingsAddEdit posts={evergreens} agencyId={agencyId} postType={viewType} />
          </div>,
        1:
          <div>
            <p className="pr-10 text-gray-900">Infographics are for posting images - usually from the IPT. Enter a URL of the picture you want to be posted and a message relating to it.</p>
            <p className="pr-10 text-gray-900">Evergreens run on Mondays and Fridays.</p>
            <SocialPostSettingsAddEdit posts={infographics} agencyId={agencyId} postType={viewType} />
          </div>,
        2:
          <div>
            <p className="pr-10 text-gray-900">Videos are for posting videos to social media. Enter a URL of the video you want to use and a message relating to that topic.</p>
            <p className="pr-10 text-gray-900">Videos run on the 14th and 28th.</p>
            <SocialPostSettingsAddEdit posts={videos} agencyId={agencyId} postType={viewType} />
          </div>,
      }[viewType]}

    </>
  )

}