import classNames from "../../../utils/classNames"

export const TextInput = ({ label, register, registerName, disabled = false, cols = "sm:col-span-1" }) => {

  return (
    <div className={cols}>
      <label className="text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        {...register(registerName)}
        disabled={disabled}
        type="text"
        className={classNames(disabled ? "bg-gray-300" : "", "w-full block mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm border-gray-300 rounded-md")}
      />
    </div>
  )
}