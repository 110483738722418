import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyDepartments(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const departments = useQuery(
    ["departments", agencyId],
    () => get(`/department/agency/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (department) => post(`/department/${department.branchId}`, department),
    {
      onSuccess: (addedDepartment) => {
        queryClient.setQueryData(
          ["departments", agencyId],
          (currentDepartments) => [addedDepartment.data, ...currentDepartments]
        );
      },
    }
  );

  const update = useMutation(
    (department) => put(`/department/${department.id}`, department),
    {
      onSuccess: (updatedDepartment) => {
        queryClient.setQueryData(
          ["departments", agencyId],
          (currentDepartments) =>
            currentDepartments.map((department) =>
              department.id === updatedDepartment.data.id
                ? updatedDepartment.data
                : department
            )
        );
      },
    }
  );

  const updateUsers = useMutation(
    (department) => put(`/department/${department.id}/users`, department.users),
    {
      onSuccess: (updatedDepartment) => {
        queryClient.setQueryData(
          ["departments", agencyId],
          (currentDepartments) =>
            currentDepartments.map((department) =>
              department.id === updatedDepartment.data.id
                ? updatedDepartment.data
                : department
            )
        );
      },
    }
  );

  const remove = useMutation((departmentId) => del(`/department/${departmentId}`), {
    onSuccess: (_, departmentId) => {
      queryClient.setQueryData(["departments", agencyId], (currentDepartments) =>
        currentDepartments.filter((department) => department.id !== departmentId)
      );
    },
  });

  return {
    departments,
    add,
    update,
    updateUsers,
    remove,
  };
}
