import { createContext, useContext, useReducer } from "react";

const lsKey = "estatetrack-user";
const UserContext = createContext();
const initalState = {
  loggedIn: false,
  loading: true,
  identity: null,
  user: null,
  agency: null,
  agencies: null,
};

export const UserContextActions = {
  loading: "loading",
  loaded: "loaded",
  setIdentity: "setIdentity",
  setUser: "setUser",
  setAgency: "setAgency",
  setIdentityUserAgency: "setIdentityUserAgency",
  removeIdentityUserAgency: "removeIdentityUserAgency",
  setAgencies: "setAgencies",
  storeTimeout: "storeTimeout",
};

function storeUser(toStore) {
  let willStore = toStore;

  let previous = JSON.parse(localStorage.getItem(lsKey));
  if (previous !== null) {
    willStore = { ...previous, ...toStore };
  }

  localStorage.setItem(lsKey, JSON.stringify(willStore));
}

function userContextReducer(state, action) {
  switch (action.type) {
    case UserContextActions.loading:
      // console.log("User Context - Loading");
      return { ...state, loading: true };
    case UserContextActions.loaded:
      // console.log("User Context - Loaded");
      return { ...state, loading: false };
    case UserContextActions.setIdentity:
      // console.log("User Context - Set Identity");
      storeUser({ identity: action.payload });
      return { ...state, loggedIn: true, identity: action.payload };
    case UserContextActions.setUser:
      // console.log("User Context - Set User");
      storeUser({ user: action.payload });
      return { ...state, user: action.payload };
    case UserContextActions.setAgency:
      storeUser({ agency: action.payload });
      return { ...state, agency: action.payload };
    case UserContextActions.setIdentityUserAgency:
      // console.log("User Context - Set Identity & User");
      return { ...state, loggedIn: true, ...action.payload };
    case UserContextActions.removeIdentityUserAgency:
      // console.log("User Context - Logout");
      clearTimeout(state.refreshTimeout);
      localStorage.removeItem(lsKey);
      return initalState;
    case UserContextActions.storeTimeout:
      // console.log("User Context - Store Refresh Timeout");
      return { ...state, refreshTimeout: action.payload };
    case UserContextActions.setAgencies:
      // console.log("User Context - Set Agencies");
      return { ...state, agencies: action.payload };
    default:
      throw Error(`Invalid action type passed to userContextReducer - ${action.type}`);
  }
}

export function UserContextProvider({ children }) {
  const [user, dispatch] = useReducer(userContextReducer, initalState);

  return (
    <UserContext.Provider value={{ user: user, dispatch }}>
      {children}
    </UserContext.Provider>
  );
}

export default function useUserContext() {
  const ctx = useContext(UserContext);

  if (!ctx) {
    throw new Error("useUserContext must be used within a UserContetProvier");
  }

  return ctx;
}
