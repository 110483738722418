import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useUserLeads() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { user: { agency, user }, } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const leads = useQuery(
    ["userLeads", user.id], () => get(`/user/${agency.id}/myleads`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  
  return {
    leads,
  };
}
