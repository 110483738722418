import { useNavigate } from "react-router-dom";
import useUserContext, { UserContextActions } from "../contexts/UserContext";
import useAuthHelper from "./useAuthHelper";

export default function useAuthService() {
  const navigate = useNavigate();
  const { post } = useAuthHelper();
  const { dispatch } = useUserContext();
  const lsKey = "estatetrack-user";

  const setTokenRefresh = (tokenReponse) => {
    let refreshTimeout;
    let timeToExp =
      new Date(tokenReponse.jwtTokenExpiration) - new Date() - 5000;
      console.log("timeToExp", timeToExp);
    refreshTimeout = setTimeout(refreshToken, timeToExp);
    dispatch({ type: UserContextActions.storeTimeout, payload: refreshTimeout })
  };

  const refreshToken = () => {
    // console.log("refreshing token");
    let user = getUser();
    if (user && user.identity) {
      post(`/auth/refresh-token/${user.identity.id}`)
        .then((result) => {
          setTokenRefresh(result);
          dispatch({ type: UserContextActions.setIdentity, payload: result });
          return result;
        })
        .catch((err) => {
          console.error(err);
          return false;
        });
    } else {
      return false;
    }
  };

  const getUser = () => {
    let user = localStorage.getItem(lsKey);
    if (!user) {
      return false;
    } else {
      let userJson = JSON.parse(user);
      return userJson;
    }
  };

  const isTokenValid = (token) => {
    let timeToExp = new Date(token) - new Date() - 1000;
    if (Math.sign(timeToExp) > 0) {
      return true;
    } else {
      return false;
    }
  };

  return {
    getUser,
    refreshToken,
    setTokenRefresh,
    isTokenValid,
  };
}
