import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyServiceToggles(agencyId) {
  const staleTime = 1000 * 60; //1 Minute
  const queryClient = useQueryClient();
  const { get } = useApiHelper();

  const serviceToggles = useQuery(
    ["serviceToggles", agencyId],
    () => !agencyId ? null : get(`/agency/${agencyId}/toggles`).then((res) => res.data),
    {
      staleTime: staleTime,
      refetchInterval: staleTime,
    }
  );


  return {
    serviceToggles,
  };
}