import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { LibraryIcon } from "@heroicons/react/outline";
import useAgencies from "../../data/useAgencies";
import LoadingWheel from "../Shared/LoadingWheel";
import { useNavigate } from "react-router-dom";
import useApiHelper from "../../services/useApiHelper";
import useAuthHelper from "../../services/useAuthHelper";

export default function ResetPasswordModal({ open, setOpen }) {
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const { register, handleSubmit, reset } = useForm();
  const { post } = useAuthHelper();
  // const { add } = useAgencies();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);

    post("/auth/forgot-password", { email: data.emailAddress })
      .then((authRes) => {
        setLoading(false);
        reset({email: ""});
        setOpen(false);
        setErrorText("");
      })
      .catch((err) => {
        setLoading(false);
        setErrorText(err.message);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <Dialog.Title
                      as="h1"
                      className="text-2xl leading-6 font-medium text-gray-900"
                    >
                      Reset Password
                    </Dialog.Title>
                    <h3 className="text-md text-gray-600 mt-2">
                      Enter your email address and we'll send you a link to reset your password.
                    </h3>
                  </div>
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <div className="mt-2">
                        {loading ? (
                          <div className="flex p-4 justify-center items-center">
                            <LoadingWheel width="w-10" height="h-10" />
                          </div>
                        ) : (
                          <div>
                            <label
                              htmlFor="agencyName"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Email Address
                            </label>
                            <div className="mt-1">
                              <input
                                {...register("emailAddress")}
                                type="text"
                                className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="my-2">
                      <span className="text-red-500">{errorText}</span>
                    </div>
                    <div className="flex justify-end">
                      {!loading && (
                        <div className="w-full sm:w-1/2 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                          <button
                            type="submit"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
                          >
                            Reset Password
                          </button>
                          <button
                            type="button"
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
