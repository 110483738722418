import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import useAgencyUsers from "../../data/useAgencyUsers";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAuthHelper from "../../services/useAuthHelper";

export default function UserProfile({ }) {
  const { user: { user, identity }, } = useUserContext();
  const { post: authPost, get: authGet } = useAuthHelper();
  const { users, update } = useAgencyUsers(user.defaultAgency);
  const { saveHandlers } = useDefaultCRUDHandlers("User");
  const { register, handleSubmit, reset, control } = useForm({ defaultValues: { firstName: user.firstName, surname: user.surname }, });
  const [error, setError] = useState(null);

  const onSubmit = (data) => {
    console.log(data);
    update.mutate(
      {
        ...user,
        firstName: data.firstName,
        surname: data.surname,
      },
      saveHandlers
    );

    reset({
      firstName: data.firstName,
      surname: data.surname,
    });
  };

  const resetPassword = (data) => {
    var body = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    setError(null);

    if (data.currentPassword === "" && data.newPassword === "" && data.confirmPassword === "") {
      setError("Please fill out all fields.");
    } else if (data.newPassword !== data.confirmPassword) {
      setError("Passwords do not match!");
    } else {
      authPost(`/auth/authorized-reset-password`, body, identity.jwtToken)
        .then((res) => {
          console.log(res);
          saveHandlers.onSuccess();
          reset({ currentPassword: "", newPassword: "", confirmPassword: "" });
        })
        .catch((err) => {
          console.error(err);
          if (err.Message === "Email or password is incorrect") {
            setError("Current password is incorrect!");
          }
        });
    }
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Profile";
  }, [user, error])

  if (!user) {
    return (
      <div className="flex p-10 mt-28 justify-center">
        <LoadingWheel width="w-28" height="h-28" />
      </div>
    );
  } else {
    return (
      <div className="p-4 px-8">
        <h1 className="text-2xl font-semibold">Profile</h1>
        <h1 className="text-gray-900">Edit your personal details here</h1>
        <div className="mt-6 p-3 bg-gray-50 border rounded-md divide-y divide-gray-200">

          <div className="-mt-2 mb-8">
            <form onSubmit={handleSubmit(onSubmit)}>

              {/* <div className="my-6">
                <div className="mt-1 flex items-center">
                  {agency.avatar ? (
                    !uploading && (
                      <div className="rounded-lg overflow-hidden">
                        <img
                          src={agency.avatar}
                          width="180"
                          height="180"
                          alt="Agency Logo"
                        />
                      </div>
                    )
                  ) : (
                    <div className="p-12 bg-white text-gray-500 border-2 border-gray-500 border-dashed rounded-lg">
                      <PhotographIcon className="w-20 h-20" />
                    </div>
                  )}
                  <div className="ml-4">
                    <input
                      type="file"
                      id="agencyLogo"
                      className="hidden"
                      onChange={handleAgencyLogoChange}
                    />
                    {uploading ? (
                      <div className="flex p-4 justify-center items-center">
                        <LoadingWheel width="w-10" height="h-10" />
                      </div>
                    ) : (
                      <label
                        htmlFor="agencyLogo"
                        className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                      >
                        Change Logo
                      </label>
                    )}
                  </div>
                </div>
              </div> */}

              <div className="">

                <h3 className="text-xl font-medium text-gray-900 my-4">
                  Basic Information
                </h3>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="domain"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("firstName")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="primaryEmail"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Surname
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("surname")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                Save
              </button>
            </form>
          </div>

          <div>
            <form onSubmit={handleSubmit(resetPassword)}>

              <div className="">
                <h3 className="text-xl font-medium text-gray-900 my-4">
                  Change Password
                </h3>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="domain"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Current Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("currentPassword")}
                        type="password"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 md:mx-2">

                  </div>
                </div>

                <div className="my-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="domain"
                      className="block text-sm font-medium text-gray-700"
                    >
                      New Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("newPassword")}
                        type="password"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="primaryEmail"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("confirmPassword")}
                        type="password"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="py-4 flex">
                <button
                  className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  type="submit">
                  Reset Password
                </button>
                {error && <p className="text-red-500 pt-1.5 ml-5 text-sm">{error}</p>}
              </div>
            </form>

          </div>
        </div>

      </div >
    );
  }
}
