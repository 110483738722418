import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyMedia() {
  const { user: { agency }, } = useUserContext();
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const media = useQuery(
    ["media", agency.id],
    () => get(`/media/agency/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const markDeleted = useMutation((mediaId) => put(`/media/${mediaId}`,),
    {
      onSuccess: () => { 
        queryClient.invalidateQueries(["media", agency.id]);
      },
    }
  );

  return {
    media,
    markDeleted
  };
}
