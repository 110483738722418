import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import useUserContext from '../../contexts/UserContext';
import InsertMediaModal from '../MediaManager/InsertMediaModal';

import { CDN_URL } from "../../services/config";

const CustomJoditEditor = ({ content, setContent, height }) => {
  const { user: { agency }, } = useUserContext();
  const editor = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentEditor, setCurrentEditor] = useState(null);
  // const [content, setContent] = useState('');

  const buttonLayout = ["bold", "italic", "underline", "strikethrough", "eraser", "|", "ul", "ol", "font", "fontsize", "paragraph", "classSpan", "indent", "outdent", "hr",
    {
      name: "insertMedia",
      iconURL: "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzAwMCIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTQuNzQxIDBILjI2Qy4xMTYgMCAwIC4xMzYgMCAuMzA0djEzLjM5MmMwIC4xNjguMTE2LjMwNC4yNTkuMzA0SDE0Ljc0Yy4xNDMgMCAuMjU5LS4xMzYuMjU5LS4zMDRWLjMwNEMxNSAuMTM2IDE0Ljg4NCAwIDE0Ljc0MSAwem0tLjI1OCAxMy4zOTFILjUxN1YuNjFoMTMuOTY2VjEzLjM5eiIvPjxwYXRoIGQ9Ik00LjEzOCA2LjczOGMuNzk0IDAgMS40NC0uNzYgMS40NC0xLjY5NXMtLjY0Ni0xLjY5NS0xLjQ0LTEuNjk1Yy0uNzk0IDAtMS40NC43Ni0xLjQ0IDEuNjk1IDAgLjkzNC42NDYgMS42OTUgMS40NCAxLjY5NXptMC0yLjc4MWMuNTA5IDAgLjkyMy40ODcuOTIzIDEuMDg2IDAgLjU5OC0uNDE0IDEuMDg2LS45MjMgMS4wODYtLjUwOSAwLS45MjMtLjQ4Ny0uOTIzLTEuMDg2IDAtLjU5OS40MTQtMS4wODYuOTIzLTEuMDg2ek0xLjgxIDEyLjE3NGMuMDYgMCAuMTIyLS4wMjUuMTcxLS4wNzZMNi4yIDcuNzI4bDIuNjY0IDMuMTM0YS4yMzIuMjMyIDAgMCAwIC4zNjYgMCAuMzQzLjM0MyAwIDAgMCAwLS40M0w3Ljk4NyA4Ljk2OWwyLjM3NC0zLjA2IDIuOTEyIDMuMTQyYy4xMDYuMTEzLjI3LjEwNS4zNjYtLjAyYS4zNDMuMzQzIDAgMCAwLS4wMTYtLjQzbC0zLjEwNC0zLjM0N2EuMjQ0LjI0NCAwIDAgMC0uMTg2LS4wOC4yNDUuMjQ1IDAgMCAwLS4xOC4xTDcuNjIyIDguNTM3IDYuMzk0IDcuMDk0YS4yMzIuMjMyIDAgMCAwLS4zNTQtLjAxM2wtNC40IDQuNTZhLjM0My4zNDMgMCAwIDAtLjAyNC40My4yNDMuMjQzIDAgMCAwIC4xOTQuMTAzeiIvPjwvZz48L3N2Zz4=",
      exec: (editor) => {
        setCurrentEditor(editor);
        setOpen(true);
      }
    },
    "\n", "spellcheck", "cut", "copy", "paste", "|", "selectall", "table", "link", "symbols", "undo", "redo", "find", "|", "print", "fullsize", "source", "preview",
  ]

  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: 'Start typing...',
      spellCheck: true,
      minHeight: height,
      buttons: buttonLayout,
      buttonsXS: buttonLayout,
      buttonsSM: buttonLayout,
      buttonsMD: buttonLayout,
      buttonsLG: buttonLayout,
      enableDragAndDropFileToEditor: true,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      addNewLine: false,
    }
  }, []);

  const handleMediaInsert = (sourceType, mediaType, selectedMedia) => {
    let src;

    switch (sourceType) {
      case "URL":
        src = selectedMedia;
        break;
      case "Upload":
      case "Media Manager":
      default:
        src = `${CDN_URL}${selectedMedia.path}`;
        break;
    }

    if (mediaType === "Image") {
      currentEditor.selection.insertHTML(`<img src="${src}" alt=""/>`);
      setOpen(false);
    } else if (mediaType === "Video") {
      currentEditor.selection.insertHTML(`<video controls><source src={${src}} type={${selectedMedia.type}}/></video>`);
      setOpen(false);
    }

  }

  // useEffect(() => {
  //   console.log("content", content)
  // }, [content])
  
  return (
    <>
      <InsertMediaModal
        open={open}
        setOpen={setOpen}
        onInsert={handleMediaInsert}
      />
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={newContent => setContent(newContent)}
        onChange={newContent => setContent(newContent)}
      />
    </>
  );
};


export default CustomJoditEditor;