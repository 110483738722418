import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import date from "date-and-time";
import { CashIcon, CheckCircleIcon, ClipboardListIcon, CollectionIcon, CurrencyPoundIcon, DocumentSearchIcon, DotsHorizontalIcon, InboxInIcon, PlusIcon } from "@heroicons/react/outline";
import useUserNotifications from "../../data/useUserNotifications";
import LoadingWheel from "../Shared/LoadingWheel";
import classNames from "../../utils/classNames";
import { useNavigate } from "react-router-dom";
import useUserContext from "../../contexts/UserContext";
import useUserNotificationsRecent from "../../data/useUserNotificationsRecent";

function NotificationMenu({ handleMarkAll, handleOpenNotifications }) {

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="rounded-full flex items-center text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none">
          <span className="sr-only">Open options</span>
          <DotsHorizontalIcon className="h-7 w-7" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleMarkAll()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm w-full'
                  )}
                >
                  <span className="flex"><CheckCircleIcon className="w-5 h-5 mr-2" /> Mark all as read</span>
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={() => handleOpenNotifications()}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  <span className="flex"><ClipboardListIcon className="h-5 w-5 mr-2" /> Open all notifications</span>
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default function NotificationPopout({ setShowNotifications, show }) {
  const navigate = useNavigate();
  const { user: { agency } } = useUserContext();
  const { recentNotifications: notifications, markRead, markAllRead } = useUserNotificationsRecent();

  const icons = [
    <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
    <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
    <PlusIcon className="mr-3 w-5 h-5" />,
    <InboxInIcon className="mr-3 w-5 h-5" />,
    <DocumentSearchIcon className="mr-3 w-5 h-5" />,
    <CurrencyPoundIcon className="mr-3 w-5 h-5" />,
    <PlusIcon className="mr-3 w-5 h-5" />,
    <DocumentSearchIcon className="mr-3 w-5 h-5" />,
    <CashIcon className="mr-3 w-5 h-5" />,
    <CollectionIcon className="mr-3 w-5 h-5" />,
  ]

  const handleClick = (notification) => {
    if (!notification.read) {
      markRead.mutate(notification.id);
    }

    switch (notification.actionType) {
      case 0:
      case 1:
      case 5:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/valuations`);
        break;
      case 2:
      case 6:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/registrations`);
        break;
      case 3:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/enquiries`);
        break;
      case 4:
      case 7:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/viewings`);
        break;
      case 8:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/mortgages`);
        break;
      case 9:
        setShowNotifications(false);
        navigate(`/${notification.agency.logicalName}/formleads`);
        break;
      default:
        break;
    }
  }

  const handleMarkAll = () => {
    if (notifications.data) {
      var ids = [];
      notifications.data.forEach(notification => {
        if (!notification.read) {
          ids.push(notification.id);
        }
      });

      if (ids.length > 0) {
        markAllRead.mutate(ids);
      }
    }
  }

  const handleOpenNotifications = () => {
    setShowNotifications(false);
    navigate(`/${agency.logicalName}/notifications`);
  }

  return (
    <div className={classNames(show ? "" : "hidden", "absolute h-full w-full z-30 left-0 top-0 ")}>
      <div onClick={() => setShowNotifications(false)} className={classNames(show ? "" : "hidden", "closer left-0 right-0 top-0 bottom-0 z-20 fixed")}> </div>
      <div className="notification-panel ml-20 fixed right-0 -translate-x-14 translate-y-14 z-30">

        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-100 transition"
          enterFrom="opacity-0 "
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-white w-[380px] min-h-[150px] max-h-[90vh] max-w-[90vw] overflow-y-auto relative rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 p-3">
            <div className="mb-3 p-2 flex justify-between">
              <h1 className="text-xl font-bold" >Notifications</h1>
              <div className="mr-1 h-7 w-7 flex items-center justify-center rounded-full">
                <NotificationMenu handleMarkAll={handleMarkAll} handleOpenNotifications={handleOpenNotifications} />
              </div>
            </div>

            <div>
              {notifications.isLoading ? (
                <div className="flex p-10 bg-white justify-center">
                  <LoadingWheel width="w-12" height="h-12" />
                </div>
              ) : (notifications.data?.length > 0 ? (
                notifications.data.map((notification, i) => (
                  <div key={i}
                    onClick={() => handleClick(notification)}
                    className="single-notification p-2 mb-2 flex justify-between items-center rounded-lg hover:bg-gray-100 hover:cursor-pointer">
                    <div className="text-gray-500">
                      {icons[notification.actionType]}
                    </div>
                    <div className="flex-grow">
                      {`${notification.title}`}
                      <br />
                      <span className="text-sm text-gray-400">{date.format(new Date(notification.created), "DD/MM/YYYY HH:mm")}</span>
                    </div>
                    <div>
                      {!notification.read && <div className="w-3 h-3 rounded-full bg-blue-600"></div>}
                    </div>
                  </div>
                ))
              ) :
                "Looks like you don't have any notifications... yet!"
              )}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  )
}