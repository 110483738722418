import { useState } from "react";
import { TrashIcon, PlusIcon } from "@heroicons/react/outline";

import Flyout from "../../containers/Flyout";
import LoadingWheel from "../Shared/LoadingWheel";
import GroupAddPartial from "./GroupAddPartial";

import useAgencyGroups from "../../data/useAgencyGroups";
import useNotification from "../notification/useNotifications";

function GroupListRow({ group, onRemoveClick }) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600 md:w-1/4">
          {group.name}
        </p>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onRemoveClick(group.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Remove</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function GroupListPartial({ agencyId }) {
  const { groups, update } = useAgencyGroups(agencyId);
  const { addNotification } = useNotification();
  const [open, setOpen] = useState(false);

  const handleAddGroup = (addedGroups) => {
    update.mutate(
      { groups: [...groups.data.map((ag) => ag.id), ...addedGroups.map((ag) => ag.id)] },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: `Added agency to ${addedGroups.length > 1 ? "groups" : "group"}`,
          });
          setOpen(false);
        },
        onError: () =>
          addNotification({
            variant: "error",
            primaryText: `Failed to add agency to ${addedGroups.length > 1 ? "groups" : "group"}`,
          }),
      }
    );
  };

  const handleRemoveGroup = (groupId) => {
    update.mutate(
      {
        groups: [
          ...groups.data.filter((g) => g.id !== groupId).map((g) => g.id),
        ],
      },
      {
        onSuccess: () =>
          addNotification({
            variant: "success",
            primaryText: "Removed agency from group",
          }),
        onError: () =>
          addNotification({
            variant: "error",
            primaryText: "Failed to remove agency from group",
          }),
      }
    );
  };

  return (
    <>
      <Flyout open={open} setOpen={setOpen} title="Add Agency to Group">
        <GroupAddPartial
          currentGroups={groups.data}
          handleAddGroup={handleAddGroup}
        />
      </Flyout>
      <div>
        <h3 className="text-xl font-medium text-gray-900">Groups</h3>
        <p className="mt-1 text-sm text-gray-500">
          A list of groups this agency is a member of
        </p>
        <button
          type="button"
          onClick={() => setOpen(true)}
          className="my-6 flex items-center justify-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
          <PlusIcon className="w-4 h-4 mr-2" />
          Add Group
        </button>
        <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
          {groups.isLoading ? (
            <li key="userInvites-loading" className="py-4 flex justify-center">
              <LoadingWheel width="w-8" height="h-8" />
            </li>
          ) : groups.data.length > 0 ? (
            groups.data.map((group, i) => (
              <GroupListRow
                group={group}
                key={i}
                onRemoveClick={handleRemoveGroup}
              />
            ))
          ) : (
            <li>
              <div className="py-5 text-center text-sm text-gray-400">
                No Groups
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
