
import React, { useEffect, useState } from "react";
import LoadingWheel from "../../Shared/LoadingWheel";

import { withAgency } from "../../../data/withAgency";

import usePropertiesReports from "../../../data/usePropertiesReports";

function _PropertiesAnalytics({ agencyReady, agency }) {
  const { propertiesStats: propertiesStats } = usePropertiesReports(agency?.id);
  const [isTotal, setIsTotal] = useState(false);

  useEffect(() => {
    if (agencyReady && !propertiesStats.isLoading && propertiesStats.data) {
      // console.log(propertiesStats.data)
    }
  }, [agency, agencyReady, propertiesStats.data, propertiesStats.isLoading, isTotal])

  return (
    <div>
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">

        </div>
        <div>
          <select onChange={(e) => setIsTotal(e.target.value === "true")}>
            <option value={"false"}>Last 30 Days</option>
            <option value={"true"}>All</option>
          </select>
        </div>
      </div>

      {propertiesStats.isLoading ? (
        <div className="py-2 col-span-1 lg:col-span-4 mx-auto flex w-full justify-center mt-[8vh]">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-4 mt-2 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >
            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Sales Views" : "Sales Views "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.salesPropertyViews?.toLocaleString() : propertiesStats.data.salesPropertyViewsMonth?.toLocaleString()}
              </dd>

              <hr className="w-4/5 my-2"/>

              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Sales Viewing Requests" : "Sales Viewing Requests"}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.salesPropertyEnquiries?.toLocaleString() : propertiesStats.data.salesPropertyEnquiriesMonth?.toLocaleString()}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Letting Views" : "Letting Views "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.lettingsPropertyViews?.toLocaleString() : propertiesStats.data.lettingsPropertyViewsMonth?.toLocaleString()}
              </dd>

              <hr className="w-4/5 my-2"/>

              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Letting Viewing Requests" : "Letting Viewing Requests "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.lettingsPropertyEnquiries?.toLocaleString() : propertiesStats.data.lettingsPropertyEnquiriesMonth?.toLocaleString()}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Property Views" : "Property Views "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.totalPropertyViews?.toLocaleString() : propertiesStats.data.totalPropertyViewsMonth?.toLocaleString()}
              </dd>

              <hr className="w-4/5 my-2"/>

              <dt className="font-medium text-gray-700 truncate">
                {isTotal ? "Total Viewing Requests" : "Total Viewing Requests "}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {isTotal ? propertiesStats.data.totalPropertyEnquiries?.toLocaleString() : propertiesStats.data.totalPropertyEnquiriesMonth?.toLocaleString()}
              </dd>
            </div>

            <div className="flex flex-col items-center px-4">
              <dt className="font-medium text-gray-700 truncate">
                Change in Views (last 30 days)
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {propertiesStats.data.totalPropertyViewingPercentage > 0 && "+"} {propertiesStats.data.totalPropertyViewingPercentage}%
              </dd>

              <hr className="w-4/5 my-2"/>

              <dt className="font-medium text-gray-700 truncate">
                Change in Viewing Requests (last 30 days)
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                {propertiesStats.data.totalPropertyEnquiriesPercentage > 0 && "+"} {propertiesStats.data.totalPropertyEnquiriesPercentage}%
              </dd>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export const PropertiesAnalytics = withAgency(_PropertiesAnalytics);