import { useForm } from "react-hook-form";
import { withAgency } from "../../data/withAgency";


function _AgencyCategoryAddEditPartial({ agency, agencyReady, category, handleSaveCategory }) {
  const { register, handleSubmit, getValues, formState: { errors }, } = useForm({ defaultValues: { id: category.id, default: false, name: category.name }, });

  return (
    <form onSubmit={handleSubmit(handleSaveCategory)}>
      <div className="my-4 block">
        <label
          htmlFor="policyName"
          className="block text-sm font-medium text-gray-700"
        >
          Category Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            {...register("name", { required: true })}
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="New category name..."
          />
        </div>
        {errors.name && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            Category Name is required
          </p>
        )}
      </div>

      <button
        type="submit"
        className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        {getValues("id") ? "Save" : "Create"}
      </button>
    </form>
  )

}



const AgencyCategoryAddEditPartial = withAgency(_AgencyCategoryAddEditPartial);

export default AgencyCategoryAddEditPartial;