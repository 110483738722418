

import { DocumentTextIcon, EyeIcon, PencilAltIcon, SearchIcon, TrashIcon, UserIcon } from '@heroicons/react/outline'
import { SortAscendingIcon, UsersIcon } from '@heroicons/react/solid'
import { useEffect } from 'react'

const posts = [
  //{ id: 1, name: 'widnes', url: 'adamsea.co.uk/marketreports/widnes.pdf', updatedDate: '01/07/2022' },

]


export default function MarketReports() {

  useEffect(() => {
    document.title = "EstateTrack Portal | Market Reports";
  }, [])

  return (<>  <div className="px-4 sm:px-6 lg:px-8">
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">Market Reports</h1>
        <p className="mt-2 text-sm text-gray-700">
          View your generated market reports.
        </p>
      </div>


    </div>
    <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Url
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
            >
              Updated
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Actions</span>
            </th>

          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {posts.map((post) => (
            <tr key={post.id}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                {post.name}

              </td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">{post.url}</td>
              <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{post.updatedDate}</td>

              <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    onClick={() => window.open("https://" + post.url)}
                    className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
                  >
                    <span className="sr-only">View</span>
                    <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </button>

                </span>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>  </>)



}