import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import PortalQualiferConfig from "./PortalQualifierConfig.js";

import ZooplaLogo from "./Assets/ZooplaLogo.png";
import RightmoveLogo from "./Assets/RightmoveLogo.png";
import OnTheMarketLogo from "./Assets/OnTheMarketLogo.png";
import EstateTrackLogo from "../../assests/EstateTrackLogo.png"


export default function PortalQualiferModal({ open, setOpen, isUpdate, qualifier, handleAddQualifier, handleUpdateQualifier, agencyId }) {
  const [isConfig, setIsConfig] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [openSite, setOpenSite] = useState(undefined);

  const qualifierSites = [
    {
      name: "Zoopla",
      logo: ZooplaLogo,
      siteName: 0,
    },
    {
      name: "Rightmove",
      logo: RightmoveLogo,
      siteName: 1,
    },
    {
      name: "OnTheMarket",
      logo: OnTheMarketLogo,
      siteName: 2,
    },
    {
      name: "Website",
      logo: EstateTrackLogo,
      siteName: 3,
    }
  ]

  const handleOpen = (qualifier) => {
    setSelected(qualifier);
    setIsConfig(true);
  };

  useEffect(() => {
    if (isUpdate) {
      setSelected(qualifier);
      setIsConfig(true)
    } else {
      setIsConfig(false)
    }
  }, [isUpdate, qualifier])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-2xl sm:w-full sm:p-6">
                {!isConfig ? (
                  <>
                    <ul className="my-6 p-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        {qualifierSites.map((qualifier, i) => (
                          <div
                            onClick={() => handleOpen(qualifier)}
                            key={i}
                            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-etpink-500"
                          >
                            <div className="flex-shrink-0">
                              <img className="w-12" src={qualifier.logo} alt="" />
                            </div>
                            <div className="flex-1 min-w-0">
                              <a href="#" className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">{qualifier.name}</p>
                                <p className="text-sm text-gray-500 truncate">Click to configure</p>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </ul>
                    <div className="flex justify-end">
                      <div className="w-full sm:w-1/2 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                          type="button"
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                          onClick={() => setOpen(false)}>
                          Close
                        </button>
                      </div>
                    </div>
                  </>)
                  :
                  (
                    <PortalQualiferConfig setIsConfig={setIsConfig} setOpen={setOpen} qualifier={selected} isUpdate={isUpdate} handleAddQualifier={handleAddQualifier} handleUpdateQualifier={handleUpdateQualifier} agencyId={agencyId} />
                  )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
