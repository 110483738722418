import { useEffect } from "react";
import { useState } from "react";

import Modal from './Modal';
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import date from 'date-and-time';

export default function SetFollowUp({ open, setOpen, lead, update, saveHandler, classes = null }) {
  const { saveHandlers } = useDefaultCRUDHandlers(saveHandler);
  const [followUpDate, setFollowUpDate] = useState(lead.followUpDate ? date.format(new Date(lead.followUpDate), "YYYY-MM-DD") : date.format(new Date(), "YYYY-MM-DD"));

  const handleSaveDate = () => {
    if (!followUpDate) return;

    let body = {
      ...lead,
      followUpDate: (`${followUpDate}T08:00:00`)
    };

    saveDate(body);
  }

  const handleClearDate = () => {
    let body = {
      ...lead,
      followUpDate: null
    };

    saveDate(body);
  }

  const saveDate = (body) => {
    update.mutate(body, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onError: (error) => {
        saveHandlers.onError(error);
      }
    });
  }

  return (
    <>
      <Modal width={"max-w-3xl"} open={open} setOpen={setOpen}>
        <h1 className="text-xl">Set a date to follow up on this lead</h1>
        <p>An email will be sent to whoever is assigned to this lead to remind them to follow up.</p>

        <div className="flex flex-col mt-4">
          <label htmlFor="followUpDate" className="text-sm font-medium text-gray-700">Follow up date</label>
          <input
            type="date"
            name="followUpDate"
            id="followUpDate"
            // initialValue={followUpDate}
            value={date.format(new Date(followUpDate), "YYYY-MM-DD")}
            onChange={(e) => {
              setFollowUpDate(e.target.value);
            }}
            className="mt-1 focus:ring-etpink-500 focus:border-etpink-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
          <div className="flex justify-between">
            <button type="button" onClick={() => handleClearDate()} className="mt-4 rounded-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 sm:w-auto">
              Clear
            </button>
            <button type="button" onClick={() => handleSaveDate()} className="mt-4 rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto">
              Save
            </button>
          </div>
        </div>
      </Modal>

      <div className={`buttons whitespace-nowrap w-full ${classes} `}>
        <div className="flex">
          <button type="button" onClick={() => setOpen(true)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto">
            Set follow up
          </button>
        </div>
      </div>
    </>
  )

}


