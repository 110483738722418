import { useState } from "react";

import ActivityItem from "./activityItem"
import classNames from "../../utils/classNames";


export default function Activity() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <div className="hidden md:flex md:flex-col md:fixed md:inset-y-0 w-60 bg-gray-50 border-l border-gray-200">
        <div className={classNames(showMore ? "overflow-auto" : "", "flex flex-col flex-grow pt-14")}>
          <div className="bg-gray-50  sm:pr-6 lg:pr-8 lg:flex-grow xl:pr-0 h-auto">
            <div className="pl-3 w-full overflow-hidden" >
              <div className="pt-6 pb-2">
                <h2 className="text-sm mb-2 font-semibold">Activity</h2>
              </div>
              <ActivityItem />
            </div>
            <h2 className="bg-gray-50 text-sm font-semibold absolute bottom-0 w-full pt-1.5 h-[35px] text-center hover:cursor-pointer hover:bg-gray-200"
              onClick={() => setShowMore(!showMore)}>
              {showMore ? "View less" : "View more"}
            </h2>
          </div>
        </div>
      </div>

    </>
  )

}