import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  EyeIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";

import useViewings from "../../data/useViewings";
import { viewingStatusEnum } from "../../constants/enums";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useUserContext from "../../contexts/UserContext";
import useAllAgencyProperties from "../../data/useAllAgencyProperties";
import useAgencyBranches from "../../data/useAgencyBranches";
import BranchListBox from "../../components/Shared/BranchListBox";
import QualifiedDropdown from "../../components/Viewing/QualifiedDropdown";
import Avatar from "../../components/Shared/Avatar";
import SalesOrLettingsListBox from "../../components/Shared/SalesOrLettingsListBox";


function ViewingsRow({ viewing, onDeleteClick }) {
  const navigate = useNavigate();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(viewing.id);
  };

  return (
    <tr onClick={() => navigate(`viewing/${viewing.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell">
        {viewing.userId &&
          <Avatar classes="w-10 h-10" userId={viewing.userId} />
        }
      </td>
      <td className="hidden px-3 pl-4 pr-1 text-sm text-gray-500 sm:table-cell">
        {viewing.propertyTitle ? <div dangerouslySetInnerHTML={{ __html: viewing.propertyTitle }}></div> : "No linked property"}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {viewing.branchName ? viewing.branchName : "No linked branch"}
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {viewing.fullName}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Status</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span
              className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${viewing.status === 0 || viewing.status === 1 ? ("text-yellow-600 bg-yellow-100") :
                  (viewing.status === 2 ?
                    ("text-blue-600 bg-blue-100")
                    : ("text-green-600 bg-green-100")
                  )}`}
            >
              {viewingStatusEnum[viewing.status]}
            </span>
          </dd>
          <dt className="sr-only sm:hidden">Published</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {/* {date.format(new Date(viewing.dateCreated), "DD/MM/YYYY HH:mm")} */}
            {`${viewing.date} ${viewing.time}`}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${viewing.status === 0 || viewing.status === 1 ? ("text-yellow-600 bg-yellow-100") :
              (viewing.status === 2 ?
                ("text-blue-600 bg-blue-100")
                : ("text-green-600 bg-green-100")
              )}`}
        >
          {viewingStatusEnum[viewing.status]}
        </span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {(viewing.isFromPortal || viewing.isQualified) && (
          <span
            className={`prose-sm rounded-full px-5 py-1 whitespace-nowrap font-bold 
          ${viewing.isQualified ?
                ("text-green-600 bg-green-100")
                :
                ("text-yellow-600 bg-yellow-100")
              }`}
          >
            {viewing.isQualified ? "Qualified" : "Not Qualified"}
          </span>
        )}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {viewing.source ? viewing.source : "Website"}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {viewing.propertyDepartment ?? "No linked property"}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {/* {date.format(new Date(viewing.dateCreated), "DD/MM/YYYY HH:mm")} */}
        {`${viewing.date} ${viewing.time}`}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(viewing.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            className="relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">View</span>
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          <button
            onClick={handleDeleteClick}
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Viewings() {
  const navigate = useNavigate();
  const { viewings: allViewings, remove } = useViewings();
  const { user: { agency }, } = useUserContext();
  const { properties } = useAllAgencyProperties(false);
  const { branches } = useAgencyBranches(agency.id);
  const { deleteHandlers } = useDefaultCRUDHandlers("Viewing");
  const [viewings, setViewings] = useState(allViewings);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortTerm, setSortTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [hideUnqualified, setHideUnqualified] = useState(false);
  const [branchFilter, setBranch] = useState(null);
  const [departmentFilter, setDepartment] = useState("All");

  const deleteViewing = (viewingId) => {
    setDeleteId(viewingId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Viewings";
    if (!allViewings.isLoading && !branches.isLoading && !properties.isLoading) {

      var filtered = allViewings.data.filter((viewing) =>
        viewing.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        viewing.source?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (hideUnqualified) {
        //Only show viewings from website, all sales viewings and qualified lettings viewings
        filtered = filtered.filter((viewing) =>
          viewing.isFromPortal === false ||
          (viewing.isQualified && viewing.isFromPortal)
        );
      }

      filtered.forEach(viewing => {
        let property = properties.data.find((p) => p.id === viewing.propertyId);

        if (property) {
          viewing.propertyTitle = property.title;
          viewing.propertyDepartment = property.department === "residential-lettings" ? "Lettings" : "Sales";

          let branch = branches.data.find((b) => b.id === property.branchId);
          if (branch) {
            viewing.branchName = branch.name;
          }
        }
      });

      if (branchFilter) {
        var foundBranch = branches.data.find((b) => b.name === branchFilter.name);

        if (foundBranch) {
          filtered = filtered.filter((viewing) => {
            let property = properties.data.find((p) => p.id === viewing.propertyId);
            if (property?.branchId) {
              return property.branchId.includes(foundBranch.id)
            }
          })
        }
      }

      if (departmentFilter !== "All") {
        filtered = filtered.filter((viewing) => viewing.propertyDepartment === departmentFilter);
      }

      setViewings(filtered);
    }
  }, [allViewings.isLoading, allViewings.data, properties.data, properties.isLoading, searchTerm, sortTerm, sortDirection, branchFilter, departmentFilter, hideUnqualified, setHideUnqualified]);

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  return (
    <>
      <ConfirmDelete
        itemName="Viewing"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Viewings</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all viewings from the website.
            </p>
          </div>
          <div>
            <div className="mt-2 flex gap-x-3">
              <div className="w-[13rem]">
                <BranchListBox setBranch={setBranch} />
              </div>
              <div className="w-[13rem]">
                <SalesOrLettingsListBox setDepartment={setDepartment} />
              </div>
              <div className="w-[13rem]">
                <QualifiedDropdown setIsQualified={setHideUnqualified} />
              </div>

              <div className="w-60 relative py-6 flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute py-6 inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name or Source"
                />
              </div>

            </div>
          </div>

          {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to=""
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Post
            </Link>
          </div> */}
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {viewings.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : viewings.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {/* Avatar */}
                  </th>
                  <th
                    // onClick={() => handleSort("property")}
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Property {sortTerm === "property" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th
                    // onClick={() => handleSort("fullName")}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                  >
                    <span className="flex">Branch {sortTerm === "fullName" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th
                    // onClick={() => handleSort("fullName")}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                  >
                    <span className="flex">Name {sortTerm === "fullName" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Status</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Qualified</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Source</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Department</span>
                  </th>
                  <th
                    // onClick={() => handleSort("dateCreated")}
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Viewing Date {sortTerm === "dateCreated" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                  >
                    <span className="flex">Date Created</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {viewings.map((viewing) => (
                  <ViewingsRow
                    key={viewing.id}
                    viewing={viewing}
                    onDeleteClick={deleteViewing}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="viewing"
                  onNewClick={() => navigate("")}
                />
              ) : (
                <NoItems searching itemName="viewing" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
