import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyUsers(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, put, del } = useApiHelper();

  const users = useQuery(
    ["users", agencyId],
    () => get(`/agency/${agencyId}/users`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation((user) => put(`/user/${user.id}`, user), {
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(["users", agencyId], (currentUsers) =>
        currentUsers.map((user) =>
          user.id === updatedUser.data.id ? updatedUser.data : user
        )
      );
    },
  });

  const remove = useMutation((userId) => del(`/user/${userId}`), {
    onSuccess: (_, userId) => {
      queryClient.invalidateQueries(["users", agencyId]);
    },
  });

  return {
    users,
    update,
    remove,
  };
}
