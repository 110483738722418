import React, { useEffect } from 'react';

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  useEffect(() => {}, [nPages, currentPage]);

  const totalPages = pageNumbers.length;
  const maxDisplayPages = 5;

  let startPages = pageNumbers.slice(0, maxDisplayPages);
  let endPages = pageNumbers.slice(totalPages - maxDisplayPages, totalPages);

  if (startPages.some(page => endPages.includes(page))) {
    startPages = pageNumbers.slice(0, Math.ceil(totalPages / 2));
    endPages = pageNumbers.slice(Math.ceil(totalPages / 2), totalPages);
  }

  const condensedPageNumbers = totalPages > 2 * maxDisplayPages 
    ? startPages.concat(["..."], endPages) 
    : pageNumbers;

  return (
    <nav className="py-2 bg-white">
      <ul className="mx-auto flex justify-center text-blue-500 border-collapse">
        <li className="border border-gray-400 border-r-0 rounded-l-lg">
          <button className="p-2" onClick={prevPage}>
            Previous
          </button>
        </li>
        {condensedPageNumbers.map((pgNumber, index) => (
          <li
            key={index}
            className={`border border-gray-400 border-r-0 ${
              currentPage == pgNumber ? 'bg-blue-500 text-white' : ''
            } `}
          >
            {pgNumber === "..." ? (
              <span className="p-2 px-4">...</span>
            ) : (
              <button onClick={() => setCurrentPage(pgNumber)} className="p-2 px-4">
                {pgNumber}
              </button>
            )}
          </li>
        ))}
        <li className="border border-gray-400 rounded-r-lg">
          <button className="p-2" onClick={nextPage}>
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;






















// import React, { useEffect } from 'react'

// const Pagination = ({ nPages, currentPage, setCurrentPage }) => {

//   const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

//   const nextPage = () => {
//     if (currentPage !== nPages) setCurrentPage(currentPage + 1)
//   }

//   const prevPage = () => {
//     if (currentPage !== 1) setCurrentPage(currentPage - 1)
//   }

//   useEffect(() => {

//   }, [nPages, currentPage])

//   return (
//     <nav className="py-2 bg-white">
//       <ul className="mx-auto flex justify-center text-blue-500 border-collapse">
//         <li className="border border-gray-400 border-r-0 rounded-l-lg">
//           <button className="p-2 "
//             onClick={prevPage}
//           >

//             Previous
//           </button>
//         </li>
//         {pageNumbers.splice(5, pageNumbers.length - 10).map(pgNumber => (
//           <li key={pgNumber}
//             className={`border border-gray-400 border-r-0 ${currentPage == pgNumber ? 'bg-blue-500 text-white' : ''} `} >
//             <button onClick={() => setCurrentPage(pgNumber)}
//               className='p-2 px-4 '
//             >
//               {pgNumber}
//             </button>
//           </li>
//         ))}
//         <li className="border border-gray-400 rounded-r-lg">
//           <button className="p-2 "
//             onClick={nextPage}
//           >

//             Next
//           </button>
//         </li>
//       </ul>
//     </nav>
//   )
// }

// export default Pagination