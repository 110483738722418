import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useFormValidation() {
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();


  const revalidate = useMutation(() => get(`/form/revalidate`, null), {
    onSuccess: () => { },
  });


  return {
    revalidate
  };
}
