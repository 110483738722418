import { useCallback, useEffect, useState } from "react";

import { useQueryClient } from "react-query";
import { useForm, useFormState } from "react-hook-form";

import { Switch } from "@headlessui/react";

import classNames from "../../utils/classNames";
import useAgencies from "../../data/useAgencies";
import LoadingWheel from "../Shared/LoadingWheel";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useFormValidation from "../../data/useFormValidation";

export default function ServicesSettingsPartial({ agencyId }) {
  const { agencies, updateServicesSettings } = useAgencies();
  const { revalidate } = useFormValidation();
  const queryClient = useQueryClient();
  const { saveHandlers } = useDefaultCRUDHandlers("Report Settings");
  const { register, handleSubmit, getValues, setValue, reset, watch, control } = useForm({
    defaultValues: {
    }
  });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const [agency, setAgency] = useState(null);
  const [monthlyMarket, setMonthlyMarket] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const onSubmit = (data) => {
    setIsLoading(true);

    var settings = {
      ...data,
    }

    updateServicesSettings.mutate({ agencyId, settings }, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.refetchQueries(["serviceToggles", agencyId]);
      },
      onError: () => {
        saveHandlers.onError();
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });

    reset({
      valuationServiceToggle: data.valuationServiceToggle,
      registrationServiceToggle: data.registrationServiceToggle,
      enquiryServiceToggle: data.enquiryServiceToggle,
      viewingServiceToggle: data.viewingServiceToggle,
      portalQualifierServiceToggle: data.portalQualifierServiceToggle,
      analyticsServiceToggle: data.analyticsServiceToggle,
      mortgageServiceToggle: data.mortgageServiceToggle,
      customFormsToggle: data.customFormsToggle,
      policiesToggle: data.policiesToggle,
    });

    setIsLoading(false);
  }

  const loadAgency = useCallback((foundAgency) => {
    setAgency(foundAgency);

    // console.log(foundAgency)

    reset({
      valuationServiceToggle: foundAgency.valuationServiceToggle,
      registrationServiceToggle: foundAgency.registrationServiceToggle,
      enquiryServiceToggle: foundAgency.enquiryServiceToggle,
      viewingServiceToggle: foundAgency.viewingServiceToggle,
      portalQualifierServiceToggle: foundAgency.portalQualifierServiceToggle,
      analyticsServiceToggle: foundAgency.analyticsServiceToggle,
      mortgageServiceToggle: foundAgency.mortgageServiceToggle,
      customFormsToggle: foundAgency.customFormsToggle,
      policiesToggle: foundAgency.policiesToggle,
    });

    setIsLoading(false);
  }, [reset]);

  useEffect(() => {
    if (agencyId) {
      let agency = agencies.data.find((a) => a.id === agencyId);
      loadAgency(agency);
    }
  }, [agencies.data, agencies.isLoading, agencyId, loadAgency])


  const valuationServiceToggle = watch("valuationServiceToggle");
  const registrationServiceToggle = watch("registrationServiceToggle");
  const enquiryServiceToggle = watch("enquiryServiceToggle");
  const viewingServiceToggle = watch("viewingServiceToggle");
  const portalQualifierServiceToggle = watch("portalQualifierServiceToggle");
  const analyticsServiceToggle = watch("analyticsServiceToggle");
  const mortgageServiceToggle = watch("mortgageServiceToggle");
  const customFormsToggle = watch("customFormsToggle");
  const policiesToggle = watch("policiesToggle");

  if (isLoading || !agency) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <div className="">
          <div className="-mx-6 px-6">
            <h3 className="text-xl font-medium text-gray-900">
              Service Settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Toggle the services on or off for this agency
            </p>
          </div>

          <form className="grid grid-cols-2" onSubmit={handleSubmit(onSubmit)} >

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Valuation Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("valuationServiceToggle")}
                  checked={valuationServiceToggle}
                  onChange={(e) => setValue("valuationServiceToggle", e)}
                  className={classNames(
                    valuationServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      valuationServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Registration Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("registrationServiceToggle")}
                  checked={registrationServiceToggle}
                  onChange={(e) => setValue("registrationServiceToggle", e)}
                  className={classNames(
                    registrationServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      registrationServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Enquiry Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("enquiryServiceToggle")}
                  checked={enquiryServiceToggle}
                  onChange={(e) => setValue("enquiryServiceToggle", e)}
                  className={classNames(
                    enquiryServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enquiryServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Viewing Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("viewingServiceToggle")}
                  checked={viewingServiceToggle}
                  onChange={(e) => setValue("viewingServiceToggle", e)}
                  className={classNames(
                    viewingServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      viewingServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Portal Qualifier Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("portalQualifierServiceToggle")}
                  checked={portalQualifierServiceToggle}
                  onChange={(e) => setValue("portalQualifierServiceToggle", e)}
                  className={classNames(
                    portalQualifierServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      portalQualifierServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Analytics Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("analyticsServiceToggle")}
                  checked={analyticsServiceToggle}
                  onChange={(e) => setValue("analyticsServiceToggle", e)}
                  className={classNames(
                    analyticsServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      analyticsServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Mortgage Service
                  </Switch.Label>
                </span>
                <Switch
                  {...register("mortgageServiceToggle")}
                  checked={mortgageServiceToggle}
                  onChange={(e) => setValue("mortgageServiceToggle", e)}
                  className={classNames(
                    mortgageServiceToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      mortgageServiceToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Custom Forms/Form Builder
                  </Switch.Label>
                </span>
                <Switch
                  {...register("customFormsToggle")}
                  checked={customFormsToggle}
                  onChange={(e) => setValue("customFormsToggle", e)}
                  className={classNames(
                    customFormsToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      customFormsToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-5 py-3 w-3/4">
              <Switch.Group as="div" className="flex justify-between">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Use ET Policies
                  </Switch.Label>
                </span>
                <Switch
                  {...register("policiesToggle")}
                  checked={policiesToggle}
                  onChange={(e) => setValue("policiesToggle", e)}
                  className={classNames(
                    policiesToggle ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      policiesToggle ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>


            <div className="mt-10 col-span-2 flex justify-between">
              <button
                type="submit"
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Save
              </button>

              <button
                type="button"
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                onClick={() => revalidate.mutate(null, saveHandlers)}
              >
                Revalidate All Forms
              </button>
            </div>

          </form>
        </div>
      </>
    );
  }
}
