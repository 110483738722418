import { useEffect, useState } from "react";
import LoadingWheel from "../../Shared/LoadingWheel";

const RequestsByPortalTable = ({ stats, isTotal }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!stats.isLoading) {
      setIsLoading(false);
    }
  }, [stats.isLoading, isTotal])

  return (
    <div className="px-8 py-4 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Enquiries By Portal - {isTotal ? "Total" : "Last 30 days"}</h1>

      <hr className="mb-2" />

      {isLoading ? (
        <div className="flex p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <div className="overflow-auto">
          <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="text-left text-sm font-semibold text-gray-900"
                >
                  {/* Blank */}
                </th>
                <th
                  scope="col"
                  className="py-4 text-center text-sm font-semibold text-gray-900"
                >
                  Rightmove
                </th>
                <th
                  scope="col"
                  className="py-4 text-center text-sm font-semibold text-gray-900"
                >
                  Zoopla
                </th>
                <th
                  scope="col"
                  className="py-4 text-center text-sm font-semibold text-gray-900"
                >
                  On The Market
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Sales Viewing Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalSales.Rightmove : stats.data.viewingRequestsByPortalSalesMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalSales.Zoopla : stats.data.viewingRequestsByPortalSalesMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalSales.OnTheMarket : stats.data.viewingRequestsByPortalSalesMonth.OnTheMarket}
                </td>
              </tr>
              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Letting Viewing Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalLettings.Rightmove : stats.data.viewingRequestsByPortalLettingsMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalLettings.Zoopla : stats.data.viewingRequestsByPortalLettingsMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortalLettings.OnTheMarket : stats.data.viewingRequestsByPortalLettingsMonth.OnTheMarket}
                </td>
              </tr>
              <tr className="!border-b-4">
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm font-semibold text-gray-900 sm:w-auto sm:max-w-none">
                  Total Viewing Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortal.Rightmove : stats.data.viewingRequestsByPortalMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortal.Zoopla : stats.data.viewingRequestsByPortalMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.viewingRequestsByPortal.OnTheMarket : stats.data.viewingRequestsByPortalMonth.OnTheMarket}
                </td>
              </tr>

              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Sales Valuation Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalSales.Rightmove : stats.data.valuationRequestsByPortalSalesMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalSales.Zoopla : stats.data.valuationRequestsByPortalSalesMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalSales.OnTheMarket : stats.data.valuationRequestsByPortalSalesMonth.OnTheMarket}
                </td>
              </tr>
              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Letting Valuation Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalLettings.Rightmove : stats.data.valuationRequestsByPortalLettingsMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalLettings.Zoopla : stats.data.valuationRequestsByPortalLettingsMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortalLettings.OnTheMarket : stats.data.valuationRequestsByPortalLettingsMonth.OnTheMarket}
                </td>
              </tr>
              <tr className="!border-b-4">
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm font-semibold text-gray-900 sm:w-auto sm:max-w-none">
                  Total Valuation Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortal.Rightmove : stats.data.valuationRequestsByPortalMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortal.Zoopla : stats.data.valuationRequestsByPortalMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.valuationRequestsByPortal.OnTheMarket : stats.data.valuationRequestsByPortalMonth.OnTheMarket}
                </td>
              </tr>

              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Sales Qualified Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalSales.Rightmove : stats.data.qualifiedRequestsByPortalSalesMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalSales.Zoopla : stats.data.qualifiedRequestsByPortalSalesMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalSales.OnTheMarket : stats.data.qualifiedRequestsByPortalSalesMonth.OnTheMarket}
                </td>
              </tr>
              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm text-gray-900 sm:w-auto sm:max-w-none">
                  Letting Qualified Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalLettings.Rightmove : stats.data.qualifiedRequestsByPortalLettingsMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalLettings.Zoopla : stats.data.qualifiedRequestsByPortalLettingsMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortalLettings.OnTheMarket : stats.data.qualifiedRequestsByPortalLettingsMonth.OnTheMarket}
                </td>
              </tr>
              <tr>
                <td className="w-full max-w-0 pl-4 py-4 pr-3 text-md text-sm font-semibold text-gray-900 sm:w-auto sm:max-w-none">
                  Total Qualified Enquiries
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortal.Rightmove : stats.data.qualifiedRequestsByPortalMonth.Rightmove}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortal.Zoopla : stats.data.qualifiedRequestsByPortalMonth.Zoopla}
                </td>
                <td className="px-3 py-4 text-sm text-gray-500 text-center">
                  {isTotal ? stats.data.qualifiedRequestsByPortal.OnTheMarket : stats.data.qualifiedRequestsByPortalMonth.OnTheMarket}
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default RequestsByPortalTable;