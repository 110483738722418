import { useQuery } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useNotificationAcitons() {
  const staleTime = Infinity;
  const { get } = useApiHelper();

  const notificationActions = useQuery(
    "notificationActions",
    () => get(`/notificationpolicy/actions`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  return {
    notificationActions,
  };
}
