import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useBranchPostcodes(branchId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const postcodes = useQuery(["postcodes", branchId], () => get(`/postcode/${branchId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((postcode) => post(`/postcode/${branchId}`, postcode), {
    onSuccess: () => {
      queryClient.invalidateQueries(["postcodes", branchId])
    },
  });

  // const update = useMutation((branch) => put(`/branch/${branch.id}`, branch), {
  //   onSuccess: (updatedBranch) => {
  //     queryClient.setQueryData(["branches", branchId], (currrentBranches) =>
  //       currrentBranches.map((branch) =>
  //         branch.id === updatedBranch.data.id ? updatedBranch.data : branch
  //       )
  //     );
  //   },
  // });

  const remove = useMutation((postcodeId) => del(`/postcode/${branchId}/${postcodeId}`), {
    onSuccess: (_, postcodeId) => {
      queryClient.setQueryData(["postcodes", branchId], (currentPostcodes) =>
        currentPostcodes.filter((postcode) => postcode.id !== postcodeId)
      );
    },
  });

  return {
    postcodes,
    add,
    // update,
    remove,
  };
}
