import { useState } from "react";

import { XCircleIcon } from "@heroicons/react/outline";

import useContentEngineLinkedPosts from "../../data/useContentEngineLinkedPosts";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ButtonInput } from "../Shared/Inputs/ButtonInput";
import LoadingWheel from "../Shared/LoadingWheel";

export default function AgencyNotPublished({ agencyId, postId }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Content Engine Post");
  const { createSinglePost } = useContentEngineLinkedPosts();
  const [publishText, setPublishText] = useState("Not Published");
  const [republishLoading, setRepublishLoading] = useState(false);

  const handleCreateSinglePost = () => {
    setRepublishLoading(true);

    createSinglePost.mutate({ postId, agencyId },
      {
        ...saveHandlers,
        onSettled: () => setRepublishLoading(false)
      }
    );
  };

  return (
    <span className="flex items-center">
      {republishLoading ?
        <span className="px-2">
          <LoadingWheel width="w-5" height="h-5" />
        </span>
        : (
          <span
            onMouseOver={() => setPublishText("Republish")}
            onMouseLeave={() => setPublishText("Not Published")}
          >
            <ButtonInput
              label={publishText}
              classes="!py-1 !bg-white !text-gray-700 hover:!bg-etpink-600 hover:!text-white"
              onClick={() => handleCreateSinglePost()}
              isSubmit={false}
            />
          </span>
        )}
      <XCircleIcon className="w-5 text-red-500" />
    </span>
  )
}