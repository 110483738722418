import { useEffect } from "react";

export default function ErrorPage() {
  useEffect(() => {
    document.title = "EstateTrack Portal | Error";
  }, [])
  return (
    <>
        <div className="bg-white h-[100vh] py-16 sm:px-6 sm:py-24 md:grid lg:px-8">
            <main className="sm:flex mx-auto mt-20">
              <p className="text-4xl font-extrabold text-etpink-600 sm:text-5xl">
                Error
              </p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                    Something went wrong!
                  </h1>
                  <p className="mt-1 text-base text-gray-500">
                    If you continue to get this error please contact support!
                  </p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <button
                    onClick={() => window.location.reload()}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    Refresh
                  </button>
                  <button
                    onClick={() => window.location = 'mailto:support@estatetrack.co.uk'}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    Contact support
                  </button>
                </div>
              </div>
            </main>
        </div>
    </>
  );
}
