import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useMortgages from "../../data/useMortgages";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Notes from "../../components/Notes/Notes";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { mortgageStatusEnum } from "../../constants/enums";
import { useForm } from "react-hook-form";
import classNames from "../../utils/classNames";
import date from "date-and-time";
import Avatar from "../../components/Shared/Avatar";
import AssignUser from "../../components/Shared/AssignUser";
import SetFollowUp from "../../components/Shared/SetFollowUp";

function StatusDropdown({ mortgage }) {
  const { update } = useMortgages();
  const { saveHandlers } = useDefaultCRUDHandlers("Mortgage");
  const { register, getValues, setValue, watch, handleSubmit, reset } = useForm({ defaultValues: { status: mortgage.status }, });

  const statuses = Object.values(mortgageStatusEnum).map((s, i) => ({
    name: s,
    value: i,
  }));

  const statusValue = watch("status");

  const updateStatus = (value) => {
    setValue("status", value);

    update.mutate(
      {
        ...mortgage,
        status: value,
      },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
        },
        onError: () => {
          saveHandlers.onError();
        }
      }
    );
  }

  return (
    <form>
      <Listbox
        {...register("status")}
        value={statusValue}
        onChange={(v) => updateStatus(v)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Status
            </Listbox.Label>
            <div className="mt-1 relative min-w-[150px]">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {
                    statuses.find(
                      (s) => s.value === statusValue
                    )?.name
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {statuses.map((s) => (
                    <Listbox.Option
                      key={s.value}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-etpink-600"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={s.value}
                    >
                      <>
                        <span
                          className={classNames(
                            getValues("status") ===
                              s.value
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {s.name}
                        </span>

                        {getValues("status") ===
                          s.value ? (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </form>
  )
}

export default function ViewMortgage() {
  const navigate = useNavigate();
  const { mortgages, update, updateNotes, removeNote } = useMortgages();
  const { agencyName, mortgageId } = useParams();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Mortgage");
  const [mortgage, setMortgage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);

  const confirmDelete = (note) => {
    var updateData = {
      noteId: note.id,
      mortgageId: mortgageId
    }

    removeNote.mutate(updateData, deleteHandlers);
  };

  const onSubmit = (data) => {
    console.log("submitting", data);
    let mutateData = {
      mortgageId: mortgageId,
      newNote: {
        note: data.note
      }
    };
    if (mortgageId) {
      updateNotes.mutate(mutateData, saveHandlers);
    }
  };


  useEffect(() => {
    if (!mortgages.isLoading) {
      if (mortgageId) {
        let foundMortgage = mortgages.data.find((e) => e.id == mortgageId);

        setMortgage(foundMortgage);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [mortgages.isLoading, mortgages.data, mortgageId]);

  const pages = [
    { to: "..", label: "Mortgages", current: false },
    {
      to: mortgageId && mortgage ? `${mortgageId}` : "",
      label:
        mortgageId && mortgage
          ? `${mortgage.fullName}'s Mortgage`
          : "New Mortgage",
      current: true,
    },
  ];

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="flex justify-center mt-5">
            <div className="bg-white block w-full shadow overflow-hidden sm:rounded-lg">
              <div className="flex justify-between">
                <div className="flex px-4 py-5 sm:px-6">
                  <div className="flex flex-col">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Mortgage Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Details related to this mortgage</p>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{date.format(new Date(mortgage.created), "DD/MM/YYYY HH:mm")}</p>
                  </div>

                  <div>
                    <Avatar classes="ml-2 w-10 h-10" userId={mortgage.userId ?? ""} />
                  </div>
                </div>

                <div className="flex mr-5 gap-x-4 items-center">
                  <StatusDropdown mortgage={mortgage} />
                  <AssignUser open={assignOpen} setOpen={setAssignOpen} lead={mortgage} update={update} saveHandler={"Mortgage"} classes={"mt-[1.4rem] !mr-0"} />
                  <SetFollowUp open={followUpOpen} setOpen={setFollowUpOpen} lead={mortgage} update={update} saveHandler={"Mortgage"} classes={"mt-[1.4rem]"} />
                </div>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 mb-20 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{mortgage.fullName}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                    <dd className="mt-1 text-sm text-gray-900">{mortgage.emailAddress}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                    <dd className="mt-1 text-sm text-gray-900">{mortgage.telephoneNumber}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Follow Up</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {mortgage.followUpDate ? date.format(new Date(mortgage.followUpDate), "DD/MM/YYYY") : "None"}
                    </dd>
                  </div>
                  <div className="sm:col-span-2 text-sm space-y-1">
                    <dt className="font-medium text-gray-500">Extra fields</dt>
                    {mortgage.stringDataFormatted ? (
                      <dd className="text-gray-900 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: mortgage.stringDataFormatted }}>
                      </dd>
                    ) : (
                      <dd className="text-gray-900 whitespace-pre-line">
                        {mortgage.stringData}
                      </dd>
                    )}

                  </div>
                </dl>
                <Notes notes={mortgage.notes} submitFunction={onSubmit} deleteFunction={confirmDelete} />

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}