import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import date from "date-and-time";
import {
  AtSymbolIcon,
  CloudIcon,
  HomeIcon,
  MailIcon,
  PhoneIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  UserIcon,
  UsersIcon,
} from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import useProperties from "../../data/useProperties";
import Notes from "../../components/Notes/Notes";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { getFullAddress } from "../../utils/getAddress";
import useUserContext from "../../contexts/UserContext";
import usePropertiesSingle from "../../data/usePropertiesSingle";

function PropertyCard({
  title,
  TitleComponent,
  children,
  wrapperClasses,
  titleClasses,
}) {
  return (
    <div className={`bg-white divide-y ${wrapperClasses}`}>
      {TitleComponent ? (
        <TitleComponent />
      ) : (
        <div
          className={`prose-lg text-gray-500 font-medium py-3 px-5 ${titleClasses}`}
        >
          {title}
        </div>
      )}
      {children}
    </div>
  );
}

function PropertyDetails({ property }) {

  return (
    <PropertyCard title="Property Details" wrapperClasses="col-span-1 row-span-1">
      <div className="text-gray-700 py-3 px-5">
        <div className="grid grid-cols-12 gap-y-2">
          <div className="col-span-1 flex items-center">
            <HomeIcon className="w-4 h-4" />
          </div>
          <div className="col-span-5">{property.bedrooms} Bedrooms</div>
          <div className="col-span-1 flex items-center">
            <CloudIcon className="w-4 h-4" />
          </div>
          <div className="col-span-5">
            {property.bathrooms} Bathrooms
          </div>
          <div className="col-span-1 flex items-center">
            <QuestionMarkCircleIcon className="w-4 h-4" />
          </div>
          <div className="col-span-11">Availability: {property.availability}</div>
          <div className="col-span-1 flex items-center">
            <StarIcon className="w-4 h-4" />
          </div>
          <div className="col-span-11">
            {property.department === "residential-lettings" ? (
              `£${property.price} pw (£${property.fullPrice} p/month)`
            ) : (
              `£${property.price}`
            )}
          </div>
        </div>
      </div>
      <div>
        <h2 className="prose-lg text-gray-500 font-medium py-3 px-5">Property Features</h2>
        <ul className="grid text-gray-700 list-outside list-disc pl-8 grid-cols-2 gap-y-2">
          {property.features.map((feature, i) => {
            return (<li key={i} className="col-span-1">{feature.feature}</li>)
          })}
        </ul>
      </div>
    </PropertyCard>
  );
}

function MapView({ property }) {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  useEffect(() => {
    setLatitude(property.latitude.replace("-.", "-0."));
    setLongitude(property.longitude.replace("-.", "-0."));
  }, [property])

  return (
    <PropertyCard title="View on Map" wrapperClasses="col-span-1 row-span-1">
      <div className="py-3 px-5">
        <iframe
          title="Google map"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen=""
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDipwICcP5ZhQHsgEpZL5ClGpR95mN6G7M&q=${latitude}%2C${longitude}`}
          width="100%"
          height="300px"
        ></iframe>
      </div>
    </PropertyCard>
  );
}

export default function PropertyView() {
  const { user: { agency } } = useUserContext();
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const { property: singleProperty, updateNotes, removeNote } = usePropertiesSingle(propertyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Property note");
  const [property, setProperty] = useState(undefined);
  const [propertyUrl, setPropertyUrl] = useState("");

  useEffect(() => {
    if (!singleProperty.isLoading) {
      let property = singleProperty.data;
      var department = property?.department === "residential-lettings" ? "property-to-rent" : "property-for-sale"; //property-for-sale or property-to-rent
      setPropertyUrl(`${agency.domain}/${department}/${property.slug}/`);
      setProperty(property);
    }
  }, [singleProperty.isLoading, singleProperty.data]);

  const deleteNote = (note) => {
    var updateData = {
      noteId: note.id,
      propertyId: propertyId
    }

    removeNote.mutate(updateData, deleteHandlers);
  };

  const addNote = (data) => {
    console.log("submitting", data);

    let mutateData = {
      propertyId: propertyId,
      newNote: {
        note: data.note
      }
    };

    if (propertyId) {
      updateNotes.mutate(mutateData, saveHandlers);
    }
  };

  const pages = [
    { to: "..", label: "Properties", current: false },
    {
      to: propertyId && property ? `${propertyId}` : "",
      label:
        propertyId && property
          ? `Property`
          : "New Property",
      current: true,
    },
  ];

  if (singleProperty.isLoading || !property) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center">
              <h1 className="text-2xl font-semibold text-gray-900">
                <div dangerouslySetInnerHTML={{ __html: getFullAddress(property) }} />
              </h1>
            </div>
            <div className="">
              <a href={propertyUrl} target="_blank" >
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
                >
                  View on site
                </button>
              </a>
            </div>
          </div>
          <div className="mb-3">
            <h1 className="text-lg">{property.views} views | {property.enquiries} enquiries</h1>
          </div>
          <div className="my-6">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <PropertyDetails property={property} />
              <MapView property={property} />
            </div>
          </div>
          <div className="bg-white p-5 mb-10">
            <h1 className="pb-3 text-xl font-semibold text-gray-900 underline">Property Description</h1>
            <p dangerouslySetInnerHTML={{ __html: property.description }} className="leading-relaxed"></p>
          </div>

          <Notes notes={property.notes} submitFunction={addNote} deleteFunction={deleteNote} />
        </div>
      </>
    );
  }
}
