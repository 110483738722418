import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import date from "date-and-time";

import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useEnquiries from "../../data/useEnquiries";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Notes from "../../components/Notes/Notes";
import Avatar from "../../components/Shared/Avatar";
import AssignUser from "../../components/Shared/AssignUser";
import SetFollowUp from "../../components/Shared/SetFollowUp";
import useUserContext from "../../contexts/UserContext";

export default function ViewEnquiry() {
  const { user: { agency } } = useUserContext();
  const { enquiries, update, updateComments, removeComment } = useEnquiries();
  const { enquiryId } = useParams();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Enquiry");
  const [enquiry, setEnquiry] = useState(undefined);
  const [assignOpen, setAssignOpen] = useState(false);
  const [followUpOpen, setFollowUpOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const confirmDelete = (comment) => {
    var updateData = {
      commentId: comment.id,
      enquiryId: enquiryId
    }

    removeComment.mutate(updateData, deleteHandlers);
  };

  const onSubmit = (data) => {
    console.log("submitting", data);

    let mutateData = {
      enquiryId: enquiryId,
      newComment: {
        content: data.note
      }
    };

    if (enquiryId) {
      updateComments.mutate(mutateData, saveHandlers);
    }
  };


  useEffect(() => {
    if (!enquiries.isLoading) {
      if (enquiryId) {
        let foundEnquiry = enquiries.data.find((e) => e.id == enquiryId);
        setEnquiry(foundEnquiry);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [enquiries.isLoading, enquiries.data, enquiryId, agency]);

  const pages = [
    { to: "..", label: "Enquiries", current: false },
    {
      to: enquiryId && enquiry ? `${enquiryId}` : "",
      label:
        enquiryId && enquiry
          ? `${enquiry.fullName}'s Enquiry`
          : "New Enquiry",
      current: true,
    },
  ];

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="flex justify-center mt-5">
            <div className="bg-white block w-full shadow overflow-hidden sm:rounded-lg">
              <div className="flex justify-between px-4 py-5 sm:px-6">
                <div className="flex items-center gap-x-2">
                  <div className="flex flex-col">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Enquiry Information</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and notes</p>
                    <p className="text-sm text-gray-500">{date.format(new Date(enquiry.created), "DD/MM/YYYY HH:mm")}</p>
                    <p className="text-sm text-gray-500">Follow Up: {enquiry.followUpDate ? date.format(new Date(enquiry.followUpDate), "DD/MM/YYYY") : "None"}</p>
                  </div>

                  <Avatar classes="ml-2 w-10 h-10" userId={enquiry.userId ?? ""} />
                </div>
                <div className="flex items-center gap-x-4">
                  <AssignUser open={assignOpen} setOpen={setAssignOpen} lead={enquiry} update={update} saveHandler={"Enquiry"} />
                  <SetFollowUp open={followUpOpen} setOpen={setFollowUpOpen} lead={enquiry} update={update} saveHandler={"Enquiry"} classes={""} />
                </div>

              </div>


              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Full name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{enquiry.fullName}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Telephone Number</dt>
                    <dd className="mt-1 text-sm text-gray-900">{enquiry.telephoneNumber}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Email address</dt>
                    <dd className="mt-1 text-sm text-gray-900">{enquiry.emailAddress}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Interested In</dt>
                    <dd className="mt-1 text-sm text-gray-900">{enquiry.iAmIntrestedIn}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Source URL</dt>
                    <dd className="mt-1 text-sm text-gray-900">{agency && enquiry.sourceUrl?.replace(agency?.domain, "").replace("/landing/", "")}</dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Note</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {enquiry.message}
                    </dd>
                  </div>
                  <Notes notes={enquiry.comments} submitFunction={onSubmit} deleteFunction={confirmDelete} />
                </dl>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}