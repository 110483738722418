import { useEffect, useState } from "react";

import date from 'date-and-time';
import { Link, useNavigate } from "react-router-dom";

import { ClipboardCopyIcon, DuplicateIcon, ExternalLinkIcon, PencilAltIcon, SearchIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import useForms from "../../data/useForms";
import ConfirmModal from './../../components/Shared/ConfirmModal';
import { formTypeEnum } from './../../constants/enums';
import useDefaultCRUDHandlers from './../../hooks/useDefaultCRUDHandlers';
import useUserContext from "../../contexts/UserContext";
import useNotification from "../../components/notification/useNotifications";
import PopOutBox from "../../components/Shared/PopOutBox";

function FormRow({ form, onDeleteClick, isLandingPage }) {
  const { user: agency } = useUserContext();
  const { duplicate } = useForms();
  const navigate = useNavigate();
  const { addNotification } = useNotification();

  const handleDelete = (e) => {
    e.stopPropagation();
    onDeleteClick(form.id);
  };

  const handleNavigate = () => {
    if (isLandingPage) {
      navigate(`landingpage/${form.id}`)
    } else {
      navigate(`form/${form.id}`)
    }
  };

  const handleCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(`${agency.agency.domain}/landing/${form.slug}`);
    addNotification({
      variant: "success",
      primaryText: "Copied to clipboard!"
    });
  };

  const handleDuplicate = (e) => {
    e.stopPropagation();
    duplicate.mutate(form.id, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Form duplicated!"
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Error duplicating form!"
        });
      }
    });
  };

  return (
    <tr
      onClick={() => handleNavigate()}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {form.title}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Slug</dt>
          <dd className="mt-1 truncate">
            {isLandingPage && "landing/"}{form.slug}
          </dd>
          <dt className="sr-only sm:hidden">Type</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {formTypeEnum[form.type]}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {isLandingPage && "landing/"}{form.slug}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {formTypeEnum[form.type]}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(form.created), "DD/MM/YYYY HH:mm")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          {isLandingPage && (
            <>
              <button
                onClick={(e) => handleCopy(e)}
                type="button"
                className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <span className="sr-only">Copy link</span>
                <ClipboardCopyIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
              <a
                onClick={(e) => e.stopPropagation()}
                href={`${agency.agency.domain}/landing/${form.slug}`} target="_blank" rel="noopener noreferrer"
                className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <span className="sr-only">Open link</span>
                <ExternalLinkIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </a>
            </>
          )}
          <button
            onClick={() => handleNavigate()}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>

          <button
            onClick={(e) => handleDuplicate(e)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Duplicate</span>
            <DuplicateIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          {(form.slug !== "sales-qualifier" && form.slug !== "lettings-qualifier" && form.slug !== "leave-a-review") && (
            <>
              <button
                onClick={(e) => handleDelete(e)}
                type="button"
                className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <span className="sr-only">Delete</span>
                <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            </>
          )}
        </span>
      </td>
    </tr>
  );
}


export default function FormBuilder({ isLandingPage }) {
  const navigate = useNavigate();
  const { forms: allForms, remove } = useForms();
  const { deleteHandlers } = useDefaultCRUDHandlers(isLandingPage ? "Landing Page" : "Forms");
  const [forms, setForms] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const deleteForm = (formId) => {
    setDeleteId(formId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = `EstateTrack Portal | ${isLandingPage ? "Landing Pages" : "Forms"}`;
    if (!allForms.isLoading) {

      let filter = allForms.data.filter((form) =>
        form.type == isLandingPage ? 1 : 0
      );

      filter = filter.filter((form) =>
        form.deleted == null
      );

      filter = filter.filter((form) =>
        form.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        form.source?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setForms(filter);
      setIsLoading(false);
    }
  }, [allForms.isLoading, allForms.data, searchTerm, isLandingPage]);

  return (
    <>
      <ConfirmModal
        title={"Form"} message={`Are you sure you want to delete this form?\n This will remove it from the website!`}
        open={confirmDeleteOpen} setOpen={setConfirmDeleteOpen} onConfirm={confirmDelete}
        confirmButtonText={"Delete"}
      />
      <div className="px-4 sm:px-6 lg:px-8">

        <div className="sm:flex sm:items-center">
          <div className="flex grow flex-col sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              {isLandingPage ? "Landing Page" : "Form"} Builder
            </h1>
            <div className="flex items-center gap-x-1">
              <p className="mt-2 text-sm text-gray-700">
                Build and Edit forms for your website here.
              </p>
              {isLandingPage && (
                <PopOutBox classes="w-[44rem] top-6">
                  <h2>If you create a link to your website you can add ?sourceName=yourSource on the end of the link and the source will appear here.</h2>
                  <h2>E.g: Your link: "wwww.estateagent.co.uk/landing/your-form?sourceName=Facebook"</h2>
                </PopOutBox>
              )}
            </div>
          </div>

          <div>
            <div className="mt-2 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Form or Source Name"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to={isLandingPage ? "landingpage" : "form"}
              type="button"
              className="mt-2 inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add New {isLandingPage ? "Page" : "Form"}
            </Link>
          </div>

        </div>


        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : forms?.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Title</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Slug</span>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <span className="flex">Type</span>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <span className="flex">Date Created</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {forms.map((form) => (
                  <FormRow
                    key={form.id}
                    form={form}
                    onDeleteClick={deleteForm}
                    isLandingPage={isLandingPage}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName={isLandingPage ? "Landing Page" : "Form"}
                  onNewClick={() => navigate(isLandingPage ? "landingpage" : "form")}
                />
              ) : (
                <NoItems searching itemName={isLandingPage ? "Landing Page" : "Form"} />
              )}
            </div>
          )}
        </div>

      </div>
    </>
  );
}