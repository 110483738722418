import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useAgencies from "../../data/useAgencies";
import { Switch } from "@headlessui/react";
import classNames from "../../utils/classNames";

export default function TranspondSettingsPartial({ agencyId }) {
  const { agencies, updateReportSettings } = useAgencies();

  const { saveHandlers } = useDefaultCRUDHandlers("Report Settings");
  const { register, setValue, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const [agency, setAgency] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const onSubmit = (data) => {
    setIsLoading(true);

    var settings = {
      monthlyStatisticsEmail: monthlyStats,
      monthlyStatisticsRecipient: data.monthlyStatisticsRecipient,
    }

    updateReportSettings.mutate({ agencyId, settings },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
        },
        onError: () => {
          saveHandlers.onError();
        },
        onSettled: () => {
          setIsLoading(false);
        }
      });
  }

  useEffect(() => {
    if (agencyId && !agencies.isLoading && agencies.data) {
      let agency = agencies.data.find((a) => a.id === agencyId);
      setMonthlyStats(agency.monthlyStatisticsEmail)
      console.log(agency)
      setValue("monthlyStatisticsRecipient", agency.monthlyStatisticsRecipient)
      setAgency(agency);
    }

    setIsLoading(false);
  }, [agencies.data, agencies.isLoading, agencyId])


  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <div className="">
          <div className="-mx-6 px-6">
            <h3 className="text-xl font-medium text-gray-900">
              Dashboard Reports
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Settings for recieving reports generated from this agency
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} >

            <div className="mt-5 py-3">
              <Switch.Group as="div" className="flex">
                <span className="mr-10">
                  <Switch.Label as="span" className="text-md text-gray-900" passive>
                    Monthly Dashboard Stats Email
                  </Switch.Label>
                </span>
                <Switch
                  checked={monthlyStats}
                  onChange={(e) => setMonthlyStats(e)}
                  className={classNames(
                    monthlyStats ? 'bg-etpink-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      monthlyStats ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            <div className="mt-4">
              <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-700">
                Monthly Statistics Recipient
              </label>
              <div className="mt-1 flex items-center">
                <input
                  {...register("monthlyStatisticsRecipient")}
                  type="text"
                  className={"shadow-sm w-1/2 focus:ring-etpink-400 focus:border-etpink-400 block sm:text-sm border-gray-300 rounded-md"}
                  placeholder="noreply@agencyemail.co.uk"
                />
              </div>
            </div>

            <div className="mt-10">
              <button
                type="submit"
                className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
              >
                Save
              </button>
            </div>

          </form>
        </div>
      </>
    );
  }
}
