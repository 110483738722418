import { useEffect, useState } from "react";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";

import LoadingWheel from "../Shared/LoadingWheel";
import BranchListBoxAdmin from "../Shared/BranchListBoxAdmin";
import SwitchInput from "../Shared/Inputs/SwitchInput";

export default function PortalQualiferConfig({ qualifier, setIsConfig, setOpen, isUpdate, handleAddQualifier, handleUpdateQualifier, agencyId }) {
  const { register, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: { api: "", salesEnabled: true, lettingsEnabled: true, salesUnqualifiedEmails: false, lettingsUnqualifiedEmails: false}
  });
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState();

  const onSubmit = (data) => {
    if (isUpdate) {
      let toUpdate = {
        ...qualifier,
        ...data,
      };
      toUpdate.branchId = branch;

      handleUpdateQualifier(toUpdate)
    } else {
      let toAdd = {
        siteName: qualifier.siteName,
        ...data,
      };
      toAdd.branchId = branch;

      handleAddQualifier(toAdd);
      setIsConfig(false);
    }
  };

  useEffect(() => {
    if (isUpdate) {
      reset({ ...qualifier })
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex items-center">
          <div className="mr-4 flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
            <AdjustmentsIcon
              className="h-5 w-5 text-gray-600"
              aria-hidden="true"
            />
          </div>
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Configure Qualifier & Enquiries
          </Dialog.Title>
        </div>
        <Dialog.Description as="p" className="text-sm text-gray-500">
          Once a configuration is saved, leads will come in from that qualifier. Turn on the toggles to enable qualifiying emails to go out and choose notification preferences.
        </Dialog.Description>
      </div>
      <div className="mt-3 sm:mt-5">
        <div className="mt-2">
          {loading ? (
            <div className="flex p-4 justify-center items-center">
              <LoadingWheel width="w-10" height="h-10" />
            </div>
          ) : (
            <>
              <div className="p-3">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700" >
                  Configuration Name
                </label>
                <input
                  {...register("name")}
                  type="text"
                  className="mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>

              <div className="p-3">
                <BranchListBoxAdmin defaultBranch={qualifier.branchId} setBranch={setBranch} agencyId={agencyId} />
              </div>

              {/* {qualifier.siteName !== 1 && qualifier.siteName !== 3 && (
                <div className="p-3">
                  <label htmlFor="api" className="block text-sm font-medium text-gray-700" >
                    API Key
                  </label>
                  <input
                    {...register("api")}
                    type="text"
                    className="mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              )} */}

              {qualifier.siteName !== 3 && (
                <>
                  {qualifier.siteName !== 0 && (
                    <div className="p-3">
                      <label htmlFor="api" className="block text-sm font-medium text-gray-700" >
                        Brand ID
                      </label>
                      <input
                        {...register("siteBrandId")}
                        type="text"
                        className="mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  )}
                  <div className="p-3">
                    <label htmlFor="api" className="block text-sm font-medium text-gray-700" >
                      Branch ID
                    </label>
                    <input
                      {...register("siteBranchId")}
                      type="text"
                      className="mt-1 shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                </>
              )}

              <div className="p-3 flex flex-col gap-y-4">
                <SwitchInput
                  label="Sales Qualifier"
                  register={register}
                  registerName="salesEnabled"
                  setValue={setValue}
                  watch={watch}
                />

                <SwitchInput
                  label="Unqualified Sales Emails"
                  register={register}
                  registerName="salesUnqualifiedEmails"
                  setValue={setValue}
                  watch={watch}
                />

                <hr className="border-gray-200" />

                <SwitchInput
                  label="Lettings Qualifier"
                  register={register}
                  registerName="lettingsEnabled"
                  setValue={setValue}
                  watch={watch}
                />

                <SwitchInput
                  label="Unqualified Lettings Emails"
                  register={register}
                  registerName="lettingsUnqualifiedEmails"
                  setValue={setValue}
                  watch={watch}
                />
              </div>


            </>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="w-full sm:w-1/2 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <button
            type="submit"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
          >
            Save
          </button>
          {isUpdate ? (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Close
            </button>
          ) : (
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
              onClick={() => setIsConfig(false)}
            >
              Back
            </button>
          )}
        </div>
      </div>
    </form>
  );
}