import { useEffect, useState } from "react";

import { closestCenter, DragOverlay, KeyboardSensor, PointerSensor, useDndContext, useSensor, useSensors } from "@dnd-kit/core"
import { DndContext } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable"

import { arrayMoveImmutable } from "../../utils/arrayMove";
import { DragItem } from "./DragItem";
import FormElement from "./FormElement"
import LoadingWheel from "../Shared/LoadingWheel";

export default function DraggableForm({ formJson, setFormJson, refresh, setRefresh, newId }) {
  const [items, setItems] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // const { activatorEvent, over } = useDndContext();
  // const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  // const activeIndex = activeId ? items?.findIndex((x) => x.id === activeId) : -1;
  // const overIndex = over?.id ? items?.findIndex((x) => x.id === over?.id) : -1;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      var newFormJson = formJson;

      setItems((items) => {
        const oldIndex = items.findIndex((x) => x.id === active.id);
        const newIndex = items.findIndex((x) => x.id === over.id);

        newFormJson.elements = arrayMoveImmutable(newFormJson.elements, oldIndex, newIndex);
        setFormJson(newFormJson);

        return arrayMoveImmutable(items, oldIndex, newIndex);
      });

      setRefresh(!refresh);
    }

    setActiveId(null);
  }

  useEffect(() => {
    if (!formJson) {
      return;
    }

    setItems(formJson.elements.map((element) => element));
    setIsLoading(false);
  }, [formJson, refresh])

  if (!items || isLoading) {
    return (<LoadingWheel />)
  }

  return (
    <div className="col-span-1 lg:col-span-3 bg-white shadow rounded-lg p-3 py-4">
      <h1 className="text-lg px-1 font-medium">Form Builder</h1>
      <hr className="pb-6" />

      <div className="grid grid-cols-6 gap-2">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          sensors={sensors}
        >
          <SortableContext
            items={items.map((element) => element.id)}
            strategy={rectSortingStrategy}
          >
            {items.map((element, i) => {
              return (
                <FormElement handle={true}
                  key={i} id={element.id}
                  element={element} formJson={formJson} setFormJson={setFormJson}
                  refresh={refresh} setRefresh={setRefresh}
                  isOpen={element.id === newId}
                />
              )
            })}
            <DragOverlay  >
              {activeId ? <DragItem id={activeId} formJson={formJson} /> : null}
            </DragOverlay>
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}