import { useState } from "react";

import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

export default function PopOutBox({ classes, children }) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div>
      <div
        className="relative"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <QuestionMarkCircleIcon className="h-5 w-5 text-gray-600" />
        {isHovering && (
          <div className={`p-1 absolute z-30 bg-white border rounded-md ${classes}`}>
            {children}
          </div>
        )}
      </div>
    </div>
  )
}