
import React, { useEffect, useState } from "react";
import date from "date-and-time";
import { withAgency } from "../../data/withAgency";
import useEmailReports from "../../data/useEmailReports";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";

const checkIfNaNOrInfinity = (value) => {
  let checked = value;

  if (value === "Infinity") checked = 100;
  else if (isNaN(value)) checked = 0;

  return checked.toFixed(1);
}

function EmailCampaignRow({ campaign }) {
  const [clickThroughRate, setclickThroughRate] = useState(0);
  const [openedRate, setOpenedRate] = useState(0);
  const [clickedRate, setClickedRate] = useState(0);

  useEffect(() => {
    setclickThroughRate(checkIfNaNOrInfinity(campaign.stats.totalClicks / campaign.stats.totalOpens * 100));

    setOpenedRate(checkIfNaNOrInfinity(campaign.stats.totalOpens / campaign.stats.totalSent * 100));

    setClickedRate(checkIfNaNOrInfinity(campaign.stats.totalClicks / campaign.stats.totalSent * 100));

  }, [campaign]);

  return (
    <tr className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {date.format(new Date(campaign.startDate), "DD/MM/YYYY")}
      </td>

      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {campaign.campaignName}
      </td>

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {campaign.stats.totalSent}
      </td>

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {campaign.stats.totalOpens} ({openedRate}%)
      </td>

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {campaign.stats.totalClicks} ({clickedRate}%)
      </td>

      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {clickThroughRate}%
      </td>
    </tr>
  );
}

function _EmailAnalytics({ agencyReady, agency }) {
  const { emailStats } = useEmailReports(agency?.id);
  const [isTotal, setIsTotal] = useState(false);
  const [clickThroughRate, setclickThroughRate] = useState(0);
  const [clickThroughRateMonth, setclickThroughRateMonth] = useState(0);
  const [marketingStats, setMarketingStats] = useState({});

  useEffect(() => {
    if (agencyReady && !emailStats.isLoading && emailStats.data) {
      setclickThroughRate(checkIfNaNOrInfinity(emailStats.data.totalClicks / emailStats.data.totalOpens * 100));
      setclickThroughRateMonth(checkIfNaNOrInfinity(emailStats.data.clickedMonth / emailStats.data.sentMonth * 100));
      // console.log({emailStats})
      // console.log(isTotal)
    }
  }, [agency, agencyReady, emailStats.data, emailStats.isLoading, isTotal])

  return (
    <div className="px-12">
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Email Marketing Analytics</h1>
          <p className="mt-2 text-sm text-gray-700">
            Details about the emails that are sent out, including subscriber counts, open rates and click rates.
          </p>
        </div>
        <div>
          <select onChange={(e) => setIsTotal(e.target.value === "true")}>
            <option value={"false"}>Last 30 Days</option>
            <option value={"true"}>All</option>
          </select>
        </div>
      </div>

      <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        {emailStats.isLoading ? (
          <div className="flex p-10 bg-white shadow rounded-lg justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-4 py-4 divide-gray-200 overflow-hidden rounded-lg bg-white shadow divide-y lg:divide-y-0 lg:divide-x" >
              {isTotal ? (
                <>
                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Total Emails Sent
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.totalSent.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Total Email Opens
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.totalOpens.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Total Emails Clicks
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.totalClicks.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Clickthrough Rate
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {clickThroughRate}%
                    </dd>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Emails Sent 
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.sentMonth.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Email Opens 
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.openedMonth?.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Emails Clicks 
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {emailStats.data.clickedMonth?.toLocaleString("en-GB")}
                    </dd>
                  </div>

                  <div className="flex flex-col items-center px-4">
                    <dt className="font-medium text-gray-700 truncate">
                      Clickthrough Rate 
                    </dt>
                    <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                      {clickThroughRateMonth}%
                    </dd>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>

      <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        {emailStats.isLoading ? (
          <div className="flex p-10 bg-white justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : emailStats.data.campaigns.length > 0 ? (
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                >
                  <span className="flex">Start Date</span>
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                >
                  <span className="flex">Campaign Name</span>
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                >
                  <span className="flex">Sent</span>
                </th>


                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                >
                  <span className="flex">Opened</span>
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                >
                  <span className="flex">Clicked</span>
                </th>

                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                >
                  <span className="flex">Clickthrough Rate</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {emailStats.data.campaigns.map((campaign) => (
                <EmailCampaignRow
                  key={campaign.id}
                  campaign={campaign}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="bg-white p-10">
            <NoItems searching itemName="campaign" />
          </div>
        )}
      </div>
    </div>
  )
}


export const EmailAnalytics = withAgency(_EmailAnalytics);