
export default function JsonRadioInput({ element }) {

  return (
    <div className="">
      <label className="block mb-2 text-sm font-medium text-gray-700">
        {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
      </label>
      {element.options.map((option, i) => {
        return (
          <div key={i} className="flex items-center">
            <input type="radio" id={i}
              name={element.label}
              className="w-5 h-5 mr-2"
            />
            <label htmlFor={i} className="block text-base font-base text-gray-700">
              {option}
            </label>
          </div>
        )
      })}

    </div>
  )
}