

export const JsonParagraph = ({ element }) => {

  return (
    <div className="">
      <p className="whitespace-pre-line block mb-2 text-base text-gray-700">
        {element.label}
      </p>
    </div>
  )
}