import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useServices from "../../data/useServices";
import useNotification from "../../components/notification/useNotifications";

export default function ServiceLog() {
  const { id } = useParams();
  const { services, runNow } = useServices();
  const { addNotification } = useNotification();
  const [service, setService] = useState(null);

  const handleRunNow = () => {
    if (id) {
      runNow.mutate(id, {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Service Started",
          });
        },
        onError: (error) => {
          addNotification({
            variant: "error",
            primaryText: "Error Starting Service",
          });
        }
      });
    }
  }

  useEffect(() => {
    if (!services.isLoading) {
      if (id) {
        setService(services.data.find((s) => s.id === parseInt(id)));
      }
    }
  }, [services.data, services.isLoading, id])

  const pages = [
    { label: "Running Services", to: "/admin/servicehealth", current: false },
    { label: `${service?.serviceName}`, to: "#", current: true },
  ];

  if (services.isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="sm:flex sm:items-center mt-4">
            <div className="sm:flex-auto">
              <h3 className="text-xl font-semibold text-gray-900">
                {service?.serviceName}
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                A log of the last 30 days job executions
              </p>
            </div>
            <div>
              <button
                onClick={() => handleRunNow()}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
              >
                Run Service Now
              </button>
            </div>
          </div>
          <div className="mt-4 flex flex-col">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                {/* <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 hidden border-b border-gray-300 bg-gray-50 bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                      >
                        Errors
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {people.map((person, personIdx) => (
                      <tr key={person.email}>
                        <td
                          className={classNames(
                            personIdx !== people.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {person.name}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== people.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell"
                          )}
                        >
                          {person.title}
                        </td>
                        <td
                          className={classNames(
                            personIdx !== people.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden lg:table-cell"
                          )}
                        >
                          {person.email}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
