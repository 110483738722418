import { Fragment, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import {
  LogoutIcon,
  BellIcon,
  CogIcon,
  MenuAlt2Icon,
  UserIcon,
  AdjustmentsIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

import classNames from "../../utils/classNames";
import useAuthService from "../../services/useAuthService";
import SwitchAgency from "./SwitchAgency";
import useUserContext, { UserContextActions } from "../../contexts/UserContext";
import NotificationPopout from "../notification/NotificationPopout";
import useUserNotifications from "../../data/useUserNotifications";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Gravatar from "react-gravatar";
import useUserNotificationsRecent from "../../data/useUserNotificationsRecent";

export default function NavBar({ setSidebarOpen }) {
  const navigate = useNavigate();
  const {
    user: { agency, user, identity },
    dispatch,
  } = useUserContext();
  const { recentNotifications: notifications, unseen, markSeen } = useUserNotificationsRecent();
  const { saveHandlers } = useDefaultCRUDHandlers("Notification");
  const [showNotifications, setShowNotifications] = useState(false);

  const handleLogout = () => {
    dispatch({ type: UserContextActions.removeIdentityUserAgency });
    navigate("/signin", { state: { logout: true } });
  };

  const toggleNotifications = () => {
    if (!showNotifications && notifications.data && !notifications.isLoading) {
      var notificationIds = [];

      notifications.data.forEach((notification) => {
        if (!notification.seen) {
          notificationIds.push(notification.id);
        }
      });

      if (notificationIds.length > 0) {
        markSeen.mutate(notificationIds);
      }
    }

    setShowNotifications(!showNotifications);
  };

  const userNavigation = [
    { name: "Your Profile", Icon: UserIcon, onClick: () => {}, href: "/profile" },
    { name: "Settings", Icon: CogIcon, onClick: () => {} },
    { name: "Sign out", Icon: LogoutIcon, onClick: () => handleLogout() },
  ];

  return (
    <>
      <NotificationPopout
        setShowNotifications={setShowNotifications}
        show={showNotifications}
      />
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-etpink-500 md:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 px-4 flex">
          <div className="flex-1 flex items-center justify-center"></div>

          {/* <form className="w-full flex md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form> */}
          <div className="ml-4 flex items-center md:ml-6">
            {identity.role === "Admin" && (
              <Link
                to="/admin"
                className="mr-2 rounded-full px-4 py-1.5 my-6 border border-transparent text-sm leading-4 font-medium shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                <span className="sr-only">Admin Dashboard</span>
                {/* <AdjustmentsIcon className="h-6 w-6" aria-hidden="true" /> */}
                Admin
              </Link>
            )}
            <a
                href="https://estatetrack.co.uk/contact-support/"
                target={"_blank"}
                className="mr-2 rounded-full px-4 py-1.5 my-6 border border-transparent text-sm leading-4 font-medium shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                <span className="sr-only">Support</span>
                {/* <AdjustmentsIcon className="h-6 w-6" aria-hidden="true" /> */}
                Support
              </a>
            {agency && (
              <>
                <button
                  onClick={() => toggleNotifications()}
                  type="button"
                  className="bg-white m-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-7 w-7" aria-hidden="true" />
                  {!unseen.isLoading && unseen.data > 0 && (
                    <span className="absolute h-4 w-4 rounded-full text-xs text-white bg-red-600 -translate-y-9 translate-x-0.5">
                      {!unseen.isLoading && unseen.data}
                    </span>
                  )}
                </button>
                <Link
                  to={`/${agency.logicalName}/settings`}
                  className="bg-white m-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                <CogIcon className="h-7 w-7" aria-hidden="true" />
                </Link>
              </>
            )}

            {/* Profile dropdown */}
            <Menu as="div" className="ml-3 relative">
              <div>
                <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500">
                  <span className="sr-only">Open user menu</span>
                  <Gravatar
                    email={user.emailAddress}
                    size={128}
                    className="h-8 w-8 rounded-full"
                  />
                  {/* <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <Link
                          to={item.href}
                          onClick={item.onClick}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "flex items-center px-4 py-2 text-sm text-gray-700 hover:cursor-pointer"
                          )}
                        >
                          <span className="mr-3">
                            <item.Icon className="w-5 h-5" />
                          </span>
                          {item.name}
                        </Link>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </>
  );
}
