import { useEffect, useState } from "react";
import YearGraph from "../../Graphs/YearGraph";
import LoadingWheel from "../../Shared/LoadingWheel";

const ValuationYearGraph = ({ stats }) => {

  const options = {
    responsive: true,
    layout: {
    },
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: true
      },
    },
    interaction: {
      intersect: false,
      mode: "index"
    },
    scales: {
      x: {
        grid: {
          drawBorder: true,
          display: false
        },
        ticks: {
          display: true
        }
      },
      y: {
        grid: {
          drawBorder: true,
          display: true
        },
        ticks: {
          display: true
        }
      }
    }
  };


  return (
    <div className="px-8 py-4 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Valuations - Last 12 Months</h1>

      <hr className="" />

      <div className="mt-2">
        <YearGraph backgroundColor="rgba(119,221,119,0.1)" borderColor="rgba(119,221,119,0.8)" data={stats.valuationMonths} newOptions={options} />
      </div>

    </div>
  )
}

export default ValuationYearGraph;