import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useBlogPostCategories() {
  const staleTime = 1000 * 60; //60 secpnds
  const { user: { agency } } = useUserContext();
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const categories = useQuery(
    ["blogPostsCategories", agency.id],
    () => get(`/blogpost/${agency.id}/categories`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((category) => post(`/blogpost/${agency.id}/categories`, category), {
    onSuccess: (addedCategory) => {
      queryClient.setQueryData(["blogPostsCategories", agency.id], (currentCategories) => [
        addedCategory.data,
        ...currentCategories,
      ]);
    },
  });

  const update = useMutation((category) => put(`/blogpost/${agency.id}/categories/${category.id}`, category),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["blogPostsCategories", agency.id])
      },
    }
  );

  // const update = useMutation((category) => put(`/blogpost/${agency.id}/categories/${category.id}`, category),
  //   {
  //     onSuccess: (updatedCategory) => {
  //       queryClient.setQueryData(["blogPostsCategories", agency.id], (currentCategories) =>
  //         currentCategories.map((category) =>
  //           category.id === updatedCategory.id ? updatedCategory : category
  //         )
  //       );
  //     },
  //   }
  // );

  const remove = useMutation((categoryId) => del(`/blogpost/${agency.id}/categories/${categoryId}`),
    {
      onSuccess: (_, categoryId) => {
        queryClient.setQueryData(["blogPostsCategories", agency.id], (currentCategories) =>
          currentCategories.filter((category) => category.id !== categoryId)
        );
      },
    }
  );


  return {
    categories,
    add,
    update,
    remove
  };
}
