import { Fragment, useRef, useState } from "react";
import { Dialog, Transition, RadioGroup } from "@headlessui/react";

import { PhotographIcon } from "@heroicons/react/solid";

import classNames from "../../utils/classNames";
import MediaManager from "./MediaManager";
import UploadMedia from "./UploadMedia";
import useUserContext from "../../contexts/UserContext";
import AdminMediaManager from "./AdminMediaManager";
import UnsplashGallery from "../Blog/UnsplashGallery";
import UnsplashMedia from "./UnsplashMedia";

function MediaType({
  mediaTypes,
  mediaType,
  setMediaType,
  sourceTypes,
  sourceType,
  setSourceType,
  setValue,
}) {
  return (
    <div className="grid grid-cols-3 -mx-2">
      <div className="col-span-2 mx-2">
        <RadioGroup
          value={sourceType}
          onChange={(v) => {
            setValue("");
            setSourceType(v);
          }}
        >
          <RadioGroup.Label className="mb-2 block text-sm font-medium text-gray-700">
            Source
          </RadioGroup.Label>
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
            {sourceTypes.map((st, i) => (
              <RadioGroup.Option
                key={i}
                value={st}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-offset-2 ring-etpink-500" : "",
                    checked
                      ? "bg-etpink-600 border-transparent text-white hover:bg-etpink-700"
                      : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                    "border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                  )
                }
              >
                <RadioGroup.Label as="span">{st}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
      {mediaTypes.length > 1 &&
        (sourceType === "URL" || sourceType === "Media Manager") && (
          <div className="col-span-1 mx-2">
            <RadioGroup value={mediaType} onChange={(v) => setMediaType(v)}>
              <RadioGroup.Label className="mb-2 block text-sm font-medium text-gray-700">
                Media Type
              </RadioGroup.Label>
              <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
                {mediaTypes.map((mt, i) => (
                  <RadioGroup.Option
                    key={i}
                    value={mt}
                    className={({ active, checked }) =>
                      classNames(
                        active ? "ring-2 ring-offset-2 ring-etpink-500" : "",
                        checked
                          ? "bg-etpink-600 border-transparent text-white hover:bg-etpink-700"
                          : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                        "border rounded-md py-2 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                      )
                    }
                  >
                    <RadioGroup.Label as="span">{mt}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          </div>
        )}
    </div>
  );
}

function URLSource({ value, setValue }) {
  return (
    <div className="mx-4 my-10 sm:grid sm:grid-cols-5 sm:gap-4 sm:items-start">
      <label
        htmlFor="URL"
        className="block text-sm text-right font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        URL
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-4">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
          name="URL"
          id="URL"
          className="max-w-lg block w-full shadow-sm focus:ring-etpink-500 focus:border-etpink-500 sm:max-w-2xl sm:text-sm border-gray-300 rounded-md"
        />
      </div>
    </div>
  );
}

export default function InsertMediaModal({
  allowURL = true,
  allowImage = true,
  allowVideo = true,
  open,
  setOpen,
  onInsert,
  isAdmin
}) {
  const sourceTypes = ["Media Manager", ...(allowURL ? ["URL"] : []), "Upload", "Unsplash"];
  const mediaTypes = [
    ...(allowImage ? ["Image"] : []),
    ...(allowVideo ? ["Video"] : []),
  ];
  const { user: { agency }, } = useUserContext();
  const [mediaType, setMediaType] = useState(mediaTypes[0]);
  const [sourceType, setSourceType] = useState(sourceTypes[0]);
  const [value, setValue] = useState("");
  const [width, setWidth] = useState();
  const [align, setAlign] = useState("");
  const uploadRef = useRef();

  const getMediaSelector = () => {
    switch (sourceType) {
      case "Media Manager":
        return (
          <div className="my-4 max-h-[32rem] overflow-y-auto">
            {agency ? (
              <MediaManager
                mediaType={mediaType.toLowerCase()}
                selected={value}
                onSelect={(m) => setValue(m)}
              />
            ) : (
              <AdminMediaManager
                mediaType={mediaType.toLowerCase()}
                selected={value}
                onSelect={(m) => setValue(m)}
              />
            )}

          </div>
        );
      case "Upload":
        return (
          <div className="my-4">
            <UploadMedia ref={uploadRef} maxFiles={1} />
          </div>
        );
      case "Unsplash":
        return <UnsplashMedia value={value} setValue={setValue} />
      case "URL":
      default:
        return <URLSource value={value} setValue={setValue} />;
    }
  };

  const handleInsert = () => {
    if (sourceType === "Upload") {
      uploadRef.current.upload().then((uploaded) => {
        onInsert(sourceType, mediaType, uploaded[0], width, align);
        setWidth();
      });
    } else if (sourceType === "Unsplash") {
      onInsert(sourceType, mediaType, value, width, align);
      setWidth();
    } else {
      if (sourceType === "URL" && value.toLowerCase().includes("youtube") || sourceType === "URL" && value.toLowerCase().includes("youtu.be")) {
        //Strips out youtube video ID
        var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = value.match(regExp);
        if (match && match[2].length == 11) {
          var embedUrl = `https://www.youtube.com/embed/${match[2]}`
          onInsert(sourceType, mediaType, embedUrl, width, align);
          setWidth();
        }
        // } else if (sourceType === "URL" && value.toLowerCase().includes("vimeo")) {
        //   //strips out vimeo video ID
        //   var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
        //   var parseUrl = value.match(regExp);
        //   var embedUrl = `https://vimeo.com/${parseUrl[5]}`;
        //   onInsert(sourceType, mediaType, embedUrl, width, align);
        //   setWidth();
      } else {
        onInsert(sourceType, mediaType, value, width, align)
        setWidth();
      }

    }
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-6xl sm:w-full sm:p-6">
                  <div className="flex items-center">
                    <div className="mr-5 flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
                      <PhotographIcon
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Insert Media
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <MediaType
                      mediaTypes={mediaTypes}
                      mediaType={mediaType}
                      setMediaType={setMediaType}
                      sourceTypes={sourceTypes}
                      sourceType={sourceType}
                      setSourceType={setSourceType}
                      setValue={setValue}
                    />
                    <div className="mt-5 border-t border-b border-gray-200">
                      {getMediaSelector(mediaType)}
                    </div>
                  </div>

                  <div className="flex justify-between">
                    <div className="w-full sm:w-1/4 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 enabled:bg-etpink-600 bg-gray-500 text-base font-medium text-white enabled:hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
                        onClick={() => handleInsert()}
                        disabled={sourceType === "Unsplash"}
                      >
                        Insert
                      </button>
                      <button
                        type="button"
                        className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
