import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import FormElement from './FormElement';

export const DragItem = forwardRef(({ id, formJson, ...props }, ref) => {
  const element = formJson.elements.find(x => x.id === id)

  // useEffect(() => {

  // }, [id])

  return (
    <div {...props} ref={ref} className="opacity-60"> 
      <FormElement id={id} element={element} />
    </div>
  )
});