import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useTranspond(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  //   const transpond = useQuery(["transpond", agencyId], () => get(`/Transpond/${agencyId}`).then((res) => res.data),
  //     {
  //       staleTime: staleTime,
  //     });

  const transpondAccounts = useQuery(["transpond", agencyId], () => get(`/Transpond/${agencyId}/accounts`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const createAccount = useMutation(["transpond", agencyId], () => post(`/Transpond/${agencyId}/createTranspond`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const updateAccount = useMutation(["transpond", agencyId], () => put(`/Transpond/${agencyId}/update`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  //   const add = useMutation((reviewSite) => post(`/Transpond/${agencyId}`, reviewSite), {
  //     onSuccess: (addedReviewSite) => {
  //       console.log(addedReviewSite);
  //       queryClient.setQueryData(["transpond", agencyId], (currentReviewSites) => 
  //       [
  //         ...currentReviewSites,
  //         addedReviewSite.data,
  //       ]
  //       );
  //     },
  //   });

  //   const update = useMutation((reviewSite) => put(`/Transpond/${agencyId}/${reviewSite.id}`, reviewSite), {
  //     onSuccess: (updatedReviewSite) => {
  //       queryClient.setQueryData(["transpond", agencyId], (currentReviewSites) =>
  //         currentReviewSites.map((site) =>
  //           site.id === updatedReviewSite.id ? updatedReviewSite : site
  //         )
  //       );
  //     },
  //   });

  //   const remove = useMutation((reviewSiteId) => del(`/Transpond/${agencyId}/${reviewSiteId}`), {
  //     onSuccess: (_, reviewSiteId) => {
  //       queryClient.setQueryData(["transpond", agencyId], (currentReviewSites) =>
  //       currentReviewSites.filter((site) => site.id !== reviewSiteId)
  //       );
  //     },
  //   });

  return {
    // reviewSites,
    transpondAccounts,
    createAccount,
    updateAccount,
    // add,
    // update,
    // remove,
  };
}
