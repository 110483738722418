import useUserContext, { UserContextActions } from "../contexts/UserContext";
import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

/**
 * A wrapper to handle logic for resolving agency logical names to an agency object.
 * @param {JSX.Element} Wrapped - Component to wrap in logic
 * @prop {Object} agency - Object containing basic agency info
 * @prop {Boolean} agencyReady - ready state set once logic is complete if true agency will contain data
 * @returns The provided JSX element with agency details injected as props
 */
export function withAgency(Wrapped) {
  return function (props) {
    const {
      user: { agency, agencies, loading },
      dispatch,
    } = useUserContext();
    const { agencyName } = useParams();
    const [agencyReady, setAgencyReady] = useState(false);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const findAgency = () => {
      if (!loading && agencies !== null && Array.isArray(agencies)) {
        return agencies.find((a) => a.logicalName === agencyName);
      } else {
        return undefined;
      }
    };

    useEffect(() => {
      if (!agency || agency.logicalName !== agencyName) {
        let foundAgency = findAgency();
        if (foundAgency) {
          dispatch({
            type: UserContextActions.setAgency,
            payload: foundAgency,
          });
          setAgencyReady(true);
        } else {
          if (agencyName) {
            setShouldRedirect(true);
          } else {
            setShouldRedirect(false);
          }
          dispatch({
            type: UserContextActions.setAgency,
            payload: null,
          });
          setAgencyReady(true);
        }
      } else {
        setAgencyReady(true);
      }
    }, [loading, agency, agencyName]);

    return shouldRedirect ? (
      <Navigate to="/" />
    ) : (
      <Wrapped {...props} agencyReady={agencyReady} agency={agency} />
    );
  };
}
