import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PencilAltIcon, TrashIcon, CheckCircleIcon, SearchIcon, } from "@heroicons/react/outline";
import date from "date-and-time";

import useReviews from "../../data/useReviews";

import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import RequestReviewModal from "../../components/Review/RequestReviewModal";
import { postStatusEnum } from "../../constants/enums";

function ReviewImportStatus() {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-800">
            Review import successfully completed on 30/05/2022 at 12:15 PM
          </p>
        </div>
      </div>
    </div>
  );
}

function ReviewTableRow({ review, deleteReview }) {
  const navigate = useNavigate();

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteReview(review.id);
  }

  return (
    <tr onClick={() => navigate(`review/${review.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {review.site}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Author</dt>
          <dd className="mt-1 truncate text-gray-700">{review.name}</dd>
          <dt className="sr-only">Rating</dt>
          <dd className="mt-1 truncate text-gray-700">{review.starRating}</dd>
          <dt className="sr-only">Status</dt>
          <dd className="mt-1 truncate">
            <span
              className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${review.status === 3
                ? "text-yellow-600 bg-yellow-100"
                : "text-green-600 bg-green-100"
                }`}
            >
              {postStatusEnum[review.status]}
            </span>
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        <span dangerouslySetInnerHTML={{ __html: review.name }}></span>
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {review.starRating}
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        <span
          className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${review.status === 3
            ? "text-yellow-600 bg-yellow-100"
            : "text-green-600 bg-green-100"
            }`}
        >
          {postStatusEnum[review.status]}
        </span>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {date.format(new Date(review.date), "DD/MM/YYYY")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            type="button"
            onClick={() => navigate(`review/${review.id}`)}
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
            onClick={(e) => handleDelete(e)}
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Reviews() {
  const { reviews: allReviews, remove } = useReviews();
  const { deleteHandlers } = useDefaultCRUDHandlers("Review");
  const [reviews, setReviews] = useState(allReviews);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [sorted, setSorted] = useState(false);
  const [requestReviewOpen, setRequestReviewOpen] = useState(false);

  const navigate = useNavigate();

  const handleAddReview = () => {
    navigate("review");
  };

  const deleteReview = (review) => {
    setDeleteId(review);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Reviews";
    if (!allReviews.isLoading) {

      var filter = allReviews.data.filter((review) => {
        return review.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          review.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          review.site?.toLowerCase().includes(searchTerm.toLowerCase())
      })
      setReviews(filter);
    }
  }, [allReviews.isLoading, allReviews.data, searchTerm, sorted]);

  return (
    <>
      <ConfirmDelete
        itemName="Review"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <RequestReviewModal open={requestReviewOpen} setOpen={setRequestReviewOpen} />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Reviews</h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage your reviews here.
            </p>
          </div>
          <div className="sm:w-1/4">
            <div className="mt-1 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="reviewSearch"
                  id="reviewSearch"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Author, Source or Content"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => setRequestReviewOpen(true)}
              className="inline-flex mr-3 items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Request Review
            </button>
            <button
              type="button"
              onClick={() => handleAddReview()}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Review
            </button>
          </div>
        </div>
        <div className="mt-8">
          {/* <ReviewImportStatus /> */}
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {reviews.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : reviews.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Source
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Author
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Rating
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Review Date
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {reviews.map((review) => (
                  <ReviewTableRow key={review.id} review={review} deleteReview={deleteReview} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="review"
                  onNewClick={() => navigate("review")}
                />
              ) : (
                <NoItems searching itemName="review" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
