import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useReviewSites(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const reviewSites = useQuery(["reviewSites", agencyId], () => get(`/ReviewSite/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const reviewSiteLinks = useQuery(["reviewSiteLinks", agencyId], () => get(`/ReviewSite/${agencyId}/links`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const add = useMutation((reviewSite) => post(`/ReviewSite/${agencyId}`, reviewSite), {
    onSuccess: (addedReviewSite) => {
      console.log(addedReviewSite);
      queryClient.setQueryData(["reviewSites", agencyId], (currentReviewSites) =>
        [
          ...currentReviewSites,
          addedReviewSite.data,
        ]
      );
    },
  });

  const update = useMutation((reviewSite) => put(`/ReviewSite/${agencyId}/${reviewSite.id}`, reviewSite), {
    onSuccess: (updatedReviewSite) => {
      queryClient.setQueryData(["reviewSites", agencyId], (currentReviewSites) =>
        currentReviewSites.map((site) =>
          site.id === updatedReviewSite.id ? updatedReviewSite : site
        )
      );
    },
  });

  const remove = useMutation((reviewSiteId) => del(`/ReviewSite/${agencyId}/${reviewSiteId}`), {
    onSuccess: (_, reviewSiteId) => {
      queryClient.setQueryData(["reviewSites", agencyId], (currentReviewSites) =>
        currentReviewSites.filter((site) => site.id !== reviewSiteId)
      );
    },
  });

  const importReviews = useMutation(() => post(`/ReviewSite/${agencyId}/import`), {
    onSuccess: () => {
    },
  });

  return {
    reviewSites,
    reviewSiteLinks,
    add,
    update,
    remove,
    importReviews,
  };
}
