import classNames from "../../../utils/classNames"

export const ButtonInput = ({ label, isSubmit, onClick, classes, disabled = false }) => {

  return (
    <button
      className={classNames(
        "text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500",
        disabled && "!bg-gray-500 hover:bg-gray-500",
        classes
      )}
      onClick={onClick}
      disabled={disabled}
      type={`${isSubmit ? 'submit' : 'button'}`}
    >
      {label}
    </button>
  )
}