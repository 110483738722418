import { useEffect, useState } from "react";
import useAgencyDepartments from "../../data/useAgencyDepartments";
import SelectInput from './../Shared/Inputs/SelectInput';

export default function DepartmentSelect({ agencyId, branch, register, setValue, watch }) {
  const { departments } = useAgencyDepartments(agencyId);
  const [isLoading, setIsLoading] = useState(true);
  const [branchDepartments, setBranchDepartments] = useState(null);

  const noDepartment = { id: 0, name: "No Department" };

  useEffect(() => {
    if (!departments.isLoading && departments.data && branch) {
      let filtered = departments.data.filter((d) => d.branchId === branch);
      filtered = [noDepartment, ...filtered];
      setBranchDepartments(filtered);
      setIsLoading(false);
    }
  }, [agencyId, branch, departments.isLoading, departments.data]);


  if (isLoading) {
    return <></>;
  }

  return (
    <SelectInput label="Department" registerName="department" options={branchDepartments} register={register} setValue={setValue} watch={watch} />
  );
}