import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Compressor from 'compressorjs';

import { UploadIcon, XIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import useNotification from "../../components/notification/useNotifications";
import useApiHelper from "../../services/useApiHelper";
import useContentEngineMedia from "../../data/useContentEngineMedia";
import { CDN_URL } from "../../services/config";
import bytesToSize from "../../utils/bytesToSize";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

export default function MediaManager() {
  const { media, markDeleted } = useContentEngineMedia();
  const { deleteHandlers } = useDefaultCRUDHandlers("Media");
  const { addNotification } = useNotification();
  const [files, setFiles] = useState([]);
  const [fileProgress, setFileProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { post, formData } = useApiHelper();

  const uploadFiles = () => {
    console.log(files);
    let toUpload = [];
    files.forEach((f) => toUpload.push(uploadFile(f)));

    console.log(toUpload);
    Promise.all(toUpload).then(() => {
      setFiles([]);
      media.refetch();
    });
  };

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      post("/media", {
        name: file.name,
        filename: file.data.name,
        type: file.data.type,
        size: file.data.size,
        altText: file.altText,
        attributionText: file.attributionText
      })
        .then((res) => {
          let fd = new FormData();
          fd.append("media", file.data, res.data.filename);
          formData(`/media/${res.data.id}`, fd).then((res2) => {
            resolve();
          });
        })
        .catch((err) => {
          console.error(err);
          reject();
        });
    });
  };

  const processFiles = (acceptFiles) => {
    console.log(acceptFiles);
    acceptFiles.forEach((file) => {
      if (file.type.includes("image")) {
        console.log(file.type)
        new Compressor(file, {
          quality: 0.7,
          success: (compressedFile) => {
            setFiles((prevFiles) => [
              {
                data: compressedFile,
                name: "",
                altText: "",
              },
              ...prevFiles,
            ]);

          }
        });
      } else {
        setFiles((prevFiles) => [
          {
            data: file,
            name: "",
            altText: "",
          },
          ...prevFiles,
        ]);
      }
    });
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((f, i) => i !== index));
  };

  const handleDelete = (mediaToRemove) => {
    if (window.confirm("Are you sure you want to remove this piece of media?")) {
      markDeleted.mutate(mediaToRemove.id, deleteHandlers);
    }
  }

  useEffect(() => {
    document.title = "EstateTrack Admin | Media Manager";
  }, [media.isLoading, media.data])

  if (media.isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl">
          <div className="max-w-3xl">
            <div className="sm:flex-auto">
              <h3 className="text-xl font-semibold text-gray-900">
                Media Manager
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                Manage media for the content engine
              </p>
            </div>
          </div>
          <div className="my-4">
            {media.data.length > 0 ? (
              <ul
                role="list"
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
              >
                {media.data.map((m) => (
                  !m.dateDeleted &&
                  <li key={m.id} className="relative">
                    <div className="group block w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 overflow-hidden">
                      {m.type.includes("video") ? (
                        <video
                          className="object-cover ml-auto mr-auto"
                          controls
                        >
                          <source src={`${CDN_URL}${m.path}`} type={m.type} />
                        </video>
                      ) : (
                        <img
                          src={`${CDN_URL}${m.path}`}
                          alt={m.altText}
                          className="object-cover ml-auto mr-auto"
                        />
                      )}
                    </div>
                    <p className="mt-2 px-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                      {m.name}
                    </p>
                    <div className="flex">
                      <div className="w-3/4">
                        <p className="block px-2 text-sm font-medium text-gray-600 pointer-events-none">
                          {m.altText}
                        </p>
                        <p className="block px-2 pb-2 text-sm font-medium text-gray-400 pointer-events-none">
                          {bytesToSize(m.size)}
                        </p>
                      </div>
                      <div className="p-1">
                        <button
                          type="button"
                          onClick={() => handleDelete(m)}
                          className="w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400">
                          Delete
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="bg-white border rounded text-center text-gray-400 px-4 py-6">
                No Media
              </div>
            )}
          </div>

          <div className="dropzoneContainer my-4">
            <Dropzone onDrop={processFiles}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div
                    className="flex flex-col items-center text-gray-500 hover:text-gray-700  bg-white border-2 border-gray-300 border-dashed rounded-lg p-10 hover:border-gray-400"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    {uploading ? (
                      <div className="w-full block">
                        <div className="bg-gray-200 rounded-full h-2.5">
                          <div
                            className="bg-etpink-600 h-2.5 rounded-full"
                            style={{ width: `${fileProgress}%` }}
                          ></div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <UploadIcon className="w-16 h-16 m-2" />
                        <span className="text-center">
                          Drop some files here, or click to select files
                        </span>
                      </>
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          {files.length > 0 && (
            <ul
              role="list"
              className="bg-gray-200 my-4 p-6 rounded grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
            >
              {files.map((file, i) => (
                <li key={`media-${i}`} className="relative">
                  <div className="group block w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 overflow-hidden">
                    {file.data.type.includes("video") ? (
                      <video className="object-cover ml-auto mr-auto" controls>
                        <source
                          src={`${URL.createObjectURL(file.data)}`}
                          type={file.data.type}
                        />
                      </video>
                    ) : (
                      <img
                        src={`${URL.createObjectURL(file.data)}`}
                        alt={file.altText}
                        className="object-cover ml-auto mr-auto"
                      />
                    )}
                    <span
                      onClick={() => removeFile(i)}
                      className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 inline-flex items-center p-1 rounded-full text-xs font-medium bg-red-100 text-red-800 hover:cursor-pointer hover:bg-red-200"
                    >
                      <XIcon className="w-5 h-5" />
                    </span>
                  </div>
                  <p className="mt-2 block text-sm font-medium text-gray-500 pointer-events-none">
                    {bytesToSize(file.data.size)}
                  </p>
                  <div className="mt-2 block">
                    <input
                      value={file.name}
                      onChange={(e) =>
                        setFiles((oldFiles) => {
                          let withoutCurrent = oldFiles.filter(
                            (f) => f.data.name !== file.data.name
                          );

                          withoutCurrent.splice(i, 0, {
                            ...oldFiles[i],
                            name: e.target.value,
                          });

                          return withoutCurrent;
                        })
                      }
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Friendly Name"
                    />
                  </div>
                  <div className="mt-2 block">
                    <input
                      value={file.altText}
                      onChange={(e) =>
                        setFiles((oldFiles) => {
                          let withoutCurrent = oldFiles.filter(
                            (f) => f.data.name !== file.data.name
                          );

                          withoutCurrent.splice(i, 0, {
                            ...oldFiles[i],
                            altText: e.target.value,
                          });

                          return withoutCurrent;
                        })
                      }
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Short Description"
                    />
                  </div>
                  <div className="mt-2 block">
                    <input
                      value={file.attributionText}
                      onChange={(e) =>
                        setFiles((oldFiles) => {
                          let withoutCurrent = oldFiles.filter(
                            (f) => f.data.name !== file.data.name
                          );

                          withoutCurrent.splice(i, 0, {
                            ...oldFiles[i],
                            attributionText: e.target.value,
                          });

                          return withoutCurrent;
                        })
                      }
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Attribution text"
                    />
                  </div>
                </li>
              ))}
            </ul>
          )}

          <button
            onClick={uploadFiles}
            className="bg-etpink-600 text-sm text-white px-3 py-2 rounded"
          >
            Upload
          </button>
        </div>
      </div>
    );
  }
}
