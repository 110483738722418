import React, { useEffect, useState } from "react";
import classNames from "../../utils/classNames";

export default function EmailChips({ additionalEmails, setAdditionalEmails }) {
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (additionalEmails) {
      setItems(additionalEmails);
    }
  }, [])

  useEffect(() => {
    setAdditionalEmails(items);

  }, [items])

  const handleKeyDown = (evt) => {
    // console.log(evt)
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var tempValue = value.trim();

      if (tempValue && isValid(tempValue)) {
        setItems(oldItems => [...oldItems, tempValue]);
        setValue("");
      }
    }
  };

  const handleOnBlur = (evt) => {
    // console.log(evt)
    evt.preventDefault();

    var tempValue = value.trim();

    if (tempValue && isValid(tempValue)) {
      setItems(oldItems => [...oldItems, tempValue]);
      setValue("");
    }
  };

  const handleChange = (evt) => {
    setValue(evt.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    var filtered = items.filter(i => i !== item)
    setItems(filtered);
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));

      setItems(oldItems => [...oldItems, ...toBeAdded]);
    }
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address`;
    }

    if (error) {
      setError(error);

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <>
      {items.map(item => (
        <div className="p-2 mt-1 mr-3 inline-block text-sm bg-gray-200 rounded-full" key={item}>
          {item}
          <button
            type="button"
            className="rounded-full bg-white ml-2 w-5 h-5"
            onClick={() => handleDelete(item)}
          >
            &times;
          </button>
        </div>
      ))}

      <input
        type="text"
        className={classNames(error && "focus:border-red-400 focus:ring-red-400", "w-full mt-5 shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block sm:text-sm border-gray-300 rounded-md")}
        value={value}
        placeholder={`Type or paste an email address and press "Space" or "Enter"...`}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleOnBlur}
        onPaste={handlePaste}
      />

      {error && <p className="text-sm m-1 text-red-500">{error}</p>}
    </>
  );
}
