import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useUserNotificationsRecent() {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const {
    user: { user }
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const recentNotifications = useQuery(["notifications", user.id, "recent"], () => get(`/Notification/recent/${user.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const unseen = useQuery(["notifications", user.id, "unseen"], () => get(`/Notification/unseen/${user.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const markSeen = useMutation(
    (notificationIds) => put(`/Notification/markallseen`, notificationIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications", user.id])
      },
    }
  );

  const markRead = useMutation((notificationId) => put(`/Notification/${notificationId}/markread`, notificationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications", user.id])
      },
    }
  );

  const markAllRead = useMutation(
    (notificationIds) => put(`/Notification/markallread`, notificationIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications", user.id])
      },
    }
  );


  return {
    recentNotifications,
    unseen,
    markSeen,
    markRead,
    markAllRead,
  };
}
