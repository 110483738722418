import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import useAreaGuidePostcodes from "../../data/useAreaGuidePostcodes";
import useBranchPostcodes from "../../data/useBranchPostcodes";
import usePostcodeKeyStats from "../../data/usePostcodeKeyStats";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import classNames from "../../utils/classNames";
import LoadingWheel from "../Shared/LoadingWheel";


export default function AreaGuidePostcodesAddEditPartial({ area }) {
  const { postcodes: originalPostcodes, add, remove } = useAreaGuidePostcodes(area.id);
  const { outcodes: allOutcodes } = usePostcodeKeyStats();
  const [outcodes, setOutcodes] = useState(allOutcodes);
  const [postcodes, setPostcodes] = useState(originalPostcodes);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!originalPostcodes.isLoading) {
      setPostcodes(originalPostcodes.data);
    }

    if (!allOutcodes.isLoading && searchTerm.length > 0) {
      setOutcodes(
        allOutcodes.data.filter((outcode) =>
          outcode.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [originalPostcodes.isLoading, originalPostcodes.data, searchTerm])

  const handleAdd = (outcode) => {

    if (!postcodes.some(pc => pc.postcodeValue == outcode)) {

      var newPostcode = {
        postcodeValue: outcode,
        postcodeType: 3
      }

      add.mutate(newPostcode);
    }
  }

  const handleRemove = (postcode) => {
    remove.mutate(postcode.id);
  }

  if (postcodes.isLoading || originalPostcodes.isLoading || postcodes.data) {
    return (
      <div className="flex p-10 bg-white justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {

    return (
      <>
        <h1 className="text-lg">Postcodes: </h1>
        {!postcodes.length > 0 && <div className="pt-3">There are no postcodes attached to this area</div>}
        <ul className="grid grid-cols-8 gap-6 py-4">
          {postcodes.map((postcode) => (
            <li key={postcode.id} className="flex justify-between items-center rounded-md border p-1">
              {postcode.postcodeValue}
              <button onClick={() => handleRemove(postcode)}>
                <XIcon className="w-5 h-5 hover:text-red-500" />
              </button>
            </li>
          ))}
        </ul>
        <div>

          <Combobox
            onChange={(outcode) => handleAdd(outcode)}
          >
            {({ open }) => (
              <>
                <Combobox.Label className="block text-sm font-medium text-gray-700">
                  Postcode areas
                </Combobox.Label>
                <div className="relative mt-1">
                  <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-etpink-500 focus:outline-none focus:ring-1 focus:ring-etpink-500 sm:text-sm"
                    onChange={(e) =>
                      setSearchTerm(e.target.value)
                    }
                    displayValue={searchTerm}
                    placeholder="Start typing to search for a postcode"
                  />
                  <Transition
                    show={open}
                    appear={true}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options
                      static
                      className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {outcodes.length > 0 && searchTerm.length > 0 ? (
                        outcodes.map((outcode, i) => (
                          <Combobox.Option
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-etpink-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-8 pr-4"
                              )
                            }
                            key={i}
                            value={outcode}
                          >
                            <span className="block truncate">
                              {outcode}
                            </span>
                          </Combobox.Option>
                        ))
                      ) : (
                        <Combobox.Option
                          disabled
                          className="cursor-default select-none relative py-2 text-center text-gray-500"
                        >
                          No postcodes match the search terms
                        </Combobox.Option>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Combobox>
        </div>
      </>
    );
  }

}