import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useServices() {
  const staleTime = 1000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const services = useQuery(
    ["services"],
    () => get(`/services`).then((res) => res.data),
    {
      staleTime: staleTime,
      refetchInterval: staleTime,
    }
  );

  const runNow = useMutation((serviceId) => post(`/services/${serviceId}`,),
    {
      onSuccess: () => { 
        queryClient.invalidateQueries(["services"]);
      },
    }
  );

  return {
    services,
    runNow
  };
}
