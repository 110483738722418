

export function getAddress(property) {
  let address = "";

    if (property.addressNameNumber) {
      address += `${property.addressNameNumber} `;
    }

    if (property.addressStreet) {
      address += `${property.addressStreet}, `;
    }

    if (property.addressTwo) {
      address += `${property.addressTwo}, `;
    }

    if (property.addressThree) {
      address += `${property.addressThree}, `;
    }

    if (property.addressFour) {
      address += `${property.addressFour}, `;
    }
    
    //Remove last comma and any whitespace after it
    address = address.replace(/,\s*$/, "");

    return address;
}

export function getFullAddress(property) {
  let address = "";

    if (property.addressNameNumber) {
      address += `${property.addressNameNumber} `;
    }

    if (property.addressStreet) {
      address += `${property.addressStreet}, `;
    }

    if (property.addressTwo) {
      address += `${property.addressTwo}, `;
    }

    if (property.addressThree) {
      address += `${property.addressThree}, `;
    }

    if (property.addressFour) {
      address += `${property.addressFour}, `;
    }

    if (property.addressPostcode) {
      address += `${property.addressPostcode}`;
    }
    
    return address;
}

export function getValuationFullAddress(valuation) {
  let address = "";

    if (valuation.houseNumber) {
      address += `${valuation.houseNumber} `;
    }

    if (valuation.street) {
      address += `${valuation.street}, `;
    }

    if (valuation.town) {
      address += `${valuation.town}, `;
    }

    if (valuation.postcode) {
      address += `${valuation.postcode}, `;
    }
    
    return address;
}