import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function usePropertiesSingle(propertyId) {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const property = useQuery(["properties", agency.id, propertyId], () => get(`/Property/${agency.id}/${propertyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const updateNotes = useMutation((mutateData) => put(`/Property/${agency.id}/${mutateData.propertyId}`, mutateData.newNote), {
    onSuccess: (updatedProperty) => {
      queryClient.setQueryData(["properties", agency.id], (currrentProperties) =>
        currrentProperties.map((property) =>
          property.id === updatedProperty.data.id ? updatedProperty.data : property
        )
      );
    },
  });

  const removeNote = useMutation((ids) => put(`/Property/${agency.id}/${ids.propertyId}/${ids.noteId}`), {
    onSuccess: (_, ids) => {
      queryClient.setQueryData(["properties", agency.id], (currentProperties) =>
        currentProperties.map((property) => {
          if (property.notes.some(c => c.id === ids.noteId)) {
            property.notes = property.notes.filter(note => note.id !== ids.noteId);
            return property;
          } else {
            return property;
          }
        })
      );
    },
  });


  return {
    property,
    updateNotes,
    removeNote,
    // add,
    // update,
    // remove,
  };
}
