import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import useApiHelper from "../../services/useApiHelper";

export default function UnsplashGallery({ searchTerms, onError, onLoading, onImagesFetched, onImageSelected }) {
  const { post, formData } = useApiHelper();
  const queryClient = useQueryClient();
  const [searchQuery, setSearchQuery] = useState(searchTerms)
  const [images, setImages] = useState(undefined)
  const [selectedImage, setSelectedImage] = useState(undefined)
  const [page, setPage] = useState(1)
  const [query, setQuery] = useState(searchTerms)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      keywords: ""
    },
  });


  const onSubmit = (data) => {
    setSearchQuery(data.keywords)
  };

  const insertImage = () => {
    if (selectedImage) {
      onLoading()
      uploadImage(selectedImage).then(imageId => onImageSelected(imageId))
    }
  }

  const uploadImage = (image) => {
    return new Promise((resolve, reject) => {
      fetch(image.urls.regular).then(response => response.blob()).then(blob => {
        post("/media", {
          name: image.description ?? "",
          filename: `${image.user.name.trim()}${image.description ? "-" + image.description.trim() : ""}.jpg`,
          type: blob.type,
          size: blob.size,
          altText: image.alt_description.trim(),
          attributionText: attributionText(image).__html
        })
          .then((res) => {
            let fd = new FormData();
            fd.append("media", blob, res.data.filename);
            formData(`/media/${res.data.id}`, fd).then((res2) => {
              // trigger Unsplash download event here
              // there's no need to wait for a response
              post('/unsplashgallery/triggerDownload', image.id)
              queryClient.invalidateQueries("media");
              resolve(res.data.id);
            });
          })
          .catch((err) => {
            console.error(err);
            reject();
          });
      })
    });
  };

  const attributionText = (image) => {
    return {
      __html:
        `<a href="https://unsplash.com/@${image.user.username}?utm_source=EstateTrack&utm_medium=referral" title="Photo by ${image.user.name} on Unsplash" target="_blank">${image.user.name}</a>`
    }
  }

  const searchImage = (query, page) => {
    onLoading()
    const searchData = {
      searchQuery: query,
      resultsPerPage: 12,
      page: page
    }
    post(`/unsplashgallery/search`, searchData).then(response => {
      if (Object.hasOwn(response, 'data') && response.data.results.length > 0) {
        const { results } = response.data;
        setSelectedImage(undefined);
        setImages(results)
        onImagesFetched()
      } else {
        onError("We couldn't find any images for these keywords. Please try different keywords.")
      }
    }).catch(error => {
      onError(`There was an error fetching images. Please try again. Error: ${error.message}`)
    })
  }

  const changePage = (page) => {
    setImages(undefined)
    setSelectedImage(undefined)
    setPage(page)
    searchImage(query, page)
  }

  useEffect(() => {
    if (searchQuery) {
      
      searchImage(query, page)
    }
  }, [searchQuery, onLoading, onImagesFetched, onError])


  useEffect(() => {
    return () => {
      setSearchQuery("")
      setImages(undefined)
      setSelectedImage(undefined)
    }
  }, [])

  return (
    <>
      <p className="mt-6">Select an image from the list below to attach to your article.</p>

      <div className="my-6">
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Not happy with these results?</h3>
          <div className="mt-2 max-w-xl text-sm text-gray-500">
            <p>Enter a few keywords below and try a different search.</p>
          </div>
          <form className="mt-5 sm:flex sm:items-center" onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full sm:max-w-xs">
              <label htmlFor="keywords" className="sr-only">
                Keywords
              </label>
              <input
                type="text"
                {...register("keywords", { required: true })}
                value={query}
                onChange={(e) => { setQuery(e.target.value); setPage(1); }}
                id="photo-search-keywords"
                className="block sm:text-sm w-full rounded-md py-1.5 text-gray-900 shadow-sm focus:ring-etpink-400 focus:border-etpink-400 border-gray-300 sm:leading-6"
                placeholder="housing trends, housing market"
              />
            </div>
            <button
              type="button"
              onClick={()=>searchImage(query, page)}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto"
            >
              Search
            </button>
          </form>
        </div>
      </div>

      {images &&
        <>
          <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 items-center">
            {images.map(image => (
              <li key={image.id} className="relative">
                <div className={`relative group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 border-etpink-600 ${selectedImage && image.id === selectedImage.id ? 'border-4' : ''}`}>
                  <img
                    src={image.urls.small}
                    alt={image.alt_description}
                    className="cursor-pointer object-cover group-hover:opacity-75"
                    title="Click to select this image"
                    onClick={() => setSelectedImage(image)}
                  />
                  <span className="text-xs px-4 py-1 absolute bottom-0 left-0 text-white w-full bg-black/50" dangerouslySetInnerHTML={attributionText(image)}>
                  </span>
                </div>
              </li>
            ))}
          </ul>


          <div className="text-center mt-6 gap-5">
            <button
              type="submit"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={()=>changePage(page - 1)}
            >
              &lt;
            </button>
            <button
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
            >
              {page}
            </button>
            <button
              type="submit"
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={()=>changePage(page + 1)}
            >
              &gt;
            </button>
          </div>

          <div className="text-center mt-6">
            <button
              type="submit"
              disabled={!selectedImage}
              className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-etpink-600 text-white hover:bg-etpink-700 focus:ring-etpink-500 px-3 py-2 text-sm font-semibold shadow-sm sm:ml-3 sm:mt-0 sm:w-auto  disabled:bg-gray-50 disabled:text-gray-500 disabled:font-normal"
              onClick={insertImage}
            >
              Insert selected image
            </button>
          </div>

        </>
      }
    </>
  )
}
