import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition, Combobox } from "@headlessui/react";

import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import classNames from "../../utils/classNames";
import useAgencyUsers from "../../data/useAgencyUsers";

export default function DepartmentAddUserModal({
  open,
  setOpen,
  agencyId,
  currentUsers,
  onAddClick,
}) {
  const { users: allUsers } = useAgencyUsers(agencyId);
  const [filteredUsers, setFilteredUsers] = useState();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    if (!allUsers.isLoading) {
      let currentUserIds = currentUsers.map((cu) => cu.userId);
      let users = allUsers.data.map((u) => {
        currentUserIds.includes(u.id)
          ? (u.avaliable = false)
          : (u.avaliable = true);
        return u;
      });

      let filtered =
        searchTerm === ""
          ? users
          : users.filter(
              (u) =>
                u.user.firstName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase()) ||
                u.user.surname.toLowerCase().includes(searchTerm.toLowerCase())
            );

      setFilteredUsers(filtered);
    }
  }, [allUsers.isLoading, allUsers.data, searchTerm, currentUsers]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-0 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Add Users
                    </Dialog.Title>
                    <div className="my-4">
                      {allUsers.isLoading || !filteredUsers ? (
                        <div className="py-4 flex justify-center">
                          <LoadingWheel width="w-6" height="h-6" />
                        </div>
                      ) : (
                        <Combobox
                          value={selectedUsers}
                          onChange={setSelectedUsers}
                          multiple
                        >
                          {({ open }) => (
                            <>
                              <Combobox.Label className="block text-sm font-medium text-gray-700">
                                Users
                              </Combobox.Label>
                              <div className="relative mt-1">
                                <Combobox.Input
                                  className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-etpink-500 focus:outline-none focus:ring-1 focus:ring-etpink-500 sm:text-sm"
                                  onChange={(e) =>
                                    setSearchTerm(e.target.value)
                                  }
                                  displayValue={(users) =>
                                    users
                                      .map((user) => `${user.user.firstName} ${user.user.surname}`)
                                  }
                                />
                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                  <SelectorIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Combobox.Button>
                                <Transition
                                  show={open}
                                  appear={true}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Combobox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {filteredUsers.length > 0 ? (
                                      filteredUsers.map((u) => (
                                        <Combobox.Option
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-etpink-600"
                                                : !u.avaliable
                                                ? "bg-gray-100 text-gray-400"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-8 pr-4"
                                            )
                                          }
                                          key={u.id}
                                          value={u}
                                          disabled={!u.avaliable}
                                        >
                                          {({ active, selected }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  "block truncate",
                                                  selected && "font-semibold"
                                                )}
                                              >
                                                {`${u.user.firstName} ${u.user.surname}`}
                                              </span>

                                              {selected && (
                                                <span
                                                  className={classNames(
                                                    "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                                    active
                                                      ? "text-white"
                                                      : "text-etpink-600"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </Combobox.Option>
                                      ))
                                    ) : (
                                      <Combobox.Option
                                        disabled
                                        className="cursor-default select-none relative py-2 text-center text-gray-500"
                                      >
                                        No users match the search terms
                                      </Combobox.Option>
                                    )}
                                  </Combobox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Combobox>
                      )}
                    </div>
                    <div className="mt-6 flex justify-end">
                      <button
                        onClick={() => onAddClick(selectedUsers)}
                        type="button"
                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:w-auto sm:text-sm"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
