import useAuthService from "./useAuthService";
import { apiUrl } from "./config";


export default function useApiHelper() {
  const { getUser, refreshToken } = useAuthService();
  // const apiUrl = "https://estatetrack-api.azurewebsites.net";
  // const apiUrl = "https://localhost:7259";

  function handleAuthRepsonse(res) {
    return res.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!res.ok) {

        if (res.status === 401) {
          refreshToken();
        }
  
        console.error(
          `Resource API Error: ${res.url} - ${res.status} ${res.statusText}`
        );
        return Promise.reject(data);
      } else {
        return data;
      }
    });
  }

  const authHeader = () => {
    let user = getUser();

    if (user.identity) {
      return { Authorization: `Bearer ${user.identity.jwtToken}` };
    }
  };

  const buildPath = (path) => {
    return `${apiUrl}/api${path}`;
  };

  const get = async (path) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", ...authHeader() },
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const post = async (path, data) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", ...authHeader() },
        body: JSON.stringify(data),
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const put = async (path, data) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json", ...authHeader() },
        body: JSON.stringify(data),
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const patch = async (path, data) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json", ...authHeader() },
        body: JSON.stringify(data),
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const del = async (path, data) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", ...authHeader() },
        body: JSON.stringify(data),
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  const formData = async (path, data) =>
    new Promise((resolve, reject) => {
      let requestOptions = {
        method: "POST",
        headers: { ...authHeader() },
        body: data,
      };

      return fetch(buildPath(path), requestOptions)
        .then(handleAuthRepsonse)
        .then((data) => resolve(data))
        .catch((err) => reject(err));
    });

  return {
    get,
    post,
    put,
    patch,
    del,
    formData,
  };
}
