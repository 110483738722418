import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, Transition, Listbox } from "@headlessui/react";

import useValuations from "../../data/useValuations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import EmailChips from "../Shared/EmailChips";
import useViewings from "../../data/useViewings";

export default function ViewingUpdateModal({ open, setOpen, viewing }) {
  const cancelButtonRef = useRef(null);
  const { update, notifyClient } = useViewings();
  const { saveHandlers } = useDefaultCRUDHandlers("Valuation");
  const { register, getValues, setValue, watch, handleSubmit, reset } =
    useForm({
      defaultValues: {
        date: viewing.date,
        time: viewing.time
      },
    });
  const [notify, setNotify] = useState(false);

  const onSubmit = (data) => {

    if (notify) {
      notifyClient.mutate({
        ...viewing,
        date: data.date,
        time: data.time
      }, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      });
    } else {
      update.mutate({
        ...viewing,
        date: data.date,
        time: data.time
      }, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setOpen(false);
        },
        onError: saveHandlers.onError,
      });
    }

    reset({
      date: data.date,
      time: data.time
    });

    setNotify(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Update Viewing Date and Time
                    </Dialog.Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="mt-8">
                        <label className="mb-2 block text-sm font-medium text-gray-700">
                          Date
                        </label>
                        <input {...register("date")}
                          type="text"
                          className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="mt-4">
                        <label className="mb-2 block text-sm font-medium text-gray-700">
                          Time
                        </label>
                        <input {...register("time")}
                          type="text"
                          className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="mt-8 flex justify-between">
                        <div>
                          <button
                            type="button"
                            onClick={() => setOpen(false)}
                            className="px-3 py-3 mr-3 mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                          >
                            Save
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            onClick={() => setNotify(true)}
                            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                          >
                            Save & Notify Client
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
