import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import date from "date-and-time";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentTextIcon,
  EyeIcon,
  FilterIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import {
  valuationSourceEnum,
  valuationStatusEnum,
  valuationTypeEnum,
} from "../../constants/enums";
import useValuations from "../../data/useValuations";
import BranchListBox from "../../components/Shared/BranchListBox";
import ValuationStatusDropdown from "../../components/Valuations/ValuationStatusDropdown";
import useAgencyBranches from "../../data/useAgencyBranches";
import useUserContext from "../../contexts/UserContext";
import Avatar from "../../components/Shared/Avatar";
import classNames from "../../utils/classNames";
import ValuationAssignee from "../../components/Valuations/ValuationAssignee";
import { getValuationFullAddress } from "../../utils/getAddress";
import SalesOrLettingsListBox from "../../components/Shared/SalesOrLettingsListBox";

function ValuationRow({ valuation, onDeleteClick, branches }) {
  const navigate = useNavigate();
  const [displayBranch, setDisplayBranch] = useState(null);

  useEffect(() => {
    if (!branches.isLoading) {
      if (valuation.branchId) {
        var foundBranch = branches.data.find((b) => b.id === valuation.branchId);
        if (foundBranch) {
          setDisplayBranch(foundBranch.name);
        }

      } else {
        setDisplayBranch("None")
      }

    }
  }, [branches.isLoading, branches.data])

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(valuation.id);
  };

  return (
    <tr
      onClick={() => navigate(`valuation/${valuation.id}`)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="hidden w-full max-w-0 py-4 px-2 sm:w-auto sm:max-w-none md:table-cell">
        {valuation.userId &&
          <Avatar classes="w-10 h-10" userId={valuation.userId} />
        }
      </td>
      <td className="font-medium text-gray-900">
        <p>{getValuationFullAddress(valuation)}</p>
        <p className="text-gray-400 text-sm">{date.format(new Date(valuation.created), "DD/MM/YYYY")} - {valuationSourceEnum[valuation.valuationSource]} - {valuationTypeEnum[valuation.valuationType]}</p>
        <dt className="sr-only md:hidden">Status</dt>
        <dd className="mt-1 truncate text-gray-500 md:hidden">
          <span
            className={`text-xs rounded-full px-4 py-1 whitespace-nowrap font-bold 
          ${valuation.status === 0 ? ("text-yellow-600 bg-yellow-100") :
                (valuation.status === 1 ?
                  ("text-red-600 bg-red-100")
                  : ("text-green-600 bg-green-100")
                )}`}
          >
            {valuationStatusEnum[valuation.status]}
          </span>
        </dd>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 lg:table-cell">
        {displayBranch}
      </td>
      <td className="hidden px-3 py-4 text-sm md:table-cell">
        <span
          className={`text-xs rounded-full px-4 py-1 whitespace-nowrap font-bold 
          ${valuation.status === 0 ? ("text-yellow-600 bg-yellow-100") :
              (valuation.status === 1 ?
                ("text-red-600 bg-red-100")
                : ("text-green-600 bg-green-100")
              )}`}
        >
          {valuationStatusEnum[valuation.status]}
        </span>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {valuation.sourceName}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {valuation.followUpDate ? date.format(new Date(valuation.followUpDate), "DD/MM/YYYY") : "N/A"}
      </td>
      <td className={classNames(valuation.updatedAppointment ? "text-black" : "text-etpink-500", "px-3 py-4 text-sm text-gray-500 sm:table-cell")}>
        {
          valuation.appointmentDateTime !== "0001-01-01T00:00:00" && valuation.appointmentDateTime ? date.format(new Date(valuation.appointmentDateTime), "DD/MM/YYYY")
            :
            <span className="text-black">N/A</span>
        }
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        {valuation.viewed && (
          <button
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500"
          >
            <span className="sr-only">Viewed</span>
            <EyeIcon className="h-5 w-5 text-etpink-600" />
          </button>
        )}
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            // onClick={() => navigate(`valuation/${valuation.id}`)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={handleDeleteClick}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2  text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Valuations() {
  const navigate = useNavigate();
  const { valuations: allValuations, remove } = useValuations();
  const { user: { agency }, } = useUserContext();
  const { branches } = useAgencyBranches(agency.id);
  const { deleteHandlers } = useDefaultCRUDHandlers("Valuation");
  const [valuations, setValuations] = useState(allValuations);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [branch, setBranch] = useState(null);
  const [departmentFilter, setDepartment] = useState("All");
  const [status, setStatus] = useState(null);
  const [assignee, setAssignee] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState(true);
  const [sortTerm, setSortTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const deleteValuation = (post) => {
    setDeleteId(post);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      if (!sortDirection) {
        setSortTerm(null);
      }
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Valuations";
    if (!allValuations.isLoading && !branches.isLoading) {

      //Concatenate whole address and remove spaces for both
      var filter = allValuations.data.filter((valuation) =>
        "".concat(valuation.houseNumber, valuation.street, valuation.town, valuation.postcode)
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(searchTerm.replace(/\s/g, '').toLowerCase())
      )

      if (branch) {
        filter = filter.filter((val) => {
          if (val.branchId) {
            return val.branchId.includes(branch.id)
          }
        })
      }

      if (departmentFilter !== "All") {
        var valType = departmentFilter === "Sales" ? 1 : 2;
        if (departmentFilter === "Combined") valType = 0;
        filter = filter.filter((valuation) => {
          return valuation.valuationType === valType
        })
      }

      if (status !== null) {
        filter = filter.filter((valuation) => {
          if (status === -1) {
            return valuation;
          } else {
            if (status === valuation.status) {
              return valuation;
            }
          }
        })
      }

      if (assignee) {
        filter = filter.filter((valuation) => {
          if (valuation.userId) {
            return valuation.userId.includes(assignee)
          }
        })
      }

      //Sorts the dates and puts the nulls at the end
      if (sortTerm && !sortDirection) {
        filter.sort((a, b) => {
          if (a[sortTerm] === null && b[sortTerm] === null) {
            return 0;
          } else if (a[sortTerm] === null) {
            return 1;
          } else if (b[sortTerm] === null) {
            return -1;
          } else {
            return new Date(b[sortTerm]).getTime() - new Date(a[sortTerm]).getTime();
          }
        });
      } else if (sortTerm && sortDirection) {
        filter.sort((a, b) => {
          if (a[sortTerm] === null && b[sortTerm] === null) {
            return 0;
          } else if (a[sortTerm] === null) {
            return 1;
          } else if (b[sortTerm] === null) {
            return -1;
          } else {
            return new Date(a[sortTerm]).getTime() - new Date(b[sortTerm]).getTime();
          }
        });
      }

      setValuations(filter);
      setIsLoading(false);
    }
  }, [
    allValuations.isLoading,
    allValuations.data,
    branches.isLoading,
    branches.data,
    searchTerm,
    branch,
    departmentFilter,
    status,
    assignee,
    sortTerm,
    sortDirection
  ]);

  return (
    <>
      <ConfirmDelete
        itemName="Valuation"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Valuations</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all valuations.
            </p>
          </div>

          <div className="flex px-2">
            <button
              type="button"
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              onClick={() => setFilterOpen(!filterOpen)}
            >
              <FilterIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
              <span>Filter</span>
            </button>
          </div>

          <div>
            <div className="flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Address"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="book"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Book Valuation
            </Link>
          </div>
        </div>

        {/* Filters hidden */}
        <div id="filters-section" className="pt-2 transition-all ease-in-out delay-250 duration-3000">
          {filterOpen && (
            <div className="grid grid-cols-5 gap-5">
              <BranchListBox setBranch={setBranch} />
              <SalesOrLettingsListBox setDepartment={setDepartment} combined />
              <ValuationStatusDropdown setStatus={setStatus} />
              <ValuationAssignee setUser={setAssignee} />
            </div>
          )}
        </div>

        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : valuations.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    {/* Blank */}
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Address
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Branch
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Source
                  </th>
                  <th
                    onClick={() => handleSort("followUpDate")}
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-etpink-700 lg:table-cell cursor-pointer"
                  >
                    <span className="flex">Follow Up {sortTerm === "followUpDate" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>

                  </th>
                  <th
                    onClick={() => handleSort("appointmentDateTime")}
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-etpink-700 lg:table-cell cursor-pointer"
                  >
                    <span className="flex">Appointment {sortTerm === "appointmentDateTime" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {valuations.map((valuation) => (
                  <ValuationRow
                    key={valuation.id}
                    branches={branches}
                    valuation={valuation}
                    onDeleteClick={deleteValuation}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="valuation"
                  onNewClick={() => navigate("valuation")}
                />
              ) : (
                <NoItems searching itemName="valuation" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
