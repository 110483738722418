import { Fragment } from "react";

import { useForm } from "react-hook-form";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";

import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import classNames from "../../utils/classNames";

export default function StatusDropdown({ lead, statusEnum, update, saveHandler, classes = null }) {
  const { saveHandlers } = useDefaultCRUDHandlers(saveHandler);
  const { register, getValues, setValue, watch } = useForm({ defaultValues: { status: lead.status }, });

  const statuses = Object.values(statusEnum).map((s, i) => ({
    name: s,
    value: i,
  }));

  const statusValue = watch("status");

  const updateStatus = (value) => {
    setValue("status", value);

    update.mutate(
      {
        ...lead,
        status: value,
      },
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
        },
        onError: () => {
          saveHandlers.onError();
        }
      }
    );

  }

  return (
    <form className={classes}>
      <Listbox
        {...register("status")}
        value={statusValue}
        onChange={(v) => updateStatus(v)}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              Status
            </Listbox.Label>
            <div className="mt-1 relative min-w-[150px]">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm">
                <span className="block truncate">
                  {
                    statuses.find(
                      (s) => s.value === statusValue
                    )?.name
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {statuses.map((s) => (
                    <Listbox.Option
                      key={s.value}
                      className={({ active }) =>
                        classNames(
                          active
                            ? "text-white bg-etpink-600"
                            : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={s.value}
                    >
                      <>
                        <span
                          className={classNames(
                            getValues("status") ===
                              s.value
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {s.name}
                        </span>

                        {getValues("status") ===
                          s.value ? (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </form>
  )
}