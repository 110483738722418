import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function usePostcodeKeyStats() {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const postcodeKeyStats = useQuery(["postcodeKeyStats", "stats"], () => get(`/PostcodeKeyStats`).then((res) => res.data),
    {
      staleTime: staleTime * 100,
    }
  );

  const outcodes = useQuery(["postcodeKeyStats", "outcodes"], () => get(`/PostcodeKeyStats/outcodes`).then((res) => res.data),
  {
    staleTime: staleTime * 100,
  }
);

  // const add = useMutation((branch) => post("/branch", branch), {
  //   onSuccess: (addedBranch) => {
  //     queryClient.setQueryData(["branches", branchId], (currentBranches) => [
  //       addedBranch.data,
  //       ...currentBranches,
  //     ]);
  //   },
  // });

  // const update = useMutation((branch) => put(`/branch/${branch.id}`, branch), {
  //   onSuccess: (updatedBranch) => {
  //     queryClient.setQueryData(["branches", branchId], (currrentBranches) =>
  //       currrentBranches.map((branch) =>
  //         branch.id === updatedBranch.data.id ? updatedBranch.data : branch
  //       )
  //     );
  //   },
  // });

  // const remove = useMutation((branchId) => del(`/branch/${branchId}`), {
  //   onSuccess: (_, branchId) => {
  //     queryClient.setQueryData(["branches", branchId], (currentBranches) =>
  //       currentBranches.filter((branch) => branch.id !== branchId)
  //     );
  //   },
  // });

  return {
    postcodeKeyStats,
    outcodes,
    // add,
    // update,
    // remove,
  };
}
