import Modal from "../Shared/Modal";

import EstateTrackStandingStan from "../../assests/EstateTrackStandingStan.jpg";

export default function ContactSupport({ open, setOpen }) {

  return (
    <Modal open={open} setOpen={setOpen} width="max-w-4xl">
      <div className="px-4 sm:px-6 lg:px-8 mx-auto flex justify-center">


        <img src={EstateTrackStandingStan} className="w-28 mr-6" />
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl font-semibold text-gray-900 text-center mb-10">Want to expand your horizons?</h1>
          <h1 className="text-2xl text-center">Please contact support to get your extra services activated now!</h1>
          <span className="mt-4 flex justify-center">
            <a
              href="https://estatetrack.co.uk/contact-support/"
              target={"_blank"}
              className="mr-2 rounded-full px-4 py-3 my-6 border border-transparent text-sm leading-4 font-medium shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
            >
              <span className="sr-only">Contact Support</span>
              Contact Us
            </a>
          </span>
        </div>

      </div>

    </Modal>
  )
}