
export const JsonSelectInput = ({ element }) => {

  return (
    <div className="">
      <label className="block mb-2 text-sm font-medium text-gray-700">
        {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
      </label>
      <select
        className="bg-white text-gray-500 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"
        placeholder={element.placeholder}
      >
        {element.placeholder && <option className="text-gray-500" value="" disabled selected>{element.placeholder}</option>}
        {element.options.map((option, i) => {
          return (
            <option key={i} selected={i === element.defaultOption} value={option}>
              {option}
            </option>
          )
        })}
      </select>
    </div>
  )
}