import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useVideoReviews() {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const videoReviews = useQuery(
    ["videoReviews", agency.id],
    () => get(`/videoreview/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (review) => post(`/videoreview/${agency.id}`, review),
    {
      onSuccess: (addedReview) => {
        queryClient.setQueryData(
          ["videoReviews", agency.id],
          (currentReviews) => [addedReview.data, ...currentReviews]
        );
      },
    }
  );

  const update = useMutation(
    (videoReview) =>
      put(`/videoreview/${agency.id}/${videoReview.id}`, videoReview),
    {
      onSuccess: (updatedVideoReview) => {
        queryClient.setQueryData(
          ["videoReviews", agency.id],
          (currentVideoReviews) =>
            currentVideoReviews.map((cvr) =>
              cvr.id === updatedVideoReview.id ? updatedVideoReview.data : cvr
            )
        );
      },
    }
  );

  const remove = useMutation(
    (videoReviewId) => del(`/videoreview/${agency.id}/${videoReviewId}`),
    {
      onSuccess: (_, videoReviewId) => {
        queryClient.setQueryData(
          ["videoReviews", agency.id],
          (currentVideoReviews) =>
            currentVideoReviews.filter((cvr) => cvr.id !== videoReviewId)
        );
      },
    }
  );

  return {
    videoReviews,
    add,
    update,
    remove,
  };
}
