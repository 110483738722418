import DefaultLeftSide from "./DefaultLeftSide"
import DefaultRightSide from "./DefaultRightSide"

export const JsonNumberConfig = ({ register }) => {

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

          <DefaultLeftSide register={register} placeholder />

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        <DefaultRightSide register={register} />

        <div className="w-full flex">
          <input
            type="checkbox"
            {...register("currency")}
            className="w-5 h-5 mt-1 mr-2"
          />
          <label className="block mt-1 text-sm font-medium text-gray-700">
            £ Prefix
          </label>
        </div>

      </div>

    </div>
  )
}