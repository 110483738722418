
import React, { useEffect, useRef, useState } from "react";
import LoadingWheel from "../../components/Shared/LoadingWheel";

import { withAgency } from "../../data/withAgency";
import useWebsiteReports from "../../data/useWebsiteReports";
import WebsiteAnalyticsGraph from "../../components/Analytics/Website/WebsiteAnalyticsGraph";


function _WebsiteAnalytics({ agencyReady, agency }) {
  const { websiteStats } = useWebsiteReports(agency?.id);
  const [isLoading, setIsLoading] = useState(true);
  const [isTotal, setIsTotal] = useState(false);

  useEffect(() => {
    if (agencyReady && !websiteStats.isLoading && websiteStats.data) {
      // console.log(websiteStats.data)
      setIsLoading(false);
    }
  }, [agency, agencyReady, websiteStats.data, websiteStats.isLoading, isTotal])

  return (
    <div className="px-12">
      <div className="sm:flex sm:items-center justify-between">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Website Analytics</h1>
          <p className="mt-2 text-sm text-gray-700">
            Details about visitor numbers, page views, sources and more.
          </p>
        </div>
        <div>
          {/* <select onChange={(e) => setIsTotal(e.target.value === "true")}>
            <option value={"false"}>Last 30 Days</option>
            <option value={"true"}>All</option>
          </select> */}
        </div>
      </div>

      {isLoading ? (
        <div className="py-2 col-span-1 lg:col-span-4 flex justify-center items-center h-40 bg-white rounded-lg shadow">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <>
          <div className="mt-4 grid grid-cols-3 gap-8">
            <div className="p-6 col-span-3 lg:col-span-2 bg-white rounded-lg shadow">
              <h1 className="text-base font-normal text-gray-800 lg:pt-2">Website Stats - Last 30 Days</h1>
              <hr className="" />
              <div className="mt-6">
                <WebsiteAnalyticsGraph stats={websiteStats.data} />
              </div>
            </div>

            <div className="p-6 col-span-3 lg:col-span-1 bg-white rounded-lg shadow">
              <div>
                <h1 className="mx-4 text-base font-normal text-gray-800 lg:pt-2">Average Website Stats - Last 30 Days</h1>
                <hr className="mx-4" />
              </div>

              <div className="divide-y space-y-2">

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Total Visitors
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.visitors30Days}
                  </dd>
                </div>

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Total Visits
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.visits30Days}
                  </dd>
                </div>

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Total Page Views
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.pageViews30Days}
                  </dd>
                </div>

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Views Per Visits
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.viewsPerVisit30Days.toFixed(2)}
                  </dd>
                </div>

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Bounce Rate (%)
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.bounceRate30Days}
                  </dd>
                </div>

                <div className="pt-2 flex flex-col items-center px-4">
                  <dt className="font-medium text-gray-700 truncate">
                    Visit Duration (Seconds)
                  </dt>
                  <dd className="mt-1 text-3xl font-semibold text-etpink-600">
                    {websiteStats.data.visitDuration30Days}
                  </dd>
                </div>

              </div>

            </div>

          </div>
        </>
      )}

      {isLoading ? (
        <div className="py-2 mt-8 col-span-1 lg:col-span-4 flex justify-center items-center h-40 bg-white rounded-lg shadow">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <>
          <div className="mt-8 grid grid-cols-2 gap-8">
            <div className="p-6 col-span-2 lg:col-span-1 bg-white rounded-lg shadow">
              <h1 className="text-base font-normal text-gray-800 lg:pt-2 flex justify-between">
                <span>Top Sources - Last 30 Days</span>
                <span>Visitors</span>
              </h1>
              <hr className="" />

              <div className="mt-4 divide-y">
                {websiteStats.data.topSources.map((source, index) => (

                  <div key={index} className="flex justify-between pt-2">
                    <dt className="font-medium text-gray-700 truncate">
                      {source.source === "Direct / None" ? "Direct" : source.source}
                    </dt>
                    <dd className="mt-1 text-xl font-semibold text-etpink-600">
                      {source.visitors}
                    </dd>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-6 col-span-2 lg:col-span-1 bg-white rounded-lg shadow">
              <h1 className="text-base font-normal text-gray-800 lg:pt-2 flex justify-between">
                <span>Top Pages  - Last 30 Days</span>
                <span>Visitors</span>
              </h1>
              <hr className="" />

              <div className="mt-4 divide-y">
                {websiteStats.data.topPages.map((page, index) => (

                  <div key={index} className="flex justify-between pt-2">
                    <dt className="font-medium text-gray-700 truncate">
                      <a href={`${agency.domain}/${page.page}`} target="_blank">{page.page === "/" ? "home" : page.page}</a>
                    </dt>
                    <dd className="mt-1 text-xl font-semibold text-etpink-600">
                      {page.visitors}
                    </dd>
                  </div>
                ))}
              </div>
            </div>

          </div>
        </>
      )}

    </div>
  )

}


export const WebsiteAnalytics = withAgency(_WebsiteAnalytics);