import { useEffect, useState } from "react";
import { RefreshIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";
import Flyout from "../../containers/Flyout";
import UserInviteAddPartial from "./UserInviteAddPartial";

import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAgencyUserInvites from "../../data/useAgencyUserInvites";
import useNotification from "../notification/useNotifications";
import useUserContext from "../../contexts/UserContext";

function UserInviteListRow({ userInvite, onResendClick, onOldPortalUserClick, onRemoveClick }) {
  const { user: { agency }, } = useUserContext();

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="">
        <p className="text-sm font-medium text-etpink-600">
          {userInvite.email}
        </p>
      </div>
      <div className="ml-4 flex items-center -mr-2">
        <button
          onClick={() => onRemoveClick(userInvite.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Delete</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <button
          onClick={() => onResendClick(userInvite)}
          className="mx-2 text-gray-400 hover:text-gray-600"
        >
          <span className="sr-only">Resend</span>
          <RefreshIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {!agency && (
          <button
            onClick={() => onOldPortalUserClick(userInvite)}
            className="px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
          >
            <span className="sr-only">Resend</span>
            OLD PORTAL USER INVITE
          </button>
        )}
      </div>
    </li>
  );
}

export default function UserInviteListPartial({ agencyId }) {
  const {
    userInvites,
    add: addInvite,
    resend: resendInvite,
    remove: removeInvite,
    oldUserInvite
  } = useAgencyUserInvites(agencyId);
  const { addNotification } = useNotification();
  const { deleteHandlers } = useDefaultCRUDHandlers("Invite");
  const [invites, setInvites] = useState(userInvites);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const resend = (invite) =>
    resendInvite.mutate(invite, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Resent invite",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Failed to resend invite",
        });
      },
    });

  const oldUserResend = (invite) =>
    oldUserInvite.mutate(invite, {
      onSuccess: () => {
        addNotification({
          variant: "success",
          primaryText: "Sent invite",
        });
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Failed to resend invite",
        });
      },
    });

  const add = (invite) => {
    setOpen(false);
    addInvite.mutate(
      { ...invite, agencyId: agencyId },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Sent invite",
          });
          setOpen(false);
        },
        onError: () => {
          addNotification({
            variant: "error",
            primaryText: "Failed to send invite",
          });
        },
      }
    );
  }

  const remove = (branchId) => removeInvite.mutate(branchId, deleteHandlers);

  useEffect(() => {
    if (!userInvites.isLoading) {
      var filtered = userInvites.data.filter(invite => {
        if (!invite.accepted) {
          return invite;
        }
      })

      setInvites(filtered);
      setIsLoading(false);
    }
  }, [userInvites.isLoading, userInvites.data])

  return (
    <div>
      <h3 className="mt-6 text-xl font-medium text-gray-900">Invited Users</h3>
      <p className="mt-1 text-sm text-gray-500">Users who have been invited to the dashboard for this agency</p>
      <Flyout open={open} setOpen={setOpen} title="Invite User">
        <UserInviteAddPartial handleSave={add} />
      </Flyout>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="my-6 flex items-center justify-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
      >
        <PlusIcon className="w-4 h-4 mr-2" />
        Invite User
      </button>
      <ul className="mt-1 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
        {userInvites.isLoading || isLoading ? (
          <li key="userInvites-loading" className="py-4 flex justify-center">
            <LoadingWheel width="w-8" height="h-8" />
          </li>
        ) : invites.length > 0 ? (
          invites.map((userInvite, i) => (
            <UserInviteListRow
              userInvite={userInvite}
              key={i}
              onResendClick={resend}
              onOldPortalUserClick={oldUserResend}
              onRemoveClick={remove}
            />
          ))
        ) : (
          <li>
            <div className="py-5 text-center text-sm text-gray-400">
              No User Invites
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}
