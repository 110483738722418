import React, {useState} from 'react';

export default function Tooltip({tip, children}){
    const [hovering, setHovering] = useState(false); 

    return (
        <div className="tooltip" onMouseEnter={()=>setHovering(true)} onMouseLeave={()=>setHovering(false)}>
            {
                !hovering ? null :
                <div className='absolute bottom-[3rem] -translate-x-1/4 z-50 bg-white p-2 shadow-lg border border-slate-200 rounded-md before:w-4 before:h-4 before:rotate-45 before:bg-white before:absolute before:-bottom-1 before:left-0 before:right-0 before:-translate-x-1/4 before:mx-auto'>
                    <p className='text-black'>{tip}</p>
                </div>
            }
            {children}
        </div>
    )
}