
import { useForm } from "react-hook-form";
import Modal from "./../Shared/Modal";
import { JsonNumberConfig } from "./Configs/JsonNumberConfig";
import { JsonSelectConfig } from "./Configs/JsonSelectConfig";
import JsonRadioConfig from "./Configs/JsonRadioConfig";
import { JsonTextareaConfig } from "./Configs/JsonTextareaConfig";
import { JsonLineBreakConfig } from "./Configs/JsonLineBreakConfig";
import { JsonHeadingConfig } from "./Configs/JsonHeadingConfig";
import { JsonParagraphConfig } from "./Configs/JsonParagraphConfig";
import useNotification from "../notification/useNotifications";
import { useEffect } from "react";
import { JsonDefaultConfig } from "./Configs/JsonDefaultConfig";

export default function ElementConfig({ open, setOpen, element, formJson, setFormJson, refresh, setRefresh }) {
  const { addNotification } = useNotification();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: {
      label: element.label,
      placeholder: element.placeholder,
      required: element.required,
      // options: element.options,
      currency: element.currency,
      defaultOption: element.defaultOption,
      defaultRows: element.defaultRows,
      showLine: element.showLine,
      cols: element.cols,
    }
  });

  const renderElement = (element) => {
    var elementToRender = "";

    switch (element.type) {
      case "input":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} placeholder />
        break;
      case "number":
        elementToRender = <JsonNumberConfig id={element.id} register={register} />
        break;
      case "select":
        elementToRender = <JsonSelectConfig id={element.id} register={register} options={element.options} element={element} formJson={formJson} setFormJson={setFormJson} />
        break;
      case "checkbox":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "radio":
        elementToRender = <JsonRadioConfig id={element.id} register={register} options={element.options} element={element} formJson={formJson} setFormJson={setFormJson} />
        break;
      case "textarea":
        elementToRender = <JsonTextareaConfig id={element.id} register={register} />
        break;
      case "date":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "time":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "datetime":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "linebreak":
        elementToRender = <JsonLineBreakConfig id={element.id} register={register} />
        break;
      case "heading":
        elementToRender = <JsonHeadingConfig id={element.id} register={register} />
        break;
      case "paragraph":
        elementToRender = <JsonParagraphConfig id={element.id} register={register} />
        break;
      case "image":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "signature":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;
      case "pdf":
        elementToRender = <JsonDefaultConfig id={element.id} register={register} />
        break;

      default:
        break;
    }

    return elementToRender;
  }

  const onSubmit = (data) => {
    var newFormJson = formJson;
    delete data.options;

    let index = newFormJson.elements.findIndex(x => x.id === element.id);

    var updatedElement = {
      ...newFormJson.elements[index],
      ...data
    };

    newFormJson.elements[index] = updatedElement;

    setFormJson(newFormJson);

    addNotification({
      variant: "success",
      primaryText: "Updated form element",
    })

    setOpen(false);
    setRefresh(!refresh);
    // console.log(newFormJson.elements[element.id])
  }

  const removeElement = () => {
    var newFormJson = formJson;

    let index = newFormJson.elements.findIndex(x => x.id === element.id);
    newFormJson.elements.splice(index, 1);

    setFormJson(newFormJson);

    addNotification({
      variant: "success",
      primaryText: "Removed form element",
    })

    setOpen(false);
    setRefresh(!refresh);
  }

  useEffect(() => {
    // console.log(element)
    reset({
      label: element.label,
      placeholder: element.placeholder,
      required: element.required,
      // options: element.options,
      currency: element.currency,
      defaultOption: element.defaultOption,
      defaultRows: element.defaultRows,
      showLine: element.showLine,
      cols: element.cols,
    })
  }, [refresh, element])

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-6xl"} >
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <h1 className="text-lg font-medium">Edit {element.type.charAt(0).toUpperCase() + element.type.slice(1)}</h1>
        <hr className="mb-4" />

        {renderElement(element)}

        <div className="mt-8 px-4 w-full flex justify-between">
          <button type="button"
            onClick={() => removeElement()}
            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
          >
            Remove
          </button>

          <button type="submit"
            className="px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
          >
            Update
          </button>
        </div>
      </form>
    </Modal>
  )
}