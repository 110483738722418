import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";
import useUserContext from "../contexts/UserContext";

export default function useValuations() {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, patch, del } = useApiHelper();

  const valuations = useQuery(
    ["valuations", agency.id],
    () => get(`/valuation/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (valuation) => post(`/valuation/${agency.id}`, valuation), {
    onSuccess: (addedValuation) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) => [
        addedValuation.data,
        ...currentValuations
      ]
      );
    },
  }
  );

  const refreshNearbySold = useMutation(
    (valuationId) => get(`/valuation/${agency.id}/${valuationId}/refresh`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["valuations", agency.id])
      },
    }
  );

  const update = useMutation((valuation) => patch(`/valuation/${agency.id}/${valuation.id}`, valuation), {
    onSuccess: (updatedValuation) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) =>
        currentValuations.map((valuation) =>
          valuation.id === updatedValuation.data.id ? updatedValuation.data : valuation
        )
      );
    },
  });

  const updateDates = useMutation((valuation) => patch(`/valuation/${agency.id}/${valuation.id}/dates`, valuation), {
    onSuccess: (updatedValuation) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) =>
        currentValuations.map((valuation) =>
          valuation.id === updatedValuation.data.id ? updatedValuation.data : valuation
        )
      );
    },
  });

  const updateWithEmail = useMutation((valuation) => patch(`/valuation/${agency.id}/${valuation.id}?sendEmail=true`, valuation), {
    onSuccess: (updatedValuation) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) =>
        currentValuations.map((valuation) =>
          valuation.id === updatedValuation.data.id ? updatedValuation.data : valuation
        )
      );
    },
  });

  const remove = useMutation((valuationId) => del(`/valuation/${agency.id}/${valuationId}`), {
    onSuccess: (_, valuationId) => {
      queryClient.invalidateQueries(["valuations", agency.id]);
    },
  });

  const updateNotes = useMutation((mutateData) => put(`/valuation/notes/${mutateData.valuationId}`, mutateData.newNote), {
    onSuccess: (updatedValuation) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) =>
        currentValuations.map((valuation) =>
          valuation.id === updatedValuation.data.id ? updatedValuation.data : valuation
        )
      );
    },
  });

  const removeNote = useMutation((ids) => put(`/valuation/notes/${ids.valuationId}/${ids.noteId}`), {
    onSuccess: (_, ids) => {
      queryClient.setQueryData(["valuations", agency.id], (currentValuations) =>
        currentValuations.map((valuation) => {
          if (valuation.notes.some(c => c.id === ids.noteId)) {
            valuation.notes = valuation.notes.filter(note => note.id !== ids.noteId);
            return valuation;
          } else {
            return valuation;
          }
        })
      );
    },
  });

  return {
    valuations,
    add,
    refreshNearbySold,
    update,
    updateDates,
    updateWithEmail,
    remove,
    updateNotes,
    removeNote
  };
}
