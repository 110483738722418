import DefaultLeftSide from "./DefaultLeftSide"
import DefaultRightSide from "./DefaultRightSide"


export const JsonHeadingConfig = ({ register }) => {

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

          <DefaultLeftSide register={register} />

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        {/* <DefaultRightSide register={register} /> */}

      </div>

    </div>
  )
}