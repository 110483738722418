import { useState } from "react";
import LoadingWheel from "../Shared/LoadingWheel";
import Modal from "../Shared/Modal";

import Papa from 'papaparse';
import useReviewsImporter from "../../data/useReviewsImporter";
import { DateTime } from "luxon";
import BranchListBoxAdmin from './../Shared/BranchListBoxAdmin';

export default function ImportReviewsFromCsv({ agencyId, open, setOpen }) {
  const { importReviews } = useReviewsImporter(agencyId);
  const [isLoading, setIsLoading] = useState(false);
  const [importSource, setImportSource] = useState("google");
  const [success, setSuccess] = useState(false);
  const [countToImport, setCountToImport] = useState(0);
  const [countImported, setCountImported] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState(false);
  const [branch, setBranch] = useState(null);

  const handleFileChange = (e) => {
    setIsLoading(true);
    setError(false);
    setSuccess(false);
    const file = e.target.files[0];
    // console.log(file)

    Papa.parse(file, {
      header: false,
      complete: (results) => {
        // console.log(results)
        var headers = getHeaders(results);
        var data = getData(results);
        // console.log(headers)
        // console.log(data)
        var csvContent = Papa.unparse({
          fields: headers,
          data: data
        })

        const parser = parseType();
        parser(csvContent);
      }
    });

  };

  //Extensible object literals for parsing different import sources in future
  const getHeaders = (results) => ({
    google: results.data[0]
  })[importSource]

  const getData = (results) => ({
    google: results.data.slice(1)
  })[importSource]

  const parseType = () => ({
    google: googleParser,
  })[importSource];

  const saveNewReviews = (newReviews) => {
    console.log(branch);
    //check reviews created against newReviews length to determine success
    importReviews.mutate(newReviews, {
      onSuccess: (newReviewsResult) => {
        setCountToImport(newReviews.length ?? 0);
        setCountImported(newReviewsResult.data?.length ?? 0);
        setSuccessMessage(newReviewsResult.message ?? "");
        setSuccess(true);
      },
      onError: () => {
        setError(true);
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });
  };

  const googleParser = (csv) => {
    Papa.parse(csv, {
      header: true,
      complete: (results) => {
        let reviews = [];

        // console.log(results);
        results.data.forEach(review => {
          //convert to iso date using luxon
          //All of these formats are possible because Excel sucks
          let date = DateTime.fromFormat(review["Date"], "yyyy-MM-dd HH:mm:ss");

          if (!date.isValid) {
            date = DateTime.fromFormat(review["Date"], "dd-MM-yyyy HH:mm");
          }
          if (!date.isValid) {
            date = DateTime.fromFormat(review["Date"], "dd/MM/yyyy HH:mm");
          }

          if (!date.isValid) return;

          var review = {
            name: review["Author"] ?? "Anonymous",
            date: date.toISO(),
            starRating: review["Rating"],
            content: review["Review"],
            reviewUrl: review["Review Url"],
            site: "google",
            branchId: branch
          };

          if (review.starRating) reviews.push(review);
        });

        saveNewReviews(reviews);
      }
    });
  };

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-4xl"}>

      {isLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="h-80 max-w-4xl flex items-center justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        </div>
      ) : (
        <div className="mx-auto flex flex-col gap-y-4">
          <h1 className="text-lg">
            Upload CSV for Google reviews
          </h1>

          <BranchListBoxAdmin setBranch={setBranch} agencyId={agencyId} none={false} />

          <input
            id="import"
            type="file"
            className="inline-flex items-center rounded-md border border-transparent bg-etpink-600 px-8 py-4 text-xl font-medium leading-4 text-white shadow-sm hover:bg-etpink-700"
            onChange={handleFileChange}
          />

          <div>
            {success && (
              <>
                <p className="text-lg text-green-500">Import Successful! {countImported}/{countToImport} Reviews Imported</p>
                <p className="text-lg text-green-500">{successMessage}</p>
              </>
            )}
            {error && <p className="text-lg text-red-500">Error - Import Unsuccessful</p>}
          </div>

        </div>
      )}


    </Modal>
  )

}