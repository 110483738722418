import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import date from "date-and-time";
import LoadingWheel from "../Shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAgencyServerSettings from "../../data/useAgencyServerSettings";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import { ConfirmNavigateAway } from "../Shared/ConfirmNavigateAway";
import ConfirmModal from "../Shared/ConfirmModal";

export default function ServerSettingsPartial({ domain, agency, agencyId }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Server Settings");
  const { kinstaSettings, update, setupWebhooks } = useAgencyServerSettings(agencyId);
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const [showWPPassword, setShowWPPassword] = useState(true);
  const [showFTPPassword, setShowFTPPassword] = useState(true);
  const [showDatabasePassword, setShowDatabasePassword] = useState(true);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    if (!kinstaSettings.isLoading && !isDirty) {
      if (kinstaSettings.data.wordPressPassword) {
        setShowWPPassword(false);
      }

      if (kinstaSettings.data.databasePassword) {
        setShowDatabasePassword(false);
      }

      if (kinstaSettings.data.ftpPassword) {
        setShowFTPPassword(false);
      }

      reset({
        wp: {
          user: kinstaSettings.data.wordPressUsername,
          password: kinstaSettings.data.wordPressPassword,
          wordPressDomain: kinstaSettings.data.wordPressDomain,
        },
        site: {
          ipAddress: kinstaSettings.data.siteIpAddress,
          path: kinstaSettings.data.path,
          externalIp: kinstaSettings.data.externalIpAddress,
        },
        ftp: {
          host: kinstaSettings.data.ftpHost,
          user: kinstaSettings.data.ftpUsername,
          password: kinstaSettings.data.ftpPassword,
          ssh: kinstaSettings.data.ftpSSHCommand,
          port: kinstaSettings.data.ftpPort,
        },
        db: {
          name: kinstaSettings.data.databaseName,
          user: kinstaSettings.data.databaseUsername,
          password: kinstaSettings.data.databasePassword,
        },
      });
    }
  }, [kinstaSettings.isLoading, kinstaSettings.data, isDirty, reset]);

  const handleSave = (data) => {
    console.log(data);
    update.mutate(
      {
        wordPressUsername: data.wp.user,
        wordPressPassword: data.wp.password,
        wordPressDomain: data.wp.wordPressDomain,
        siteIpAddress: data.site.ipAddress,
        path: data.site.path,
        externalIpAddress: data.site.externalIp,
        ftpHost: data.ftp.host,
        ftpUsername: data.ftp.user,
        ftpPassword: data.ftp.password,
        ftpSSHCommand: data.ftp.ssh,
        ftpPort: data.ftp.port,
        databaseName: data.db.name,
        databaseUsername: data.db.user,
        databasePassword: data.db.password,
      },
      saveHandlers
    );
    reset({
      wp: {
        user: data.wp.user,
        password: data.wp.password,
        wordPressDomain: data.wp.wordPressDomain,
      },
      site: {
        ipAddress: data.site.ipAddress,
        path: data.site.path,
        externalIp: data.site.externalIp,
      },
      ftp: {
        host: data.ftp.host,
        user: data.ftp.user,
        password: data.ftp.password,
        ssh: data.ftp.ssh,
        port: data.ftp.port,
      },
      db: {
        name: data.db.name,
        user: data.db.user,
        password: data.db.password,
      },
    });
  };

  const confirmWebhooks = () => {
    setupWebhooks.mutate(null,
      {
        onSuccess: () => {
          saveHandlers.onSuccess();
          setConfirm(false);
        },
        OnError: () => {
          saveHandlers.onError();
        }
      }
    );
  };

  if (kinstaSettings.isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingWheel width="w-12" height="h-12" />
      </div>
    );
  } else {
    return (
      <>
        <ConfirmModal title={"Setup Webhooks"} message={"Are you sure you want to set up webhooks?"} confirmButtonText={"Confirm"} open={confirm} setOpen={setConfirm} onConfirm={confirmWebhooks} />
        <form onSubmit={handleSubmit(handleSave)}>
          <ConfirmNavigateAway
            show={showPrompt}
            onOK={confirmNavigation}
            onCancel={cancelNavigation}
          />
          <div className="divide-y-2 divide-gray-200">
            <div className="-mx-6 px-6">
              <h3 className="text-xl font-medium text-gray-900">
                WordPress Settings
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                WordPress settings for this agency
              </p>
              <div className="my-6">
                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-4">
                    <label
                      htmlFor="wpUser"
                      className="block text-sm font-medium text-gray-700"
                    >
                      WordPress Username
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("wp.user")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="wpuser"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-4">
                    <label
                      htmlFor="wpPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      WordPress Password
                    </label>
                    {!showWPPassword ? (
                      <button
                        type="button"
                        className="w-full h-[40px] mt-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                        onClick={() =>
                          showWPPassword
                            ? setShowWPPassword(false)
                            : setShowWPPassword(true)
                        }
                      >
                        Show
                      </button>
                    ) : (
                      <>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            {...register("wp.password")}
                            type="text"
                            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-l-md"
                            placeholder="wppassword"
                          />
                          <span
                            onClick={() => setShowWPPassword(false)}
                            className="w-3/12 md:w-2/12 inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                          >
                            Hide
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-span-10 md:col-span-2 flex items-end">
                    <a
                      href={`${kinstaSettings?.data?.wordPressDomain ?? domain}/login`}
                      target="_blank"
                      className="h-[40px] leading-5 text-center w-full items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                    >
                      Go to site admin
                    </a>
                  </div>

                  <div className="col-span-10 md:col-span-4">
                    <label
                      htmlFor="wpDomain"
                      className="block text-sm font-medium text-gray-700"
                    >
                      WordPress Domain
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("wp.wordPressDomain")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="http://example.estate-track.co.uk"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="-mx-6 px-6 pt-6">
              <h3 className="text-xl font-medium text-gray-900">Site Settings</h3>
              <p className="mt-1 text-sm text-gray-500">
                Site settings for the Wordpress site for this agency
              </p>
              <div className="my-6">
                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="siteIP"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Site IP
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("site.ipAddress")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="0.0.0.0"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="siteExternalIP"
                      className="block text-sm font-medium text-gray-700"
                    >
                      IP Address for External Connections
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("site.externalIp")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="0.0.0.0"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="sitePath"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Path
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("site.path")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="/path/to/site"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="-mx-6 px-6 pt-6">
              <h3 className="text-xl font-medium text-gray-900">FTP Settings</h3>
              <p className="mt-1 text-sm text-gray-500">
                FTP Settings for the Wordpress site for this agency
              </p>
              <div className="my-6">
                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-8">
                    <label
                      htmlFor="ftpHost"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Host
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("ftp.host")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="ftp.mysite.com"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-2">
                    <label
                      htmlFor="ftpPort"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Port
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("ftp.port")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="21"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="ftpUsername"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Username
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("ftp.user")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="ftpuserjohn"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="ftpPassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    {!showFTPPassword ? (
                      <button
                        type="button"
                        className="w-full h-[40px] mt-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                        onClick={() =>
                          showFTPPassword
                            ? setShowFTPPassword(false)
                            : setShowFTPPassword(true)
                        }
                      >
                        Show
                      </button>
                    ) : (
                      <>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            {...register("ftp.password")}
                            type="text"
                            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-l-md"
                            placeholder="ftp password"
                          />
                          <span
                            onClick={() => setShowFTPPassword(false)}
                            className="w-3/12 md:w-2/12 inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                          >
                            Hide
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-span-10">
                    <label
                      htmlFor="ftpSSHCommand"
                      className="block text-sm font-medium text-gray-700"
                    >
                      SSH Command
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("ftp.ssh")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="ssh ftpusername@ftp.mysite.com"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="-mx-6 px-6 pt-6">
              {/*<h3 className="text-xl font-medium text-gray-900">
                Database Settings
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Database settings for the Wordpress site for this agency
              </p>
               <div className="my-6">
                <div className="grid grid-cols-10 gap-4">
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="databaseName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Database Name
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("db.name")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="dbname"
                      />
                    </div>
                  </div>
                  <div className="hidden md:block md:col-span-5"></div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="databaseUser"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Database User
                    </label>
                    <div className="mt-1">
                      <input
                        {...register("db.user")}
                        type="text"
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="dbuser"
                      />
                    </div>
                  </div>
                  <div className="col-span-10 md:col-span-5">
                    <label
                      htmlFor="databasePassword"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Database Password
                    </label>
                    {!showDatabasePassword ? (
                      <button
                        type="button"
                        className="w-full h-[40px] mt-1 inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                        onClick={() =>
                          showDatabasePassword
                            ? setShowDatabasePassword(false)
                            : setShowDatabasePassword(true)
                        }
                      >
                        Show
                      </button>
                    ) : (
                      <>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <input
                            {...register("db.password")}
                            type="text"
                            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-l-md"
                            placeholder="dbpassword"
                          />
                          <span
                            onClick={() => setShowDatabasePassword(false)}
                            className="w-3/12 md:w-2/12 inline-flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 shadow-sm text-sm font-medium text-gray-700 hover:cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                          >
                            Hide
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div> */}
              <div className="!mt-8 -my-6 -mx-4 sm:-m-6 lg:-mb-8 p-4 flex justify-between border-t-2 border-gray-200 bg-white">
                <div>
                  <button
                    type="button"
                    onClick={() => setConfirm(true)}
                    className="px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Setup Webhooks
                  </button>
                  {agency.webhooksSet && <span className="ml-2 text-sm text-gray-500">- last setup on {date.format(new Date(agency.webhooksSet), "DD/MM/YY HH:mm")} </span>}
                </div>
                <button
                  type="submit"
                  className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
}
