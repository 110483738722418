import { useEffect, useState } from "react";

import { CheckCircleIcon } from "@heroicons/react/outline";

import useContentEngineLinkedPosts from "../../data/useContentEngineLinkedPosts";
import useGroupAgencies from "../../data/useGroupAgencies";
import useGroups from "../../data/useGroups";
import LoadingWheel from "../Shared/LoadingWheel";

import AgencyNotPublished from "./AgencyNotPublished";


export default function AgenciesList({ postId, groupId }) {
  const { contentEngineLinkedPosts } = useContentEngineLinkedPosts(postId);
  const { groupAgencies } = useGroupAgencies(groupId);
  const { groups } = useGroups();
  const [group, setGroup] = useState();
  const [linkedPosts, setLinkedPosts] = useState(null);


  //Only check for published posts if created is true
  useEffect(() => {
    if (!groups.isLoading && !groupAgencies.isLoading && groupId) {
      const group = groups.data.find((g) => g.id === groupId);
      setGroup(group);

      if (postId && contentEngineLinkedPosts.data && !contentEngineLinkedPosts.isLoading) {
        setLinkedPosts(contentEngineLinkedPosts.data);
      }
    }
  }, [groupId, postId, groups.isLoading, groups.data, groupAgencies.data, groupAgencies.isLoading, contentEngineLinkedPosts.data, contentEngineLinkedPosts.isLoading]);

  if (groups.isLoading || groupAgencies.isLoading || !group) {
    return (
      <div className="flex items-center justify-center h-12">
        <LoadingWheel width="w-8" height="h-8" />
      </div>
    );
  }
  else {
    return (
      <>
        <label className="mb-2 block text-sm font-medium text-gray-700">
          Agencies in {group.name}
        </label>
        <div>
          <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
            {groupAgencies.data.map((agency) => (
              <li key={agency.id} className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div className="w-0 flex-1 flex justify-between items-center">
                  <span className="ml-2 flex-1 w-0 truncate">
                    {agency.name}
                  </span>
                  <span>
                    {postId && (!linkedPosts ?
                      (<LoadingWheel width="w-5" height="h-5" />) :
                      (
                        linkedPosts.some((lp) => lp.agencyId === agency.id) ? (
                          <span className="flex">Published <CheckCircleIcon className="w-5 ml-3 text-green-500" /></span>
                        ) : (
                          <AgencyNotPublished agencyId={agency.id} postId={postId} />
                        )
                      )
                    )}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>

      </>
    );
  }
}