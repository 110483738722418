import { useForm } from "react-hook-form";

export default function BranchAddEditPartial({ branch, handleSave }) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: branch.id,
      agencyId: branch.agencyId,
      name: branch.name,
      addressLine1: branch.addressLine1,
      addressLine2: branch.addressLine2,
      addressLine3: branch.addressLine3,
      city: branch.city,
      county: branch.county,
      postcode: branch.postcode,
    },
  });

  const save = (data) => {
    handleSave(data);
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <div className="block">
        <label
          htmlFor="BranchName"
          className="block text-sm font-medium text-gray-700"
        >
          Branch Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            {...register("name", { required: true })}
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="Homes4U London"
          />
        </div>
        {errors.name && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            Branch Name is required
          </p>
        )}
      </div>
      <div className="my-4 block md:flex md:-mx-2">
        <div className="w-full md:w-1/2 md:mx-2">
          <label
            htmlFor="AddressLine1"
            className="block text-sm font-medium text-gray-700"
          >
            Address Line 1
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("addressLine1", { required: true })}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="76"
            />
          </div>
          {errors.addressLine1 && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Address Line 1 is required
            </p>
          )}
        </div>
        <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
          <label
            htmlFor="AddressLine2"
            className="block text-sm font-medium text-gray-700"
          >
            Address Line 2
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("addressLine2")}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Estate Street"
            />
          </div>
        </div>
      </div>
      <div className="my-4 block md:flex md:-mx-2">
        <div className="w-full md:w-1/2 md:mx-2">
          <label
            htmlFor="AddressLine3"
            className="block text-sm font-medium text-gray-700"
          >
            Address Line 3
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("addressLine3")}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="House road"
            />
          </div>
        </div>
        <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
          <label
            htmlFor="City"
            className="block text-sm font-medium text-gray-700"
          >
            City
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("city", { required: true })}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="London"
            />
          </div>
          {errors.city && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              City is required
            </p>
          )}
        </div>
      </div>
      <div className="my-4 block md:flex md:-mx-2">
        <div className="w-full md:w-1/2 md:mx-2">
          <label
            htmlFor="County"
            className="block text-sm font-medium text-gray-700"
          >
            County
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("county", { required: true })}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Greater London"
            />
          </div>
          {errors.county && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              County is required
            </p>
          )}
        </div>
        <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
          <label
            htmlFor="Postcode"
            className="block text-sm font-medium text-gray-700"
          >
            Postcode
          </label>
          <div className="mt-1">
            <input
              type="text"
              {...register("postcode", { required: true })}
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="SW1 1AA"
            />
          </div>
          {errors.postcode && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Postcode is required
            </p>
          )}
        </div>
      </div>
      <div className="mt-8">
        <button
          type="submit"
          className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
          {getValues("id") ? "Save" : "Create"}
        </button>
      </div>
    </form>
  );
}
