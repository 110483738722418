import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useTeamMembers() {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

  const teamMembers = useQuery(["teamMembers", agency.id], () => get(`/TeamMembers/agency/${agency.id}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((teamMember) => post(`/TeamMembers/${teamMember.branch}`, teamMember), {
    onSuccess: (addedMember) => {
      queryClient.setQueryData(["teamMembers", agency.id], (currentMembers) => [
        ...currentMembers,
        addedMember.data,
      ]);
    },
  });

  const update = useMutation((teamMember) => put(`/TeamMembers/${teamMember.branch}/${teamMember.id}`, teamMember), {
    onSuccess: () => {
      queryClient.invalidateQueries(["teamMembers", agency.id])
    },
  }
  );

  const remove = useMutation((teamMemberId) => del(`/TeamMembers/${agency.id}/${teamMemberId}`),
    {
      onSuccess: (_, memberId) => {
        queryClient.setQueryData(["teamMembers", agency.id], (currentMembers) =>
          currentMembers.filter((member) => member.id !== memberId)
        );
      },
    }
  );

  return {
    teamMembers,
    add,
    update,
    remove,
  };
}
