import { PlusIcon } from "@heroicons/react/solid";
import { PlusCircleIcon } from "@heroicons/react/outline";

import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";

/**
 * @param {string} itemName Label to use for describing the items that would be in the list e.g "Users". Plurals and capitalization is handled for you so provide the string without e.g to get the label "Users" provide the string "user"
 * @param {void} onNewClick Function to run when the "new" button is clicked
 */
export default function NoItems({
  itemName,
  onNewClick,
  plural = "",
  hasButton = true,
  searching = false,
}) {
  const itemNameParts = itemName.split(" ");

  return (
    <div className="flex flex-col items-center">
      {!searching && (
        <>
          {" "}
          <PlusCircleIcon className="w-12 h-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No{" "}
            {plural !== ""
              ? plural
              : itemNameParts.map((word, i) =>
                i === itemNameParts.length - 1
                  ? `${capitalizeFirstLetter(word)}s`
                  : `${capitalizeFirstLetter(word)} `
              )}
          </h3>
        </>
      )}
      {searching ? (
        <p className="text-sm text-gray-500">
          No {itemName}s match your search terms.
        </p>
      ) : (
        <p className="mt-1 text-sm text-gray-500">
          Get started by creating a new {itemName}.
        </p>
      )}
      {(!searching && hasButton) && (
        <div className="mt-6">
          <button
            type="button"
            onClick={onNewClick}
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            New {itemNameParts.map((word) => `${capitalizeFirstLetter(word)} `)}
          </button>
        </div>
      )}
    </div>
  );
}
