
export default function JsonCheckboxInput({ element }) {

  return (
    <div className="flex">
      <input type="checkbox" className="w-5 h-5 mt-1 mr-2"/>
      <label className="block text-base font-base text-gray-700">
        {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
      </label>
    </div>
  )
}