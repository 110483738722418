import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEmailReports(agencyId) {
  const staleTime = 600000;
  const queryClient = useQueryClient();

  const { get, post, put, del } = useApiHelper();

  const emailStats = useQuery(
    ["emailStats", agencyId],
    () => get(`/Reports/${agencyId}/emailStats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  return {
    emailStats,
  };
}
