import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useContentEngineMedia() {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const media = useQuery(
    "media",
    () => get("/media/content-engine").then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const markDeleted = useMutation((mediaId) => put(`/media/${mediaId}`,),
    {
      onSuccess: () => { 
        queryClient.invalidateQueries("media");
      },
    }
  );

  return {
    media,
    markDeleted
  };
}
