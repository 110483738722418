
export const JsonNumberInput = ({ element }) => {

  return (
    <div className="">
      <label className="block mb-2 text-sm font-medium text-gray-700">
        {element.label} {element.required && <span className="text-red-500 text-xs">(Required)</span>}
      </label>

      {element.currency ? (
        <div className={`flex`} >
          <span className="flex select-none items-center border border-r-0 bg-white border-gray-300 text-gray-500 rounded-md rounded-r-none pl-3">
            £
          </span>
          <input type="number" placeholder={element.placeholder}
            className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 border-l-0 block w-full sm:text-sm border-gray-300 rounded-md rounded-l-none"}
          />
        </div>
      ) : (
        <input type="number" placeholder={element.placeholder}
          className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
        />
      )}

    </div>
  )
}