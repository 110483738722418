import { Switch } from "@headlessui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { withAgency } from "../../data/withAgency";
import classNames from "../../utils/classNames";


function _ContentEngineCategoryAddEditPartial({ agency, agencyReady, category, handleSaveCategory }) {
  const { register, handleSubmit, getValues, setValue, formState: { errors }, } = useForm({ defaultValues: { id: category.id, name: category.name }, });
  const [defaultCategory, setDefaultCategory] = useState(false);

  const onSubmit = (category) => {
    category.default = defaultCategory;

    handleSaveCategory(category);
  }

  function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  useEffect(() => {
    //If there's no delay it gets updated before the animation finishes and looks terrible
    //A better solution might be to figure out how to use hook forms for the switch
    delay(500).then(() => {
      setDefaultCategory(category.default);
    });
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="my-4 block">
        <label
          htmlFor="policyName"
          className="block text-sm font-medium text-gray-700"
        >
          Category Name
        </label>
        <div className="mt-1">
          <input
            type="text"
            {...register("name", { required: true })}
            className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="New category name..."
          />
        </div>
        {errors.name && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            Category Name is required
          </p>
        )}
      </div>

      <div className="flex justify-between">
        <button
          type="submit"
          className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
          {getValues("id") ? "Save" : "Create"}
        </button>

        {/* <Switch.Group as="div" className="flex items-center">
          <Switch.Label as="span" className="ml-3">
            <span className="mr-2 text-sm font-medium text-gray-900">Default</span>
          </Switch.Label>
          <Switch
            checked={defaultCategory}
            onChange={setDefaultCategory}
            className={classNames(
              defaultCategory ? 'bg-etpink-600' : 'bg-gray-200',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                defaultCategory ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
              )}
            />
          </Switch>

        </Switch.Group> */}
      </div>

    </form>
  )

}



const ContentEngineCategoryAddEditPartial = withAgency(_ContentEngineCategoryAddEditPartial);

export default ContentEngineCategoryAddEditPartial;