import { useEffect, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { ChevronDownIcon, ChevronUpIcon, PencilAltIcon, SearchIcon } from "@heroicons/react/outline";

import { PropertiesAnalytics } from "../../components/Analytics/Properties/PropertiesAnalytics";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import Pagination from "../../components/Shared/Pagination";
import useProperties from "../../data/useProperties";
import { getAddress } from "../../utils/getAddress";
import usePropertiesPartial from "../../data/usePropertiesPartial";

function PropertyRow({ property }) {
  const navigate = useNavigate();

  return (
    <tr onClick={() => navigate(`property/${property.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="hidden px-3 pl-4 pr-1 text-sm text-gray-500 sm:table-cell">
        {property.pictures && <img className="w-24" src={property.pictures[0]?.pictureUrl} onError={property.pictures[1]?.pictureUrl} />} {/* .replace("_large", "_small") - doesn't work anymore */}
      </td>
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        <div dangerouslySetInnerHTML={{ __html: getAddress(property) }}></div>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Views</dt>
          <dt className="sr-only sm:hidden">Department</dt>
          <dt className="sr-only sm:hidden">Published</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {date.format(new Date(property.created), "DD/MM/YYYY")}
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 pl-6 text-sm text-gray-500 sm:table-cell">
        {property.addressPostcode}
      </td>
      <td className="hidden px-3 py-4 pl-6 text-sm text-gray-500 sm:table-cell">
        {property.availability}
      </td>
      <td className="hidden px-3 py-4 pl-6 text-sm text-gray-500 sm:table-cell">
        {property.views}
      </td>
      <td className="hidden px-3 py-4 pl-6 text-sm text-gray-500 sm:table-cell">
        {property.enquiries ?? "0"}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        {date.format(new Date(property.created), "DD/MM/YYYY")}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={() => navigate(`property/${property.id}`)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Properties() {
  const navigate = useNavigate();
  const { properties: allProperties } = usePropertiesPartial();
  const [properties, setProperties] = useState(allProperties);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortTerm, setSortTerm] = useState("created");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(20);
  const [nPages, setNPages] = useState(0);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  useEffect(() => {
    document.title = "EstateTrack Portal | Properties";
    if (!allProperties.isLoading) {

      let filtered = allProperties.data;

      filtered = filtered.filter((property) =>
        getAddress(property)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        property.addressPostcode?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (sortTerm && !sortDirection) {
        if (sortTerm === "views" || sortTerm === "enquiries") {
          filtered.sort((a, b) => a[sortTerm] - b[sortTerm]);
        } else {
          filtered.sort((a, b) => {
            const nameA = a[sortTerm]?.toUpperCase();
            const nameB = b[sortTerm]?.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        }
      } else if (sortTerm && sortDirection) {
        if (sortTerm === "views" || sortTerm === "enquiries") {
          filtered.sort((b, a) => a[sortTerm] - b[sortTerm]);
        } else {
          filtered.sort((b, a) => {
            const nameA = a[sortTerm]?.toUpperCase();
            const nameB = b[sortTerm]?.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          })
        }
      }

      setNPages(Math.ceil(filtered.length / recordsPerPage))

      filtered = filtered.slice(indexOfFirstRecord, indexOfLastRecord);

      setProperties(filtered);
    }
  }, [allProperties.isLoading, allProperties.data, searchTerm, sortTerm, sortDirection, currentPage]);

  const handleSort = (newSortTerm) => {
    if (sortTerm === newSortTerm) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
      setSortTerm(newSortTerm);
    }
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Properties</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all properties on the market. You can view the properties from your website from here.
            </p>
          </div>

        </div>

        <div>
          <PropertiesAnalytics />
        </div>

        <hr className="w-full my-4" />

        <div className="mt-2 w-full flex justify-between">
          <div></div>

          <div className="relative focus-within:z-10">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              name="name"
              id="name"
              onChange={(e) => setSearchTerm(e.target.value)}
              className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
              placeholder="Address or postcode"
            />
          </div>
        </div>

        <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {properties.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : properties.length > 0 ? (
            <>
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                    >

                    </th>
                    <th
                      onClick={() => handleSort("title")}
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 hover:cursor-pointer"
                    >
                      <span className="flex">Instructions {sortTerm === "title" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                    </th>
                    <th
                      onClick={() => handleSort("addressPostcode")}
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Postcode {sortTerm === "addressPostcode" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      <span className="flex">Availability</span>
                    </th>
                    <th
                      onClick={() => handleSort("views")}
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Views {sortTerm === "views" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                    </th>
                    <th
                      onClick={() => handleSort("enquiries")}
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Viewing Requests {sortTerm === "enquiries" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                    </th>
                    <th
                      onClick={() => handleSort("created")}
                      scope="col"
                      className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell hover:cursor-pointer"
                    >
                      <span className="flex">Listed Date {sortTerm === "created" && (sortDirection ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />)}</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {properties.map((property) => (
                    <PropertyRow
                      key={property.id}
                      property={property}
                    />
                  ))}
                </tbody>
              </table>
              <Pagination
                nPages={nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="property"
                  onNewClick={() => navigate("")}
                />
              ) : (
                <NoItems searching itemName="property" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
