import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../services/config";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useAgencies from "../../data/useAgencies";


export default function UnsubscribeSuccessful() {
  const { personId } = useParams();
  const [person, setPerson] = useState();
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {

  //   if (personId) {
  //     fetch(`${apiUrl}/api/person/${personId}`).then((res) => res.json()).then((data) => {
  //       console.log(data)
  //       setPerson(data);
  //       setIsLoading(false);
  //     });
  //   }
  // }, [personId, setPerson, setIsLoading])

  if (isLoading) {
    return (
      <div className="flex justify-center mt-[25vh]">
        <LoadingWheel width="w-40" height="h-40" />
      </div>
    )
  } else {
    return (
      <div className="unsubscribe-successful m-10">
        <h1>Unsubscribe Successful!</h1>
      </div>
    )
  }
}