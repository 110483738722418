import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { DocumentReportIcon, LibraryIcon } from "@heroicons/react/outline";
import useAgencies from "../../data/useAgencies";
import LoadingWheel from "../Shared/LoadingWheel";
import { useNavigate } from "react-router-dom";

export default function AreaGuideAddModal({ add, open, setOpen }) {
  const { register, reset, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    add.mutate(
      { name: data.areaGuideName },
      {
        onSuccess: () => {
          setLoading(false);
          setOpen(false);
        },
      }
    );

    reset({})
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex items-center">
                    <div className="mr-5 flex items-center justify-center h-10 w-10 rounded-full bg-gray-100">
                      <DocumentReportIcon
                        className="h-5 w-5 text-gray-600"
                        aria-hidden="true"
                      />
                    </div>
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Add Area Guide
                    </Dialog.Title>
                  </div>
                  <div className="mt-3 sm:mt-5">
                    <div className="mt-2">
                      {loading ? (
                        <div className="flex p-4 justify-center items-center">
                          <LoadingWheel width="w-10" height="h-10" />
                        </div>
                      ) : (
                        <div>
                          <label
                            htmlFor="agencyName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Area Guide Name
                          </label>
                          <div className="mt-1">
                            <input
                              {...register("areaGuideName")}
                              type="text"
                              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Plymouth"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <div className="w-full sm:w-1/2 mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                      <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
