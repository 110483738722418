import { useEffect, useState } from "react";
import LoadingWheel from "../../Shared/LoadingWheel";


const ReferrerRow = ({ referrer }) => {
  return (
    <tr>
      <td className="w-full max-w-0 pl-4 py-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
        {referrer.referrerName}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.total}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.new}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.updated}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.contactMade}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.booked}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.appraisalSent}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.lost}
      </td>
      <td className="px-3 py-4 text-sm text-gray-500 text-center">
        {referrer.won}
      </td>
    </tr>
  );
}

const ValuationReferrersTable = ({ stats }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [referrers, setReferrers] = useState(null);

  useEffect(() => {

    //Sort by total
    setReferrers((stats.referrers).sort((b, a) => a.total - b.total));

    setIsLoading(false);
    // console.log("referral tables finished")

  }, [stats])

  return (
    <div className="px-8 py-4 w-full">
      <div className="mb-4">
        <h1 className="text-base font-normal text-gray-800 lg:pt-2 mb-2">Valuation Referrers</h1>
        <h2 className="text-sm font-semibold">If you create a link to your website you can add ?sourceName=yourSource on the end of the link and the source will appear here.</h2>
        <h2 className="text-sm font-semibold mb-1">E.g: Your link: "www.estateagent.co.uk/valuation?sourceName=Facebook"</h2>

      </div>

      <hr className="" />

      {isLoading ? (
        <div className="flex p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <div className="overflow-auto pt-2">
          <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Referrer Name
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Total
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                >
                  New
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Updated
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Contact Made
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Booked
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Appraisal Sent
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Lost
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Won
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {referrers.map((referrer, i) => (
                <ReferrerRow referrer={referrer} key={i} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default ValuationReferrersTable;