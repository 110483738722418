import DefaultLeftSide from "./DefaultLeftSide"
import DefaultRightSide from "./DefaultRightSide"


export const JsonTextareaConfig = ({ register }) => {

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

          <DefaultLeftSide register={register} placeholder />

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        <DefaultRightSide register={register} />

        <div>
          <label className="block mb-2 text-sm font-medium text-gray-700">
            Rows
          </label>
          <input type="number" {...register("defaultRows")} min="1" max="10"
            className={"shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"}
          />
        </div>

      </div>

    </div>
  )
}