import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";

import useAuthHelper from "../../services/useAuthHelper";
import useAuthService from "../../services/useAuthService";
import useApiHelper from "../../services/useApiHelper";
import useUserContext, { UserContextActions } from "../../contexts/UserContext";
import "./signin.css";
import FullscreenLoading from "../../components/Shared/FullscreenLoading";
import { useForm } from "react-hook-form";

import EstateTrackLogo from "../../assests/EstateTrackLogo.png";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { post } = useAuthHelper();
  const { register, handleSubmit, reset, control } = useForm({ defaultValues: { email: search.get("email") }, });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "EstateTrack Portal | Forgot Password";
  }, []);

  const onSubmit = (data) => {
    console.log(data, search.get("token"));

    if (data.password !== data.confirmPassword) {
      alert("Passwords do not match!"); 
    } else {
      post("/auth/reset-password", { password: data.password, confirmPassword: data.confirmPassword, token: search.get("token") })
      .then((authRes) => {
        reset({ password: "", confirmPassword: "" });
        navigate("/signin");
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    }
  }

  if (loading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="w-screen block md:flex">
          <div className="signinImage flex-1"></div>
          <div className="w-full h-screen sm:2-3/4 md:w-2/4 lg:w-2/5 2xl:w-1/4 py-4 flex justify-center bg-white overflow-y-auto">
            <div className="flex w-11/12 lg:w-10/12">
              <div className="flex flex-col justify-center my-8 w-full">
                <img
                  className="mb-10 h-10 mx-auto w-auto"
                  src={EstateTrackLogo}
                  alt="Workflow"
                />
                {loading ? (
                  <div className="flex justify-center">
                    <LoadingWheel width="w-36" height="w-36" />
                  </div>
                ) : (
                  <>
                    <h2 className="text-3xl font-bold text-gray-900">Reset Password</h2>
                    <div className="mt-6">
                      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              disabled={true}
                              {...register("email")}
                              autoComplete="email"
                              required
                              className="bg-gray-100 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            New Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              type="password"
                              {...register("password")}
                              required
                              className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm`}
                            />
                          </div>
                        </div>

                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm New Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              type="password"
                              {...register("confirmPassword")}
                              required
                              className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm`}
                            />
                          </div>
                        </div>

                        <div>
                          <button
                            type="submit"
                            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500`}
                          >
                            Reset Password
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
