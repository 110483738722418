import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useAgencyServerSettings(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put } = useApiHelper();

  const kinstaSettings = useQuery(
    ["kinstaSettings", agencyId],
    () => get(`/wordpress/${agencyId}/server`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const update = useMutation(
    (updatedSettings) =>
      put(`/wordpress/${agencyId}/server`, updatedSettings),
    {
      onSuccess: (updatedSettings) => {
        queryClient.setQueryData(["kinstaSettings", agencyId], updatedSettings.data);
      },
    }
  );

  const setupWebhooks = useMutation(() => post(`/wordpress/${agencyId}/setup-webhooks`),
    {
      onSuccess: () => {
      },
    }
  );

  return {
    kinstaSettings,
    update,
    setupWebhooks
  };
}
