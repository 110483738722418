import { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Combobox, Dialog, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { ChevronRightIcon, SearchIcon } from "@heroicons/react/solid";

import useUserContext from "../../contexts/UserContext";
import classNames from "../../utils/classNames";
import EstateTrackLogo from "./e_logo.jpg";

export function SwitchAgencyDialog({ open, setOpen }) {
  const { user: { agencies }, } = useUserContext();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [agenciesList, setAgenciesList] = useState(null);
  const [selected, setSelected] = useState({ id: undefined });

  const switchAgency = (agency) => {
    setOpen(false);
    navigate(`/${agency.logicalName}`);
  };

  const filteredAgencies =
    query === ""
      ? []
      : agenciesList.filter((agency) => {
        return agency.name.toLowerCase().includes(query.toLowerCase());
      });

  useEffect(() => {
    if (agencies) {
      //Hide these agencies
      setAgenciesList(agencies.filter((agency) =>
        !agency.name.toLowerCase().includes("content engine") &&
        !agency.name.toLowerCase().includes("thumbnails")
      ));
    }
  }, [agencies])

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => setQuery("")}
      appear
    >
      <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as="div"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                {({ activeOption }) => (
                  <>
                    <div className="relative">
                      <SearchIcon
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query === "" || filteredAgencies.length > 0) && (
                      <Combobox.Options
                        as="div"
                        static
                        className="flex divide-y divide-gray-100 flex-col sm:flex-row sm:divide-x"
                      >
                        <div className="min-h-80 min-w-0 h-80 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 sm:min-h-96 sm:h-96">
                          <div className="-mx-2 text-sm text-gray-700">
                            {(query === "" ? agenciesList : filteredAgencies)?.map(
                              (agency) => (
                                <Combobox.Option
                                  as="div"
                                  key={agency.id}
                                  value={agency}
                                  onClick={() => setSelected(agency)}
                                  className={({ active }) =>
                                    classNames(active || selected.id === agency.id ? "bg-gray-100 text-gray-900" : "",
                                      "flex cursor-pointer select-none items-center rounded-md p-2"
                                    )
                                  }
                                >
                                  {({ active }) => (
                                    <>
                                      <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                                        <span className="text-xs font-medium leading-none text-white">
                                          {/* For agency initals, split on spaces, get the words that aren't special chars,
                                          map over the result, returning first char of each word, limited to 2 words
                                         */}
                                          {agency.name
                                            .split(" ")
                                            .filter((w) => RegExp(/^\p{L}/, "u").test(w))
                                            .map((w, i) =>
                                              i < 2
                                                ? w.substring(0, 1)
                                                  .toUpperCase()
                                                : ""
                                            )}
                                        </span>
                                      </span>
                                      <span className="ml-3 flex-auto truncate">
                                        {agency.name}
                                      </span>
                                      {Boolean(active || selected.id === agency.id) && (
                                        <ChevronRightIcon
                                          className="ml-3 h-5 w-5 flex-none text-gray-400"
                                          aria-hidden="true"
                                        />
                                      )}
                                    </>
                                  )}
                                </Combobox.Option>
                              )
                            )}
                          </div>
                        </div>

                        {selected.id ? (
                          <div className="h-80 w-full flex-none flex-col divide-y divide-gray-100 overflow-y-auto flex sm:w-1/2 sm:h-96">
                            <div className="flex-none p-6 text-center">
                              {/* <img
                                src=""
                                alt=""
                                className="mx-auto h-16 w-16 rounded-full"
                              /> */}
                              <span className="w-16 h-16 inline-flex items-center justify-center rounded-full">

                                {selected.avatar ?
                                  (
                                    <img
                                      src={selected.avatar}
                                      className="inline-block h-17 w-17 rounded-full mr-2"
                                    />
                                  ) : (
                                    <span className="w-16 h-16 bg-gray-500 inline-flex items-center justify-center rounded-full">
                                      <span className="text-2xl font-medium leading-none text-white bg-gray-500">
                                        {selected.name
                                          .split(" ")
                                          .filter((w) => RegExp(/^\p{L}/, "u").test(w))
                                          .map((w, i) =>
                                            i < 2
                                              ? w.substring(0, 1).toUpperCase()
                                              : "")}
                                      </span>
                                    </span>
                                  )}

                              </span>
                              <h2 className="mt-3 font-semibold text-gray-900">
                                {selected.name}
                              </h2>
                            </div>
                            <div className="flex flex-auto flex-col justify-between p-6">
                              <dl className="grid grid-cols-1 gap-x-6 gap-y-3 text-sm text-gray-700">
                                <dt className="col-end-1 font-semibold text-gray-900">
                                  URL
                                </dt>
                                <dd className="truncate">
                                  <a
                                    target={"_blank"}
                                    href={`${selected.domain}`}
                                    className="text-etpink-600 underline"
                                  >
                                    {`${selected.domain}`}
                                  </a>
                                </dd>
                              </dl>
                              <button
                                onClick={() => switchAgency(selected)}
                                type="button"
                                className="w-full rounded-md border border-transparent bg-etpink-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2"
                              >
                                Switch
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="h-80 w-full sm:h-96 sm:w-1/2"></div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== "" && filteredAgencies.length === 0 && (
                      <div className="py-14 px-6 text-center text-sm sm:px-14">
                        <SearchIcon
                          className="mx-auto h-10 w-10 text-gray-400"
                          aria-hidden="true"
                        />
                        <p className="mt-4 font-semibold text-gray-900">
                          No agencies found
                        </p>
                        <p className="mt-2 text-gray-500">
                          We couldn't find any agencies with that name. Please
                          try again.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export function SwitchAgency({ setOpen, sidebarOpen, setSidebarOpen }) {
  const { user: { identity, agency, loading: userLoading }, } = useUserContext();

  useEffect(() => { }, [userLoading, agency]);

  return (
    <div
      onClick={() => {
        if (sidebarOpen) {
          setSidebarOpen(false);
        }
        setOpen(true);
      }}
      className="w-full border p-2 flex items-center text-sm text-grey-600 rounded-md hover:cursor-pointer hover:text-grey-800"
    >
      {agency !== null ? (
        <>
          {agency.avatar ? (
            <img
              src={agency.avatar}
              className="inline-block object-contain h-8 w-8 rounded-full mr-2"
            />
          ) : (
            <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
              <span className="text-xs font-medium leading-none text-white">
                {agency.name
                  .split(" ")
                  .filter((w) => RegExp(/^\p{L}/, "u").test(w))
                  .map((w, i) =>
                    i < 2
                      ? w.substring(0, 1).toUpperCase()
                      : ""
                  )}
              </span>
            </span>
          )}
          <span className="ml-2 mr-2 flex-1">{agency.name}</span>
        </>
      ) : identity.role === "Admin" ? (
        <>
          <img
            className="inline-block h-6 w-6 rounded-full mr-2"
            src={EstateTrackLogo}
            alt=""
          />
          <span className="ml-2 mr-2 flex-1">Admin Panel</span>
        </>
      ) : (
        <>
          <span className="inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <span className="ml-2 mr-2 flex-1">None</span>
        </>
      )}
      <ChevronDownIcon className="w-5 h-5" />
    </div>
  );
}
