import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEmailTemplates() {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get } = useApiHelper();

  const emailTemplates = useQuery(
    ["emailtemplates"],
    () => get(`/emailtemplate`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );


  return {
    emailTemplates,
  };
}
