import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useForm, useFormState } from "react-hook-form";
import "react-quill/dist/quill.snow.css";

import { PlusIcon, RefreshIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/outline";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import useNotification from "../../components/notification/useNotifications";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import Flyout from "../../containers/Flyout";
import MemberAddPartial from "../../components/Groups/MemberAddPartial";

import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useGroups from "../../data/useGroups";
import useGroupAgencies from "../../data/useGroupAgencies";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import ConfirmModal from "../../components/Shared/ConfirmModal";

function MemberListRow({ member, onRemoveClick }) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600 md:w-1/4">
          {member.name}
        </p>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={() => onRemoveClick(member.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Remove</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function GroupEdit() {
  const { groupId } = useParams();
  const { groups, update } = useGroups();
  const { groupAgencies, update: updateMembers, updateYoastHeaders } = useGroupAgencies(groupId);
  const { register, handleSubmit, reset, control } = useForm();
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Group");
  const { addNotification } = useNotification();
  const [group, setGroup] = useState(undefined);
  const [addOpen, setAddOpen] = useState(false);
  const [updateYoastHeadersOpen, setUpdateYoastHeadersOpen] = useState(false);

  const handleAddMember = (addedAgencies) => {
    updateMembers.mutate(
      {
        agencies: [
          ...groupAgencies.data.map((ag) => ag.id),
          ...addedAgencies.map((ag) => ag.id),
        ],
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: `Added ${addedAgencies.length > 1 ? "agencies" : "agency"
              } to group`,
          });
          setAddOpen(false);
        },
        onError: () =>
          addNotification({
            variant: "error",
            primaryText: `Failed to add ${addedAgencies.length > 1 ? "agencies" : "agency"
              } to group`,
          }),
      }
    );
  };

  const handleRemoveMember = (agencyId) => {
    updateMembers.mutate(
      {
        agencies: [
          ...groupAgencies.data
            .filter((ga) => ga.id !== agencyId)
            .map((a) => a.id),
        ],
      },
      {
        onSuccess: () => {
          addNotification({
            variant: "success",
            primaryText: "Removed agency from group",
          });
        },
        onError: () =>
          addNotification({
            variant: "error",
            primaryText: "Failed to remove agency from group",
          }),
      }
    );
  };

  const onSubmit = (data) => {
    let updatedPost = {
      ...group,
      name: data.name,
    };

    update.mutate(updatedPost, saveHandlers);

    reset({
      name: data.name,
    });

    setGroup(updatedPost);
  };

  const handleUpdateYoastHeaders = () => {
    updateYoastHeaders.mutate(null, saveHandlers);
  }

  const loadGroup = useCallback(
    (group) => {
      setGroup(group);
      reset({
        name: group.name,
      });
    },
    [reset]
  );

  useEffect(() => {
    if (!groupAgencies.isLoading && !groups.isLoading) {
      let group = groups.data.find((g) => g.id === groupId);
      loadGroup(group);
    }
  }, [groupAgencies.isLoading, groupAgencies.data, loadGroup]);

  const pages = [
    { to: "..", label: "Groups", current: false },
    {
      to: "",
      label: group ? group.name : "",
      current: true,
    },
  ];

  if (groupAgencies.isLoading || groups.isLoading || !group) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <ConfirmModal
          title="Update Yoast Group" message="Would you like to update the yoast group of all the agencies in this group?" confirmButtonText="Confirm"
          open={updateYoastHeadersOpen} setOpen={setUpdateYoastHeadersOpen} onConfirm={handleUpdateYoastHeaders}
        />
        <Flyout open={addOpen} setOpen={setAddOpen} title="Add Agencies to Group">
          <MemberAddPartial
            currentMembers={groupAgencies.data}
            handleAddGroup={handleAddMember}
          />
        </Flyout>
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />
          <div className="max-w-7xl">
            <div className="max-w-3xl">
              <div className="sm:flex-auto my-4">
                <h3 className="text-xl font-semibold text-gray-900">
                  {group.name}
                </h3>
                <p className="mt-2 text-sm text-gray-700">
                  Edit and add members to {group.name}
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Group Name
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("name")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="London group"
                    />
                  </div>
                </div>
                <div className="my-4">
                  <div className="">
                    <h3 className="text-md font-semibold text-gray-900 mb-4">
                      Members
                    </h3>
                    <div className="flex justify-between">
                      <button
                        type="button"
                        onClick={() => setAddOpen(true)}
                        className="flex items-center justify-center w-full md:w-auto px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                      >
                        <PlusIcon className="w-4 h-4 mr-2" />
                        Add Member
                      </button>
                      <button
                        type="button"
                        onClick={() => setUpdateYoastHeadersOpen(true)}
                        className="flex items-center justify-center w-full md:w-auto px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                      >
                        <RefreshIcon className="w-4 h-4 mr-2" />
                        Update all Yoast Groups
                      </button>
                    </div>
                  </div>
                  <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
                    {groupAgencies.data.isLoading ? (
                      <li
                        key="userInvites-loading"
                        className="py-4 flex justify-center"
                      >
                        <LoadingWheel width="w-8" height="h-8" />
                      </li>
                    ) : groupAgencies.data.length > 0 ? (
                      groupAgencies.data.map((member, i) => (
                        <MemberListRow
                          member={member}
                          key={i}
                          onRemoveClick={handleRemoveMember}
                        />
                      ))
                    ) : (
                      <li>
                        <div className="py-5 text-center text-sm text-gray-400">
                          No members
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
                <button
                  type="submit"
                  className="saveBlogEntry inline-flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                >
                  Save
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
