import { useState } from "react";
import { useNavigate } from "react-router-dom";
import date from "date-and-time";

import useNotification from "../notification/useNotifications";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import LoadingWheel from "../Shared/LoadingWheel";
import useReviewSites from "../../data/useReviewSites";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { qualifierEnum } from "../../constants/enums";
import ConfirmDelete from "../Shared/ConfirmDelete";
import PortalQualiferModal from "./PortalQualiferModal";
import useQualifiers from "../../data/useQualifiers";
import portalQualifiers from "../../stubs/portalQualifiers.json"
import usePortalQualifiers from "../../data/usePortalQualifiers";


function PortalQualiferListRow({ qualifier, setConfirmDeleteOpen, setDeleteId, handleUpdate }) {

  const handleDeleteClick = (e, qualifierId) => {
    e.stopPropagation();
    setDeleteId(qualifierId);
    setConfirmDeleteOpen(true);
  }

  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm hover:cursor-pointer hover:bg-gray-100" onClick={() => handleUpdate(qualifier)}>
      <div className="w-0 flex-1 flex flex-col items-start md:flex-row md:items-center">
        <p className="flex-shrink-0 text-sm font-medium text-etpink-600">
          {qualifierEnum[qualifier.siteName]} - {qualifier.name} {qualifier.lastDate ? `- ${date.format(new Date(qualifier.lastDate), "DD/MM/YYYY HH:mm")}` : ""}
        </p>
      </div>
      <div className="ml-4 flex-shrink-0 flex items-center -mr-2">
        <button
          onClick={(e) => handleDeleteClick(e, qualifier.id)}
          className="mx-2 text-red-400 hover:text-red-600"
        >
          <span className="sr-only">Remove</span>
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
    </li>
  );
}

export default function PortalQualifer({ agencyId }) {
  const { portalQualifiers, add, remove, update } = usePortalQualifiers(agencyId);
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Portal Qualifier Configuration");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [qualifier, setQualifier] = useState(undefined);
  const [isUpdate, setIsUpdate] = useState(false);

  const handleAddQualifier = (addedQualifier) => {
    add.mutate(addedQualifier, saveHandlers);
  };

  const handleRemoveQualifier = () => {
    remove.mutate(deleteId, deleteHandlers);
    setConfirmDeleteOpen(false);
  };

  const handleUpdateQualifier = (updateQualifier) => {
    // console.log(updateQualifier)
    update.mutate(updateQualifier, saveHandlers)
  }

  const handleUpdate = (qualifier) => {
    setIsUpdate(true);
    setQualifier(qualifier);
    setOpen(true);
  }

  const handleAdd = () => {
    setIsUpdate(false);
    setOpen(true);
  }

  return (
    <>
      <ConfirmDelete
        itemName="Portal Qualifier Configuration"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={handleRemoveQualifier}
      />
      <PortalQualiferModal
        open={open}
        setOpen={setOpen}
        qualifier={qualifier}
        isUpdate={isUpdate}
        handleAddQualifier={handleAddQualifier}
        handleUpdateQualifier={handleUpdateQualifier}
        agencyId={agencyId}
      />
      <div>
        <h3 className="text-xl font-medium text-gray-900">Portal Qualifiers & Enquiries</h3>
        <p className="mt-1 text-sm text-gray-500">
          These are the websites that we support connecting with
        </p>
        <button
          type="button"
          onClick={() => handleAdd()}
          className="my-6 flex items-center justify-center w-full md:w-auto px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
        >
          <PlusIcon className="w-4 h-4 mr-2" />
          Add Qualifier
        </button>
        <ul className="my-6 border border-gray-200 bg-white rounded-md divide-y divide-gray-200">
          {portalQualifiers.isLoading ? (
            <li key="userInvites-loading" className="py-4 flex justify-center">
              <LoadingWheel width="w-8" height="h-8" />
            </li>
          ) : portalQualifiers.data?.length > 0 ? (
            portalQualifiers.data.map((qualifier, i) => (
              <PortalQualiferListRow
                qualifier={qualifier}
                key={i}
                setConfirmDeleteOpen={setConfirmDeleteOpen}
                setDeleteId={setDeleteId}
                handleUpdate={handleUpdate}
              />
            ))
          ) : (
            <li>
              <div className="py-5 text-center text-sm text-gray-400">
                No Portal Qualifiers & Enquiries Configured
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}