import React, { useEffect, useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import LoadingWheel from "../../Shared/LoadingWheel";

const LiveValuations = ({ valuations, branchId = null }) => {
  const [graph, setGraph] = useState(null);
  const [valStats, setValStats] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    if (!valuations.isLoading) {
      var newGraph = <Doughnut id="areaChart" options={options} />;

      // var component = document.getElementById("areaChart");
      var component = document.createElement("canvas");
      var ctx = component.getContext("2d");

      const overallValStats = valuations.data.find((val) => val.branchId === branchId);
      setValStats(overallValStats);

      const data = getData(overallValStats);

      newGraph = React.cloneElement(newGraph, { data: data })

      setGraph(newGraph);
      setIsLoading(false);
    }
  }, [valuations.isLoading, valuations.data, branchId])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: 'top',
        display: false
      },
      title: {
        display: false,

      },
    }
  };

  const getData = (valStats) => {

    return {
      type: "donut",
      labels: ['Instant', 'In Person', 'Virtual'],
      datasets: [
        {
          data: [valStats?.instantValCount, valStats?.inPersonValCount, valStats?.virtualValCount],
          backgroundColor: [
            'rgb(119,221,119)',
            'rgb(54, 162, 235)',
            'rgb(246, 153, 63)'
          ],
          hoverOffset: 4
        },
      ],
    }
  };

  return (
    <div className="px-8 py-4 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Live Valuations</h1>

      <hr className="" />

      {valuations.isLoading || isLoading ? (
        <div className="flex p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <>
          <div className="mt-2 p-8">
            {!isLoading && graph}
          </div>

          <div className="text-base pb-4 divide-y">
            <p className="flex justify-between py-2">
              <span><span className="bg-[rgb(119,221,119)] h-3 w-3 rounded-full inline-block mr-5"></span>Instant</span>
              <span className="font-semibold">{valStats?.instantValCount}</span>
            </p>
            <p className="flex justify-between py-2">
              <span><span className="bg-[rgb(54,162,235)] h-3 w-3 rounded-full inline-block mr-5"></span>In Person</span>
              <span className="font-semibold">{valStats?.inPersonValCount}</span>
            </p>
            <p className="flex justify-between py-2">
              <span><span className="bg-[rgb(246,153,63)] h-3 w-3 rounded-full inline-block mr-5"></span>Virtual</span>
              <span className="font-semibold">{valStats?.virtualValCount}</span>
            </p>

            <p className="flex justify-between py-2">
              <span>Total</span>
              <span className="font-semibold">{valStats.virtualValCount + valStats.inPersonValCount + valStats.instantValCount}</span>
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default LiveValuations;