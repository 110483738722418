import { useState } from "react";
import LoadingWheel from "../Shared/LoadingWheel";

import { CDN_URL } from "../../services/config";
import useContentEngineMedia from "../../data/useContentEngineMedia";
import bytesToSize from "../../utils/bytesToSize";
import { useEffect } from "react";
import classNames from "../../utils/classNames";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

export default function AdminMediaManager({
  mediaType = "all",
  multiple = false,
  selected,
  onSelect,
}) {
  const { media: allMedia, markDeleted } = useContentEngineMedia();
  const { deleteHandlers } = useDefaultCRUDHandlers("Media");
  const [media, setMedia] = useState([]);

  useEffect(() => {
    if (!allMedia.isLoading) {
      switch (mediaType) {
        case "video":
          setMedia(allMedia.data.filter((am) => am.type.includes("video")));
          break;
        case "image":
          setMedia(allMedia.data.filter((am) => !am.type.includes("video")));
          break;
        case "all":
        default:
          setMedia(allMedia.data);
          break;
      }
    }
  }, [allMedia.data, allMedia.isLoading, allMedia.data, mediaType]);

  const handleDelete = (mediaToRemove) => {
    if (window.confirm("Are you sure you want to remove this piece of media?")) {
      markDeleted.mutate(mediaToRemove.id, deleteHandlers);
      media.filter((m) => m.id !== mediaToRemove.id);
    }
  }

  return (
    <>
      {allMedia.isLoading ? (
        <div className="py-6 flex justify-center">
          <LoadingWheel width="w-8" height="h-8" />
        </div>
      ) : media.length > 0 ? (
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
        >
          {media.map((m) => {
            let isSelected = selected
              ? multiple
                ? selected.some((s) => s.id === m.id)
                : selected.id === m.id
              : false;

            return (
              !m.dateDeleted &&
              <li key={m.id} className="relative">
                <div
                  onClick={() => onSelect(m)}
                  className={classNames(
                    "rounded-lg hover:bg-gray-200",
                    isSelected && "bg-etpink-200 hover:bg-etpink-300"
                  )}
                >
                  <div className="group block w-full text-center aspect-w-10 aspect-h-7 rounded-lg bg-gray-200 overflow-hidden max-h-[32rem] overflow-y-auto">
                    {m.type.includes("video") ? (
                      <video className="object-cover ml-auto mr-auto" controls>
                        <source src={`${CDN_URL}${m.path}`} type={m.type} />
                      </video>
                    ) : (
                      <img
                        src={`${CDN_URL}${m.path}`}
                        alt={m.altText}
                        className="object-cover ml-auto mr-auto"
                      />
                    )}
                  </div>

                  <p className="mt-2 px-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                    {m.name}
                  </p>
                  <div className="flex">
                    <div className="w-3/4">
                      <p className="block px-2 text-sm font-medium text-gray-600 pointer-events-none">
                        {m.altText}
                      </p>
                      <p className="block px-2 pb-2 text-sm font-medium text-gray-400 pointer-events-none">
                        {bytesToSize(m.size)}
                      </p>
                    </div>
                    <div className="p-1">
                      <button
                        type="button"
                        onClick={() => handleDelete(m)}
                        className="w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className="bg-white border rounded text-center text-gray-400 px-4 py-6">
          No Media
        </div>
      )}
    </>
  );
}
