import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useSocialPosts(agencyId) {
  const staleTime = 1000 * 60;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const socialPosts = useQuery(
    ["socialPosts", agencyId],
    () => get(`/socialPost/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (newPost) => post(`/socialpost/${agencyId}`, newPost),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["socialPosts", agencyId])
      },
    }
  );

  const update = useMutation(
    (post) => put(`/socialpost`, post),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["socialPosts", agencyId])
      },
    }
  );

  const reorder = useMutation(
    (values) => put(`/socialpost/${values.postId}/reorder?increase=${values.increase}`, null),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["socialPosts", agencyId])
      },
    }
  );

  const updateWebhook = useMutation(
    (body) => put(`/socialpost/${body.agencyId}/webhook`, body),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["socialPosts", agencyId])
      },
    }
  );

  const remove = useMutation(
    (postId) => del(`/socialpost/${postId}`),
    {
      onSuccess: (_, postId) => {
        queryClient.refetchQueries(["socialPosts", agencyId])
      },
    }
  );

  return {
    socialPosts,
    add,
    update,
    reorder,
    updateWebhook,
    remove
  };
}
