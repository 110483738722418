import { Fragment, useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Listbox, Switch, Transition } from "@headlessui/react";
import { CheckIcon, MinusCircleIcon, PlusIcon, SelectorIcon } from "@heroicons/react/outline";

import useAgencyBranches from "../../data/useAgencyBranches";
import useAgencyDepartments from "../../data/useAgencyDepartments";
import useAgencyPostcodes from "../../data/useAgencyPostcodes";
import useAgencyUsers from "../../data/useAgencyUsers";
import useNotificationAcitons from "../../data/useNotificationActions";
import { withAgency } from "../../data/withAgency";
import classNames from "../../utils/classNames";
import LoadingWheel from "../Shared/LoadingWheel";
import NotificationExclusionAddModal from "./NotificationExclusionAddModal";
import NotificationManualEmailAddModal from "./NotificationManualEmailAddModal";
import NotificationRecieverAddModal from "./NotificationRecipientAddModal";

function _NotificationPolicyAddEditPartial({
  agency,
  agencyReady,
  policy,
  onSave,
}) {
  const { notificationActions } = useNotificationAcitons();
  const { departments } = useAgencyDepartments(agency.id);
  const { postcodes } = useAgencyPostcodes(agency.id);
  const { branches } = useAgencyBranches(agency.id);
  const { users } = useAgencyUsers(agency.id);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [members, setMembers] = useState([]);
  const [excludedUsers, setExcludedUsers] = useState([]);
  const [manualEmails, setManualEmails] = useState([]);
  const [addMemberOpen, setAddMemberOpen] = useState(false);
  const [addExclusionOpen, setAddExclusionOpen] = useState(false);
  const [addManualEmailOpen, setAddManualEmailOpen] = useState(false);
  const [notificationEvents, setNotificationEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [enabled, setEnabled] = useState(false)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: policy.id,
      policyName: policy.policyName,
    },
  });

  useEffect(() => {
    if (!notificationActions.isLoading) {
      let naKeys = Object.keys(notificationActions.data);

      if (policy.notificationPolicyActions) {

        // If combined, remove and add sales and lettings objects
        if (policy.notificationPolicyActions.some(pa => pa.notificationActionType === 5)) {
          policy.notificationPolicyActions = policy.notificationPolicyActions.filter(pa => pa.notificationActionType !== 5);
          policy.notificationPolicyActions.push({ notificationActionType: 0 });
          policy.notificationPolicyActions.push({ notificationActionType: 1 });
        }

        setSelectedEvents(
          policy.notificationPolicyActions.map((pa) => ({
            label: naKeys[pa.notificationActionType],
            value: pa.notificationActionType,
          }))
        );

      }

      //Remove combined and form leads from the list
      naKeys.indexOf("Combined Valuation") > -1 && naKeys.splice(naKeys.indexOf("Combined Valuation"), 1);
      naKeys.indexOf("Form Leads") > -1 && naKeys.splice(naKeys.indexOf("Form Leads"), 1);

      setNotificationEvents(
        naKeys.map((na) => ({
          label: na,
          value: notificationActions.data[na],
        }))
      );
    }
  }, [notificationActions.isLoading, notificationActions.data, policy, policy.notificationPolicyActions]);

  useEffect(() => {
    if (policy.notificationPolicyRecipients) {
      setMembers(policy.notificationPolicyRecipients);
    }

    if (policy.notificationPolicyExcludedUsers) {
      setExcludedUsers(
        policy.notificationPolicyExcludedUsers.map((eu) => ({
          userId: eu.userId,
        }))
      );
    }

    if (policy.notificationPolicyManualEmails) {
      setManualEmails(
        policy.notificationPolicyManualEmails.map((me) => me.email)
      );
    }

    if (policy.branch) {
      setSelectedBranch(policy.branch);
    }

    if (policy.isActive) {
      setEnabled(policy.isActive)
    }
  }, [policy]);

  const save = (data) => {
    let toSave = {
      ...data,
      isActive: enabled,
      branchId: selectedBranch.id,
      recipients: members,
      excludedUsers: excludedUsers,
      manualEmails: manualEmails.map((me) => ({ email: me })),
      actions: selectedEvents.map((se) => ({ actionType: se.value })),
    };

    onSave(toSave);
  };

  const handleSelectedEvents = (event) => {
    if (selectedEvents.some((se) => se.value === event.value)) {
      setSelectedEvents((oldEvents) =>
        oldEvents.filter((oe) => oe.value !== event.value)
      );
    } else {
      setSelectedEvents((oldEvents) => [...oldEvents, event]);
    }
  };

  const addMember = (member) => {
    setMembers((oldMembers) => [...oldMembers, { type: 2, value: member.id }]);
    setAddMemberOpen(false);
  };

  const removeMember = (member) => {
    setMembers((oldMembers) =>
      oldMembers.filter((om) => om.value !== member.value)
    );
  };

  const addExcludedUsers = (users) => {
    setExcludedUsers((oldUsers) => [
      ...oldUsers,
      ...users.map((u) => ({ userId: u.userId })),
    ]);
    setAddExclusionOpen(false);
  };

  const removeExcludedUser = (user) => {
    setExcludedUsers((oldUsers) =>
      oldUsers.filter((ou) => ou.userId !== user.userId)
    );
  };

  const addManualEmail = (email) => {
    setManualEmails((oldEmails) => [...oldEmails, email]);
    setAddManualEmailOpen(false);
  };

  const removeManualEmail = (email) => {
    setManualEmails((oldEmails) => oldEmails.filter((oe) => oe !== email));
  };

  const getUserName = (userId) => {
    let user = users.data.find((u) => u.userId === userId);
    return `${user.user.firstName} ${user.user.surname}`;
  };

  if (
    !agencyReady ||
    postcodes.isLoading ||
    users.isLoading ||
    branches.isLoading
  ) {
    return (
      <div className="p-10 flex justify-center">
        <LoadingWheel width="w-8" height="h-8" />
      </div>
    );
  } else {
    return (
      <>
        <NotificationRecieverAddModal
          open={addMemberOpen}
          setOpen={setAddMemberOpen}
          onAddClick={addMember}
          branchId={selectedBranch.id}
        />
        <NotificationExclusionAddModal
          open={addExclusionOpen}
          setOpen={setAddExclusionOpen}
          onAddClick={addExcludedUsers}
          agencyId={agency.id}
          currentDepartments={members}
        />
        <NotificationManualEmailAddModal
          open={addManualEmailOpen}
          setOpen={setAddManualEmailOpen}
          onAddClick={addManualEmail}
        />
        <form onSubmit={handleSubmit(save)}>
          <div className="my-4 block">
            <label
              htmlFor="policyName"
              className="block text-sm font-medium text-gray-700"
            >
              Profile Name
            </label>
            <div className="mt-1">
              <input
                type="text"
                {...register("policyName", { required: true })}
                className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="New Notification Policy"
              />
            </div>
            {errors.name && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                Profile Name is required
              </p>
            )}
          </div>

          <div className="my-4 block">
            <h1 className="text-md">Selected Events: </h1>
            {!selectedEvents.length > 0 && <div className="pt-3">There are no events selected for this policy</div>}
            <ul className="grid grid-cols-3 gap-2 py-1 ml-4 list-disc">
              {selectedEvents.map((event, i) => (
                <li key={i} className="">
                  {event.label}
                </li>
              ))}
            </ul>

            <Listbox
              value={selectedEvents}
              onChange={handleSelectedEvents}
            >
              {({ open }) => (
                <>
                  <div className="mt-1 relative">
                    <Listbox.Button className={"bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"}>
                      <span className="block truncate">
                        Choose Event
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      appear={true}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {notificationEvents.map((e) => (
                          <Listbox.Option
                            key={e.value}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-etpink-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-8 pr-4"
                              )
                            }
                            value={e}
                          >
                            <>
                              <span
                                className={classNames(
                                  selectedEvents.some(
                                    (se) => se.value === e.value
                                  )
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {e.label}
                              </span>

                              {selectedEvents.some(
                                (se) => se.value === e.value
                              ) ? (
                                <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>

          </div>

          <div className="my-4 block">
            <Listbox value={selectedBranch} onChange={setSelectedBranch} disabled={Object.keys(policy).length > 0}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium text-gray-700">
                    Branch
                  </Listbox.Label>
                  <div className="mt-1 relative">
                    <Listbox.Button className={classNames(Object.keys(policy).length > 0 ? "bg-gray-300" : "bg-white",
                      "relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm")}>
                      <span className="block truncate">
                        {policy.policyName?.toLowerCase() !== "system default" ? (
                          selectedBranch ? (selectedBranch.name) : ("Choose...")
                        ) : ("All Branches")
                        }
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      appear={true}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {branches.data.map((b) => (
                          <Listbox.Option
                            key={b.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-etpink-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-8 pr-4"
                              )
                            }
                            value={b}
                          >
                            <>
                              <span
                                className={classNames(
                                  selectedBranch.id === b.id
                                    ? "font-semibold"
                                    : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {b.name}
                              </span>

                              {selectedBranch.id === b.id ? (
                                <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {selectedBranch && (
            <>
              <div className="my-4 block">
                <div className="flex items-end justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Departments
                  </label>
                  <button
                    onClick={() => setAddMemberOpen(true)}
                    type="button"
                    className="text-center w-full md:w-auto flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    <PlusIcon className="w-3 h-3 mr-1" />
                    Add
                  </button>
                </div>

                <div className="mt-2">
                  {departments.isLoading ? (
                    <div className="py-4 flex justify-center">
                      <LoadingWheel width="w-6" height="h-6" />
                    </div>
                  ) : (
                    <ul className="relative z-0 divide-y divide-gray-200 border rounded-lg">
                      {members.length > 0 ? (
                        members.map((member) => (
                          <li key={member.value} className="bg-white">
                            <div className="relative px-4 py-2 flex items-center space-x-3">
                              <div className="flex-1 min-w-0">
                                <p className="text-sm text-gray-900">
                                  {
                                    departments.data.find(
                                      (d) => d.id === member.value
                                    ).name
                                  }
                                </p>
                              </div>
                              <div className="flex-shrink-0">
                                <button
                                  onClick={() => removeMember(member)}
                                  className="px-1 py-1 flex items-center rounded text-red-500 hover:text-red-700"
                                >
                                  <MinusCircleIcon className="w-5 h-5" />
                                </button>
                              </div>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li className="py-3 text-center text-sm font-medium text-gray-400">
                          No Recepients
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              </div>

              <div className="my-4 block">
                <div className="flex items-end justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Excluded Users
                  </label>
                  <button
                    onClick={() => setAddExclusionOpen(true)}
                    type="button"
                    className="text-center w-full md:w-auto flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    <PlusIcon className="w-3 h-3 mr-1" />
                    Add
                  </button>
                </div>
                <div className="mt-2">
                  <ul className="relative z-0 divide-y divide-gray-200 border rounded-lg">
                    {excludedUsers.length > 0 ? (
                      excludedUsers.map((eu) => (
                        <li key={eu.id} className="bg-white">
                          <div className="relative px-4 py-2 flex items-center space-x-3">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm text-gray-900">
                                {getUserName(eu.userId)}
                              </p>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                onClick={() => removeExcludedUser(eu)}
                                className="px-1 py-1 flex items-center rounded text-red-500 hover:text-red-700"
                              >
                                <MinusCircleIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="py-3 text-center text-sm font-medium text-gray-400">
                        No Excluded Users
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              <div className="my-4 block">
                <div className="flex items-end justify-between">
                  <label className="block text-sm font-medium text-gray-700">
                    Manual Emails
                  </label>
                  <button
                    onClick={() => setAddManualEmailOpen(true)}
                    type="button"
                    className="text-center w-full md:w-auto flex items-center px-3 py-2 text-sm leading-4 font-medium rounded-md shadow-sm text-etpink-700 bg-etpink-100 hover:bg-etpink-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
                  >
                    <PlusIcon className="w-3 h-3 mr-1" />
                    Add
                  </button>
                </div>
                <div className="mt-2">
                  <ul
                    role="list"
                    className="relative z-0 divide-y divide-gray-200 border rounded-lg"
                  >
                    {manualEmails.length > 0 ? (
                      manualEmails.map((email) => (
                        <li key={email} className="bg-white">
                          <div className="relative px-4 py-2 flex items-center space-x-3">
                            <div className="flex-1 min-w-0">
                              <p className="text-sm text-gray-900">{email}</p>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                onClick={() => removeManualEmail(email)}
                                className="px-1 py-1 flex items-center rounded text-red-500 hover:text-red-700"
                              >
                                <MinusCircleIcon className="w-5 h-5" />
                              </button>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="py-3 text-center text-sm font-medium text-gray-400">
                        No Manual Emails
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </>
          )}

          <div className="mt-8 flex justify-between">
            {policy.policyName?.toLowerCase() !== "system default" ? (
              <button
                type="submit"
                className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
              >
                {getValues("id") ? "Save" : "Create"}
              </button>
            ) : (
              <span className="text-sm text-gray-500">Please contact support if you need the default policy email changed</span>
            )}

            <Switch.Group as="div" className="flex items-center">
              <Switch.Label as="span" className="ml-3">
                <span className="mr-2 text-sm font-medium text-gray-900">Enabled</span>
              </Switch.Label>
              <Switch
                checked={enabled}
                onChange={setEnabled}
                className={classNames(
                  enabled ? 'bg-etpink-600' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500'
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>

            </Switch.Group>
          </div>
        </form>
      </>
    );
  }
}

const NotificationPolicyAddEditPartial = withAgency(
  _NotificationPolicyAddEditPartial
);

export default NotificationPolicyAddEditPartial;
