import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function usePortalQualifiers(agencyId) {
  const staleTime = 10000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const portalQualifiers = useQuery(["portalQualifiers", agencyId],
    () => get(`/PortalQualifier/${agencyId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((portalQualifier) => post(`/PortalQualifier/${agencyId}`, portalQualifier), {
    onSuccess: () => {
      queryClient.invalidateQueries(["portalQualifiers", agencyId]);
    },
  });

  const update = useMutation((portalQualifier) => put(`/PortalQualifier/${agencyId}/${portalQualifier.id}`, portalQualifier), {
    onSuccess: (updatedPortalQualifier) => {
      queryClient.invalidateQueries(["portalQualifiers", agencyId]);
    },
  });

  const remove = useMutation((portalQualifierId) => del(`/PortalQualifier/${agencyId}/${portalQualifierId}`), {
    onSuccess: (_, portalQualifierId) => {
      queryClient.invalidateQueries(["portalQualifiers", agencyId]);
    },
  });

  return {
    portalQualifiers,
    add,
    update,
    remove
  };
}
