import useUserContext from "../../contexts/UserContext";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { useEffect } from "react";

export default function AdminHome() {
  const {
    user: { user },
  } = useUserContext();

  useEffect(() => {
    document.title = "EstateTrack Admin";
  });

  return (
    <div className="my-4 mx-0 sm:mx-4 p-2 sm:p-4 bg-white">
      <p className="mb-6 text-3xl font-semibold text-gray-900">
        Hello {user.firstName} {user.surname}
      </p>
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <CheckCircleIcon
              className="h-5 w-5 text-green-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-green-800">
              Everything looks good! Admin Dashboard Coming Soon...
            </p>

          </div>
        </div>
      </div>
    </div>
  );
}
