import React, { useEffect, useState } from "react";
import LoadingWheel from "../../Shared/LoadingWheel";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Doughnut, Line } from "react-chartjs-2";

const ValuationsReferrersPie = ({ stats }) => {
  const [graph, setGraph] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var newGraph = <Doughnut id="areaChart" options={options} />;

    // var component = document.getElementById("areaChart");
    var component = document.createElement("canvas");
    var ctx = component.getContext("2d");

    const data = getData(stats.referrers);

    newGraph = React.cloneElement(newGraph, { data: data })

    setGraph(newGraph);
    setIsLoading(false);
    // console.log("referrers finished")
  }, [stats])

  const options = {
    responsive: true,
    plugins: {
      legend: {
        // position: 'top',
        display: false
      },
      title: {
        display: false,

      },
    }
  };

  const getData = (unsorted) => {

    const stats = unsorted.sort((a, b) => (a.total < b.total) ? 1 : -1);

    const formattedData = stats.map((referrer) => {
      return referrer.total
    })

    const labels = stats.map((referrer) => {
      return referrer.referrerName
    })

    let bgColours = [];
    for (let i = 0; i < stats.length; i++) {
      bgColours.push(`rgb(${225 + i * 220 / stats.length},${i * 200 / stats.length},${200 + i * 200 / stats.length})`)
    }

    return {
      type: "donut",
      labels: labels,
      datasets: [
        {
          data: formattedData,
          backgroundColor: bgColours,
          hoverOffset: 4
        },
      ],
    }
  };

  return (
    <div className="px-8 py-4 w-full">
      <h1 className="text-base font-normal text-gray-800  lg:pt-2 mb-4">Valuation Referrers</h1>

      <hr className="" />

      {isLoading ? (
        <div className="flex p-10 bg-white justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      ) : (
        <>
          <div className="mt-2 p-8">
            {!isLoading && graph}
          </div>
        </>
      )}
    </div>
  )
}

export default ValuationsReferrersPie;
