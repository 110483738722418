import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useEstasBranches() {
  const staleTime = 1000000;
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const estasBranches = useQuery(["estasBranches"], () => get(`/ReviewSite/estas`).then((res) => res.data),
    {
      staleTime: staleTime,
    });

  return {
    estasBranches
  };
}
