import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useForm, useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import AiPromptModal from "../../components/Blog/AiPromptModal";
import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import CustomJoditEditor from "../../components/Shared/CustomJoditEditor";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import useAllMedia from "../../data/useAllMedia";
import useBlogPostCategories from "../../data/useBlogPostCategories";
import useBlogPosts from "../../data/useBlogPosts";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import classNames from "../../utils/classNames";
import PopOutBox from "../../components/Shared/PopOutBox";

export default function BlogPostAddEdit() {
  const { user: { agency }, } = useUserContext();
  const queryClient = useQueryClient();
  const { media } = useAllMedia();
  const { postId } = useParams();
  const { blogPosts, add, update } = useBlogPosts();
  const { categories } = useBlogPostCategories();
  const navigate = useNavigate();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm({
      defaultValues: {
        category: "",
        featuredMedia: null,
        excerpt: "",
        content: "",
        datePublished: date.format(new Date(), "YYYY-MM-DD HH:mm")
      },
    });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Blog post");
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [post, setPost] = useState(undefined);
  const [excerpt, setExcerpt] = useState("");
  const [content, setContent] = useState("");
  const [isDraft, setIsDraft] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [setup, setSetup] = useState(false);
  const [openAiContentModal, setOpenAiContentModal] = useState(false);
  const blogPreviewRef = useRef(null);

  const previewBlogEntry = () => {
    blogPreviewRef.current.setOpen(true);
  };

  const onSubmit = (data) => {
    // console.log("submitting", data);
    setIsLoading(true);
    if (postId) {
      update.mutate(
        {
          ...post,
          title: data.title,
          categoryId: data.category,
          mediaManagerId: data.featuredMedia,
          excerpt: excerpt,
          content: content,
          status: isDraft ? 3 : 0,
          datePublished: new Date(data.datePublished),
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            // navigate(`/${agency.logicalName}/blog`);
            setIsLoading(false);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    } else {
      add.mutate(
        {
          ...post,
          title: data.title,
          categoryId: data.category,
          mediaManagerId: data.featuredMedia,
          excerpt: excerpt,
          content: content,
          publish: !isDraft,
          datePublished: new Date(data.datePublished),
          metaTitle: data.metaTitle,
          metaDescription: data.metaDescription,
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            navigate(`/${agency.logicalName}/blog`);
            setIsLoading(false);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    }

    reset({
      title: data.title,
      category: data.category,
      featuredMedia: data.featuredMedia,
      excerpt: excerpt,
      content: content,
      datePublished: date.format(new Date(data.datePublished), "YYYY-MM-DD HH:mm"),
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
    });

    setPost({
      ...post,
      title: data.title,
      categoryId: data.category,
      groupId: data.group,
      mediaManagerId: data.featuredMedia,
      excerpt: excerpt,
      content: content,
      status: isDraft ? 3 : 0,
      datePublished: data.datePublished,
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
    });
  };

  const loadPost = useCallback(
    (post) => {
      setPost(post);

      setContent(post.content);
      setExcerpt(post.excerpt);

      //Failsafe
      var category;
      if (post.blogPostCategories.length > 0) {
        category = post.blogPostCategories[0].categoryId;
      } else {
        category = categories.data[0].id;
      }

      reset({
        title: post.title,
        //Just grabs the first category for now, need a new method for displaying which categories are being chosen
        category: category,
        group: post.groupId,
        featuredMedia: post.mediaManagerId,
        excerpt: post.excerpt,
        content: post.content,
        datePublished: date.format(new Date(post.datePublished), "YYYY-MM-DD HH:mm"),
        metaTitle: post.metaTitle,
        metaDescription: post.metaDescription,
      });
    },
    [reset]
  );

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("allMedia");

    setTimeout(() => {
      setValue("featuredMedia", media.id, { shouldDirty: true });
      setFeaturedMediaOpen(false);
    }, 1000);
  };

  useEffect(() => {
    if (!setup) {
      register("excerpt");
      register("content");
      register("featuredMedia");
      if (!blogPosts.isLoading && !categories.isLoading && !media.isLoading) {
        if (postId) {
          let post = blogPosts.data.find((bp) => bp.id === postId);
          loadPost(post);
        } else {
          setValue("category", categories.data[0].id);
        }

        setSetup(true);
      }
    }
  }, [
    blogPosts.isLoading,
    blogPosts.data,
    categories.isLoading,
    categories.data,
    media.isLoading,
    media.data,
    postId,
    loadPost,
    register,
    setValue,
  ]);

  const pages = [
    { to: "..", label: "Blog Posts", current: false },
    {
      to: postId && post ? `${postId}` : "",
      label: postId ? `${getValues("title")}` : "New Blog Post",
      current: true,
    },
  ];

  const categoryValue = watch("category");
  const featuredMediaValue = watch("featuredMedia");

  if (isLoading || blogPosts.isLoading || categories.isLoading || media.isLoading || categoryValue === "") {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          open={featuredMediaOpen}
          setOpen={setFeaturedMediaOpen}
          onInsert={handleFeaturedMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="flex justiy-between">
            <div className="sm:flex-auto my-4">
              <h3 className="text-xl font-semibold text-gray-900">
                {postId ? <span dangerouslySetInnerHTML={{ __html: getValues("title") }}></span> : "New Blog Post"}
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                {postId
                  ? <>View and edit <span dangerouslySetInnerHTML={{ __html: getValues("title") }}></span></>
                  : "Create a new blog post"}
              </p>
            </div>

            <div className="mt-8">
              {postId &&
                <a
                  href={post.postUrl}
                  target="_blank"
                  className="publishBlogEntry px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:cursor-pointer hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                >
                  View Blog Post
                </a>
              }
            </div>

          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="titleContainer my-4">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <input
                      {...register("title")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Blog Title"
                    />
                  </div>
                </div>

                <div className="flex mt-4 md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("category")}
                      value={categoryValue}
                      onChange={(v) => setValue("category", v.id)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                            Category
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-400 focus:border-etpink-400 sm:text-sm">
                              <span className="block truncate">
                                {
                                  categories.data.find(
                                    (c) => c.id === categoryValue
                                  ).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {categories.data.map((c) => (
                                  <Listbox.Option
                                    key={c.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={c}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("category") === c.id
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {c.name}
                                      </span>

                                      {getValues("category") === c.id ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label className="mb-2 block text-sm font-medium text-gray-700">
                      Published
                    </label>
                    <input {...register("datePublished")}
                      type="datetime-local"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>




                <div className="mt-8">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Excerpt
                  </label>
                  <CustomJoditEditor
                    content={excerpt}
                    setContent={setExcerpt}
                    height={165}
                  />
                </div>

                <div className="my-8">
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Content
                    </label>
                    {/* <button
                      className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-etpink-600 text-base font-medium text-white hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:col-start-2 sm:text-sm"
                      onClick={(e) => {e.preventDefault(); e.stopPropagation(); setOpenAiContentModal(true)}}
                    >
                      AI Blog Writer
                    </button> */}
                  </div>
                  <AiPromptModal
                    open={openAiContentModal}
                    setOpen={setOpenAiContentModal}
                    onGenerate={aiContent => setContent(aiContent)}
                  />
                  <CustomJoditEditor
                    content={content}
                    setContent={setContent}
                    height={260}
                  />
                </div>
              </div>

              <div className="mt-7">

                <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label className="mb-2 flex gap-x-2 text-sm font-medium text-gray-700">
                    Featured Image <PopOutBox classes="w-60">For best results images should be 1920x1080 pixels and no more than 200kb</PopOutBox>
                  </label>
                  <div className="border h-60">
                    {featuredMediaValue && !media.isLoading && media.data ? (
                      <img
                        src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                        className="h-full w-full object-contain"
                      />
                    ) : (
                      <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaOpen(true)}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    {!featuredMediaValue ? "Choose" : "Change"}
                  </button>
                </div>

                <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Meta Information
                  </label>
                  <div className="titleContainer my-4">
                    <input
                      {...register("metaTitle")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Meta Title"
                    />
                  </div>

                  <div className="titleContainer">
                    <textarea
                      {...register("metaDescription")}
                      type="text"
                      className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                      placeholder="Meta Description"
                      rows={3}
                    />
                  </div>
                </div>

                <div className="my-4 mx-auto">
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveBlogEntry px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishBlogEntry px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
