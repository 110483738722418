import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useActivityLogs() {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const {
    user: { agency },
  } = useUserContext();
  const { get, post, put, del } = useApiHelper();

//   const activityLogs = useQuery(["logging", agency.id], () => get(`/Logging/${agency.id}`).then((res) => res.data),
//     {
//       staleTime: staleTime,
//     }
//  );

 const recentActivityLogs = useQuery(["logging", agency.id, "recent"], () => get(`/Logging/${agency.id}/recent`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const actionTypes = useQuery(["logging", agency.id, "types"], () => get(`/Logging/${agency.id}/actiontypes`).then((res) => res.data),
    {
      staleTime: staleTime * 100,
    }
  );

  const actionSources = useQuery(["logging", agency.id, "sources"], () => get(`/Logging/${agency.id}/actionsources`).then((res) => res.data),
  {
    staleTime: staleTime * 100,
  }
);

//   const add = useMutation((review) => post(`/Review/${agency.id}`, review), {
//     onSuccess: (addedReview) => {
//       console.log(addedReview);
//       queryClient.setQueryData(["reviews", agency.id], (currentReviews) => [
//         addedReview.data,
//         ...currentReviews,
//       ]);
//     },
//   });

//   const update = useMutation(
//     (review) => put(`/Review/${agency.id}/${review.id}`, review),
//     {
//       onSuccess: (updatedReview) => {
//         queryClient.setQueryData(["reviews", agency.id], (currentReviews) =>
//           currentReviews.map((site) =>
//             site.id === updatedReview.id ? updatedReview : site
//           )
//         );
//       },
//     }
//   );

//   const remove = useMutation(
//     (reviewId) => del(`/Review/${agency.id}/${reviewId}`),
//     {
//       onSuccess: (_, reviewId) => {
//         queryClient.setQueryData(["reviews", agency.id], (currentReviews) =>
//           currentReviews.filter((site) => site.id !== reviewId)
//         );
//       },
//     }
//   );

  return {
    // activityLogs,
    recentActivityLogs,
    actionTypes,
    actionSources,
    // add,
    // update,
    // remove,
  };
}
