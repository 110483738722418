import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  EyeIcon,
  PencilAltIcon,
  SearchIcon,
  TrashIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";

import { postStatusEnum } from "../../constants/enums";
import useNotification from "../../components/notification/useNotifications";
import NoItems from "../../components/Shared/NoItems";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import RolesStub from "../../stubs/roles.json";
import BranchesStub from "../../stubs/branches.json";
import useTeamMembers from "../../data/useTeamMembers";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useAgencyBranches from "../../data/useAgencyBranches";
import useUserContext from "../../contexts/UserContext";
import BranchListBox from "../../components/Shared/BranchListBox";

function TeamMembersRow({ member, deleteTeamMember }) {
  const navigate = useNavigate();

  const handleDelete = (e) => {
    e.stopPropagation();
    deleteTeamMember(member.id);
  }

  return (
    <tr onClick={() => navigate(`member/${member.id}`)} className="hover:bg-gray-100 hover:cursor-pointer">
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
      <span dangerouslySetInnerHTML={{__html: member.name}}></span>
        <dl className="font-normal lg:hidden">
          <dt className="sr-only">Role</dt>
          <dd className="mt-1 truncate">
            {member.role}
          </dd>
          <dt className="sr-only sm:hidden">Branch</dt>
          {/* <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {member.branch.name}
          </dd> */}
          <dt className="sr-only sm:hidden">Status</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            <span
              className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${member.status === 3
                ? "text-yellow-600 bg-yellow-100"
                : "text-green-600 bg-green-100"
                }`}
            >
              {postStatusEnum[member.status]}
            </span>
          </dd>
        </dl>
      </td>
      <td className="hidden px-3 py-4 text-sm lg:table-cell">
        {member.role}
      </td>
      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
        <span
          className={`rounded-full px-5 py-1 whitespace-nowrap font-bold ${member.status === 3
            ? "text-yellow-600 bg-yellow-100"
            : "text-green-600 bg-green-100"
            }`}
        >
          {postStatusEnum[member.status]}
        </span>
      </td>
      {/* <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
      <span dangerouslySetInnerHTML={{__html: member.branch.name}}></span>
      </td> */}
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={() => navigate(`member/${member.id}`)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <PencilAltIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <button
            onClick={(e) => handleDelete(e)}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2  text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function TeamMembers() {
  const navigate = useNavigate();
  const { deleteHandlers } = useDefaultCRUDHandlers("Team member");
  const { teamMembers: allTeamMembers, remove } = useTeamMembers();
  const [teamMembers, setTeamMembers] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);
  const [branch, setBranch] = useState(null);

  const deleteTeamMember = (teamMember) => {
    setDeleteId(teamMember);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Portal | Team Members";
    if (!allTeamMembers.isLoading) {

      var filter = allTeamMembers.data.filter((member) =>
        member.name?.toLowerCase().includes(searchTerm.toLowerCase()) || 
        member.role?.toLowerCase().includes(searchTerm.toLowerCase())
      )

      if (branch) {
        filter = filter.filter((member) => {
          if (member.branch) {
            return member.branch.name?.toLowerCase().includes(branch.toLowerCase())
          }
        })
      }

      setTeamMembers(filter);
    }
  }, [allTeamMembers.isLoading, allTeamMembers.data, searchTerm, branch]);

  return (
    <>
      <ConfirmDelete
        itemName="Team Member"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">

        <div className="sm:flex sm:items-center">
          <div className="flex grow flex-col sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Team Members
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage your teams here.
            </p>
          </div>

          <div className="flex w-[13rem] mb-4 px-2">
            <BranchListBox setBranch={setBranch} />
          </div>

          <div>
            <div className="mt-2 flex rounded-md ">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  name="name"
                  id="name"
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Name or Role"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Link
              to="member"
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Team Member
            </Link>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {allTeamMembers.isLoading || !teamMembers ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : teamMembers.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Role
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Status
                  </th>
                  {/* <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Branch
                  </th> */}
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {teamMembers.map((tm) => (
                  <TeamMembersRow key={tm.id} member={tm} deleteTeamMember={deleteTeamMember} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              <NoItems
                itemName="team member"
                onNewClick={() => navigate("member")}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
