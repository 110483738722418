
export default function useFacebookSdk() {

  const initFacebookSdk = () => {
    return new Promise((resolve, reject) => {
      // Load the Facebook SDK asynchronously
      console.log("Loading Facebook SDK")
      if (window.FB) {
        window.FB.init({
          appId: '1498820034065136',
          // configId: '2390275227971349',
          cookie: true,
          xfbml: true,
          version: 'v19.0'
        });
        console.log("Facebook SDK loaded")
        // Resolve the promise when the SDK is loaded
        resolve();
      } else {
        console.error("Facebook SDK not loaded")
        reject();
      }
    });
  };

  const getFacebookLoginStatus = () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus((response) => {
        resolve(response);
      });
    });
  };

  const fbLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        console.log(response)
        resolve(response)
      }, {
        config_id: '800797808188233'
      })
    })
  };

  const fbMe = () => {
    return new Promise((resolve, reject) => {
      window.FB.api('/me', (response) => {
        console.log(response)
        resolve(response)
      })
    })
  };

  const reRequestPermissions = () => {
    return new Promise((resolve, reject) => {
      window.FB.login((response) => {
        console.log(response)
        resolve(response)
      }, {
        response_type: 'token',
        config_id: '800797808188233',
        auth_type: 'rerequest',
      })
    })
  };

  return {
    initFacebookSdk,
    getFacebookLoginStatus,
    fbLogin,
    fbMe,
    reRequestPermissions
  }

}

