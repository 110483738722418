import { useEffect, useState } from "react";

import date from "date-and-time";
import { useParams } from "react-router-dom";

import Notes from "../../components/Notes/Notes";
import AssignUser from "../../components/Shared/AssignUser";
import Avatar from "../../components/Shared/Avatar";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import SetFollowUp from "../../components/Shared/SetFollowUp";
import StatusDropdown from "../../components/Shared/StatusDropdown";
import { formLeadStatusEnum, formTypeEnum } from "../../constants/enums";
import useUserContext from "../../contexts/UserContext";
import useFormLeads from "../../data/useFormLeads";
import useForms from "../../data/useForms";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

export default function FormLeadView() {
  const { user: { agency } } = useUserContext();
  const { formLeads, update, updateNotes, removeNote } = useFormLeads();
  const { forms } = useForms();
  const { formLeadId } = useParams();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Form Lead Note");
  const [formLead, setFormLead] = useState(null);
  const [form, setForm] = useState(null);
  const [formImages, setFormImages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userOpen, setUserOpen] = useState(false)
  const [followUpOpen, setFollowUpOpen] = useState(false)

  const confirmDelete = (note) => {
    var updateData = {
      noteId: note.id,
      formDataId: formLeadId
    }

    removeNote.mutate(updateData, deleteHandlers);
  };

  const onSubmit = (data) => {
    // console.log("submitting", data);

    let mutateData = {
      formDataId: formLeadId,
      newNote: {
        note: data.note
      }
    };

    if (formLeadId) {
      updateNotes.mutate(mutateData, saveHandlers);
    }
  };


  useEffect(() => {
    if (!formLeads.isLoading && !forms.isLoading) {
      if (formLeadId) {
        let foundLead = formLeads.data.find((e) => e.id == formLeadId);
        let foundForm = forms.data.find((e) => e.id == foundLead.formId);

        if (foundLead && foundLead.images) {
          //split out the images from the string and regex to remove quotes
          let images = foundLead.images.replace("[", "").replace("]", "").split(",");
          images = images.map((image) => {
            return image.replace(/['"]+/g, '').trim();
          });

          setFormImages(images);
        }

        setFormLead(foundLead);
        setForm(foundForm);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [formLeads.isLoading, formLeads.data, forms.isLoading, forms.data, formLeadId, agency]);

  const pages = [
    { to: "..", label: "Form Leads", current: false },
    {
      to: formLeadId && formLead ? `${formLeadId}` : "",
      label:
        formLeadId && formLead
          ? `${formLead.fullName ?? "Client"}'s Lead`
          : "New Lead",
      current: true,
    },
  ];

  if (loading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <Breadcrumbs pages={pages} />
        <div className="flex justify-center mt-5">
          <div className="bg-white block w-full shadow overflow-hidden sm:rounded-lg">
            <div className="flex justify-between px-4 py-5 sm:px-6">
              <div className="flex items-center gap-x-2">
                <div className="flex flex-col">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">{formTypeEnum[form.type]} - {form.title}</h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and extra fields</p>
                  <p className="text-sm text-gray-500">{date.format(new Date(formLead.created), "DD/MM/YYYY HH:mm")}</p>
                  <p className="text-sm text-gray-500">Follow Up: {formLead.followUpDate ? date.format(new Date(formLead.followUpDate), "DD/MM/YYYY") : "None"}</p>
                </div>
                <Avatar classes="ml-2 w-10 h-10" userId={formLead.userId ?? ""} />
              </div>
              <div className="flex items-center gap-x-4">
                <StatusDropdown lead={formLead} statusEnum={formLeadStatusEnum} update={update} saveHandler={"Form Lead"} classes={"mb-6"} />
                <AssignUser open={userOpen} setOpen={setUserOpen} lead={formLead} update={update} saveHandler={"Form Lead"} />
                <SetFollowUp open={followUpOpen} setOpen={setFollowUpOpen} lead={formLead} update={update} saveHandler={"Form Lead"} classes={""} />
              </div>
            </div>


            <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Full name</dt>
                  <dd className="text-gray-900">{formLead.fullName}</dd>
                </div>
                <div className="sm:col-span-1 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Telephone Number</dt>
                  <dd className="text-gray-900">{formLead.telephoneNumber}</dd>
                </div>
                <div className="sm:col-span-1 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Email Address</dt>
                  <dd className="text-gray-900">{formLead.emailAddress}</dd>
                </div>
                <div className="sm:col-span-1 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Source</dt>
                  <dd className="text-gray-900">{formLead.source}</dd>
                </div>
                <div className="sm:col-span-1 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Source URL</dt>
                  <dd className="text-gray-900">{agency && formLead.sourceUrl?.replace(agency?.domain, "").replace("/landing/", "")}</dd>
                </div>
                <div className="sm:col-span-2 text-sm space-y-1">
                  <dt className="font-medium text-gray-500">Extra fields</dt>
                  {formLead.stringDataFormatted ? (
                    <dd className="text-gray-900 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: formLead.stringDataFormatted }}>
                    </dd>
                  ) : (
                    <dd className="text-gray-900 whitespace-pre-line">
                      {formLead.stringData}
                    </dd>
                  )}

                </div>
                {formImages && formImages.length > 0 && (
                  <div className="sm:col-span-2 text-sm space-y-1">
                    <dt className="font-medium text-gray-500">Images</dt>
                    <dd className="text-gray-900">
                      <div className="flex gap-x-2">
                        {formImages.map((image, i) => (
                          <img key={i} src={image} alt="Lead Image" className="w-48 object-contain" />
                        ))}
                      </div>
                    </dd>
                  </div>
                )}
              </dl>
            </div>

            <div className="w-full px-4 py-5 sm:px-6">
              <Notes notes={formLead.notes} submitFunction={onSubmit} deleteFunction={confirmDelete} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}