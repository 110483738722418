import { useEffect, useState } from "react";
import Tooltip from "../../components/Shared/Tooltips";
import { useNavigate } from "react-router-dom";

import {
  EyeIcon,
  PencilAltIcon,
  UserGroupIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { SortAscendingIcon } from "@heroicons/react/solid";

import LoadingWheel from "../../components/Shared/LoadingWheel";
import NoItems from "../../components/Shared/NoItems";
import ConfirmDelete from "../../components/Shared/ConfirmDelete";
import AddGroupModal from "../../components/Groups/GroupAddModal";

import useGroups from "../../data/useGroups";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

function GroupListRow({ group, onDeleteClick }) {
  const navigate = useNavigate();

  const membersString =
    group.agencyGroupCount === 0 || group.agencyGroupCount > 1
      ? "Members"
      : "Member";

  const handleEditClick = (e) => {
    e.stopPropagation();
    navigate(group.id);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDeleteClick(group.id);
  };

  return (
    <tr
      onClick={() => navigate(group.id)}
      className="hover:bg-gray-100 hover:cursor-pointer"
    >
      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
        {group.name}
        <dl className="font-normal lg:hidden">
          <dt className="sr-only sm:hidden">Published</dt>
          <dd className="mt-1 truncate text-gray-500 sm:hidden">
            {group.agencyGroupCount} {membersString}
          </dd>
        </dl>
      </td>
      <td className="px-3 py-4 text-sm text-gray-500">
        {group.agencyGroupCount} {membersString}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
          <button
            onClick={handleEditClick}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Edit</span>
            <Tooltip tip="Edit">
              <PencilAltIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Tooltip>
          </button>
          <button
            onClick={handleDeleteClick}
            type="button"
            className="-ml-px relative inline-flex items-center px-2 py-2  text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
          >
            <span className="sr-only">Delete</span>
            <Tooltip tip="Delete">
            <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Tooltip>
          </button>
        </span>
      </td>
    </tr>
  );
}

export default function Groups() {
  const navigate = useNavigate();
  const { groups: allGroups, remove } = useGroups();
  const { deleteHandlers } = useDefaultCRUDHandlers("Group");
  const [addOpen, setAddOpen] = useState(false);
  const [groups, setGroups] = useState(allGroups);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(undefined);

  const deleteGroup = (groupId) => {
    setDeleteId(groupId);
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = () => {
    setConfirmDeleteOpen(false);
    remove.mutate(deleteId, deleteHandlers);
  };

  useEffect(() => {
    document.title = "EstateTrack Admin | Groups";
    if (!allGroups.isLoading) {
      setGroups(
        allGroups.data.filter((groups) =>
          groups.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [allGroups.isLoading, allGroups.data, searchTerm]);

  return (
    <>
      <AddGroupModal open={addOpen} setOpen={setAddOpen} />
      <ConfirmDelete
        itemName="Group"
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={confirmDelete}
      />
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Groups</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all groups. You can edit and remove groups from here.
            </p>
          </div>
          <div>
            <div className="mt-2 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <UserGroupIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="name"
                  id="name"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="focus:ring-etpink-500 focus:border-etpink-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300"
                  placeholder="Group name"
                />
              </div>
              {/* <button
                type="button"
                className="-ml-px ml-2 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500"
              >
                <SortAscendingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Sort</span>
              </button> */}
            </div>
          </div>

          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              onClick={() => setAddOpen(true)}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-etpink-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-etpink-500 focus:ring-offset-2 sm:w-auto"
            >
              Add Group
            </button>
          </div>
        </div>
        <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          {groups.isLoading ? (
            <div className="flex p-10 bg-white justify-center">
              <LoadingWheel width="w-12" height="h-12" />
            </div>
          ) : groups.length > 0 ? (
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Members
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {groups.map((group) => (
                  <GroupListRow
                    key={group.id}
                    group={group}
                    onDeleteClick={deleteGroup}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="bg-white p-10">
              {searchTerm === "" ? (
                <NoItems
                  itemName="group"
                  onNewClick={() => navigate("/blog/designer")}
                />
              ) : (
                <NoItems searching itemName="group" />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
