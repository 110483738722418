import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useContentEngineLinkedPosts(postId) {
  const staleTime = 1000 * 30; //30 seconds
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const contentEngineLinkedPosts = useQuery(
    ["contentEngineLinkedPosts", postId],
    () => postId && get(`/contentengine/posts/linked/${postId}`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const createSinglePost = useMutation(
    (data) => post(`/contentengine/posts/${data.postId}/${data.agencyId}/single`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["contentEngineLinkedPosts", postId]);
      },
    }
  );

  return {
    contentEngineLinkedPosts,
    createSinglePost
  };
}
