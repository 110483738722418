import { useEffect, useState, Fragment } from "react";
import { Listbox, Combobox, Transition } from "@headlessui/react";

import { SelectorIcon, CheckIcon } from "@heroicons/react/outline";

import LoadingWheel from "../Shared/LoadingWheel";

import useGroups from "../../data/useGroups";
import classNames from "../../utils/classNames";

export default function GroupAddPartial({ currentGroups, handleAddGroup }) {
  const { groups: allGroups } = useGroups();
  const [filteredGroups, setFilteredGroups] = useState();
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!allGroups.isLoading) {
      let currentGroupIds = currentGroups.map((cg) => cg.id);

      let groups = allGroups.data.map((g) => {
        currentGroupIds.includes(g.id)
          ? (g.avaliable = false)
          : (g.avaliable = true);
        return g;
      });

      let filtered =
        searchTerm === ""
          ? groups
          : groups.filter((g) =>
              g.name.toLowerCase().includes(searchTerm.toLowerCase())
            );

      setFilteredGroups(filtered);
    }
  }, [allGroups.isLoading, allGroups.data, searchTerm]);

  if (allGroups.isLoading || !filteredGroups) {
    return (
      <div className="flex items-center">
        <LoadingWheel width="w-8" height="h-8" />
      </div>
    );
  } else {
    return (
      <>
        <div className="block">
          <Combobox
            value={selectedGroups}
            onChange={setSelectedGroups}
            multiple
          >
            {({ open }) => (
              <>
                <Combobox.Label className="block text-sm font-medium text-gray-700">
                  Groups
                </Combobox.Label>
                <div className="relative mt-1">
                  <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-etpink-500 focus:outline-none focus:ring-1 focus:ring-etpink-500 sm:text-sm"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    displayValue={(groups) =>
                      groups.map((group) => group.name).join(", ")
                    }
                  />
                  <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Combobox.Button>
                  <Transition
                    show={open}
                    appear={true}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options
                      static
                      className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {filteredGroups.length > 0 ? (
                        filteredGroups.map((group) => (
                          <Combobox.Option
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-etpink-600"
                                  : !group.avaliable
                                  ? "bg-gray-100 text-gray-400"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-8 pr-4"
                              )
                            }
                            key={group.id}
                            value={group}
                            disabled={!group.avaliable}
                          >
                            {({ active, selected }) => (
                              <>
                                <span
                                  className={classNames(
                                    "block truncate",
                                    selected && "font-semibold"
                                  )}
                                >
                                  {group.name}
                                </span>

                                {selected && (
                                  <span
                                    className={classNames(
                                      "absolute inset-y-0 left-0 flex items-center pl-1.5",
                                      active ? "text-white" : "text-etpink-600"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ))
                      ) : (
                        <Combobox.Option disabled className="cursor-default select-none relative py-2 text-center text-gray-500">
                            No groups match the search terms
                        </Combobox.Option>
                      )}
                    </Combobox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Combobox>
        </div>
        <div className="mt-8">
          <button
            type="button"
            onClick={() => handleAddGroup(selectedGroups)}
            className="text-center w-full md:w-auto items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500"
          >
            Add
          </button>
        </div>
      </>
    );
  }
}
