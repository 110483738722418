import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useContentEnginePosts() {
  const staleTime = 1000 * 60; //60 seconds
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const contentEnginePosts = useQuery(
    "contentEnginePosts",
    () => get("/contentengine/posts").then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation(
    (blogPost) => post("/contentengine/posts", blogPost),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("contentEnginePosts");
      },
    }
  );

  const update = useMutation(
    (post) => put(`/contentengine/posts/${post.id}`, post),
    {
      onSuccess: (updatedPost) => {
        queryClient.setQueryData("contentEnginePosts", (currentPosts) =>
          currentPosts.map((post) =>
            post.id === updatedPost.data.id ? updatedPost.data : post
          )
        );
      },
    }
  );

  const remove = useMutation(
    (postId) => del(`/contentengine/posts/${postId}`),
    {
      onSuccess: (_, postId) => {
        queryClient.setQueryData("contentEnginePosts", (currentPosts) =>
          currentPosts.filter((post) => post.id !== postId)
        );
      },
    }
  );

  return {
    contentEnginePosts,
    add,
    update,
    remove,
  };
}
