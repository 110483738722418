import { useMutation, useQuery, useQueryClient } from "react-query";
import useUserContext from "../contexts/UserContext";
import useApiHelper from "../services/useApiHelper";

export default function useReports(agency) {
  const staleTime = 600000;
  const queryClient = useQueryClient();

  const { get, post, put, del } = useApiHelper();

  const valuationsStats = useQuery(
    ["valuationStats", agency?.id],
    () => !agency ? null : get(`/Reports/${agency?.id}/valuationstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const portalStats = useQuery(
    ["portalStats", agency?.id],
    () => !agency ? null : get(`/Reports/${agency?.id}/portalstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const registrationStats = useQuery(
    ["registrationStats", agency?.id],
    () => !agency ? null : get(`/Reports/${agency?.id}/registrationstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const reviewStats = useQuery(
    ["reviewStats", agency?.id],
    () => !agency ? null : get(`/Reports/${agency?.id}/reviewstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const viewingStats = useQuery(
    ["viewingStats", agency?.id],
    () => !agency ? null : get(`/Reports/${agency?.id}/viewingstats`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  // const emailStats = useQuery(
  //   ["emailStats", agency?.id],
  //   () => get(`/Reports/${agency?.id}/emailStats`).then((res) => res.data),
  //   {
  //     staleTime: staleTime,
  //   }
  // );

  return {
    reviewStats,
    valuationsStats,
    portalStats,
    registrationStats,
    viewingStats,
    // emailStats
  };
}
