

export const JsonLineBreakConfig = ({ register }) => {

  return (
    <div className="w-full h-full grid grid-cols-2 divide-x-2">

      <div className="px-4 flex flex-col">

        <div className="space-y-6">

        </div>
      </div>

      <div className="px-4 flex flex-col space-y-6">

        <div className="w-full flex">
          <input
            type="checkbox"
            {...register("showLine")}
            className="w-5 h-5 mt-1 mr-2"
          />
          <label className="block mt-1 text-sm font-medium text-gray-700">
            Show line
          </label>
        </div>

      </div>

    </div>
  )
}