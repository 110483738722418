import { useEffect, useState } from "react";

import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

import SelectInput from "../Shared/Inputs/SelectInput";
import Modal from "../Shared/Modal";
import ThankYouMessage from './SubmissionOptions/ThankYouMessage';
import UrlRedirect from "./SubmissionOptions/UrlRedirect";


export default function SubmissionOptions({ options, register, getValues, setValue, watch }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    // console.log(getValues("submissionOption"));
  }, [])

  console.log(getValues("submissionOption"));

  return (
    <>
      <Modal open={tooltipOpen} setOpen={setTooltipOpen} width="max-w-4xl" >
        <div className="p-2">
          <h1 className="mb-2 text-lg font-medium">Submission Options</h1>
          <hr className="mb-4" />
          <p className="mb-2">Submission options allow you to customize what happens after a user submits your form.</p>
          <p className="mb-2">You can choose to display a thank you message, redirect the user to a URL, or upload a PDF for the user to receive upon form completion.</p>
        </div>
      </Modal>
      <div className="w-full col-span-3 bg-white shadow rounded-lg p-3 py-6">
        <div className="flex justify-between">
          <h1 className="text-lg font-medium">Submission Options</h1>
          <button type="button" onClick={() => setTooltipOpen(true)}>
            <QuestionMarkCircleIcon className="w-5 h-5" />
          </button>
        </div>
        <hr className="pb-6" />

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Submit Button Text
          </label>
          <div className="mt-1">
            <input
              {...register("submissionButtonText")}
              type="text"
              className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            ></input>
          </div>
        </div>

        <div className="mb-4">
          <SelectInput label="Option" register={register} registerName="submissionOption" options={options} setValue={setValue} watch={watch} />
        </div>

        {{
          0: <ThankYouMessage register={register} getValues={getValues} setValue={setValue} />,
          1: <UrlRedirect register={register} />,
          // 2: <UploadPdf getValues={getValues} setValue={setValue} />,
        }[getValues("submissionOption")]}

      </div>
    </>
  )
}