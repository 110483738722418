import { Fragment, useCallback, useEffect, useState } from "react";

import { useForm, useFormState } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

import InsertMediaModal from "../../components/MediaManager/InsertMediaModal";
import Breadcrumbs from "../../components/Shared/Breadcrumbs";
import { ConfirmNavigateAway } from "../../components/Shared/ConfirmNavigateAway";
import CustomJoditEditor from "../../components/Shared/CustomJoditEditor";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import useUserContext from "../../contexts/UserContext";
import useAgencyBranches from "../../data/useAgencyBranches";
import useAgencyMedia from "../../data/useAgencyMedia";
import useTeamMembers from "../../data/useTeamMembers";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { CDN_URL } from "../../services/config";
import classNames from "../../utils/classNames";

import "react-quill/dist/quill.snow.css";
import DepartmentSelect from "../../components/Department/DepartmentSelect";
import useAgencyDepartments from "../../data/useAgencyDepartments";


export default function TeamMemberAddEdit() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { media } = useAgencyMedia();
  const { user: { agency }, } = useUserContext();
  const { teamMemberId } = useParams();
  const { teamMembers, add, update } = useTeamMembers();
  const { departments } = useAgencyDepartments(agency.id);
  const { branches } = useAgencyBranches(agency.id);
  const { register, getValues, setValue, watch, handleSubmit, reset, control } = useForm({
    defaultValues: { branch: "", featuredMedia: null, order: "", description: "", department: 0 }
  });
  const { isDirty } = useFormState({ control });
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isDirty);
  const { saveHandlers } = useDefaultCRUDHandlers("Team Member");
  const [teamMember, setTeamMember] = useState(null);
  const [description, setDescription] = useState("");
  const [isDraft, setIsDraft] = useState(true);
  const [featuredMediaOpen, setFeaturedMediaOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (!teamMemberId) {
    setValue("order", 0);
  }

  const loadTeamMember = useCallback((member, department, branches) => {
    setTeamMember(member);

    setDescription(member.description);

    console.log("department", department)

    reset({
      ...member,
      branch: member.branchId,
      featuredMedia: member.mediaManagerId,
      department: department?.id != null ? department.id : 0
    });
  },
    [reset]
  );

  const onSubmit = (data) => {
    console.log("submitting", data);
    setIsLoading(true);
    let departmentName = null;

    if (data.department) {
      departmentName = departments.data.find((d) => d.id === data.department).name;
    }

    if (teamMemberId) {
      update.mutate(
        {
          ...teamMember,
          ...data,
          branchId: branchValue,
          description: description,
          status: isDraft ? 3 : 0,
          mediaManagerId: data.featuredMedia,
          department: departmentName
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            setIsLoading(false);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    } else {
      add.mutate(
        {
          ...teamMember,
          ...data,
          branchId: branchValue,
          description: description,
          publish: !isDraft,
          mediaManagerId: data.featuredMedia,
          department: departmentName
        },
        {
          onSuccess: () => {
            saveHandlers.onSuccess();
            reset();
            navigate(-1);
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    }

    reset({
      ...data,
      description: description,
    });

    setTeamMember({
      ...teamMember,
      ...data,
      branch: branchValue,
      description: description,
      status: isDraft ? 3 : 0,
      mediaManagerId: data.featuredMedia,
      department: departmentName
    });
  };

  const handleFeaturedMediaChange = (_, __, media) => {
    queryClient.refetchQueries("media");

    setTimeout(() => {
      setValue("featuredMedia", media.id, { shouldDirty: true });
      setFeaturedMediaOpen(false);
    }, 1000);
  };

  useEffect(() => {
    register("description");
    register("featuredMedia");
    if (!teamMembers.isLoading && !branches.isLoading && !departments.isLoading) {
      if (teamMemberId) {
        let foundMember = teamMembers.data.find((tm) => tm.id == teamMemberId);
        let foundDepartment = departments.data?.find((d) => d.name === foundMember.department && d.branchId === foundMember.branchId);
        loadTeamMember(foundMember, foundDepartment, branches);
      } else {
        setValue("branch", branches.data[0].id)
      }
    }
  }, [teamMembers.data, teamMembers.isLoading, branches.data, branches.isLoading, media.data, media.isLoading, departments.data, departments.isLoading, loadTeamMember])

  const pages = [
    { to: "..", label: "Team", current: false },
    {
      to: teamMemberId && teamMember ? `${teamMemberId}` : "",
      label: teamMemberId ? `${getValues("name")}` : "New Team Member",
      current: true,
    },
  ];

  const branchValue = watch("branch");
  const featuredMediaValue = watch("featuredMedia");

  if (teamMembers.isLoading || branches.isLoading || media.isLoading || branchValue === "" || isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mt-20 flex justify-center">
          <LoadingWheel width="w-20" height="h-20" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <InsertMediaModal
          allowVideo={false}
          allowURL={false}
          open={featuredMediaOpen}
          setOpen={setFeaturedMediaOpen}
          onInsert={handleFeaturedMediaChange}
        />
        <ConfirmNavigateAway
          show={showPrompt}
          onOK={confirmNavigation}
          onCancel={cancelNavigation}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="sm:flex-auto my-4">
            <h3 className="text-xl font-semibold text-gray-900">
              {teamMemberId ? teamMember.name : "New Team Member"}
            </h3>
            <p className="mt-2 text-sm text-gray-700">
              {teamMemberId
                ? `View and edit ${teamMember.name}'s details`
                : "Enter your new team members details below"}
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">
              <div className="grid col-span-1 lg:pr-10 lg:col-span-3">

                <div className="mt-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="FirstName"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="Name"
                        id="Name"
                        {...register("name")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Joe Bloggs"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="LastName"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Role
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="Role"
                        id="Role"
                        {...register("role")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Head of Sales"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="Email"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      E-Mail
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="Email"
                        id="Email"
                        {...register("emailAddress")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="joe.bloggs@email.co.uk"
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="PhoneNumber"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="PhoneNumber"
                        id="PhoneNumber"
                        {...register("phoneNumber")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="0712345678"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 block md:flex md:-mx-2">

                  <div className="w-full md:mt-0 md:w-1/2 md:mx-2">
                    <Listbox
                      {...register("branch")}
                      value={branchValue}
                      onChange={(b) => setValue("branch", b.id)}
                    >
                      {({ open }) => (
                        <>
                          <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
                            Branch
                          </Listbox.Label>
                          <div className="mt-1 relative">
                            <Listbox.Button className={"bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"}>
                              <span className="block truncate">
                                {
                                  branches.data.find(
                                    (b) => b.id === branchValue
                                  ).name
                                }
                              </span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </span>
                            </Listbox.Button>

                            <Transition
                              show={open}
                              appear={true}
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                {branches.data.map((b) => (
                                  <Listbox.Option
                                    key={b.id}
                                    className={({ active }) =>
                                      classNames(
                                        active
                                          ? "text-white bg-etpink-600"
                                          : "text-gray-900",
                                        "cursor-default select-none relative py-2 pl-8 pr-4"
                                      )
                                    }
                                    value={b}
                                  >
                                    <>
                                      <span
                                        className={classNames(
                                          getValues("branch") === b.id
                                            ? "font-semibold"
                                            : "font-normal",
                                          "block truncate"
                                        )}
                                      >
                                        {b.name}
                                      </span>

                                      {getValues("branch") === b.id ? (
                                        <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </>
                      )}
                    </Listbox>
                  </div>

                  <div className="w-full md:w-1/2 md:mx-2">
                    <DepartmentSelect agencyId={agency.id} branch={branchValue} register={register} setValue={setValue} watch={watch} />
                  </div>
                </div>

                <div className="mt-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="CustomLink"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Custom Link
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="CustomLink"
                        id="CustomLink"
                        {...register("customLink")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-full mt-4 md:mt-0 md:w-1/2 md:mx-2">
                    <label
                      htmlFor="CusromLinkButtonText"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Custom Link Button Text
                    </label>
                    <div className="mt-1">
                      <input
                        type="Text"
                        name="CustomLinkButtonText"
                        id="CustomLinkButtonText"
                        {...register("customLinkButtonText")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 block md:flex md:-mx-2">
                  <div className="w-full md:w-1/2 md:mx-2">
                    <label
                      htmlFor="order"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Site Order
                    </label>
                    <div className="mt-1">
                      <input
                        type="number"
                        name="Order"
                        id="Order"
                        min={0}
                        {...register("order")}
                        className="shadow-sm focus:ring-etpink-500 focus:border-etpink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="Order number"
                      />
                    </div>
                  </div>

                  <div className="w-full md:w-1/2 md:mx-2"></div>
                </div>

                <div className="my-4">
                  <label
                    htmlFor="Description"
                    className="block mb-2 text-sm font-medium text-gray-700"
                  >
                    Bio/Description
                  </label>
                  <CustomJoditEditor
                    content={description}
                    setContent={setDescription}
                    height={260}
                  />
                </div>

              </div>

              <div className="mt-7">

                <div className="my-4 bg-white rounded-md shadow-sm p-4">
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Featured Image
                  </label>
                  <div className="border h-60">
                    {featuredMediaValue && !media.isLoading && media.data ? (
                      <img
                        src={`${CDN_URL}` + `${media.data.find((m) => m.id === featuredMediaValue)?.path}`}
                        className="h-full w-full object-contain"
                      />
                    ) : (
                      <div className="w-3/4 mx-auto mt-20 text-gray-400 text-center text-sm">Choose an image below</div>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => setFeaturedMediaOpen(true)}
                    className="mt-2 w-full px-3 py-3 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    {!featuredMediaValue ? "Choose" : "Change"}
                  </button>
                </div>

                <div className="my-[3.75rem] mx-auto">
                  <button
                    type="submit"
                    onClick={() => setIsDraft(true)}
                    className="saveTeamMember px-3 py-3 w-full mb-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Save as draft
                  </button>
                  <br />
                  <button
                    type="submit"
                    onClick={() => setIsDraft(false)}
                    className="publishTeamMember px-3 py-3 w-full border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-etpink-600 hover:bg-etpink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-400"
                  >
                    Publish
                  </button>
                </div>

              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
