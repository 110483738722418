import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { Doughnut, Line } from "react-chartjs-2";

export default function YearGraph({ backgroundColor, borderColor, data, newOptions = null }) {
  const [graph, setGraph] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var newGraph = <Line id="areaChart" options={newOptions ?? options} className="py-6 px-6" />;

    // var component = document.getElementById("areaChart");
    var component = document.createElement("canvas");
    var ctx = component.getContext("2d");

    var gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(255, 73, 119,0.8)');
    gradient.addColorStop(0.8, 'rgba(255, 73, 119,0)');

    const data = getData(gradient);

    newGraph = React.cloneElement(newGraph, { data: data })

    setGraph(newGraph);
    setIsLoading(false);
  }, [data])

  const labels = Object.keys(data);
  const clicksPerMonth = Object.values(data);

  const options = {
    responsive: true,
    layout: {
      padding: {
        top: 2,
        right: 2,
        left: 2,
      }
    },
    plugins: {
      legend: {
        // position: 'top',
        display: false
      },
      title: {
        display: false
      },
    },
    interaction: {
      intersect: false,
      mode: "index"
    },

    elements: {
      point: {
        radius: 0
      },
    },
    scales: {
      x: {

        grid: {
          drawBorder: false,
          display: false
        },
        ticks: {

          display: false
        }
      },
      y: {

        grid: {
          drawBorder: false,
          display: false
        },
        ticks: {
          display: false
        }
      }
    }
  };

  const getData = (gradient) => {

    return {
      type: "area",
      labels,
      datasets: [
        {
          fill: true,

          lineTension: 0.3,
          data: clicksPerMonth,
          borderColor: borderColor,
          backgroundColor: backgroundColor,
        },
      ],
    }
  };

  return (
    <div className="px-0 py-0 w-full">

      <div className="mt-2">
        {!isLoading && graph}
      </div>
    </div>
  )
}