import { useEffect } from "react";
import { Link } from 'react-router-dom';
import date from "date-and-time";
import { ClockIcon, ExclamationCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/solid";

import useServices from "../../data/useServices";
import LoadingWheel from "../../components/Shared/LoadingWheel";
import { logStatusEnum } from "../../constants/enums";

export default function ServiceHealth() {
  const { services } = useServices();

  useEffect(() => {
    document.title = "EstateTrack Admin | Service Health";
  }, [services.data, services.isLoading])

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex-auto ml-2 mb-4">
          <h1 className="text-xl font-semibold text-gray-900">
            Running Services
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            View and manage your services
          </p>
        </div>

        {services.isLoading ? (
          <div className="flex p-10 bg-white justify-center">
            <LoadingWheel width="w-12" height="h-12" />
          </div>
        ) : (
          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {services.data.map((service) => (
                <li key={service.id}>
                  <Link 
                  to={`${service.id}`}
                  className="block hover:bg-gray-50">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="text-sm font-medium text-etpink-600 truncate">
                              {service.serviceName}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <ClockIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                Next Run: {date.format(new Date(service.nextRun), "DD/MM/YYYY HH:mm")}
                              </span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              
                              {service.updated ? (
                                <p className="text-sm text-gray-900">
                                Last run on{" "}
                                <time dateTime={service.updated}>
                                  {date.format(new Date(service.updated), "DD/MM/YYYY HH:mm")}
                                </time>
                              </p>
                              ): (
                                <p className="text-sm text-gray-900">This service hasn't run yet!</p>
                              )}
                              
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                {service.status === 3 && (
                                  <ExclamationCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                    aria-hidden="true"
                                  />
                                )}
                                {service.status === 2 && (
                                  <CheckCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                    aria-hidden="true"
                                  />
                                )}
                                {service.status === 1 && (
                                  <ClockIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400"
                                    aria-hidden="true"
                                  />
                                )}
                                {service.status === 0 && (
                                  <MinusCircleIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}
                                
                                {service.status !== 3 ? (logStatusEnum[service.status]) :
                                (
                                  `${logStatusEnum[service.status]}`
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
