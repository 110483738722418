import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useDefaultPolicies() {
  const staleTime = 1000 * 60; // 1 minute
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const policies = useQuery("policies",
    () => get("/policy").then((res) => res.data),
    {
      staleTime: staleTime,
    });

  const updatePrivacy = useMutation((body) => put(`/policy/privacy`, body), {
    onSuccess: () => {
      queryClient.refetchQueries("policies");
    },
  });

  const updateTerms = useMutation((body) => put(`/policy/terms`, body), {
    onSuccess: () => {
      queryClient.refetchQueries("policies");
    },
  });

  const updateCookies = useMutation((body) => put(`/policy/cookies`, body), {
    onSuccess: () => {
      queryClient.refetchQueries("policies");
    },
  });

  return {
    policies,
    updatePrivacy,
    updateTerms,
    updateCookies,
  };
}
